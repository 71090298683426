import { faFile, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Card } from "react-bootstrap";
import { IImage } from "../../../models/image";
import { getRequest } from "store/actions/request";

export interface IProps {
  images: IImage[];
  viewFile: (image: IImage) => any;
  clickedViewFile?: (value: boolean) => any;
  removeImage?: (image: IImage) => any;
  canTrash?: boolean;
  bg?: string;
}

const QcCheckpointAttachments = (props: IProps) => {
  const {
    images,
    viewFile,
    clickedViewFile,
    removeImage,
    canTrash,
    bg,
  } = props;

  const download = (image: IImage) => {
    getRequest(`/images/show/${image.id}`).then((response) => {
      if (response.status !== 200) {
        return;
      }

      const data = response.json;
      if (data === null) return;
      fetch(data)
        .then((res) => res.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          const title = image?.name ?? "undefined";

          const save = document.createElement("a");
          save.href = blobUrl;
          save.target = "_blank";
          save.download = title;

          if (
            navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
            navigator.userAgent.search("Chrome") < 0
          ) {
            window.open(blobUrl, "_blank");
          } else {
            var evt = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });
            save.dispatchEvent(evt);
            (window.URL || window.webkitURL).revokeObjectURL(save.href);
          }
          save.remove();
        });
    });
  };

  return (
    <div className="d-flex" style={{ flexDirection: "column" }}>
      {images?.map((x) => {
        return (
          <Card key={`checkpoint-image-${x.id}`} bg={bg ?? "secondary"}>
            <Card.Body className="m-0 pt-1 pb-2">
              <div className="mb-1">
                <FontAwesomeIcon icon={faFile} /> {x.name}
              </div>
              <div>
                {x.name.includes("pptx") || x.name.includes("docx") ? (
                  <Button
                    onClick={() => {
                      download(x);
                    }}
                  >
                    Download
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      viewFile(x);
                      if (clickedViewFile) clickedViewFile(true);
                    }}
                  >
                    View File
                  </Button>
                )}
                {x.uid !== undefined && (
                  <span className="ms-2" style={{ color: "red" }}>
                    NEW!
                  </span>
                )}
                {canTrash && (
                  <Button
                    disabled={x.uid !== undefined}
                    variant="danger"
                    className="float-end"
                    onClick={() => {
                      if (removeImage) removeImage(x);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                )}
              </div>
            </Card.Body>
          </Card>
        );
      })}
    </div>
  );
};

export default QcCheckpointAttachments;
