import {
  OrderLineActionTypes,
  FETCH_ORDERLINES_SUCCESS,
  FETCH_FILTERED_ORDERLINES_SUCCESS,
  ORDERLINE_REQUEST_FAILURE,
  FETCH_ORDERLINE_SUCCESS,
  ORDERLINE_REQUEST_ERRORS,
  CREATE_ORDERLINE_SUCCESS,
  DELETE_ORDERLINE_SUCCESS,
  EDIT_ORDERLINE_SUCCESS,
} from "../actions/orderlines";
import { IOrderLine } from "../../models/orderline";
import { DataList } from "../../utils/DataList";

export interface OrderLineState {
  readonly orderLines: IOrderLine[];
  readonly filteredOrderLines: DataList<IOrderLine>;
  readonly orderLine: IOrderLine | null;
  readonly errors: any;
}

export const initialState: OrderLineState = {
  orderLines: [],
  filteredOrderLines: { data: [], total: 0 },
  orderLine: null,
  errors: null,
};

const orderLinesReducer = (
  state = initialState,
  action: OrderLineActionTypes
): OrderLineState => {
  switch (action.type) {
    case FETCH_ORDERLINES_SUCCESS:
      return { ...state, orderLines: action.orderLines };
    case FETCH_FILTERED_ORDERLINES_SUCCESS:
      return { ...state, filteredOrderLines: action.orderLines };
    case ORDERLINE_REQUEST_FAILURE:
      return { ...state, errors: action.errors };
    case FETCH_ORDERLINE_SUCCESS:
      return { ...state, orderLine: action.orderLine };
    case ORDERLINE_REQUEST_ERRORS:
      return { ...state, errors: action.errors };
    case CREATE_ORDERLINE_SUCCESS:
      return { ...state, orderLine: action.orderLine };
    case DELETE_ORDERLINE_SUCCESS:
      return { ...state, orderLine: null };
    case EDIT_ORDERLINE_SUCCESS:
      return { ...state, orderLine: action.orderLine };
    default:
      return state;
  }
};

export default orderLinesReducer;
