import React, { useState } from "react";
import { faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "react-bootstrap";
import { IUser, isAdmin } from "models/user";
import { IQcCheckpoint } from "models/QualityControl/qcCheckpoint";

export interface IProps {
  id?: number;
  text?: string;
  isNew?: boolean;
  disabled?: boolean;
  currentUser: IUser;
  addedCheckpoint?: IQcCheckpoint;
  onUpdate?: (id: number, text: string) => void;
  onRemove?: (id: number) => void;
  onAdd?: (text: string) => void;
}

const AddCheckpointInput: React.FC<IProps> = (props: IProps) => {
  const {
    id,
    text,
    isNew,
    disabled,
    currentUser,
    addedCheckpoint,
    onUpdate,
    onRemove,
    onAdd,
  } = props;

  const [checkpoint, setCheckpoint] = useState(text ?? "");

  return (
    <div className="d-inline-flex p-1">
      {!isNew &&
        (currentUser?.id === addedCheckpoint?.createdById ||
          isAdmin(currentUser)) && (
          <Button
            variant="danger"
            className="me-1"
            onClick={() => {
              if (onRemove && id) onRemove(id);
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        )}
      <Input
        onChange={(e) => {
          setCheckpoint(e.value);
          if (onUpdate && id) onUpdate(id, e.value);
        }}
        value={checkpoint}
        style={{ width: "100%" }}
        placeholder={"Checkpoint Name"}
        readOnly={disabled}
      />
      {isNew && !disabled && (
        <Button
          variant="success"
          className="ms-1"
          disabled={disabled}
          onClick={() => {
            if (onAdd && checkpoint !== "") {
              onAdd(checkpoint);
              setCheckpoint("");
            }
          }}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      )}
    </div>
  );
};

export default AddCheckpointInput;
