import { IUser } from "./user";
import { IProductImage } from "./image";
import { IJig } from "./jig";
import { IIssue } from "./issue";
import { IImage } from "./image";
import { IQcCheckpoint } from "./QualityControl/qcCheckpoint";

export interface IProduct {
  productNo: string;
  descNO: string;
  descEN: string;
  needsApproval: boolean;
  weldInspectionRequired: boolean;
  defaultStatus: number;
  requiredTrainingDays: number;
  createdAt: Date;
  updatedAt: Date;
  images: IProductImage[];
  kitImages: IImage[];

  jigs: IJig[];
  issues: IIssue[];
  productUsers: IProductUser[];
  notificationUsers: IUser[];
  checkpoints: IQcCheckpoint[];

  code: string;
  completedStation: string;
  element: string;
  materialEn: string;
  materialNo: string;
  quality: number;
  revision: string;
  format: string;
  subStructure: number;
  parentProduct: string;
  parentProductStructures: IProductStructure[];
  childrenProductStructures: IProductStructure[];
  subject: string;
  stockBalance: IStockBalance;
  shpBal: IShpBal;
  location: string;
}

export interface IProductUser {
  product: IProduct;
  productNo: string;
  user: IUser;
  userId: number;
  trainingUserTypeId: number;
  expiresAt: Date;
}

export interface IProductStructure {
  productNo: string;
  desc: string;
  qty: number;
  images: IProductImage[];
  parentProductStructures: IProductStructure[];
  childrenProductStructures: IProductStructure[];
  revision: string;
  expanded: boolean;
}

export interface IStockBalance {
  productNo: string;
  normalLocation: string;
  stcNo: number;
  bal: number;
  stcInc: number;
}

export interface IShpBal {
  productNo: string;
  shpNo: number;
  stcNo: number;
  loc: string;
}

export function getProductLanguage(user: IUser, product: IProduct) {
  var lang = user.userPrefs[0].lang;
  switch (lang) {
    case "no":
    default:
      return product.descNO;
    case "en":
      if (product.descEN === "" || product.descEN === " ")
        return product.descNO;
      return product.descEN;
  }
}

export function getDescFieldName(user: IUser) {
  var lang = user.userPrefs[0].lang;
  switch (lang) {
    case "no":
    default:
      return "descNO";
    case "en":
      return "descEN";
  }
}
