const CheckpointGridColumns = [
  {
    title: "No",
    show: true,
    qc: true,
    tt: true,
    case: true,
    jig: true,
  },
  {
    title: "Name",
    show: true,
    qc: true,
    tt: true,
    case: true,
    jig: true,
  },
  {
    title: "Qc Products",
    show: true,
    qc: true,
    tt: false,
    case: false,
    jig: false,
  },
  {
    title: "Attribute Values",
    show: true,
    qc: true,
    tt: false,
    case: false,
    jig: false,
  },
  {
    title: "Station",
    show: true,
    qc: true,
    tt: false,
    case: false,
    jig: false,
  },
  {
    title: "Tags",
    show: true,
    qc: false,
    tt: false,
    case: true,
    jig: false,
  },
  {
    title: "Jigs",
    show: true,
    qc: false,
    tt: false,
    case: false,
    jig: true,
  },
  {
    title: "Product Nos",
    show: true,
    qc: false,
    tt: true,
    case: false,
    jig: false,
  },
  {
    title: "Product Qc",
    show: true,
    qc: false,
    tt: true,
    case: false,
    jig: false,
  },
  {
    title: "Attachments",
    show: true,
    qc: true,
    tt: true,
    case: true,
    jig: true,
  },
  {
    title: "Training",
    show: true,
    qc: true,
    tt: false,
    case: false,
    jig: false,
  },
  {
    title: "Position",
    show: true,
    qc: true,
    tt: true,
    case: true,
    jig: true,
  },
  {
    title: "Version",
    show: true,
    qc: true,
    tt: true,
    case: true,
    jig: true,
  },
] as ICheckpointColumns[];

export interface ICheckpointColumns {
  title: string;
  show: boolean;
  qc: boolean;
  tt: boolean;
  case: boolean;
  jig: boolean;
}

export default CheckpointGridColumns;
