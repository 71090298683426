import {
  LinkActionTypes,
  FETCH_LINKS_SUCCESS,
  FETCH_LINK_SUCCESS,
  CREATE_LINK_SUCCESS,
  EDIT_LINK_SUCCESS,
  DELETE_LINK_SUCCESS,
  LINK_REQUEST_ERRORS,
  LINK_REQUEST_FAILURE,
} from "../actions/links";
import { ILink } from "../../models/link";

export interface LinkState {
  readonly links: ILink[];
  readonly link: ILink | null;
  readonly errors: any;
}

export const initialState: LinkState = {
  links: [],
  link: null,
  errors: null,
};

const linkReducer = (
  state = initialState,
  action: LinkActionTypes
): LinkState => {
  switch (action.type) {
    case FETCH_LINKS_SUCCESS:
      return { ...state, links: action.links };
    case FETCH_LINK_SUCCESS:
      return { ...state, link: action.link };
    case CREATE_LINK_SUCCESS:
      return {
        ...state,
        link: action.link,
        links:
          state.links.length > 0
            ? [...state.links.concat([action.link])]
            : [action.link],
      };
    case EDIT_LINK_SUCCESS:
      if (state.links.length > 0) {
        state.links.splice(
          state.links.findIndex((t) => t.id === action.link.id),
          1,
          action.link
        );
      }
      return {
        ...state,
        link: action.link,
        links: state.links.length > 0 ? state.links : [action.link],
      };
    case DELETE_LINK_SUCCESS:
      return { ...state, link: null };
    case LINK_REQUEST_ERRORS:
      return { ...state, errors: action.errors };
    case LINK_REQUEST_FAILURE:
      return { ...state, errors: action.errors };
    default:
      return state;
  }
};

export default linkReducer;
