import { getRequest, putRequest } from "../request";
import { Dispatch } from "redux";
import {
  IChecklistInspections,
  IPrevChecklist,
  IQcChecklist,
} from "../../../models/QualityControl/qcChecklist";
import { IQcChassi } from "models/QualityControl/qcChassis";
import { saveFailed, saveSuccess } from "../alerts";
import { IQcInspection } from "models/QualityControl/qcInspection";
import { IStationProgress } from "models/training";

export const FETCH_QC_CHECKLIST_SUCCESS = "FETCH_QC_CHECKLIST_SUCCESS";
export const FETCH_CHECKLIST_INSPECTIONS_SUCCESS =
  "FETCH_CHECKLIST_INSPECTIONS_SUCCESS";
export const FETCH_PREVIOUS_CHECKLIST_SUCCESS =
  "FETCH_PREVIOUS_CHECKLIST_SUCCESS";
export const EDIT_QC_CHECKLIST_SUCCESS = "EDIT_QC_CHECKLIST_SUCCESS";
export const EDIT_CHECKLIST_CHECKPOINT_SUCCESS =
  "EDIT_CHECKLIST_CHECKPOINT_SUCCESS";
export const FETCH_QC_CHECKLIST_PROGRESS_SUCCESS =
  "FETCH_QC_CHECKLIST_PROGRESS_SUCCESS";
export const QC_CHECKLIST_REQUEST_FAILURE = "QC_CHECKLIST_REQUEST_FAILURE";
export const QC_CHECKLIST_REQUEST_ERROR = "QC_CHECKLIST_REQUEST_ERROR";

export interface QcChecklistActions {
  FETCH_QC_CHECKLIST_SUCCESS: {
    type: typeof FETCH_QC_CHECKLIST_SUCCESS;
    qcChecklist: IQcChecklist;
  };
  FETCH_CHECKLIST_INSPECTIONS_SUCCESS: {
    type: typeof FETCH_CHECKLIST_INSPECTIONS_SUCCESS;
    inspections: IChecklistInspections;
  };
  FETCH_PREVIOUS_CHECKLIST_SUCCESS: {
    type: typeof FETCH_PREVIOUS_CHECKLIST_SUCCESS;
    prevChecklist: IPrevChecklist;
  };
  FETCH_QC_CHECKLIST_PROGRESS_SUCCESS: {
    type: typeof FETCH_QC_CHECKLIST_PROGRESS_SUCCESS;
    stationProgress: IStationProgress[];
  };
  EDIT_QC_CHECKLIST_SUCCESS: {
    type: typeof EDIT_QC_CHECKLIST_SUCCESS;
    qcChassi: IQcChassi;
  };
  EDIT_CHECKLIST_CHECKPOINT_SUCCESS: {
    type: typeof EDIT_CHECKLIST_CHECKPOINT_SUCCESS;
    inspection: IQcInspection;
  };
  QC_CHECKLIST_REQUEST_FAILURE: {
    type: typeof QC_CHECKLIST_REQUEST_FAILURE;
    errors: any;
  };
  QC_CHECKLIST_REQUEST_ERROR: {
    type: typeof QC_CHECKLIST_REQUEST_ERROR;
    errors: any;
  };
}

export type QcChecklistActionTypes =
  | QcChecklistActions[typeof FETCH_QC_CHECKLIST_SUCCESS]
  | QcChecklistActions[typeof FETCH_CHECKLIST_INSPECTIONS_SUCCESS]
  | QcChecklistActions[typeof FETCH_PREVIOUS_CHECKLIST_SUCCESS]
  | QcChecklistActions[typeof FETCH_QC_CHECKLIST_PROGRESS_SUCCESS]
  | QcChecklistActions[typeof QC_CHECKLIST_REQUEST_FAILURE]
  | QcChecklistActions[typeof EDIT_QC_CHECKLIST_SUCCESS]
  | QcChecklistActions[typeof EDIT_CHECKLIST_CHECKPOINT_SUCCESS];

export const actionCreators = {
  fetchQcChecklistSuccess: (
    qcChecklist: IQcChecklist
  ): QcChecklistActions[typeof FETCH_QC_CHECKLIST_SUCCESS] => ({
    type: FETCH_QC_CHECKLIST_SUCCESS,
    qcChecklist: qcChecklist,
  }),
  fetchChecklistInspectionsSuccess: (
    inspections: IChecklistInspections
  ): QcChecklistActions[typeof FETCH_CHECKLIST_INSPECTIONS_SUCCESS] => ({
    type: FETCH_CHECKLIST_INSPECTIONS_SUCCESS,
    inspections: inspections,
  }),
  fetchPrevChecklistSuccess: (
    prevChecklist: IPrevChecklist
  ): QcChecklistActions[typeof FETCH_PREVIOUS_CHECKLIST_SUCCESS] => ({
    type: FETCH_PREVIOUS_CHECKLIST_SUCCESS,
    prevChecklist: prevChecklist,
  }),
  fetchChecklistProgressSuccess: (
    stationProgress: IStationProgress[]
  ): QcChecklistActions[typeof FETCH_QC_CHECKLIST_PROGRESS_SUCCESS] => ({
    type: FETCH_QC_CHECKLIST_PROGRESS_SUCCESS,
    stationProgress: stationProgress,
  }),
  qcChecklistRequestFailure: (
    status: number
  ): QcChecklistActions[typeof QC_CHECKLIST_REQUEST_FAILURE] => ({
    type: QC_CHECKLIST_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
  editQcChecklistSuccess: (
    qcChassi: IQcChassi
  ): QcChecklistActions[typeof EDIT_QC_CHECKLIST_SUCCESS] => ({
    type: EDIT_QC_CHECKLIST_SUCCESS,
    qcChassi: qcChassi,
  }),
  editChecklistCheckpointSuccess: (
    inspection: IQcInspection
  ): QcChecklistActions[typeof EDIT_CHECKLIST_CHECKPOINT_SUCCESS] => ({
    type: EDIT_CHECKLIST_CHECKPOINT_SUCCESS,
    inspection: inspection,
  }),
  qcChecklistRequestError: (
    errors: any
  ): QcChecklistActions[typeof QC_CHECKLIST_REQUEST_ERROR] => ({
    type: QC_CHECKLIST_REQUEST_ERROR,
    errors: errors,
  }),
};

export const fetchQcChecklist = (
  qcChassiId: number,
  stationId: number | null
) => {
  return async (dispatch: Dispatch) => {
    let query = `/qcChecklists/${qcChassiId}`;
    if (stationId !== null) {
      query = query + `/${stationId}`;
    }
    const { status, json } = await getRequest(query);
    return status === 200
      ? dispatch(actionCreators.fetchQcChecklistSuccess(json))
      : dispatch(actionCreators.qcChecklistRequestFailure(status));
  };
};

export const fetchChecklistInspections = (
  qcChassiId: number,
  stationId: number | null
) => {
  return async (dispatch: Dispatch) => {
    let query = `/qcChecklists/inspections/${qcChassiId}`;
    if (stationId !== null) {
      query = query + `/${stationId}`;
    }
    const { status, json } = await getRequest(query);
    return status === 200
      ? dispatch(actionCreators.fetchChecklistInspectionsSuccess(json))
      : dispatch(actionCreators.qcChecklistRequestFailure(status));
  };
};

export const fetchPrevChecklist = (qcChassiId: number, stationId: number) => {
  return async (dispatch: Dispatch) => {
    let query = `/qcChecklists/previous/${qcChassiId}/${stationId}`;
    const { status, json } = await getRequest(query);
    return status === 200
      ? dispatch(actionCreators.fetchPrevChecklistSuccess(json))
      : dispatch(actionCreators.qcChecklistRequestFailure(status));
  };
};

export const fetchChecklistProgress = (qcChassiId: number) => {
  return async (dispatch: Dispatch) => {
    let query = `/qcChecklists/progress/${qcChassiId}`;
    const { status, json } = await getRequest(query);
    return status === 200
      ? dispatch(actionCreators.fetchChecklistProgressSuccess(json))
      : dispatch(actionCreators.qcChecklistRequestFailure(status));
  };
};

export const editChecklist = (
  qcChassiId: number,
  stationId: number,
  qcChassi: IQcChassi
) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(
      `/qcChecklists/${qcChassiId}/${stationId}`,
      qcChassi
    );
    switch (status) {
      case 204:
        dispatch(saveSuccess(qcChassi.no));
        return dispatch(actionCreators.editQcChecklistSuccess(json));
      case 400:
        dispatch(saveFailed(qcChassi.no));
        return dispatch(actionCreators.qcChecklistRequestError(json));
      default:
        dispatch(saveFailed(qcChassi.no));
        return dispatch(actionCreators.qcChecklistRequestFailure(status));
    }
  };
};

export const editChecklistCheckpoint = (
  qcChassiId: number,
  stationId: number,
  inspection: IQcInspection
) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(
      `/qcChecklists/checkpoint/${qcChassiId}/${stationId}`,
      inspection
    );
    switch (status) {
      case 200:
        dispatch(saveSuccess(inspection.qcCheckpointName));
        return dispatch(actionCreators.editChecklistCheckpointSuccess(json));
      case 204:
        dispatch(saveSuccess(inspection.qcCheckpointName));
        return dispatch(actionCreators.editChecklistCheckpointSuccess(json));
      case 400:
        dispatch(saveFailed(inspection.qcCheckpointName));
        return dispatch(actionCreators.qcChecklistRequestError(json));
      default:
        dispatch(saveFailed(inspection.qcCheckpointName));
        return dispatch(actionCreators.qcChecklistRequestFailure(status));
    }
  };
};
