import React from "react";
import { Accordion, Card, Col, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { History } from "history";

import IssueFormContainer from "../../../containers/IssueFormContainer";
import { IIssue } from "../../../models/issue";
import { IQcChassi } from "../../../models/QualityControl/qcChassis";
import { IStation } from "../../../models/station";
import { Paths } from "../../../routes";
import { IProduct } from "../../../models/product";
import { IJig } from "models/jig";

type IProps = {
  chassis?: IQcChassi;
  station?: IStation;
  timetrackProd?: IProduct;
  orderProducts?: IProduct[];
  orderNo?: number;
  unitsSCrapped?: number;
  history: History;
  clickedCheckpoint?: string;
  clickedCheckpointNote?: string;
  clickedCheckpointDesc?: string;
  timetrackIssue?: boolean;
  reworkWeldsIssue?: boolean;
  scrappedWeldsIssue?: boolean;
  checklistIssue?: boolean;
  missingPart?: boolean;
  warranty?: boolean;
  qcRework?: boolean;
  waitingForPartsCase?: boolean;
  maintenanceJig?: IJig;
  show: boolean;
  onHide: () => any;
  setModalShow: (value: boolean) => any;
  setProduct?: (product: IProduct) => any;
  setSavedIssue?: (value: boolean) => any;
};

export const CreateIssueModal: React.FC<IProps> = (props) => {
  const {
    chassis,
    station,
    history,
    clickedCheckpoint,
    clickedCheckpointNote,
    clickedCheckpointDesc,
    checklistIssue,
    timetrackProd,
    orderProducts,
    orderNo,
    unitsSCrapped,
    timetrackIssue,
    reworkWeldsIssue,
    scrappedWeldsIssue,
    missingPart,
    warranty,
    qcRework,
    maintenanceJig,
    waitingForPartsCase,
    show,
    onHide,
    setModalShow,
    setProduct,
    setSavedIssue,
  } = props;

  const [issueAccordionState, setIssueAccordionState] = React.useState<boolean>(
    false
  );

  const ProdArray = new Array<string>();
  if (clickedCheckpointDesc !== undefined) {
    const splitRegex = clickedCheckpointDesc.split(/(#[0-9]*)/g);
    for (var regexCount = 0; regexCount < splitRegex.length; regexCount++) {
      const word = splitRegex[regexCount];
      const productMatches = word.trim().match(/(^#[0-9]+)/g);
      if (productMatches !== null) {
        ProdArray.push(word);
        continue;
      }
    }
  }

  React.useEffect(() => {
    setIssueAccordionState(false);
  }, [clickedCheckpoint]);

  const onIssueClick = (issue: IIssue) => {
    localStorage.setItem(
      "checklistIssue_redir",
      JSON.stringify({
        history: history.location.pathname,
        clickedCheckpoint: clickedCheckpoint,
        clickedCheckpointNote: clickedCheckpointNote,
        clickedCheckpointDesc: clickedCheckpointDesc,
      })
    );
    history.push(Paths.gIssuesId(issue.id));
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {missingPart
            ? "Open Part Request"
            : qcRework
            ? "Open Rework chassis case"
            : waitingForPartsCase
            ? "Waiting for parts case"
            : "Open Case"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <IssueFormContainer
          issueId={"new"}
          checklistIssue={checklistIssue}
          embed={true}
          qcChassi={chassis}
          history={history}
          checklistIssueTitle={
            ((clickedCheckpoint !== undefined &&
              clickedCheckpoint.length > 0) ||
            waitingForPartsCase ||
            qcRework
              ? (clickedCheckpoint !== undefined && clickedCheckpoint.length > 0
                  ? " (" + clickedCheckpoint + ") "
                  : "") +
                (station !== undefined ? " (" + station.name + ") " : "")
              : "General case for chassis ") + chassis?.no
          }
          checkpointNote={clickedCheckpointNote}
          checkpointProds={ProdArray}
          timeTrackIssue={timetrackIssue}
          timetrackProd={timetrackProd}
          missingPart={missingPart}
          warranty={warranty}
          qcRework={qcRework}
          maintenanceJig={maintenanceJig}
          waitingForPartsCase={waitingForPartsCase}
          reworkWeldsIssue={reworkWeldsIssue}
          scrappedWeldsIssue={scrappedWeldsIssue}
          orderNo={orderNo}
          unitsSCrapped={unitsSCrapped}
          orderProducts={orderProducts}
          setTimetrackProd={setProduct}
          setChecklistModalShow={setModalShow}
          setSavedIssue={setSavedIssue}
        />
        {chassis && chassis.id && !waitingForPartsCase && !qcRework && (
          <Accordion defaultActiveKey="0">
            <Card>
              <Accordion.Header
                eventKey={chassis.id.toString()}
                as={Card.Header}
                onClick={() => setIssueAccordionState(!issueAccordionState)}
              >
                <div className="container-fluid">
                  Related Cases for this chassis:{" "}
                  {chassis.issues ? chassis.issues.length : 0}
                  <span className="float-end">
                    <FontAwesomeIcon
                      icon={issueAccordionState ? faChevronUp : faChevronDown}
                    />{" "}
                  </span>
                </div>
              </Accordion.Header>
              <Accordion.Collapse
                eventKey={chassis.id.toString()}
                as={Card.Body}
                in={issueAccordionState}
                className="p-0"
              >
                {chassis.issues && chassis.issues.length > 0 ? (
                  <Col className="px-0">
                    <Card className="p-2 qcChassis-related-issues-card">
                      <p>
                        Warning! Unsaved checklist changes will be lost when
                        clicking on an case link!
                      </p>
                    </Card>
                    {chassis.issues.map((i) => {
                      return (
                        <Card
                          className="p-2 qcChassis-related-issues-card"
                          key={i.id}
                          onClick={() => onIssueClick(i)}
                        >
                          <p className="text-success">
                            {i.title}{" "}
                            <span
                              style={{ float: "right" }}
                              className={
                                (i.status === "Open" ? "text-warning " : "") +
                                "ms-2"
                              }
                            >
                              status: {i.status}
                            </span>
                          </p>
                        </Card>
                      );
                    })}
                  </Col>
                ) : (
                  <Card.Body>No related cases</Card.Body>
                )}
              </Accordion.Collapse>
            </Card>
          </Accordion>
        )}
      </Modal.Body>
    </Modal>
  );
};
