import React, { useEffect, useState } from "react";
import {
  DropDownTree,
  DropDownTreeChangeEvent,
  DropDownTreeFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import {
  DataSourceRequestState,
  FilterDescriptor,
} from "@progress/kendo-data-query";
import { filterBy } from "@progress/kendo-react-data-tools";

import { ITag } from "models/tag";

interface IProps {
  tags: any;
  selectedTag: ITag | null;
  requestState: DataSourceRequestState;
  setSelectedTag: (tag: ITag | null) => any;
  setRequestState: (state: DataSourceRequestState) => any;
}

export const TagDropDownTree: React.FC<IProps> = (props) => {
  const {
    tags,
    selectedTag,
    requestState,
    setSelectedTag,
    setRequestState,
  } = props;

  const [filteredCategories, setFilteredCategories] = useState<any[]>(tags);
  const [filter, setFilter] = React.useState<FilterDescriptor>({
    value: "",
    operator: "contains",
  });

  useEffect(() => {
    setFilteredCategories(tags);
  }, [tags]);

  const onTagFilterChange = (event: DropDownTreeFilterChangeEvent) => {
    setFilter(event.filter);
    const filteredTags = filterBy(tags, [event.filter], "tags");
    if (event.filter.value.length > 0) {
      filteredTags.forEach((ft) => {
        if (ft.id >= 1000 && ft.tags !== undefined && ft.tags.length > 0) {
          ft.expanded = true;
        }
      });
    } else {
      filteredTags.forEach((ft) => {
        if (ft.id >= 1000 && ft.tags.some((t) => t.id === selectedTag?.id)) {
          ft.expanded = true;
        } else if (ft.id >= 1000) {
          ft.expanded = false;
        }
      });
    }
    setFilteredCategories(filteredTags);
  };

  const onExpandChange = (item: any) => {
    if (tags === undefined) return;
    let categoryList = [...filteredCategories];
    var itemIndex = categoryList.findIndex((x) => x.id === item.id);
    if (itemIndex === -1) return;
    categoryList[itemIndex].expanded = !item.expanded;
    setFilteredCategories(categoryList);
  };

  const onTagSelect = (tag: any) => {
    if (tags === undefined) return;
    let tagList = [...filteredCategories];
    var itemIndex = tagList.findIndex((x) => x.id === tag.id);
    tagList.forEach((t, index) => {
      return (t.selected =
        index === itemIndex
          ? tagList[itemIndex].selected
            ? false
            : true
          : false);
    });
    tagList.forEach((tl) => {
      if (tl.tags !== undefined) {
        var tagIndex = tl.tags.findIndex((x) => x.id === tag.id);
        tl.tags.forEach((t, index) => {
          return (t.selected =
            index === tagIndex
              ? tl.tags[tagIndex].selected
                ? false
                : true
              : false);
        });
      }
    });
    setFilteredCategories(tagList);
  };

  return (
    <div className="k-filtercell">
      <DropDownTree
        popupSettings={{
          className: "tag-multiselecttree issue-grid-tags",
          height: "auto",
        }}
        name="tags"
        textField="name"
        expandField="expanded"
        subItemsField="tags"
        dataItemKey="id"
        data={filteredCategories}
        value={selectedTag}
        filterable
        onFilterChange={onTagFilterChange}
        filter={filter.value}
        onExpandChange={(e) => onExpandChange(e.item)}
        onChange={(event: DropDownTreeChangeEvent) => {
          if (event.value === null) {
            setSelectedTag(null);
            onTagSelect({ id: -1 } as ITag);
            setRequestState({ ...requestState, skip: 0 });
          } else if (event.value.id < 1000) {
            setSelectedTag(event.value);
            onTagSelect(event.value);
            setRequestState({ ...requestState, skip: 0 });
          } else {
            onExpandChange(event.value);
          }
        }}
      />
    </div>
  );
};

export default TagDropDownTree;
