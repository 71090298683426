import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export interface IIssueFilterButton {
  title?: string;
  icons: IconDefinition[];
  className?: string;
  active: boolean | string | undefined;
  roles: string[];
  handleClick: () => void;
}

const IssueFilterButton: React.FC<IIssueFilterButton> = (props) => {
  const { title, icons, className, active, handleClick } = props;
  const icon: IconDefinition =
    icons.length === 1 || active === "Open" ? icons[0] : icons[1];

  return (
    <button
      title={title}
      onClick={handleClick}
      className={`issue-filter-button border-0 py-2 px-0 text-${
        !active ? "muted" : "white"
      } ${className ? className : "me-3"}`}
      style={{ cursor: "pointer" }}
    >
      <FontAwesomeIcon icon={icon} size="2x" />
    </button>
  );
};

export default IssueFilterButton;
