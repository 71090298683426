import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  requestBegin,
  requestEnd,
} from "./request";
import { ITraining } from "../../models/training";
import { Dispatch } from "redux";
import { DataResult } from "@progress/kendo-data-query";
import { deleteSuccess, saveFailed, saveSuccess } from "./alerts";

export const FETCH_TRAININGS_SUCCESS = "FETCH_TRAININGS_SUCCESS";
export const FETCH_TRAINING_SUCCESS = "FETCH_TRAINING_SUCCESS";
export const FETCH_FILTERED_TRAININGS_SUCCESS =
  "FETCH_FILTERED_TRAININGS_SUCCESS";
export const CREATE_TRAINING_SUCCESS = "CREATE_TRAINING_SUCCESS";
export const EDIT_TRAINING_SUCCESS = "EDIT_TRAINING_SUCCESS";
export const DELETE_TRAINING_SUCCESS = "DELETE_TRAINING_SUCCESS";
export const TRAINING_REQUEST_FAILURE = "TRAINING_REQUEST_FAILURE";
export const TRAINING_REQUEST_ERROR = "TRAINING_REQUEST_ERROR";

export interface TrainingActions {
  FETCH_TRAININGS_SUCCESS: {
    type: typeof FETCH_TRAININGS_SUCCESS;
    trainings: ITraining[];
  };
  FETCH_TRAINING_SUCCESS: {
    type: typeof FETCH_TRAINING_SUCCESS;
    training: ITraining;
  };
  FETCH_FILTERED_TRAININGS_SUCCESS: {
    type: typeof FETCH_FILTERED_TRAININGS_SUCCESS;
    trainings: DataResult;
  };
  CREATE_TRAINING_SUCCESS: {
    type: typeof CREATE_TRAINING_SUCCESS;
    training: ITraining;
  };
  EDIT_TRAINING_SUCCESS: {
    type: typeof EDIT_TRAINING_SUCCESS;
    training: ITraining;
  };
  DELETE_TRAINING_SUCCESS: {
    type: typeof DELETE_TRAINING_SUCCESS;
  };
  TRAINING_REQUEST_FAILURE: {
    type: typeof TRAINING_REQUEST_FAILURE;
    errors: any;
  };
  TRAINING_REQUEST_ERROR: {
    type: typeof TRAINING_REQUEST_ERROR;
    errors: any;
  };
}

export type TrainingActionTypes =
  | TrainingActions[typeof FETCH_TRAININGS_SUCCESS]
  | TrainingActions[typeof FETCH_TRAINING_SUCCESS]
  | TrainingActions[typeof FETCH_FILTERED_TRAININGS_SUCCESS]
  | TrainingActions[typeof CREATE_TRAINING_SUCCESS]
  | TrainingActions[typeof EDIT_TRAINING_SUCCESS]
  | TrainingActions[typeof DELETE_TRAINING_SUCCESS]
  | TrainingActions[typeof TRAINING_REQUEST_FAILURE]
  | TrainingActions[typeof TRAINING_REQUEST_ERROR];

export const actionCreators = {
  fetchTrainingsSuccess: (
    trainings: ITraining[]
  ): TrainingActions[typeof FETCH_TRAININGS_SUCCESS] => ({
    type: FETCH_TRAININGS_SUCCESS,
    trainings: trainings,
  }),
  fetchTrainingSuccess: (
    training: ITraining
  ): TrainingActions[typeof FETCH_TRAINING_SUCCESS] => ({
    type: FETCH_TRAINING_SUCCESS,
    training: training,
  }),
  fetchFilteredTrainingsSuccess: (
    trainings: DataResult
  ): TrainingActions[typeof FETCH_FILTERED_TRAININGS_SUCCESS] => ({
    type: FETCH_FILTERED_TRAININGS_SUCCESS,
    trainings: trainings,
  }),
  createTrainingSuccess: (
    training: ITraining
  ): TrainingActions[typeof CREATE_TRAINING_SUCCESS] => ({
    type: CREATE_TRAINING_SUCCESS,
    training: training,
  }),
  editTrainingSuccess: (
    training: ITraining
  ): TrainingActions[typeof EDIT_TRAINING_SUCCESS] => ({
    type: EDIT_TRAINING_SUCCESS,
    training: training,
  }),
  deleteTrainingSuccess: (): TrainingActions[typeof DELETE_TRAINING_SUCCESS] => ({
    type: DELETE_TRAINING_SUCCESS,
  }),
  trainingRequestFailure: (
    status: number
  ): TrainingActions[typeof TRAINING_REQUEST_FAILURE] => ({
    type: TRAINING_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
  trainingRequestError: (
    errors: any
  ): TrainingActions[typeof TRAINING_REQUEST_ERROR] => ({
    type: TRAINING_REQUEST_ERROR,
    errors: errors,
  }),
};

export function fetchTrainings() {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_TRAININGS_SUCCESS));
    const { status, json } = await getRequest("/trainings");
    dispatch(requestEnd(FETCH_TRAININGS_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchTrainingsSuccess(json))
      : dispatch(actionCreators.trainingRequestFailure(status));
  };
}

export const fetchTraining = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/trainings/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchTrainingSuccess(json))
      : dispatch(actionCreators.trainingRequestFailure(status));
  };
};

export function fetchFilteredTrainings(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_FILTERED_TRAININGS_SUCCESS));
    const { status, json } = await getRequest(
      `/trainings/filtered?${queryStr}`
    );
    dispatch(requestEnd(FETCH_FILTERED_TRAININGS_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchFilteredTrainingsSuccess(json))
      : dispatch(actionCreators.trainingRequestFailure(status));
  };
}

export const createTraining = (training: ITraining) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest("/trainings", training);
    switch (status) {
      case 200:
        dispatch(
          saveSuccess(
            training.trainee.fullName +
              " - " +
              (training.station !== undefined
                ? training.station?.name
                : training.product.descEN)
          )
        );
        return dispatch(actionCreators.createTrainingSuccess(json));
      case 400:
        return dispatch(actionCreators.trainingRequestError(json));
      default:
        dispatch(
          saveFailed(
            training.trainee.fullName +
              " - " +
              (training.station !== undefined
                ? training.station?.name
                : training.product.descEN)
          )
        );
        return dispatch(actionCreators.trainingRequestFailure(status));
    }
  };
};

export const editTraining = (id: number, training: ITraining) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(`/trainings/${id}`, training);
    switch (status) {
      case 200:
        dispatch(
          saveSuccess(
            training.trainee.fullName +
              " - " +
              (training.station !== undefined
                ? training.station?.name
                : training.product.descEN)
          )
        );
        return dispatch(actionCreators.editTrainingSuccess(json));
      case 400:
        dispatch(
          saveFailed(
            training.trainee.fullName +
              " - " +
              (training.station !== undefined
                ? training.station?.name
                : training.product.descEN)
          )
        );
        return dispatch(actionCreators.trainingRequestError(json));
      default:
        dispatch(
          saveFailed(
            training.trainee.fullName +
              " - " +
              (training.station !== undefined
                ? training.station?.name
                : training.product.descEN)
          )
        );
        return dispatch(actionCreators.trainingRequestFailure(status));
    }
  };
};

export const deleteTraining = (id: number, training: ITraining) => {
  return async (dispatch: Dispatch) => {
    const { status } = await deleteRequest(`/trainings/${id}`, training);
    if (status === 200) {
      dispatch(
        deleteSuccess(
          training.trainee.fullName +
            " - " +
            (training.station !== undefined
              ? training.station?.name
              : training.product.descEN)
        )
      );
      return dispatch(actionCreators.deleteTrainingSuccess());
    }
    dispatch(
      saveFailed(
        training.trainee.fullName +
          " - " +
          (training.station !== undefined
            ? training.station?.name
            : training.product.descEN)
      )
    );
    return dispatch(actionCreators.trainingRequestFailure(status));
  };
};
