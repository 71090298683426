import { Dispatch } from "redux";

export const TOGGLE_NAVIGATION = "TOGGLE_NAVIGATION";

export type UIActions = {
  TOGGLE_NAVIGATION: {
    type: typeof TOGGLE_NAVIGATION;
  };
};

export type UIActionsTypes = UIActions[typeof TOGGLE_NAVIGATION];

export const actionCreators = {
  toggleNavigation: (): UIActions[typeof TOGGLE_NAVIGATION] => ({
    type: TOGGLE_NAVIGATION,
  }),
};

export function toggleNavigation() {
  return async (dispatch: Dispatch) => {
    return dispatch(actionCreators.toggleNavigation());
  };
}
