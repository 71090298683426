import {
  faBars,
  faClipboardCheck,
  faClipboardList,
  faCog,
  faCrop,
  faFlag,
  faHammer,
  faHome,
  faSitemap,
  faStickyNote,
  faTags,
  faTasks,
  faTools,
  faUser,
  faUsersCog,
  faUserShield,
  faClock,
  faSignOutAlt,
  faUsers,
  faCheckCircle,
  faDraftingCompass,
  faChalkboardTeacher,
  faBolt,
  faThLarge,
  faStopwatch,
  faMinusCircle,
  faSpinner,
  faProjectDiagram,
  faInfoCircle,
  faFileArchive,
  faAddressBook,
  faSignInAlt,
  faQuestion,
  faTractor,
  faBell,
  faComment,
  faToolbox,
  faCropAlt,
  faHardHat,
  faList,
  faArchive,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HubConnectionState } from "@microsoft/signalr";
import { History } from "history";
import { IIssue } from "models/issue";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Image, Modal, Navbar, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  fetchRecentlyReadIssues,
  fetchUnreadIssues,
  updateAllUnreadIssues,
  updateUnreadIssue,
} from "store/actions/issues";
import dateTimeFormat from "utils/dateTimeFormat";
import Drawer from "../components/Navigation/Drawer";
import { IProps as DrawerItemProps } from "../components/Navigation/DrawerItem";
import {
  isInternal,
  isResourceAdmin,
  isServicePoint,
  isTechnicalSupport,
  IUser,
} from "../models/user";
import { Paths } from "../routes";
import {
  clearUpdatedCases,
  connection,
  leaveIssue,
  startConnection,
} from "../store/actions/signalr";
import { IRootState } from "../store/index";
import Aux from "../utils/auxiliary";

export interface IProps {
  history: History;
  currentUser: IUser;
  unreadIssues: IIssue[];
  recentlyReadIssues: IIssue[];
  isAuthenticated: boolean;
  authFailed: boolean;
  startConnection: () => void;
  leaveIssue: () => void;
  fetchUnreadIssues: (userId: number) => any;
  fetchRecentlyReadIssues: (userId: number) => any;
  updateUnreadIssue: (issueId: number, userId: number) => any;
  updateAllUnreadIssues: (userId: number) => any;
  clearUpdatedCases: () => any;
  children?: JSX.Element;
  signalRIssues: IIssue[];
}

const DrawerContainer: React.FC<IProps> = (props) => {
  const {
    history,
    currentUser,
    unreadIssues,
    recentlyReadIssues,
    isAuthenticated,
    signalRIssues,
    startConnection,
    leaveIssue,
    fetchUnreadIssues,
    updateUnreadIssue,
    updateAllUnreadIssues,
    fetchRecentlyReadIssues,
    clearUpdatedCases,
    authFailed,
  } = props;

  const [showAddHome, setShowAddHome] = useState<boolean>(false);
  const [showUnread, setShowUnread] = useState<boolean>(false);
  const [unreadCases, setUnreadCases] = useState<IIssue[]>([]);

  const updateIssueMessages = useCallback(() => {
    const combined = unreadIssues.filter(
      (ui) => !signalRIssues.some((si) => si.id === ui.id)
    );
    combined.unshift(...signalRIssues);
    setUnreadCases(combined);
  }, [signalRIssues, unreadIssues]);

  useEffect(() => {
    updateIssueMessages();
  }, [updateIssueMessages]);

  const generateItems = () => {
    const internalRoles = process.env.REACT_APP_INTERNAL_ROLES;

    const internalRolesArray =
      internalRoles !== undefined
        ? (JSON.parse(internalRoles) as string[])
        : [];

    const orkelDirekteItem: DrawerItemProps = {
      text: "Orkel Direkte",
      id: 301,
      faIcon: faTractor,
      route: Paths.orkelDirekte,
    };

    const KittingStatusItem: DrawerItemProps = {
      text: "Kitting order status",
      id: 902,
      parentId: 900,
      faIcon: faList,
      route: Paths.order.kittingstatus,
      roles: ["admin", "kitter", "kitting-admin", "welder-admin"],
    };

    const workListItem: DrawerItemProps = {
      text: "Work list",
      id: 603,
      parentId: 600,
      faIcon: faList,
      route: Paths.order.worklist,
      roles: ["welder-admin", "admin", "kitter", "purchaser", "qc-teamleader"],
      children: [],
    };

    const technicalSupportItem: DrawerItemProps = {
      text: "Technical Support",
      id: 301,
      faIcon: faInfoCircle,
      route: Paths.technicalSupport,
    };

    const caseItems: DrawerItemProps[] = [
      {
        text: "Cases",
        id: 300,
        faIcon: faCheckCircle,
        "data-expanded": false,
      },
      {
        text: "Cases",
        id: 301,
        parentId: 300,
        faIcon: faCheckCircle,
        route: Paths.issues,
      },
      {
        text: "HMS",
        id: 309,
        parentId: 300,
        faIcon: faHardHat,
        route: Paths.hms,
        roles: internalRolesArray,
      },
      {
        text: "Part Request",
        id: 304,
        parentId: 300,
        faIcon: faMinusCircle,
        route: Paths.partrequest,
        roles: internalRolesArray,
      },
      {
        text: "Administration",
        id: 305,
        parentId: 300,
        faIcon: faInfoCircle,
        route: Paths.administration,
        roles: internalRolesArray,
      },
      {
        text: "Warranty",
        id: 306,
        parentId: 300,
        faIcon: faInfoCircle,
        route: Paths.warranty,
      },
      {
        text: "Technical Support",
        id: 311,
        parentId: 300,
        faIcon: faInfoCircle,
        route: Paths.technicalSupport,
      },
      {
        text: "Orkel Direkte",
        id: 307,
        parentId: 300,
        faIcon: faTractor,
        route: Paths.orkelDirekte,
      },
      {
        text: "Maintenance",
        id: 310,
        parentId: 300,
        faIcon: faToolbox,
        route: Paths.maintenance,
      },
      {
        text: "Tags",
        id: 302,
        parentId: 300,
        faIcon: faTags,
        route: Paths.issue.tags,
        roles: ["admin", "case-admin"],
      },
      {
        text: "Case Checkpoints",
        id: 303,
        parentId: 300,
        faIcon: faFlag,
        route: Paths.issue.checkpoints,
        roles: ["admin", "case-admin"],
      },
      {
        text: "Case Types",
        id: 308,
        parentId: 300,
        faIcon: faComment,
        route: Paths.issue.types,
        roles: ["admin", "case-admin"],
      },
      {
        text: "Case Templates",
        id: 312,
        parentId: 300,
        faIcon: faComment,
        route: Paths.issue.templates,
        roles: internalRolesArray,
      },
    ];

    let newItems: DrawerItemProps[] = [
      {
        text: "Products",
        id: 400,
        faIcon: faDraftingCompass,
        route: Paths.products,
        roles: internalRolesArray,
      },
      {
        text: "Orders",
        id: 700,
        faIcon: faThLarge,
        route: Paths.orders,
        roles: ["super-admin"],
        children: [],
      },
      {
        text: "Equipment",
        id: 500,
        faIcon: faCrop,
        "data-expanded": false,
        roles: internalRolesArray,
      },
      {
        text: "Equipment",
        id: 501,
        parentId: 500,
        faIcon: faCrop,
        route: Paths.equipments,
        roles: internalRolesArray,
      },
      {
        text: "Checkpoints",
        id: 502,
        parentId: 500,
        faIcon: faFlag,
        route: Paths.equipment.checkpoints,
        roles: ["admin", "jig-admin"],
      },
      {
        text: "Categories",
        id: 503,
        parentId: 500,
        faIcon: faCropAlt,
        route: Paths.equipment.categories,
        roles: ["admin", "jig-admin"],
      },
      {
        text: "Timetracks",
        id: 600,
        faIcon: faClock,
        roles: [
          "admin",
          "tt-admin",
          "welder",
          "kitter",
          "kitting-admin",
          "qc-admin",
          "qc-inspector",
          "weldment-inspector",
          "purchaser",
          "qc-teamleader",
        ],
        "data-expanded": false,
      },
      {
        text: "Timetracks",
        id: 601,
        parentId: 600,
        faIcon: faStopwatch,
        route: Paths.order.TimeTracks,
        roles: [
          "admin",
          "tt-admin",
          "welder",
          "kitter",
          "kitting-admin",
          "qc-inspector",
        ],
      },
      {
        text: "My Orders",
        id: 602,
        parentId: 600,
        faIcon: faUser,
        route: Paths.order.MyOrders,
        roles: [
          "admin",
          "tt-admin",
          "welder",
          "welder-admin",
          "kitter",
          "kitting-admin",
          "qc-inspector",
          "pre-assembly",
        ],
      },
      {
        text: "Pre Assembly",
        id: 608,
        parentId: 600,
        faIcon: faList,
        route: Paths.order.assembly,
        roles: [
          "welder-admin",
          "admin",
          "kitter",
          "purchaser",
          "qc-teamleader",
        ],
        children: [],
      },
      {
        text: "Archived",
        id: 604,
        parentId: 600,
        faIcon: faArchive,
        route: Paths.order.ArchivedTimeTracks,
        roles: ["admin", "tt-admin", "purchaser"],
      },
      {
        text: "Checkpoints",
        id: 606,
        parentId: 600,
        faIcon: faFlag,
        route: Paths.order.checkpoints,
        roles: ["admin", "qc-admin", "tt-admin"],
      },
      {
        text: "Weldment Inspections",
        id: 607,
        parentId: 600,
        faIcon: faClipboardCheck,
        route: Paths.order.weldInspections,
        roles: ["admin", "qc-admin", "tt-admin", "weldment-inspector"],
      },
      {
        text: "Kitting",
        id: 900,
        faIcon: faBolt,
        "data-expanded": false,
        roles: ["admin", "kitter", "kitting-admin", "basic"],
      },
      {
        text: "Kitting",
        id: 901,
        parentId: 900,
        faIcon: faBolt,
        route: Paths.order.kitting,
        roles: ["admin", "kitter", "kitting-admin", "basic"],
      },

      {
        text: "Order Attachments",
        id: 903,
        faIcon: faFileArchive,
        route: Paths.order.images,
        roles: ["admin", "kitter", "kitting-admin", "supplier", "purchaser"],
      },
      {
        text: "Quality Control",
        id: 200,
        "data-expanded": false,
        faIcon: faClipboardList,
        roles: internalRolesArray,
      },
      {
        text: "Machine Specs",
        id: 202,
        parentId: 200,
        faIcon: faHammer,
        route: Paths.qc.machineSpecs,
        roles: internalRolesArray,
      },
      {
        text: "Checklist",
        id: 201,
        parentId: 200,
        faIcon: faTasks,
        route: Paths.qc.default,
        roles: ["admin", "qc-admin", "qc-inspector", "qc-teamleader"],
      },
      {
        text: "Progress",
        id: 208,
        parentId: 200,
        faIcon: faTasks,
        route: Paths.qc.progress,
        roles: ["admin", "qc-admin", "qc-inspector", "qc-teamleader"],
      },
      {
        text: "Chassis",
        id: 203,
        parentId: 200,
        faIcon: faCog,
        route: Paths.qc.chassis,
        roles: ["admin", "qc-admin", "qc-teamleader", "chassis-admin"],
      },
      {
        text: "Checkpoints",
        id: 204,
        parentId: 200,
        faIcon: faFlag,
        route: Paths.qc.checkpoints,
        roles: ["admin", "qc-admin", "qc-teamleader"],
      },
      {
        text: "Inspections",
        id: 205,
        parentId: 200,
        faIcon: faClipboardCheck,
        route: Paths.qc.inspectionsList,
        roles: ["admin", "qc-admin", "qc-teamleader"],
      },
      {
        text: "Specs",
        id: 206,
        parentId: 200,
        faIcon: faStickyNote,
        route: Paths.qc.specs,
        roles: ["admin", "qc-admin", "qc-teamleader"],
      },
      {
        text: "Settings",
        id: 207,
        parentId: 200,
        faIcon: faTools,
        route: Paths.qc.settings,
        roles: ["admin", "qc-settings-admin", "qc-teamleader", "protek"],
      },
      {
        text: "Training logs",
        id: 800,
        faIcon: faChalkboardTeacher,
        route: Paths.tranings,
        roles: internalRolesArray,
      },
      {
        text: "Admin",
        id: 100,
        faIcon: faUserShield,
        "data-expanded": false,
        roles: ["admin", "user-admin", "chassis-admin"],
      },
      {
        text: "Stations",
        id: 101,
        parentId: 100,
        faIcon: faSitemap,
        route: Paths.stations,
        roles: ["admin", "chassis-admin"],
      },
      {
        text: "Groups",
        id: 102,
        parentId: 100,
        faIcon: faUsersCog,
        route: Paths.groups,
        roles: ["admin"],
      },
      {
        text: "Users",
        id: 104,
        parentId: 100,
        faIcon: faUsers,
        route: Paths.users,
        roles: ["admin", "user-admin"],
      },
      {
        text: "Roles",
        id: 105,
        parentId: 100,
        faIcon: faAddressBook,
        route: Paths.roles,
        roles: ["admin"],
      },
      { separator: true, id: 997 },
      {
        text: "My Profile",
        id: 998,
        faIcon: faUser,
        route: Paths.profile,
      },
      {
        text: "Logout",
        id: 999,
        faIcon: faSignOutAlt,
        route: Paths.logout,
        visible: isAuthenticated,
      },
      {
        text: "Login",
        faIcon: faSignInAlt,
        route: "/login",
        visible: !isAuthenticated,
      },
      { separator: true, id: 1000 },
      {
        text: "help@exigo.no",
        faIcon: faQuestion,
        id: 1001,
        className: "text-success",
      },
      {
        text: "Add to Homescreen",
        id: 1002,
        faIcon: faHome,
        visible: showAddHome,
      },
    ];

    const licenseItem: DrawerItemProps = {
      text: "Licenses",
      id: 801,
      faIcon: faProjectDiagram,
      route: Paths.resources,
      roles: internalRolesArray,
    };

    if (
      currentUser.id &&
      (currentUser.administeredResources.length > 0 ||
        isResourceAdmin(currentUser))
    ) {
      newItems.splice(
        newItems.findIndex((i) => i.id === 800) + 1,
        0,
        licenseItem
      );
    }
    if (
      currentUser.id &&
      (isInternal(currentUser) || currentUser.supplierNo === 20396)
    ) {
      newItems.splice(
        newItems.findIndex((i) => i.id === 901) + 1,
        0,
        KittingStatusItem
      );
    }
    if (currentUser.id && isInternal(currentUser)) {
      newItems.splice(
        newItems.findIndex((i) => i.id === 602) + 1,
        0,
        workListItem
      );
    }
    if (currentUser.id && isServicePoint(currentUser)) {
      newItems.unshift(orkelDirekteItem);
    } else if (currentUser.id && isTechnicalSupport(currentUser)) {
      newItems.unshift(technicalSupportItem);
    } else {
      newItems.unshift(...caseItems);
    }

    const roleItems = newItems.filter(
      (item) =>
        item.roles === undefined ||
        (currentUser === undefined && item.roles.length === 0) ||
        item.roles?.findIndex(
          (r) => currentUser?.roles?.findIndex((cr) => r === cr) > -1
        ) > -1
    );

    roleItems.forEach(
      (item) =>
        (item.hideExpand = !roleItems.some((i) => i.parentId === item.id))
    );

    return roleItems;
  };

  const [expanded, setExpanded] = useState(false);
  const [items, setItems] = useState<DrawerItemProps[]>(generateItems());
  const [deferredPromt, setDeferredPromt] = useState<any>();

  useEffect(() => {
    setItems(generateItems());
  }, [isAuthenticated, showAddHome, currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

  const onBeforeInstall = (e: any) => {
    e.preventDefault();
    setShowAddHome(true);
    setDeferredPromt(e);
  };
  const [init, setInit] = useState<boolean>(true);

  const checkissues = () => {
    if (currentUser && currentUser.id > 0) {
      fetchUnreadIssues(currentUser.id);
    }
    //setTimeout(checkissues, 60 * 1000);
  };

  useEffect(() => {
    if (currentUser && currentUser.id > 0 && init) {
      setInit(false);
      checkissues();
    }
  }, [currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", onBeforeInstall);

    return () => {
      window.removeEventListener("beforeinstallprompt", onBeforeInstall);
    };
  }, []);

  const startSignalr = useCallback(startConnection, [startConnection]);
  useEffect(() => {
    if (isAuthenticated && connection.state !== HubConnectionState.Connected) {
      startSignalr();
    }
  }, [isAuthenticated, startSignalr]);

  useEffect(() => {
    if (connection.state !== HubConnectionState.Connected) return;
    if (history.location.pathname.includes(Paths.issues)) return;
    if (history.location.pathname === Paths.root) return;
    leaveIssue();
  }, [history.location.pathname, leaveIssue]);

  let keysToRemove = [
    "temp_qc_inspectionList_request_state",
    "temp_qc_machine_spec_request_state",
    "temp_timetrack_req_state",
    "temp_image_grid_req_state",
    "timetrack_redir",
    "checklistIssue_redir",
    "chassis_grid_view_hidden_state",
    "temp_training_grid_state",
    "kitting_prod_redir",
    "kitting_kit_redir",
    "temp_timetracks_gird_req_state",
    "products_grid_request_state",
    "view_hidden_tags",
    "temp_resources_req_state",
    "view_hidden_resources",
    "temp_timetracks_gird_req_state",
    "tag_grid_state",
    "jigNr",
  ];

  const onSelect = (itemTargetProps: any) => {
    const currentItem = itemTargetProps;
    keysToRemove.forEach((k) => localStorage.removeItem(k));
    if (currentItem.id === 1002) {
      if (deferredPromt) {
        deferredPromt.prompt();
        deferredPromt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the A2HS prompt");
          } else {
            console.log("User dismissed the A2HS prompt");
          }
          setDeferredPromt(null);
        });
      }
    }

    if (currentItem.id === 1001) {
      window.open("mailto: help@exigo.no");
      return;
    }

    const isParent = currentItem["data-expanded"] !== undefined;
    const nextExpanded = !currentItem["data-expanded"];

    const newData = items.map((item) => {
      const {
        selected,
        "data-expanded": currentExpanded,
        id,
        ...others
      } = item;
      const isCurrentItem = currentItem.id === id;

      return {
        selected: isCurrentItem,
        "data-expanded":
          isCurrentItem && isParent ? nextExpanded : currentExpanded,
        id,
        ...others,
      };
    });

    setItems(newData);
    if (
      !isParent &&
      items.find((x) => x.parentId === currentItem.id) === undefined
    ) {
      setExpanded(false);
    }

    const route = itemTargetProps.route;
    if (route === undefined) return;

    if (route.startsWith("http")) {
      window.open(route);
    } else {
      history.push(route);
    }
  };

  const handleOverlayClick = () => {
    setExpanded((prevState) => !prevState);
  };

  const setSelectedItem = (pathName: any) => {
    const currentPath: DrawerItemProps | undefined = items.find(
      (item) => item.route === pathName
    );
    if (typeof currentPath !== "undefined") {
      return currentPath.text;
    }
  };
  const selected = setSelectedItem(history.location.pathname);

  const [scroll, setScroll] = useState<number>(0);

  window.addEventListener("scroll", (event) => {
    let scroll = window.scrollY;
    setScroll(scroll);
  });

  const onIssueClick = (issue: IIssue) => {
    setShowUnread(false);
    history.push(Paths.gIssuesId(issue.id));
  };

  const mql = window.matchMedia("(min-width: 768px)");

  return (
    <Aux>
      <Navbar className="pt-3">
        <a
          className="ms-4"
          href={Paths.root}
          onClick={(e) => {
            e.preventDefault();
            onSelect(items[items.findIndex((i) => i.id === 301)]);
          }}
        >
          <Image
            src="/assets/images/PI-RGB_white_outline.svg"
            className="logo"
            alt="orkel-logo"
          />
        </a>
        <div
          className={
            (selected === "Training logs" || selected === "Stations") &&
            scroll < 40
              ? "position-fixed"
              : "position-absolute"
          }
          style={{ right: "10px" }}
        >
          <button
            className={"ms-auto me-3 "}
            onClick={() => {
              setShowUnread(!showUnread);
              fetchRecentlyReadIssues(currentUser.id);
            }}
            style={{
              backgroundColor: "transparent",
              border: "none",
            }}
          >
            <div>
              {unreadCases.length > 0 && (
                <span
                  style={{
                    position: "absolute",
                    marginTop: "-0.50em",
                    marginLeft: "0.40em",
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "red",
                  }}
                >
                  {unreadCases.length}
                </span>
              )}
              <div>
                <FontAwesomeIcon
                  icon={faBell}
                  className={`text-${
                    unreadCases.length > 0 ? "white" : "muted"
                  }`}
                />
              </div>
            </div>
          </button>
          {currentUser && (
            <a
              href={Paths.profile}
              onClick={(e) => {
                e.preventDefault();
                onSelect(items[items.findIndex((i) => i.id === 998)]);
              }}
              className={"small text-muted ms-auto me-5 "}
            >
              <span className="me-3">
                <FontAwesomeIcon icon={faUser} /> {currentUser.fullName}
              </span>
            </a>
          )}
        </div>

        <button
          className={
            "navbar-toggler d-block border-0 me-3 p-0 " +
            ((selected === "Training logs" || selected === "Stations") &&
            scroll < 40
              ? "position-fixed"
              : "position-absolute")
          }
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <FontAwesomeIcon
            icon={faBars}
            size="2x"
            className={`text-${expanded ? "white" : "muted"}`}
          />
        </button>
      </Navbar>
      {isAuthenticated ? (
        <Drawer
          onSelect={(e) => onSelect(e.itemTarget.props)}
          selected={selected}
          items={items}
          expanded={expanded}
          onOverlayClick={handleOverlayClick}
        >
          {props.children}
        </Drawer>
      ) : (
        <Aux>
          {props.children}{" "}
          {!authFailed && (
            <div>
              <p className="text-center">Authenticating</p>
              <p className="text-center m-3">
                <FontAwesomeIcon icon={faSpinner} size="3x" spin />
              </p>
            </div>
          )}
        </Aux>
      )}
      {showUnread && (
        <Modal
          show={showUnread}
          onHide={() => {
            setShowUnread(false);
          }}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className={mql.matches ? "" : "mobile-notification-view"}
        >
          <Modal.Header id="contained-modal-title-vcenter" closeButton>
            <h5>Case overview for {currentUser.fullName}</h5>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <div className="mt-3">
              <Row>
                <Col>
                  <p className="mx-4">New or updated cases </p>
                </Col>
                <Col>
                  {unreadCases.length > 0 && (
                    <Button
                      className="pt-2 pb-2 float-end me-2"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        updateAllUnreadIssues(currentUser.id);
                        clearUpdatedCases();
                      }}
                    >
                      Mark all as read
                    </Button>
                  )}
                </Col>
              </Row>

              {unreadCases.length > 0 ? (
                unreadCases.map((i) => {
                  return (
                    <Col key={i.id} className="mx-0 px-0">
                      <Card
                        className="p-2 qcChassis-related-issues-card"
                        onClick={() => onIssueClick(i)}
                      >
                        <Row>
                          <Col className="col-sm-12 col-mb-9 col-lg-8 col-xl-4 ">
                            <p className="text-success p-2 ps-3">
                              {i.id + " - " + i.title}
                            </p>
                          </Col>

                          <Col className="col-sm-12 col-mb-9 col-lg-8 col-xl-4">
                            <p className="text-success p-2">
                              Updated:{" "}
                              {dateTimeFormat.format(new Date(i.updatedAt))}
                            </p>
                          </Col>
                          <Col className="col-sm-12 col-mb-9 col-lg-8 col-xl-2 ">
                            <p className="text-success p-2">
                              status:{" "}
                              {i.status.toString() === "0"
                                ? "Open"
                                : i.status.toString() === "1"
                                ? "Closed"
                                : i.status}
                            </p>
                          </Col>
                          <Col className="col-sm-12 col-mb-9 col-lg-8 col-xl-2 ">
                            <Button
                              className="mx-0 mt-2 py-0 px-1 float-end"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                updateUnreadIssue(i.id, currentUser.id);
                                clearUpdatedCases();
                              }}
                            >
                              Mark as read
                            </Button>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  );
                })
              ) : (
                <Col className="mx-0 px-0">
                  <Card className="p-2 qcChassis-related-issues-card">
                    <small className="m-0 p-3">No unread cases</small>
                  </Card>
                </Col>
              )}
            </div>
            <div className="mt-3">
              <p className="mx-4">Recently read cases:</p>
              {recentlyReadIssues.map((i, index) => {
                return (
                  <Col key={i.id + "-" + index} className="mx-0 px-0">
                    <Card
                      className="p-2 qcChassis-related-issues-card"
                      onClick={() => onIssueClick(i)}
                    >
                      <p className="m-0 p-3 text-success">
                        {i.id + " - " + i.title}{" "}
                        <span className="float-end ms-5">
                          status: {i.status}
                        </span>
                        <span className="float-end ms-5">
                          Updated:{" "}
                          {dateTimeFormat.format(new Date(i.updatedAt))}
                        </span>
                      </p>
                    </Card>
                  </Col>
                );
              })}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </Aux>
  );
};

const mapStateToProps = (state: IRootState) => ({
  currentUser: state.authReducer.user,
  isAuthenticated: state.authReducer.isAuthenticated,
  authFailed: state.authReducer.authFailed,
  unreadIssues: state.issuesReducer.unreadIssues,
  recentlyReadIssues: state.issuesReducer.recentlyReadIssues,
  signalRIssues: state.signalReducer.signalRIssues,
});

const mapDispatchToProps = (dispatch: any) => ({
  startConnection: () => dispatch(startConnection()),
  leaveIssue: () => dispatch(leaveIssue()),
  fetchUnreadIssues: (userId: number) => dispatch(fetchUnreadIssues(userId)),
  fetchRecentlyReadIssues: (userId: number) =>
    dispatch(fetchRecentlyReadIssues(userId)),
  updateUnreadIssue: (issueId: number, userId: number) =>
    dispatch(updateUnreadIssue(issueId, userId)),
  updateAllUnreadIssues: (userId: number) =>
    dispatch(updateAllUnreadIssues(userId)),
  clearUpdatedCases: () => dispatch(clearUpdatedCases()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawerContainer);
