import React from "react";
import { Button, ButtonGroup, ButtonToolbar, Modal } from "react-bootstrap";
import { IImage } from "../../models/image";
import { IMessage } from "../../models/message";

export interface IProp {
  show: boolean;
  image: IImage | undefined;
  message: IMessage | undefined;
  handleHide: () => void;
  onConfirmed: (image: IImage, message: IMessage) => void;
}

const IssueImageDelete = (props: IProp) => {
  const { show, image, message, handleHide, onConfirmed } = props;

  return (
    <div>
      {image !== undefined && message !== undefined && (
        <Modal centered show={show} onHide={handleHide}>
          <Modal.Header>Confirm Deletion of image: {image.name}</Modal.Header>
          <Modal.Body>
            <ButtonToolbar>
              <ButtonGroup className="w-100">
                <Button
                  className="w-50"
                  variant="danger"
                  onClick={() => onConfirmed(image, message)}
                >
                  Yes, Delete!
                </Button>

                <Button onClick={() => handleHide()} variant="secondary">
                  Cancel
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default IssueImageDelete;
