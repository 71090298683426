import React, { useEffect, useState } from "react";
import { faSpinner, faThList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataResult } from "@progress/kendo-data-query";
import { History } from "history";
import { connect } from "react-redux";
import IssueAccordion from "../components/Issues/IssueAccordion";
import IssueGrid from "../components/Issues/IssueGrid";

import { store } from "../index";
import { fetchTags } from "store/actions/tag";
import { ITag } from "models/tag";
import { IIssue } from "../models/issue";
import {
  isInternal,
  isServicePoint,
  isTechnicalSupport,
  IUser,
} from "../models/user";
import { Paths } from "../routes";
import { updateUser } from "../store/actions/auth";
import {
  deleteIssue,
  fetchFilteredIssues,
  FETCH_FILTERED_ISSUES_SUCCESS,
} from "../store/actions/issues";
import { editUser } from "../store/actions/users";
import { IRootState } from "../store/index";
import { useAsyncErrorHandler } from "../utils/asyncErrorHandler";
import { IGroup } from "models/group";
import { fetchGroups } from "store/actions/groups";
import Aux from "../utils/auxiliary";
import AddNewButton from "components/Common/AddNewButton";
import { Col, Row } from "react-bootstrap";

export interface IProps {
  fetchFilteredIssues: (queryStr: string) => Promise<any>;
  fetchTags: () => any;
  fetchGroups: () => any;
  deleteIssue: (id: number, _Issue: IIssue) => any;
  editUser: (id: number, user: IUser) => any;
  updateUser: () => any;
  loading: boolean;
  issues: DataResult;
  current: IUser;
  match: any;
  history: History;
  tags: ITag[];
  groups: IGroup[];
}

export const IssuesContainer: React.FC<IProps> = (props) => {
  const {
    fetchFilteredIssues,
    editUser,
    updateUser,
    fetchTags,
    fetchGroups,
    tags,
    groups,
    issues,
    loading,
    history,
    current,
    match,
  } = props;

  useAsyncErrorHandler(store.getState().issuesReducer.errors);

  const [showGrid, setShowGrid] = useState<boolean>(
    localStorage.getItem("show_grid") != null
  );
  const [caseView, setCaseView] = useState<number>(
    match.path === "/partrequest"
      ? 1
      : match.path === "/administration"
      ? 2
      : match.path === "/warranty"
      ? 3
      : match.path === "/orkeldirekte"
      ? 5
      : match.path === "/hms"
      ? 6
      : match.path === "/maintenance"
      ? 7
      : match.path === "/technicalsupport"
      ? 8
      : isServicePoint(current)
      ? 5
      : 4
  );

  useEffect(() => {
    const address = match.path;
    if (isTechnicalSupport(current)) {
      setCaseView(8);
      localStorage.setItem("issueView", "technicalsupport");
    } else if (address === "/partrequest") {
      setCaseView(1);
      localStorage.setItem("issueView", "partrequest");
    } else if (address === "/administration") {
      setCaseView(2);
      localStorage.setItem("issueView", "administration");
    } else if (address === "/warranty") {
      setCaseView(3);
      localStorage.setItem("issueView", "warranty");
    } else if (address === "/orkeldirekte") {
      setCaseView(5);
      localStorage.setItem("issueView", "orkeldirekte");
    } else if (address === "/hms") {
      setCaseView(6);
      localStorage.setItem("issueView", "hms");
    } else if (address === "/maintenance") {
      setCaseView(7);
      localStorage.setItem("issueView", "maintenance");
    } else if (address === "/technicalsupport") {
      setCaseView(8);
      localStorage.setItem("issueView", "technicalsupport");
    } else if (isServicePoint(current)) {
      setCaseView(5);
      localStorage.setItem("issueView", "orkeldirekte");
    } else {
      localStorage.setItem("issueView", "case");
      setCaseView(4);
    }
    if (tags.length <= 0 && (isInternal(current) || isServicePoint(current))) {
      fetchTags();
    }
    if (groups.length <= 0) {
      fetchGroups();
    }
  }, [match]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFetch = (query: string) => {
    fetchFilteredIssues(query).catch((e) => {
      console.error(e);
    });
  };

  useEffect(() => {
    if (current.id <= 0) {
      updateUser();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onUserEdit = (user: IUser) => {
    return new Promise<void>(async (resolve, reject) => {
      if (user.id) {
        const result = await editUser(user.id, user);
        if (result) {
          updateUser();
          resolve();
        } else reject();
      }
    });
  };

  const newIssue = () => {
    history.push(Paths.issuesCreate);
  };

  const editIssue = (i: IIssue) => {
    history.push(Paths.gIssuesId(i.id));
  };

  const mql = window.matchMedia("(min-width: 1000px)");

  return (
    <Aux>
      <Row className="mb-2">
        {mql.matches && (
          <Col className="col-12 col-md-4">
            {!isTechnicalSupport(current) && (
              <FontAwesomeIcon
                icon={faThList}
                size="2x"
                title="Toggle grid view"
                className={`cursor${showGrid ? "" : " text-muted"}`}
                onClick={() => {
                  const active = !showGrid;
                  setShowGrid(active);
                  active
                    ? localStorage.setItem("show_grid", "true")
                    : localStorage.removeItem("show_grid");
                }}
              />
            )}
          </Col>
        )}
        <Col className="col-12 col-md-4">
          <h4 className={"text-center"}>
            {caseView === 4
              ? "Cases"
              : caseView === 1
              ? "Part Request"
              : caseView === 2
              ? "Administration"
              : caseView === 3
              ? "Warranty cases"
              : caseView === 5
              ? "Orkel Direkte"
              : caseView === 6
              ? "HMS"
              : caseView === 7
              ? "Maintenance"
              : "Technical Support"}
          </h4>
        </Col>
        <Col className="col-12 col-md-4"></Col>
      </Row>
      {mql.matches && showGrid && !isTechnicalSupport(current) ? (
        <IssueGrid
          issues={issues}
          handleFetch={handleFetch}
          handleRowClick={editIssue}
          current={current}
          onUserEdit={onUserEdit}
          caseView={caseView}
        />
      ) : (
        <IssueAccordion
          issues={issues.data}
          totalIssues={issues.total}
          history={history}
          current={current}
          editIssue={editIssue}
          handleFetch={handleFetch}
          onUserEdit={onUserEdit}
          caseView={caseView}
        />
      )}
      {loading && (
        <p className="text-center m-3">
          <FontAwesomeIcon icon={faSpinner} size="3x" spin />
        </p>
      )}
      <AddNewButton onCLick={() => newIssue()} />
    </Aux>
  );
};

const mapStateToProps = (state: IRootState) => ({
  current: state.authReducer.user,
  issues: state.issuesReducer.filteredIssues,
  tags: state.tagReducer.tags,
  groups: state.groupsReducer.groups,
  loading:
    state.loadingReducer.inProgress.includes(FETCH_FILTERED_ISSUES_SUCCESS) &&
    state.issuesReducer.filteredIssues.data.length === 0,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchFilteredIssues: (queryStr: string) =>
    dispatch(fetchFilteredIssues(queryStr)),
  deleteIssue: (id: number, issue: IIssue) => dispatch(deleteIssue(id, issue)),
  editUser: (id: number, user: IUser) => dispatch(editUser(id, user)),
  updateUser: () => dispatch(updateUser()),
  fetchTags: () => dispatch(fetchTags()),
  fetchGroups: () => dispatch(fetchGroups()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IssuesContainer);
