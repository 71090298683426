const locales = ["nb", "nn", "da", "de"];

const options: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};

export const dateTimeFormatDay = Intl.DateTimeFormat(locales, options);

export const dateTimeFormatHour = Intl.DateTimeFormat(locales, {
  ...options,
  hour: "2-digit",
});

export const dateTimeFormatMinute = Intl.DateTimeFormat(locales, {
  ...options,
  hour: "2-digit",
  minute: "2-digit",
});

export const dateTimeFormatSecond = Intl.DateTimeFormat(locales, {
  ...options,
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
});

export default dateTimeFormatMinute;
