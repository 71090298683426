import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  requestBegin,
  requestEnd,
} from "../../store/actions/request";
import { Dispatch } from "redux";
import { DataResult } from "@progress/kendo-data-query";
import { deleteSuccess, saveFailed, saveSuccess } from "./alerts";
import { IResource } from "models/resource";

export const FETCH_RESOURCE_SUCCESS = "FETCH_RESOURCE_SUCCESS";
export const FETCH_RESOURCES_SUCCESS = "FETCH_RESOURCES_SUCCESS";
export const FETCH_FILTERED_RESOURCES_SUCCESS =
  "FETCH_FILTERED_RESOURCES_SUCCESS";
export const CREATE_RESOURCE_SUCCESS = "CREATE_RESOURCE_SUCCESS";
export const EDIT_RESOURCE_SUCCESS = "EDIT_RESOURCE_SUCCESS";
export const DELETE_RESOURCE_SUCCESS = "DELETE_RESOURCE_SUCCESS";
export const RESOURCE_REQUEST_ERRORS = "RESOURCE_REQUEST_ERRORS";
export const RESOURCE_REQUEST_FAILURE = "RESOURCE_REQUEST_FAILURE";

export type ResourceActions = {
  FETCH_RESOURCE_SUCCESS: {
    type: typeof FETCH_RESOURCE_SUCCESS;
    resource: IResource;
  };
  FETCH_RESOURCES_SUCCESS: {
    type: typeof FETCH_RESOURCES_SUCCESS;
    resources: IResource[];
  };
  FETCH_FILTERED_RESOURCES_SUCCESS: {
    type: typeof FETCH_FILTERED_RESOURCES_SUCCESS;
    resources: DataResult;
  };
  CREATE_RESOURCE_SUCCESS: {
    type: typeof CREATE_RESOURCE_SUCCESS;
    resource: IResource;
  };
  DELETE_RESOURCE_SUCCESS: {
    type: typeof DELETE_RESOURCE_SUCCESS;
  };
  EDIT_RESOURCE_SUCCESS: {
    type: typeof EDIT_RESOURCE_SUCCESS;
    resource: IResource;
  };
  RESOURCE_REQUEST_ERRORS: {
    type: typeof RESOURCE_REQUEST_ERRORS;
    errors: any;
  };
  RESOURCE_REQUEST_FAILURE: {
    type: typeof RESOURCE_REQUEST_FAILURE;
    errors: any;
  };
};

export type ResourceActionsTypes =
  | ResourceActions[typeof FETCH_RESOURCE_SUCCESS]
  | ResourceActions[typeof FETCH_RESOURCES_SUCCESS]
  | ResourceActions[typeof FETCH_FILTERED_RESOURCES_SUCCESS]
  | ResourceActions[typeof CREATE_RESOURCE_SUCCESS]
  | ResourceActions[typeof DELETE_RESOURCE_SUCCESS]
  | ResourceActions[typeof EDIT_RESOURCE_SUCCESS]
  | ResourceActions[typeof RESOURCE_REQUEST_ERRORS]
  | ResourceActions[typeof RESOURCE_REQUEST_FAILURE];

export const actionCreators = {
  fetchResourceSuccess: (
    resource: IResource
  ): ResourceActions[typeof FETCH_RESOURCE_SUCCESS] => ({
    type: FETCH_RESOURCE_SUCCESS,
    resource: resource,
  }),
  fetchResourcesSuccess: (
    resources: IResource[]
  ): ResourceActions[typeof FETCH_RESOURCES_SUCCESS] => ({
    type: FETCH_RESOURCES_SUCCESS,
    resources: resources,
  }),
  fetchFilteredResourcesSuccess: (
    resources: DataResult
  ): ResourceActions[typeof FETCH_FILTERED_RESOURCES_SUCCESS] => ({
    type: FETCH_FILTERED_RESOURCES_SUCCESS,
    resources: resources,
  }),
  createResourceSuccess: (
    resource: IResource
  ): ResourceActions[typeof CREATE_RESOURCE_SUCCESS] => ({
    type: CREATE_RESOURCE_SUCCESS,
    resource: resource,
  }),
  deleteResourceSuccess: (): ResourceActions[typeof DELETE_RESOURCE_SUCCESS] => ({
    type: DELETE_RESOURCE_SUCCESS,
  }),
  editResourceSuccess: (
    resource: IResource
  ): ResourceActions[typeof EDIT_RESOURCE_SUCCESS] => ({
    type: EDIT_RESOURCE_SUCCESS,
    resource: resource,
  }),
  resourceRequestErrors: (
    errors: any
  ): ResourceActions[typeof RESOURCE_REQUEST_ERRORS] => ({
    type: RESOURCE_REQUEST_ERRORS,
    errors: errors,
  }),
  resourceRequestFailure: (
    status: number
  ): ResourceActions[typeof RESOURCE_REQUEST_FAILURE] => ({
    type: RESOURCE_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
};

export const fetchResource = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/resources/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchResourceSuccess(json))
      : dispatch(actionCreators.resourceRequestFailure(status));
  };
};

export function fetchResources() {
  return async (dispatch: Dispatch) => {
    const { json } = await getRequest("/resources");
    return dispatch(actionCreators.fetchResourcesSuccess(json));
  };
}

export function fetchFilteredResources(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_FILTERED_RESOURCES_SUCCESS));
    const { status, json } = await getRequest(
      `/resources/filtered?${queryStr}`
    );
    dispatch(requestEnd(FETCH_FILTERED_RESOURCES_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchFilteredResourcesSuccess(json))
      : dispatch(actionCreators.resourceRequestFailure(status));
  };
}

export const createResource = (resource: IResource) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest(`/resources`, resource);
    switch (status) {
      case 200:
        dispatch(saveSuccess(resource.name));
        return dispatch(actionCreators.createResourceSuccess(json));
      case 400:
        dispatch(saveFailed(resource.name));
        return dispatch(actionCreators.resourceRequestErrors(json));
      default:
        dispatch(saveFailed(resource.name));
        return dispatch(actionCreators.resourceRequestFailure(status));
    }
  };
};

export const editResource = (id: number, resource: IResource) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(`/resources/${id}`, resource);
    switch (status) {
      case 200:
        dispatch(saveSuccess(resource.name));
        return dispatch(actionCreators.editResourceSuccess(json));
      case 400:
        dispatch(saveFailed(resource.name));
        return dispatch(actionCreators.resourceRequestErrors(json));
      default:
        dispatch(saveFailed(resource.name));
        return dispatch(actionCreators.resourceRequestFailure(status));
    }
  };
};

export const deleteResource = (id: number, resource: IResource) => {
  return async (dispatch: Dispatch) => {
    const { status } = await deleteRequest(`/resources/${id}`, resource);
    if (status === 200) {
      dispatch(deleteSuccess(resource.name));
      return dispatch(actionCreators.deleteResourceSuccess());
    }
    dispatch(saveFailed(resource.name));
    return dispatch(actionCreators.resourceRequestFailure(status));
  };
};
