import { UIActionsTypes, TOGGLE_NAVIGATION } from "../actions/ui";

export type UIState = {
  readonly navigationActive: boolean;
};

export const initialState: UIState = {
  navigationActive: true,
};

export default function uiReducer(
  state = initialState,
  action: UIActionsTypes
): UIState {
  switch (action.type) {
    case TOGGLE_NAVIGATION:
      return { ...state, navigationActive: !state.navigationActive };
    default:
      return state;
  }
}
