import {
  getRequest,
  postRequest,
  requestBegin,
  requestEnd,
} from "../../store/actions/request";
import { IProductImage } from "../../models/image";
import { Dispatch } from "redux";
import { DataList } from "utils/DataList";

export const FETCH_PRODUCTSIMAGES_SUCCESS = "FETCH_PRODUCTSIMAGES_SUCCESS";
export const FETCH_FILTERED_PRODUCTSIMAGES_SUCCESS =
  "FETCH_FILTERED_PRODUCTSIMAGES_SUCCESS";
export const FETCH_PRODUCTIMAGE_SUCCESS = "FETCH_PRODUCTIMAGE_SUCCESS";
export const PRODUCTIMAGE_REQUEST_ERRORS = "PRODUCTIMAGE_REQUEST_ERRORS";
export const PRODUCTIMAGE_REQUEST_FAILURE = "PRODUCTIMAGE_REQUEST_FAILURE";
export const UPDATE_PRODUCTSIMAGES_SUCCESS = "FETCH_PRODUCTSIMAGES_SUCCESS";
export const CHECK_FOR_PRODUCT_IMAGE = "CHECK_FOR_PRODUCT_IMAGE";

export interface ProductImageActions {
  FETCH_PRODUCTSIMAGES_SUCCESS: {
    type: typeof FETCH_PRODUCTSIMAGES_SUCCESS;
    productsimages: IProductImage[];
  };
  FETCH_FILTERED_PRODUCTSIMAGES_SUCCESS: {
    type: typeof FETCH_FILTERED_PRODUCTSIMAGES_SUCCESS;
    productsimages: DataList<IProductImage>;
  };
  FETCH_PRODUCTIMAGE_SUCCESS: {
    type: typeof FETCH_PRODUCTIMAGE_SUCCESS;
    productimage: IProductImage;
  };
  PRODUCTIMAGE_REQUEST_ERRORS: {
    type: typeof PRODUCTIMAGE_REQUEST_ERRORS;
    errors: any;
  };
  PRODUCTIMAGE_REQUEST_FAILURE: {
    type: typeof PRODUCTIMAGE_REQUEST_FAILURE;
    errors: any;
  };
  UPDATE_PRODUCTSIMAGES_SUCCESS: {
    type: typeof UPDATE_PRODUCTSIMAGES_SUCCESS;
    productsimages: IProductImage[];
  };
  CHECK_FOR_PRODUCT_IMAGE: {
    type: typeof CHECK_FOR_PRODUCT_IMAGE;
    productImages: IProductImage[];
  };
}

export type ProductImageActionsTypes =
  | ProductImageActions[typeof FETCH_PRODUCTSIMAGES_SUCCESS]
  | ProductImageActions[typeof FETCH_FILTERED_PRODUCTSIMAGES_SUCCESS]
  | ProductImageActions[typeof FETCH_PRODUCTIMAGE_SUCCESS]
  | ProductImageActions[typeof PRODUCTIMAGE_REQUEST_ERRORS]
  | ProductImageActions[typeof PRODUCTIMAGE_REQUEST_FAILURE]
  | ProductImageActions[typeof UPDATE_PRODUCTSIMAGES_SUCCESS]
  | ProductImageActions[typeof CHECK_FOR_PRODUCT_IMAGE];

export const actionCreators = {
  fetchProductProductsImagesSuccess: (
    productsimages: IProductImage[]
  ): ProductImageActions[typeof FETCH_PRODUCTSIMAGES_SUCCESS] => ({
    type: FETCH_PRODUCTSIMAGES_SUCCESS,
    productsimages: productsimages,
  }),
  fetchFilteredProductsImagesSuccess: (
    productsimages: DataList<IProductImage>
  ): ProductImageActions[typeof FETCH_FILTERED_PRODUCTSIMAGES_SUCCESS] => ({
    type: FETCH_FILTERED_PRODUCTSIMAGES_SUCCESS,
    productsimages: productsimages,
  }),
  fetchProductImageSuccess: (
    productimage: IProductImage
  ): ProductImageActions[typeof FETCH_PRODUCTIMAGE_SUCCESS] => ({
    type: FETCH_PRODUCTIMAGE_SUCCESS,
    productimage: productimage,
  }),
  productimageRequestErrors: (
    errors: any
  ): ProductImageActions[typeof PRODUCTIMAGE_REQUEST_ERRORS] => ({
    type: PRODUCTIMAGE_REQUEST_ERRORS,
    errors: errors,
  }),
  productimageRequestFailure: (
    status: number
  ): ProductImageActions[typeof PRODUCTIMAGE_REQUEST_FAILURE] => ({
    type: PRODUCTIMAGE_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
  updateProductProductsImagesSuccess: (
    productsimages: IProductImage[]
  ): ProductImageActions[typeof UPDATE_PRODUCTSIMAGES_SUCCESS] => ({
    type: UPDATE_PRODUCTSIMAGES_SUCCESS,
    productsimages: productsimages,
  }),
  checkForProductImageSuccess: (
    productImages: IProductImage[]
  ): ProductImageActions[typeof CHECK_FOR_PRODUCT_IMAGE] => ({
    type: CHECK_FOR_PRODUCT_IMAGE,
    productImages: productImages,
  }),
};

export function fetchProductsImages() {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_PRODUCTSIMAGES_SUCCESS));
    const { status, json } = await getRequest("/productsimages");
    dispatch(requestEnd(FETCH_PRODUCTSIMAGES_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchProductProductsImagesSuccess(json))
      : null;
  };
}

export function fetchFilteredProductsImages(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(PRODUCTIMAGE_REQUEST_FAILURE));
    const { status, json } = await getRequest(`/products/${id}/images`);
    dispatch(requestEnd(PRODUCTIMAGE_REQUEST_FAILURE));
    return status === 200
      ? dispatch(actionCreators.fetchFilteredProductsImagesSuccess(json))
      : dispatch(actionCreators.productimageRequestFailure(status));
  };
}

export function requestProductImagesUpdate() {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_PRODUCTSIMAGES_SUCCESS));
    const { status, json } = await postRequest(`/productsimages/`, {});
    dispatch(requestEnd(FETCH_PRODUCTSIMAGES_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.updateProductProductsImagesSuccess(json))
      : dispatch(actionCreators.productimageRequestFailure(status));
  };
}

export function checkForProductImage(productNo: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(CHECK_FOR_PRODUCT_IMAGE));
    const { status, json } = await getRequest(
      `/productsimages/product/${productNo}`
    );
    dispatch(requestEnd(CHECK_FOR_PRODUCT_IMAGE));
    return status === 200
      ? dispatch(actionCreators.checkForProductImageSuccess(json))
      : dispatch(actionCreators.productimageRequestFailure(status));
  };
}

export const fetchProductImage = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/productsimages/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchProductImageSuccess(json))
      : dispatch(actionCreators.productimageRequestFailure(status));
  };
};
