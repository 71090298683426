import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  requestBegin,
  requestEnd,
} from "../request";
import { IQcProd } from "../../../models/QualityControl/qcProd";
import { Dispatch } from "redux";
import { DataResult } from "@progress/kendo-data-query";
import { deleteSuccess, saveFailed, saveSuccess } from "../alerts";

export const FETCH_QC_PRODS_SUCCESS = "FETCH_QC_PRODS_SUCCESS";
export const FETCH_QC_PROD_SUCCESS = "FETCH_QC_PROD_SUCCESS";
export const FETCH_FILTERED_QC_PRODS_SUCCESS =
  "FETCH_FILTERED_QC_PRODS_SUCCESS";
export const CREATE_QC_PROD_SUCCESS = "CREATE_QC_PROD_SUCCESS";
export const EDIT_QC_PROD_SUCCESS = "EDIT_QC_PROD_SUCCESS";
export const DELETE_QC_PROD_SUCCESS = "DELETE_QC_PROD_SUCCESS";
export const QC_PROD_REQUEST_FAILURE = "QC_PROD_REQUEST_FAILURE";
export const QC_PROD_REQUEST_ERROR = "QC_PROD_REQUEST_ERROR";

export interface QcProdActions {
  FETCH_QC_PRODS_SUCCESS: {
    type: typeof FETCH_QC_PRODS_SUCCESS;
    qcProds: IQcProd[];
  };
  FETCH_QC_PROD_SUCCESS: {
    type: typeof FETCH_QC_PROD_SUCCESS;
    qcProd: IQcProd;
  };
  FETCH_FILTERED_QC_PRODS_SUCCESS: {
    type: typeof FETCH_FILTERED_QC_PRODS_SUCCESS;
    qcProds: DataResult;
  };
  CREATE_QC_PROD_SUCCESS: {
    type: typeof CREATE_QC_PROD_SUCCESS;
    qcProd: IQcProd;
  };
  EDIT_QC_PROD_SUCCESS: {
    type: typeof EDIT_QC_PROD_SUCCESS;
    qcProd: IQcProd;
  };
  DELETE_QC_PROD_SUCCESS: {
    type: typeof DELETE_QC_PROD_SUCCESS;
  };
  QC_PROD_REQUEST_FAILURE: {
    type: typeof QC_PROD_REQUEST_FAILURE;
    errors: any;
  };
  QC_PROD_REQUEST_ERROR: {
    type: typeof QC_PROD_REQUEST_ERROR;
    errors: any;
  };
}

export type QcProdActionTypes =
  | QcProdActions[typeof FETCH_QC_PRODS_SUCCESS]
  | QcProdActions[typeof FETCH_QC_PROD_SUCCESS]
  | QcProdActions[typeof FETCH_FILTERED_QC_PRODS_SUCCESS]
  | QcProdActions[typeof CREATE_QC_PROD_SUCCESS]
  | QcProdActions[typeof EDIT_QC_PROD_SUCCESS]
  | QcProdActions[typeof DELETE_QC_PROD_SUCCESS]
  | QcProdActions[typeof QC_PROD_REQUEST_FAILURE]
  | QcProdActions[typeof QC_PROD_REQUEST_ERROR];

export const actionCreators = {
  fetchQcProdsSuccess: (
    qcProds: IQcProd[]
  ): QcProdActions[typeof FETCH_QC_PRODS_SUCCESS] => ({
    type: FETCH_QC_PRODS_SUCCESS,
    qcProds: qcProds,
  }),
  fetchQcProdSuccess: (
    qcProd: IQcProd
  ): QcProdActions[typeof FETCH_QC_PROD_SUCCESS] => ({
    type: FETCH_QC_PROD_SUCCESS,
    qcProd: qcProd,
  }),
  fetchFilteredQcProdsSuccess: (
    qcProds: DataResult
  ): QcProdActions[typeof FETCH_FILTERED_QC_PRODS_SUCCESS] => ({
    type: FETCH_FILTERED_QC_PRODS_SUCCESS,
    qcProds: qcProds,
  }),
  createQcProdSuccess: (
    qcProd: IQcProd
  ): QcProdActions[typeof CREATE_QC_PROD_SUCCESS] => ({
    type: CREATE_QC_PROD_SUCCESS,
    qcProd: qcProd,
  }),
  editQcProdSuccess: (
    qcProd: IQcProd
  ): QcProdActions[typeof EDIT_QC_PROD_SUCCESS] => ({
    type: EDIT_QC_PROD_SUCCESS,
    qcProd: qcProd,
  }),
  deleteQcProdSuccess: (): QcProdActions[typeof DELETE_QC_PROD_SUCCESS] => ({
    type: DELETE_QC_PROD_SUCCESS,
  }),
  qcProdRequestFailure: (
    status: number
  ): QcProdActions[typeof QC_PROD_REQUEST_FAILURE] => ({
    type: QC_PROD_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
  qcProdRequestError: (
    errors: any
  ): QcProdActions[typeof QC_PROD_REQUEST_ERROR] => ({
    type: QC_PROD_REQUEST_ERROR,
    errors: errors,
  }),
};

export function fetchQcProds() {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_QC_PRODS_SUCCESS));
    const { status, json } = await getRequest("/qcProds");
    dispatch(requestEnd(FETCH_QC_PRODS_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchQcProdsSuccess(json))
      : dispatch(actionCreators.qcProdRequestFailure(status));
  };
}

export const fetchQcProd = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/qcProds/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchQcProdSuccess(json))
      : dispatch(actionCreators.qcProdRequestFailure(status));
  };
};

export function fetchFilteredQcProds(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_FILTERED_QC_PRODS_SUCCESS));
    const { status, json } = await getRequest(`/qcProds/filtered?${queryStr}`);
    dispatch(requestEnd(FETCH_FILTERED_QC_PRODS_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchFilteredQcProdsSuccess(json))
      : dispatch(actionCreators.qcProdRequestFailure(status));
  };
}

export const createQcProd = (qcProd: IQcProd) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest("/qcProds", qcProd);
    switch (status) {
      case 200:
        dispatch(saveSuccess(qcProd.name));
        return dispatch(actionCreators.createQcProdSuccess(json));
      case 400:
        dispatch(saveFailed(qcProd.name));
        return dispatch(actionCreators.qcProdRequestError(json));
      default:
        dispatch(saveFailed(qcProd.name));
        return dispatch(actionCreators.qcProdRequestFailure(status));
    }
  };
};

export const editQcProd = (id: number, qcProd: IQcProd) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(`/qcProds/${id}`, qcProd);
    switch (status) {
      case 200:
        dispatch(saveSuccess(qcProd.name));
        return dispatch(actionCreators.editQcProdSuccess(json));
      case 400:
        dispatch(saveFailed(qcProd.name));
        return dispatch(actionCreators.qcProdRequestError(json));
      default:
        dispatch(saveFailed(qcProd.name));
        return dispatch(actionCreators.qcProdRequestFailure(status));
    }
  };
};

export const deleteQcProd = (id: number, qcProd: IQcProd) => {
  return async (dispatch: Dispatch) => {
    const { status } = await deleteRequest(`/qcProds/${id}`, qcProd);
    if (status === 200) {
      dispatch(deleteSuccess(qcProd.name));
      return dispatch(actionCreators.deleteQcProdSuccess());
    }
    dispatch(saveFailed(qcProd.name));
    return dispatch(actionCreators.qcProdRequestFailure(status));
  };
};
