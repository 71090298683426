import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  requestBegin,
  requestEnd,
} from "../request";
import {
  IQcSpec,
  IQcSpecSearchResult,
} from "../../../models/QualityControl/qcSpec";
import { Dispatch } from "redux";
import { DataResult } from "@progress/kendo-data-query";
import { deleteSuccess, saveFailed, saveSuccess } from "../alerts";

export const FETCH_QC_SPECS_SUCCESS = "FETCH_QC_SPECS_SUCCESS";
export const FETCH_QC_SPEC_SUCCESS = "FETCH_QC_SPEC_SUCCESS";
export const FETCH_FILTERED_QC_SPECS_SUCCESS =
  "FETCH_FILTERED_QC_SPECS_SUCCESS";
export const FETCH_SEARCH_QC_SPECS_SUCCESS = "FETCH_SEARCH_QC_SPECS_SUCCESS";
export const CREATE_QC_SPEC_SUCCESS = "CREATE_QC_SPEC_SUCCESS";
export const EDIT_QC_SPEC_SUCCESS = "EDIT_QC_SPEC_SUCCESS";
export const DELETE_QC_SPEC_SUCCESS = "DELETE_QC_SPEC_SUCCESS";
export const QC_SPEC_REQUEST_FAILURE = "QC_SPEC_REQUEST_FAILURE";
export const QC_SPEC_REQUEST_ERROR = "QC_SPEC_REQUEST_ERROR";

export interface QcSpecActions {
  FETCH_QC_SPECS_SUCCESS: {
    type: typeof FETCH_QC_SPECS_SUCCESS;
    qcSpecs: IQcSpec[];
  };
  FETCH_QC_SPEC_SUCCESS: {
    type: typeof FETCH_QC_SPEC_SUCCESS;
    qcSpec: IQcSpec;
  };
  FETCH_FILTERED_QC_SPECS_SUCCESS: {
    type: typeof FETCH_FILTERED_QC_SPECS_SUCCESS;
    qcSpecs: DataResult;
  };
  FETCH_SEARCH_QC_SPECS_SUCCESS: {
    type: typeof FETCH_SEARCH_QC_SPECS_SUCCESS;
    searchResult: IQcSpecSearchResult;
  };
  CREATE_QC_SPEC_SUCCESS: {
    type: typeof CREATE_QC_SPEC_SUCCESS;
    qcSpec: IQcSpec;
  };
  EDIT_QC_SPEC_SUCCESS: {
    type: typeof EDIT_QC_SPEC_SUCCESS;
    qcSpec: IQcSpec;
  };
  DELETE_QC_SPEC_SUCCESS: {
    type: typeof DELETE_QC_SPEC_SUCCESS;
  };
  QC_SPEC_REQUEST_FAILURE: {
    type: typeof QC_SPEC_REQUEST_FAILURE;
    errors: any;
  };
  QC_SPEC_REQUEST_ERROR: {
    type: typeof QC_SPEC_REQUEST_ERROR;
    errors: any;
  };
}

export type QcSpecActionTypes =
  | QcSpecActions[typeof FETCH_QC_SPECS_SUCCESS]
  | QcSpecActions[typeof FETCH_QC_SPEC_SUCCESS]
  | QcSpecActions[typeof FETCH_FILTERED_QC_SPECS_SUCCESS]
  | QcSpecActions[typeof FETCH_SEARCH_QC_SPECS_SUCCESS]
  | QcSpecActions[typeof CREATE_QC_SPEC_SUCCESS]
  | QcSpecActions[typeof EDIT_QC_SPEC_SUCCESS]
  | QcSpecActions[typeof DELETE_QC_SPEC_SUCCESS]
  | QcSpecActions[typeof QC_SPEC_REQUEST_FAILURE]
  | QcSpecActions[typeof QC_SPEC_REQUEST_ERROR];

export const actionCreators = {
  fetchQcSpecsSuccess: (
    qcSpecs: IQcSpec[]
  ): QcSpecActions[typeof FETCH_QC_SPECS_SUCCESS] => ({
    type: FETCH_QC_SPECS_SUCCESS,
    qcSpecs: qcSpecs,
  }),
  fetchQcSpecSuccess: (
    qcSpec: IQcSpec
  ): QcSpecActions[typeof FETCH_QC_SPEC_SUCCESS] => ({
    type: FETCH_QC_SPEC_SUCCESS,
    qcSpec: qcSpec,
  }),
  fetchFilteredQcSpecsSuccess: (
    qcSpecs: DataResult
  ): QcSpecActions[typeof FETCH_FILTERED_QC_SPECS_SUCCESS] => ({
    type: FETCH_FILTERED_QC_SPECS_SUCCESS,
    qcSpecs: qcSpecs,
  }),
  fetchSearchQcSpecsSuccess: (
    searchResult: IQcSpecSearchResult
  ): QcSpecActions[typeof FETCH_SEARCH_QC_SPECS_SUCCESS] => ({
    type: FETCH_SEARCH_QC_SPECS_SUCCESS,
    searchResult: searchResult,
  }),
  createQcSpecSuccess: (
    qcSpec: IQcSpec
  ): QcSpecActions[typeof CREATE_QC_SPEC_SUCCESS] => ({
    type: CREATE_QC_SPEC_SUCCESS,
    qcSpec: qcSpec,
  }),
  editQcSpecSuccess: (
    qcSpec: IQcSpec
  ): QcSpecActions[typeof EDIT_QC_SPEC_SUCCESS] => ({
    type: EDIT_QC_SPEC_SUCCESS,
    qcSpec: qcSpec,
  }),
  deleteQcSpecSuccess: (): QcSpecActions[typeof DELETE_QC_SPEC_SUCCESS] => ({
    type: DELETE_QC_SPEC_SUCCESS,
  }),
  qcSpecRequestFailure: (
    status: number
  ): QcSpecActions[typeof QC_SPEC_REQUEST_FAILURE] => ({
    type: QC_SPEC_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
  qcSpecRequestError: (
    errors: any
  ): QcSpecActions[typeof QC_SPEC_REQUEST_ERROR] => ({
    type: QC_SPEC_REQUEST_ERROR,
    errors: errors,
  }),
};

export function fetchQcSpecs() {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_QC_SPECS_SUCCESS));
    const { status, json } = await getRequest("/qcSpecs");
    dispatch(requestEnd(FETCH_QC_SPECS_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchQcSpecsSuccess(json))
      : dispatch(actionCreators.qcSpecRequestFailure(status));
  };
}

export const fetchQcSpec = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/qcSpecs/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchQcSpecSuccess(json))
      : dispatch(actionCreators.qcSpecRequestFailure(status));
  };
};

export function fetchFilteredQcSpecs(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_FILTERED_QC_SPECS_SUCCESS));
    const { status, json } = await getRequest(`/qcSpecs/filtered?${queryStr}`);
    dispatch(requestEnd(FETCH_FILTERED_QC_SPECS_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchFilteredQcSpecsSuccess(json))
      : dispatch(actionCreators.qcSpecRequestFailure(status));
  };
}

export function fetchSearchQcSpecs(attributeFilter: number[], prodId?: number) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_SEARCH_QC_SPECS_SUCCESS));
    const { status, json } = await getRequest(
      `/qcSpecs/search?jsonAttrFilter=${encodeURIComponent(
        JSON.stringify(attributeFilter)
      )}${prodId === undefined ? `` : `&productId=` + prodId}`
    );
    dispatch(requestEnd(FETCH_SEARCH_QC_SPECS_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchSearchQcSpecsSuccess(json))
      : dispatch(actionCreators.qcSpecRequestFailure(status));
  };
}

export const createQcSpec = (qcSpec: IQcSpec) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest("/qcSpecs", qcSpec);
    switch (status) {
      case 200:
        dispatch(saveSuccess(qcSpec.name));
        return dispatch(actionCreators.createQcSpecSuccess(json));
      case 400:
        dispatch(saveFailed(qcSpec.name));
        return dispatch(actionCreators.qcSpecRequestError(json));
      default:
        dispatch(saveFailed(qcSpec.name));
        return dispatch(actionCreators.qcSpecRequestFailure(status));
    }
  };
};

export const editQcSpec = (id: number, qcSpec: IQcSpec) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(`/qcSpecs/${id}`, qcSpec);
    switch (status) {
      case 200:
        dispatch(saveSuccess(qcSpec.name));
        return dispatch(actionCreators.editQcSpecSuccess(json));
      case 400:
        dispatch(saveFailed(qcSpec.name));
        return dispatch(actionCreators.qcSpecRequestError(json));
      default:
        dispatch(saveFailed(qcSpec.name));
        return dispatch(actionCreators.qcSpecRequestFailure(status));
    }
  };
};

export const deleteQcSpec = (id: number, qcSpec: IQcSpec) => {
  return async (dispatch: Dispatch) => {
    const { status } = await deleteRequest(`/qcSpecs/${id}`, qcSpec);
    if (status === 200) {
      dispatch(deleteSuccess(qcSpec.name));
      return dispatch(actionCreators.deleteQcSpecSuccess());
    }
    dispatch(saveFailed(qcSpec.name));
    return dispatch(actionCreators.qcSpecRequestFailure(status));
  };
};
