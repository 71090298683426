import React, { useEffect, useState } from "react";
import {
  Grid,
  GridColumn,
  GridExpandChangeEvent,
} from "@progress/kendo-react-grid";
import { History } from "history";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { fetchProductStructures } from "store/actions/products";
import { checkForProductImage } from "store/actions/productsimages";
import { IProductImage } from "models/image";
import { IProduct, IProductStructure } from "models/product";
import { Paths } from "routes";

export interface IProps {
  productStructures: IProductStructure[];
  ShowParents: boolean;
  history: History;
  handleStructureImageClick: (productStructure: IProductStructure) => any;
  fetchProductStructures: (id: string) => any;
  checkForProductImage: (productNo: string) => any;
}

const ProductStructureView: React.FC<IProps> = (props) => {
  const {
    productStructures,
    ShowParents,
    history,
    handleStructureImageClick,
    fetchProductStructures,
    checkForProductImage,
  } = props;

  const [structureData, setStructureData] = useState<IProductStructure[]>();
  const [productLoading, setProductLoading] = useState<boolean>(false);
  const [selectedStructure, setSelectedStructure] = useState<string>("");
  const [showNoImagesFound, setShowNoImagesFound] = useState<boolean>(false);
  const [CheckingForImage, setCheckingForImage] = useState<boolean>(false);

  useEffect(() => {
    setStructureData(productStructures);
  }, [productStructures]);

  useEffect(() => {
    if (showNoImagesFound) {
      setTimeout(() => setShowNoImagesFound(false), 1500);
    }
  }, [showNoImagesFound]);

  const onExpandChange = (e: GridExpandChangeEvent) => {
    if (structureData === undefined) return;
    const structureList = [...structureData];
    var itemIndex = structureList.findIndex(
      (x) => x.productNo === e.dataItem.productNo
    );
    if (itemIndex === -1) return;

    setSelectedStructure(structureList[itemIndex].productNo);

    if (
      !structureList[itemIndex].expanded &&
      structureList[itemIndex].productNo.trim().length !== 0
    ) {
      setProductLoading(true);
      fetchProductStructures(structureList[itemIndex].productNo).then(
        ({ product }: { product: IProduct }) => {
          structureList[itemIndex].childrenProductStructures =
            product.childrenProductStructures;
          structureList[itemIndex].parentProductStructures =
            product.parentProductStructures;
          structureList[itemIndex].expanded = !e.dataItem.expanded;
          setStructureData(structureList);
          setProductLoading(false);
          setSelectedStructure("");
        }
      );
    } else {
      structureList[itemIndex].expanded = !e.dataItem.expanded;
      setStructureData(structureList);
      setSelectedStructure("");
    }
  };

  const handleCheckForImageClick = (strcture: IProductStructure) => {
    if (structureData === undefined) return;
    const structureList = [...structureData];
    var itemIndex = structureList.findIndex(
      (x) => x.productNo === strcture.productNo
    );

    if (itemIndex === -1) return;
    setCheckingForImage(true);
    checkForProductImage(strcture.productNo).then(
      ({ productImages }: { productImages: IProductImage[] }) => {
        if (productImages && productImages.length > 0) {
          structureList[itemIndex].images = productImages;
          strcture.images = productImages;
          setStructureData(structureList);
          handleStructureImageClick(strcture);
          setCheckingForImage(false);
        } else {
          setShowNoImagesFound(true);
          setCheckingForImage(false);
        }
      }
    );
  };

  const handleProdClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    productNo: string
  ) => {
    event.preventDefault();
    history.push(Paths.gProductsId(productNo));
  };

  return (
    <div>
      <Grid
        data={productStructures}
        detail={(props) => {
          return (
            <div>
              {(ShowParents
                ? props.dataItem.parentProductStructures
                : props.dataItem.childrenProductStructures) !== undefined &&
              (ShowParents
                ? props.dataItem.parentProductStructures
                : props.dataItem.childrenProductStructures
              ).length > 0 ? (
                <ProductStructureView
                  productStructures={
                    ShowParents
                      ? props.dataItem.parentProductStructures
                      : props.dataItem.childrenProductStructures
                  }
                  ShowParents={ShowParents}
                  history={history}
                  handleStructureImageClick={handleStructureImageClick}
                  fetchProductStructures={fetchProductStructures}
                  checkForProductImage={checkForProductImage}
                />
              ) : (
                <p className="ms-4">
                  This product{" "}
                  {ShowParents
                    ? "is not used in another product"
                    : "doesn't contain another product"}{" "}
                </p>
              )}
            </div>
          );
        }}
        expandField="expanded"
        onExpandChange={(e) => onExpandChange(e)}
        rowRender={(props, item) => {
          const propProps = { ...props.props };
          return { ...props, props: { ...propProps } };
        }}
      >
        <GridColumn
          field="productNo"
          title="(Product No) Description"
          cell={(cell) => {
            return (
              <td>
                <Row>
                  <Col className="col-15 col-md-7">
                    <a
                      href={"/products/" + cell.dataItem.productNo}
                      style={{ textDecoration: "none", padding: "20px" }}
                      title={"Product " + cell.dataItem.productNo}
                      onClick={(e) =>
                        handleProdClick(e, cell.dataItem.productNo)
                      }
                      className="mx-0 px-0 text-success"
                    >
                      {"(" +
                        cell.dataItem.productNo +
                        ") " +
                        (cell.dataItem.desc && cell.dataItem.desc.length > 0
                          ? cell.dataItem.desc
                          : "")}
                    </a>
                    {productLoading &&
                      selectedStructure === cell.dataItem.productNo && (
                        <p className="text-center m-3">
                          <FontAwesomeIcon icon={faSpinner} size="3x" spin />
                        </p>
                      )}
                  </Col>
                  <Col className="col-15 col-md-2">
                    <p className="my-2">Qty: {cell.dataItem.qty}</p>
                  </Col>
                  <Col className="col-15 col-md-3">
                    {cell.dataItem.images && cell.dataItem.images.length > 0 ? (
                      <Button
                        className="float-end"
                        variant="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          handleStructureImageClick(cell.dataItem);
                        }}
                      >
                        Product drawing
                      </Button>
                    ) : (
                      <Button
                        className="float-end"
                        variant="secondary"
                        onClick={(e) => {
                          e.preventDefault();
                          handleCheckForImageClick(cell.dataItem);
                        }}
                      >
                        Check for images
                      </Button>
                    )}
                  </Col>
                </Row>
              </td>
            );
          }}
        />
      </Grid>

      <Modal
        show={showNoImagesFound}
        onHide={() => setShowNoImagesFound(false)}
        centered
      >
        <Modal.Body>
          <h5 className="px-4 py-2">No images found</h5>
        </Modal.Body>
      </Modal>
      {CheckingForImage && (
        <p className="text-center m-3">
          <FontAwesomeIcon
            icon={faSpinner}
            size="3x"
            spin
            style={{
              top: "50%",
              left: "50%",
              position: "fixed",
              zIndex: 999,
            }}
          />
        </p>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchProductStructures: (id: string) => dispatch(fetchProductStructures(id)),
  checkForProductImage: (productNo: string) =>
    dispatch(checkForProductImage(productNo)),
});

export default connect(null, mapDispatchToProps)(ProductStructureView);
