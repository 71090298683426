import React, { useCallback, useState } from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DataResult,
  DataSourceRequestState,
  toDataSourceRequestString,
} from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { History } from "history";
import { connect } from "react-redux";

import Aux from "../utils/auxiliary";
import ProductGrid from "../components/Products/ProductGrid";
import { store } from "../index";
import { IProduct } from "../models/product";
import { IUser } from "../models/user";
import { Paths } from "../routes";
import { getRequest, postRequest } from "../store/actions/request";
import { useAsyncErrorHandler } from "../utils/asyncErrorHandler";
import { IRootState } from "../store/index";
import {
  fetchFilteredProducts,
  FETCH_FILTEREDPRODUCTS_SUCCESS,
} from "../store/actions/products";
import { Button } from "react-bootstrap";

export interface IProps {
  fetchFilteredProducts: (queryStr: string) => any;
  products: DataResult;
  productsExport: DataResult;
  history: History;
  current: IUser;
}

export const ProductsContainer: React.FC<IProps> = (props) => {
  useAsyncErrorHandler(store.getState().productsReducer.errors);

  const { fetchFilteredProducts, products, history, current } = props;

  const [exportState, setExportState] = useState<ExcelExport>();
  const [exporting, setExportingState] = useState<boolean>();
  const [updatingImages, setImageUpdateState] = useState<boolean>();

  const filteredProdCb = useCallback(fetchFilteredProducts, [
    fetchFilteredProducts,
  ]);

  const fetchProductData = useCallback(
    (state: DataSourceRequestState) => {
      const queryStr = `${toDataSourceRequestString(state)}`;
      return filteredProdCb(queryStr);
    },
    [filteredProdCb]
  );

  const handleProductRequestChange = useCallback(
    (event: DataSourceRequestState) => {
      return fetchProductData(event);
    },
    [fetchProductData]
  );

  const handleViewClick = (i: IProduct) => {
    history.push(Paths.gProductsId(i.productNo));
  };

  const handleProductExcelRequestChange = (exporter: ExcelExport) => {
    setExportState(exporter);
  };

  const handleExportClick = () => {
    const newState: DataSourceRequestState = {
      skip: undefined,
      take: undefined,
      filter: {
        filters: [],
        logic: "and" as const,
      },
    };

    setExportingState(true);
    const queryStr = `${toDataSourceRequestString(newState)}`;
    getRequest(`/products/filtered?${queryStr}`).then((response) => {
      exportState?.save(response.json.data);
      setExportingState(false);
    });
  };

  const handleRefreshImagesClick = () => {
    const refreshRequest: Record<string, string> = {
      reRender: "true",
    };

    setImageUpdateState(true);
    const queryStr = `${new URLSearchParams(refreshRequest).toString()}`;
    postRequest(`/productsimages/?${queryStr}`, {}).then((response) => {
      setImageUpdateState(false);
    });
  };

  return (
    <Aux>
      <ProductGrid
        productsData={products}
        onProductRequestChange={handleProductRequestChange}
        onViewClick={handleViewClick}
        handleProductExcelRequestChange={handleProductExcelRequestChange}
        current={current}
      />

      <br></br>

      <div style={{ width: "170px" }}>
        <Button
          title="Export Excel"
          className="k-button k-primary"
          onClick={handleExportClick}
        >
          Export to Excel
        </Button>

        {exporting && (
          <FontAwesomeIcon
            icon={faSpinner}
            size="2x"
            style={{ float: "right" }}
            spin
          />
        )}
      </div>
      <br></br>

      {current.roles.find((x) => x === "admin") && (
        <div style={{ width: "210px" }}>
          <Button
            title="Force Refresh Images"
            className="k-button k-primary"
            onClick={handleRefreshImagesClick}
            disabled={updatingImages}
          >
            Force Refresh Images
          </Button>

          {updatingImages && (
            <FontAwesomeIcon
              icon={faSpinner}
              size="2x"
              style={{ float: "right" }}
              spin
            />
          )}
        </div>
      )}
    </Aux>
  );
};

const mapStateToProps = (state: IRootState) => ({
  current: state.authReducer.user,
  products: state.productsReducer.filteredProducts,
  loading:
    state.loadingReducer.inProgress.includes(FETCH_FILTEREDPRODUCTS_SUCCESS) &&
    state.productsReducer.filteredProducts.data.length === 0,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchFilteredProducts: (queryStr: string) =>
    dispatch(fetchFilteredProducts(queryStr)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsContainer);
