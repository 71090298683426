import { Dispatch } from "redux";
import { IMeasurementUnit } from "../../models/measurementunit";
import { deleteSuccess, saveFailed, saveSuccess } from "./alerts";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  requestBegin,
  requestEnd,
} from "./request";

export const FETCH_MEASUREMENTUNITS_SUCCESS = "FETCH_MEASUREMENTUNITS_SUCCESS";
export const FETCH_MEASUREMENTUNIT_SUCCESS = "FETCH_MEASUREMENTUNIT_SUCCESS";
export const CREATE_MEASUREMENTUNIT_SUCCESS = "CREATE_MEASUREMENTUNIT_SUCCESS";
export const EDIT_MEASUREMENTUNITS_SUCCESS = "EDIT_MEASUREMENTUNITS_SUCCESS";
export const EDIT_MEASUREMENTUNIT_SUCCESS = "EDIT_MEASUREMENTUNIT_SUCCESS";
export const DELETE_MEASUREMENTUNIT_SUCCESS = "DELETE_MEASUREMENTUNIT_SUCCESS";
export const MEASUREMENTUNIT_REQUEST_ERRORS = "MEASUREMENTUNIT_REQUEST_ERRORS";
export const MEASUREMENTUNIT_REQUEST_FAILURE =
  "MEASUREMENTUNIT_REQUEST_FAILURE";

export interface MeasurementActions {
  FETCH_MEASUREMENTUNITS_SUCCESS: {
    type: typeof FETCH_MEASUREMENTUNITS_SUCCESS;
    measurementUnits: IMeasurementUnit[];
  };
  FETCH_MEASUREMENTUNIT_SUCCESS: {
    type: typeof FETCH_MEASUREMENTUNIT_SUCCESS;
    measurementUnit: IMeasurementUnit;
  };
  CREATE_MEASUREMENTUNIT_SUCCESS: {
    type: typeof CREATE_MEASUREMENTUNIT_SUCCESS;
    measurementUnit: IMeasurementUnit;
  };
  EDIT_MEASUREMENTUNIT_SUCCESS: {
    type: typeof EDIT_MEASUREMENTUNIT_SUCCESS;
    measurementUnit: IMeasurementUnit;
  };
  EDIT_MEASUREMENTUNITS_SUCCESS: {
    type: typeof EDIT_MEASUREMENTUNITS_SUCCESS;
    measurementUnits: IMeasurementUnit[];
  };
  DELETE_MEASUREMENTUNIT_SUCCESS: {
    type: typeof DELETE_MEASUREMENTUNIT_SUCCESS;
  };
  MEASUREMENTUNIT_REQUEST_ERRORS: {
    type: typeof MEASUREMENTUNIT_REQUEST_ERRORS;
    errors: any;
  };
  MEASUREMENTUNIT_REQUEST_FAILURE: {
    type: typeof MEASUREMENTUNIT_REQUEST_FAILURE;
    errors: any;
  };
}

export type MeasurementActionTypes =
  | MeasurementActions[typeof FETCH_MEASUREMENTUNITS_SUCCESS]
  | MeasurementActions[typeof FETCH_MEASUREMENTUNIT_SUCCESS]
  | MeasurementActions[typeof CREATE_MEASUREMENTUNIT_SUCCESS]
  | MeasurementActions[typeof EDIT_MEASUREMENTUNIT_SUCCESS]
  | MeasurementActions[typeof EDIT_MEASUREMENTUNITS_SUCCESS]
  | MeasurementActions[typeof DELETE_MEASUREMENTUNIT_SUCCESS]
  | MeasurementActions[typeof MEASUREMENTUNIT_REQUEST_ERRORS]
  | MeasurementActions[typeof MEASUREMENTUNIT_REQUEST_FAILURE];

export const actionCreators = {
  fetchMeasurementUnitsSuccess: (
    measurementUnits: IMeasurementUnit[]
  ): MeasurementActions[typeof FETCH_MEASUREMENTUNITS_SUCCESS] => ({
    type: FETCH_MEASUREMENTUNITS_SUCCESS,
    measurementUnits: measurementUnits,
  }),
  fetchMeasurementUnitSuccess: (
    measurementUnit: IMeasurementUnit
  ): MeasurementActions[typeof FETCH_MEASUREMENTUNIT_SUCCESS] => ({
    type: FETCH_MEASUREMENTUNIT_SUCCESS,
    measurementUnit: measurementUnit,
  }),
  createMeasurementUnitSuccess: (
    measurementUnit: IMeasurementUnit
  ): MeasurementActions[typeof CREATE_MEASUREMENTUNIT_SUCCESS] => ({
    type: CREATE_MEASUREMENTUNIT_SUCCESS,
    measurementUnit: measurementUnit,
  }),
  editMeasurementUnitSuccess: (
    measurementUnit: IMeasurementUnit
  ): MeasurementActions[typeof EDIT_MEASUREMENTUNIT_SUCCESS] => ({
    type: EDIT_MEASUREMENTUNIT_SUCCESS,
    measurementUnit: measurementUnit,
  }),
  editMeasurementUnitsSuccess: (
    measurementUnits: IMeasurementUnit[]
  ): MeasurementActions[typeof EDIT_MEASUREMENTUNITS_SUCCESS] => ({
    type: EDIT_MEASUREMENTUNITS_SUCCESS,
    measurementUnits: measurementUnits,
  }),
  deleteMeasurementUnitSuccess: (): MeasurementActions[typeof DELETE_MEASUREMENTUNIT_SUCCESS] => ({
    type: DELETE_MEASUREMENTUNIT_SUCCESS,
  }),
  measurementUnitRequestErrors: (
    errors: any
  ): MeasurementActions[typeof MEASUREMENTUNIT_REQUEST_ERRORS] => ({
    type: MEASUREMENTUNIT_REQUEST_ERRORS,
    errors: errors,
  }),
  measurementUnitRequestFailure: (
    status: number
  ): MeasurementActions[typeof MEASUREMENTUNIT_REQUEST_FAILURE] => ({
    type: MEASUREMENTUNIT_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
};

export function fetchMeasurementUnits() {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_MEASUREMENTUNITS_SUCCESS));
    const { status, json } = await getRequest("/measurementunits");
    dispatch(requestEnd(FETCH_MEASUREMENTUNITS_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchMeasurementUnitsSuccess(json))
      : null;
  };
}

export const fetchMeasurementUnit = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/measurementunits/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchMeasurementUnitSuccess(json))
      : dispatch(actionCreators.measurementUnitRequestFailure(status));
  };
};

export const createMeasurementUnit = (measurementUnit: IMeasurementUnit) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest(
      "/measurementunits",
      measurementUnit
    );
    switch (status) {
      case 200:
        dispatch(saveSuccess(measurementUnit.unit));
        return dispatch(actionCreators.createMeasurementUnitSuccess(json));
      case 400:
        dispatch(saveFailed(measurementUnit.unit));
        return dispatch(actionCreators.measurementUnitRequestErrors(json));
      default:
        dispatch(saveFailed(measurementUnit.unit));
        return dispatch(actionCreators.measurementUnitRequestFailure(status));
    }
  };
};

export const editMeasurementUnit = (
  id: number,
  measurementUnit: IMeasurementUnit
) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(
      `/measurementunits/${id}`,
      measurementUnit
    );
    switch (status) {
      case 200:
        dispatch(saveSuccess(measurementUnit.unit));
        return dispatch(actionCreators.editMeasurementUnitSuccess(json));
      case 400:
        dispatch(saveFailed(measurementUnit.unit));
        return dispatch(actionCreators.measurementUnitRequestErrors(json));
      default:
        dispatch(saveFailed(measurementUnit.unit));
        return dispatch(actionCreators.measurementUnitRequestFailure(status));
    }
  };
};

export const editMeasurementUnits = (measurementUnits: IMeasurementUnit[]) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(
      `/measurementunits`,
      measurementUnits
    );
    switch (status) {
      case 200:
        dispatch(saveSuccess("Measurement Units"));
        return dispatch(actionCreators.editMeasurementUnitsSuccess(json));
      case 400:
        dispatch(saveFailed("Measurement Units"));
        return dispatch(actionCreators.measurementUnitRequestErrors(json));
      default:
        dispatch(saveFailed("Measurement Units"));
        return dispatch(actionCreators.measurementUnitRequestFailure(status));
    }
  };
};

export const deleteMeasurementUnit = (
  id: string,
  measurementUnit: IMeasurementUnit
) => {
  return async (dispatch: Dispatch) => {
    const { status } = await deleteRequest(
      `/measurementunit/${id}`,
      measurementUnit
    );
    if (status === 200) {
      dispatch(deleteSuccess(measurementUnit.unit));
      return dispatch(actionCreators.deleteMeasurementUnitSuccess());
    }
    dispatch(saveFailed(measurementUnit.unit));
    return dispatch(actionCreators.measurementUnitRequestFailure(status));
  };
};
