import { ConnectedRouter } from "connected-react-router";
import { History } from "history";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import AuthContext from "../components/Auth/AuthContext";
import Layout from "../components/Navigation/Layout";
import { IUser } from "../models/user";
import { completeAuthentication } from "../store/actions/auth";

export interface IProps {
  history: History;
}

export interface IDispatchProps {
  completeAuthentication: () => any;
}

export interface IStateProps {
  isAuthenticated: boolean;
  user: IUser | null;
}

export const App: React.FC<IProps & IDispatchProps & IStateProps> = (props) => {
  const { history, user, isAuthenticated, completeAuthentication } = props;

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("code")) {
      completeAuthentication();
    }
  }, [props.history]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthContext.Provider value={{ user, isAuthenticated }}>
      <ConnectedRouter history={history}>
        <Layout history={history} />
      </ConnectedRouter>
    </AuthContext.Provider>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.authReducer.user,
  isAuthenticated: state.authReducer.isAuthenticated,
});

const mapDispatchToProps = (dispatch: any) => ({
  completeAuthentication: () => dispatch(completeAuthentication()),
});

export default connect<IStateProps, IDispatchProps, IProps>(
  mapStateToProps,
  mapDispatchToProps
)(App);
