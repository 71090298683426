import React from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { IRootState } from "../../store";
export interface IProps {
  isAuthenticated: boolean;
}

const Forbidden: React.FC<IProps> = (props) => {
  const { isAuthenticated } = props;

  return (
    <div className="text-center">
      <h1>Forbidden</h1>
      <p>You are forbidden to access this resource.</p>
      <p>
        If you were given a new role you might need to re-login to get access
      </p>
      {isAuthenticated ? (
        <div>
          <Button variant="danger" href="/logout" className="m-2">
            Log out
          </Button>
          <Button variant="primary" href="/">
            Go to start
          </Button>
        </div>
      ) : (
        <Button variant="primary" href="/">
          Go to start
        </Button>
      )}
    </div>
  );
};

const mapStateToProps = (state: IRootState) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
});

export default connect(mapStateToProps)(Forbidden);
