import React, { useRef, useState } from "react";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Accordion, Button, Card, Col, Form, Row } from "react-bootstrap";
import { isQcAdmin, IUser } from "../../models/user";
import { UploadFileInfo } from "@progress/kendo-react-upload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { IImage } from "../../models/image";
import { IIssue, IIssueRootCause } from "../../models/issue";
import QcCheckpointAttachments from "../QualityControl/QcCheckpoint/QcCheckpoinAttachements";
import Aux from "../../utils/auxiliary";
import QcCheckpointImageModal from "../QualityControl/QcCheckpoint/QcCheckpointImageModal";
import { useEffect } from "react";
import SaveBottomBar from "../Common/SaveBottomBar";
import dateTimeFormat from "utils/dateTimeFormat";
import Upload from "components/Common/Upload";

export interface IProps {
  currentUser: IUser;
  issue: IIssue;
  isShown: boolean;
  dirty: boolean;
  uploading: boolean;
  setUploading: (value: boolean) => any;
  setIssueClone: (issue: IIssue) => any;
  setDirty: (value: boolean) => any;
  refetchIssue: (id: number) => any;
}

const IssueRootCauseForm: React.FC<IProps> = (props) => {
  const {
    currentUser,
    issue,
    isShown,
    dirty,
    uploading,
    setUploading,
    setIssueClone,
    setDirty,
    refetchIssue,
  } = props;

  const [noteFilesView, setNoteFilesView] = useState<boolean>(false);
  const [images, setImages] = useState<IImage[]>([]);
  const [viewImage, setViewImage] = useState<IImage>();
  const [textAreaHeight, setTextAreaHeight] = useState("38px");
  const [text, setText] = useState("");
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [files, setFiles] = useState<UploadFileInfo[]>([]);

  useEffect(() => {
    if (
      issue.issueRootCause &&
      issue.issueRootCause.statusId === 1 &&
      issue.issueRootCause.note &&
      issue.issueRootCause.note.length > 0 &&
      isShown
    )
      setTextAreaHeight(`${textAreaRef.current!.scrollHeight}px`);
  }, [text, textAreaHeight, issue, isShown]);

  useEffect(() => {
    if (issue.issueRootCause === undefined || issue.issueRootCause.id > 0)
      setFiles([]);
  }, [issue.issueRootCause]);

  const editedObject = () => {
    if (!dirty) {
      setDirty(true);
    }
  };

  const handleFileAdd = (file: IImage) => {
    if (issue.issueRootCause) {
      issue.issueRootCause.images.push(file);
    } else {
      let newRootCause = {
        statusId: 0,
        issueId: issue.id,
        images: [file],
      } as IIssueRootCause;
      issue.issueRootCause = newRootCause;
    }
    editedObject();
    setIssueClone({ ...issue });
  };

  const handleFileRemove = (file: UploadFileInfo) => {
    issue.issueRootCause.images = issue.issueRootCause.images.filter(
      (i) => !i.name.includes(file.name)
    );
    setIssueClone({ ...issue });
  };

  const handleImageRemove = (file: IImage) => {
    issue.issueRootCause.images = issue.issueRootCause.images.filter(
      (i) => !i.name.includes(file.name)
    );
    editedObject();
    setIssueClone({ ...issue });
  };

  const handleStatusChange = (value: number) => {
    if (issue.issueRootCause) {
      issue.issueRootCause.statusId = value;
      issue.issueRootCause.userId = currentUser.id;
      issue.issueRootCause.user = currentUser;
      issue.issueRootCause.checkedAt = new Date();
    } else {
      let newRootCause = {
        statusId: value,
        issueId: issue.id,
        userId: currentUser.id,
        user: currentUser,
        checkedAt: value > 0 ? new Date() : "",
        images: [] as IImage[],
      } as IIssueRootCause;
      issue.issueRootCause = newRootCause;
    }
    editedObject();
    setIssueClone({ ...issue });
  };

  const handleNoteChange = (value: string) => {
    if (issue.issueRootCause) {
      issue.issueRootCause.note = value;
    } else {
      let newRootCause = {
        statusId: 0,
        issueId: issue.id,
        note: value,
        images: [] as IImage[],
      } as IIssueRootCause;
      issue.issueRootCause = newRootCause;
    }
    editedObject();
    setIssueClone({ ...issue });
  };

  const mql = window.matchMedia("(max-width: 500px)");

  return (
    <Aux>
      <Card bg="secondary">
        <Card.Header style={{ backgroundColor: "rgb(60, 60, 60)" }}>
          <h5>Root Cause Detected</h5>
        </Card.Header>
        <Card.Body
          style={{ backgroundColor: "rgb(60, 60, 60)" }}
          className={mql.matches ? "py-1" : ""}
        >
          <Form.Group className="mb-3">
            <Row className="mb-2">
              <Col lg={4}>
                <Checkbox
                  className="checklist-checkpoint"
                  name="statusId"
                  label="Yes"
                  style={{ height: "22px", width: "22px" }}
                  checked={
                    issue?.issueRootCause?.statusId
                      ? issue.issueRootCause?.statusId === 1
                      : false
                  }
                  onChange={(e) => {
                    handleStatusChange(
                      issue?.issueRootCause
                        ? issue?.issueRootCause?.statusId !== 1
                          ? 1
                          : 0
                        : 1
                    );
                  }}
                  disabled={issue.status === "Closed"}
                />
              </Col>
              <Col lg={4}>
                <Checkbox
                  className="checklist-checkpoint"
                  name="statusId"
                  label="No"
                  style={{ height: "22px", width: "22px" }}
                  checked={
                    issue?.issueRootCause?.statusId
                      ? issue.issueRootCause?.statusId === 2
                      : false
                  }
                  onChange={(e) => {
                    handleStatusChange(
                      issue?.issueRootCause
                        ? issue?.issueRootCause?.statusId !== 2
                          ? 2
                          : 0
                        : 2
                    );
                  }}
                  disabled={issue.status === "Closed"}
                />
              </Col>
              <Col lg={4}>
                <Checkbox
                  className="checklist-checkpoint"
                  name="statusId"
                  label="Not Applicable"
                  style={{ height: "22px", width: "22px" }}
                  checked={
                    issue?.issueRootCause
                      ? issue.issueRootCause?.statusId === 3
                      : false
                  }
                  onChange={(e) => {
                    handleStatusChange(
                      issue?.issueRootCause
                        ? issue?.issueRootCause?.statusId !== 3
                          ? 3
                          : 0
                        : 3
                    );
                  }}
                  disabled={issue.status === "Closed"}
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col className="text-muted">
                <small>
                  {issue &&
                  issue.issueRootCause &&
                  issue.issueRootCause.user &&
                  issue.issueRootCause.statusId > 0 ? (
                    <em>
                      Checked by: {issue.issueRootCause.user.fullNameWithTitle}{" "}
                      {issue.issueRootCause.checkedAt
                        ? dateTimeFormat.format(
                            new Date(issue.issueRootCause.checkedAt)
                          )
                        : ""}
                    </em>
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </small>
              </Col>
            </Row>
            <div className="d-flex">
              <div style={{ width: "100%" }}>
                <Form.Control
                  type="text"
                  name="note"
                  as="textarea"
                  placeholder="Describe root cause"
                  value={
                    issue?.issueRootCause ? issue?.issueRootCause?.note : ""
                  }
                  ref={textAreaRef}
                  rows={1}
                  style={{
                    minHeight: "40px",
                    height: textAreaHeight,
                    overflow: "hidden",
                  }}
                  onChange={(e) => {
                    setTextAreaHeight("auto");
                    setText(e.target.value);
                    handleNoteChange(e.target.value);
                  }}
                  required={issue.issueRootCause?.statusId === 1}
                />
                <Accordion>
                  <Accordion.Collapse in={noteFilesView} eventKey="2">
                    <div>
                      <Upload
                        images={images}
                        currentUser={currentUser}
                        files={files}
                        disabled={false}
                        setUploading={setUploading}
                        setImages={setImages}
                        setFiles={setFiles}
                        handleFileAdd={handleFileAdd}
                        handleFileRemove={handleFileRemove}
                      />
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              </div>
              <Button
                variant="secondary"
                onClick={() => setNoteFilesView(!noteFilesView)}
                style={{ height: "40px" }}
              >
                <FontAwesomeIcon icon={faPaperclip} />
              </Button>
            </div>
            <QcCheckpointAttachments
              images={issue.issueRootCause ? issue.issueRootCause.images : []}
              viewFile={(i) => setViewImage(i)}
              canTrash={isQcAdmin(currentUser)}
              removeImage={(i) => handleImageRemove(i)}
              bg="dark"
            />
          </Form.Group>
        </Card.Body>
      </Card>
      {issue.id && (
        <SaveBottomBar
          show={dirty}
          disabled={uploading}
          onCancel={() => {
            refetchIssue(issue.id);
            setDirty(false);
          }}
        />
      )}
      <QcCheckpointImageModal
        handleHide={() => setViewImage(undefined)}
        view={viewImage !== undefined}
        image={viewImage}
      />
    </Aux>
  );
};

export default IssueRootCauseForm;
