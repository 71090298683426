import { getRequest, putRequest } from "../../store/actions/request";
import { IMessage } from "../../models/message";
import { saveSuccess } from "../../store/actions/alerts";
import { Dispatch } from "redux";

export const FETCH_MESSAGES_BY_ISSUE = "FETCH_MESSAGES_BY_ISSUE";
export const EDIT_MESSAGE_SUCCESS = "EDIT_MESSAGE_SUCCESS";
export const MESSAGE_REQUEST_ERRORS = "MESSAGE_REQUEST_ERRORS";
export const MESSAGE_REQUEST_FAILURE = "MESSAGE_REQUEST_FAILURE";

export interface MessageActions {
  FETCH_MESSAGES_BY_ISSUE: {
    type: typeof FETCH_MESSAGES_BY_ISSUE;
    messages: IMessage[];
  };
  EDIT_MESSAGE_SUCCESS: {
    type: typeof EDIT_MESSAGE_SUCCESS;
    message: IMessage;
  };
  MESSAGE_REQUEST_ERRORS: {
    type: typeof MESSAGE_REQUEST_ERRORS;
    errors: any;
  };
  MESSAGE_REQUEST_FAILURE: {
    type: typeof MESSAGE_REQUEST_FAILURE;
    errors: any;
  };
}

export type MessageActionsTypes =
  | MessageActions[typeof FETCH_MESSAGES_BY_ISSUE]
  | MessageActions[typeof EDIT_MESSAGE_SUCCESS]
  | MessageActions[typeof MESSAGE_REQUEST_ERRORS]
  | MessageActions[typeof MESSAGE_REQUEST_FAILURE];

export const actionCreators = {
  fetchMessagesByIssueSuccess: (
    messages: IMessage[]
  ): MessageActions[typeof FETCH_MESSAGES_BY_ISSUE] => ({
    type: FETCH_MESSAGES_BY_ISSUE,
    messages: messages,
  }),
  editMessageSuccess: (
    message: IMessage
  ): MessageActions[typeof EDIT_MESSAGE_SUCCESS] => ({
    type: EDIT_MESSAGE_SUCCESS,
    message: message,
  }),
  messageRequestErrors: (
    errors: any
  ): MessageActions[typeof MESSAGE_REQUEST_ERRORS] => ({
    type: MESSAGE_REQUEST_ERRORS,
    errors: errors,
  }),
  messageRequestFailure: (
    status: number
  ): MessageActions[typeof MESSAGE_REQUEST_FAILURE] => ({
    type: MESSAGE_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
};

export const fetchMessagesByIssue = (issueId: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(
      `/issuemessages/issue/${issueId}`
    );
    switch (status) {
      case 200:
        return dispatch(actionCreators.fetchMessagesByIssueSuccess(json));
      case 400:
        return dispatch(actionCreators.messageRequestErrors(json));
      default:
        return dispatch(actionCreators.messageRequestFailure(status));
    }
  };
};

export const editMessage = (id: number, message: IMessage) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(`/issuemessages/${id}`, message);
    switch (status) {
      case 200:
        dispatch(saveSuccess(message.body));
        return dispatch(actionCreators.editMessageSuccess(json));
      case 400:
        return dispatch(actionCreators.messageRequestErrors(json));
      default:
        return dispatch(actionCreators.messageRequestFailure(status));
    }
  };
};
