import {
  QcProdTypeActionTypes,
  FETCH_QC_PRODTYPES_SUCCESS,
  FETCH_QC_PRODTYPE_SUCCESS,
  FETCH_FILTERED_QC_PRODTYPES_SUCCESS,
  CREATE_QC_PRODTYPE_SUCCESS,
  EDIT_QC_PRODTYPE_SUCCESS,
  DELETE_QC_PRODTYPE_SUCCESS,
} from "../../actions/QualityControl/qcProdType";
import { IQcProdType } from "../../../models/QualityControl/qcProdType";
import { DataList } from "../../../utils/DataList";

export interface QcProdTypeState {
  readonly qcProdTypes: IQcProdType[];
  readonly filteredQcProdTypes: DataList<IQcProdType>;
  readonly qcProdType: IQcProdType | null;
  readonly errors: any;
}

export const initialState: QcProdTypeState = {
  qcProdTypes: [],
  filteredQcProdTypes: { data: [], total: 0 },
  qcProdType: null,
  errors: null,
};

const qcProdTypeReducer = (
  state = initialState,
  action: QcProdTypeActionTypes
): QcProdTypeState => {
  switch (action.type) {
    case FETCH_QC_PRODTYPES_SUCCESS:
      return { ...state, qcProdTypes: action.qcProdTypes };
    case FETCH_QC_PRODTYPE_SUCCESS:
      return { ...state, qcProdType: action.qcProdType };
    case FETCH_FILTERED_QC_PRODTYPES_SUCCESS:
      return { ...state, filteredQcProdTypes: action.qcProdTypes };
    case CREATE_QC_PRODTYPE_SUCCESS:
      return {
        ...state,
        qcProdType: action.qcProdType,
        qcProdTypes:
          state.qcProdTypes.length > 0
            ? [...state.qcProdTypes.concat([action.qcProdType])]
            : [],
      };
    case EDIT_QC_PRODTYPE_SUCCESS:
      if (state.qcProdTypes.length > 0) {
        state.qcProdTypes.splice(
          state.qcProdTypes.findIndex((t) => t.id === action.qcProdType.id),
          1,
          action.qcProdType
        );
      }
      return {
        ...state,
        qcProdType: action.qcProdType,
        qcProdTypes: state.qcProdTypes.length > 0 ? state.qcProdTypes : [],
      };
    case DELETE_QC_PRODTYPE_SUCCESS:
      return { ...state, qcProdType: null, qcProdTypes: [] };
    default:
      return state;
  }
};

export default qcProdTypeReducer;
