import {
  TagActionsTypes,
  FETCH_TAGS_SUCCESS,
  FETCH_FILTERED_TAGS_SUCCESS,
  TAG_REQUEST_FAILURE,
  FETCH_TAG_SUCCESS,
  TAG_REQUEST_ERRORS,
  CREATE_TAG_SUCCESS,
  DELETE_TAG_SUCCESS,
  EDIT_TAG_SUCCESS,
} from "../actions/tag";
import { ITag } from "../../models/tag";
import { DataList } from "../../utils/DataList";

export interface TagState {
  readonly tags: ITag[];
  readonly filteredTags: DataList<ITag>;
  readonly tag: ITag;
  readonly errors: any;
}

export const initialState: TagState = {
  tags: [],
  filteredTags: { data: [], total: 0 },
  tag: {} as ITag,
  errors: null,
};

const tagReducer = (
  state = initialState,
  action: TagActionsTypes
): TagState => {
  switch (action.type) {
    case FETCH_TAGS_SUCCESS:
      return { ...state, tags: action.tags };
    case FETCH_FILTERED_TAGS_SUCCESS:
      return { ...state, filteredTags: action.tags };
    case TAG_REQUEST_FAILURE:
      return { ...state, errors: action.errors };
    case FETCH_TAG_SUCCESS:
      return { ...state, tag: action.tag };
    case TAG_REQUEST_ERRORS:
      return { ...state, errors: action.errors };
    case CREATE_TAG_SUCCESS:
      return {
        ...state,
        tag: action.tag,
        tags: state.tags.length > 0 ? [...state.tags.concat([action.tag])] : [],
      };
    case DELETE_TAG_SUCCESS:
      return { ...state, tag: {} as ITag };
    case EDIT_TAG_SUCCESS:
      if (state.tags.length > 0) {
        state.tags.splice(
          state.tags.findIndex((t) => t.id === action.tag.id),
          1,
          action.tag
        );
      }
      return {
        ...state,
        tag: action.tag,
        tags: state.tags.length > 0 ? state.tags : [],
      };
    default:
      return state;
  }
};

export default tagReducer;
