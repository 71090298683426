import {
  QcProdActionTypes,
  FETCH_QC_PRODS_SUCCESS,
  FETCH_QC_PROD_SUCCESS,
  FETCH_FILTERED_QC_PRODS_SUCCESS,
  CREATE_QC_PROD_SUCCESS,
  EDIT_QC_PROD_SUCCESS,
  DELETE_QC_PROD_SUCCESS,
} from "../../actions/QualityControl/qcProd";
import { IQcProd } from "../../../models/QualityControl/qcProd";
import { DataList } from "../../../utils/DataList";

export interface QcProdState {
  readonly qcProds: IQcProd[];
  readonly filteredQcProds: DataList<IQcProd>;
  readonly qcProd: IQcProd | null;
  readonly errors: any;
}

export const initialState: QcProdState = {
  qcProds: [],
  filteredQcProds: { data: [], total: 0 },
  qcProd: null,
  errors: null,
};

const qcProdReducer = (
  state = initialState,
  action: QcProdActionTypes
): QcProdState => {
  switch (action.type) {
    case FETCH_QC_PRODS_SUCCESS:
      return { ...state, qcProds: action.qcProds };
    case FETCH_QC_PROD_SUCCESS:
      return { ...state, qcProd: action.qcProd };
    case FETCH_FILTERED_QC_PRODS_SUCCESS:
      return { ...state, filteredQcProds: action.qcProds };
    case CREATE_QC_PROD_SUCCESS:
      return {
        ...state,
        qcProd: action.qcProd,
        qcProds:
          state.qcProds.length > 0
            ? [...state.qcProds.concat([action.qcProd])]
            : [],
      };
    case EDIT_QC_PROD_SUCCESS:
      if (state.qcProds.length > 0) {
        state.qcProds.splice(
          state.qcProds.findIndex((t) => t.id === action.qcProd.id),
          1,
          action.qcProd
        );
      }
      return {
        ...state,
        qcProd: action.qcProd,
        qcProds: state.qcProds.length > 0 ? state.qcProds : [],
      };
    case DELETE_QC_PROD_SUCCESS:
      return { ...state, qcProd: null, qcProds: [] };
    default:
      return state;
  }
};

export default qcProdReducer;
