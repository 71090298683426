import React, { useEffect, useRef, useState } from "react";
import {
  faChevronDown,
  faChevronUp,
  faInfoCircle,
  faPaperclip,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataResult } from "@progress/kendo-data-query";
import { filterBy } from "@progress/kendo-react-data-tools";
import {
  ComboBox,
  MultiSelectTree,
  MultiSelectTreeFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { History } from "history";
import { process as filterProcess } from "@progress/kendo-data-query";
import { UploadFileInfo } from "@progress/kendo-react-upload";
import {
  Accordion,
  Alert,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  Col,
  Form,
  Row,
} from "react-bootstrap";
import SignatureCanvas from "react-signature-canvas";
import { Prompt } from "react-router";

import { IGroup } from "../../models/group";
import { IImage } from "../../models/image";
import { IIssue, IIssueSfCase, IUserSignature } from "../../models/issue";
import { IIssueType } from "../../models/issuetype";
import { IMessage } from "../../models/message";
import { IPriority } from "../../models/priority";
import { IProduct } from "../../models/product";
import {
  isHmsAdmin,
  isKitter,
  isServicePoint,
  isTechnicalSupport,
  IUser,
} from "../../models/user";
import { ITag, ITagCategory } from "../../models/tag";
import { IQcChassi } from "../../models/QualityControl/qcChassis";
import { IOrder } from "../../models/order";
import { IJig } from "models/jig";
import Aux from "../../utils/auxiliary";
import Upload from "components/Common/Upload";
import IssueRootCauseForm from "./IssueRootCauseForm";
import TaggableInputField from "components/Common/TaggableInputField";
import IssueChecklistContainer from "../../containers/IssueChecklistContainer";
import TaggedIssueItems from "./TaggedIssueItems";
import IssuePdfExport from "./IssuePdfExport";
import AddCheckpointInput from "./AddCheckpointInput";
import { IQcCheckpoint } from "models/QualityControl/qcCheckpoint";
import dateTimeFormat from "utils/dateTimeFormat";
import { IQcInspection } from "models/QualityControl/qcInspection";

export interface IProps {
  onSubmit: (message: IMessage, issue: IIssue) => any;
  onCancel: () => any;
  followIssue: (issueId: number, userId: number) => any;
  onJigRequestChange: (token: any) => Promise<any>;
  onUserRequestChange: (token: any) => any;
  setReplyTo: (replyTo: IMessage | undefined) => void;
  refetchIssue: (id: number) => any;
  setDidSave: (value: boolean) => any;
  setIssueUsers: (users: IUser[]) => any;
  caseView: number;
  didSave: boolean;
  usersData: DataResult;
  issueUsers: IUser[];
  groups: IGroup[];
  priorities: IPriority[];
  issueTypes: IIssueType[];
  replyTo?: IMessage;
  issue: IIssue;
  message: IMessage;
  messages: IMessage[];
  history: History;
  currentUser: IUser;
  tags: ITag[];
  jigs: IJig[];
  tagCategories: ITagCategory[];
  checklistIssue?: boolean;
  timetrackIssue?: boolean;
  waitingForPartsCase?: boolean;
  reworkWeldsIssue?: boolean;
  scrappedWeldsIssue?: boolean;
  watching?: number;
  saveLoading: boolean;
  signalRInspections: IQcInspection[];
  externalUsers: IUser[];
}

const IssueForm: React.FC<IProps> = (props) => {
  const {
    onSubmit,
    onUserRequestChange,
    onJigRequestChange,
    setReplyTo,
    refetchIssue,
    setDidSave,
    setIssueUsers,
    followIssue,
    caseView,
    didSave,
    usersData,
    issueUsers,
    groups,
    priorities,
    issueTypes,
    replyTo,
    issue,
    message,
    messages,
    history,
    currentUser,
    tags,
    jigs,
    tagCategories,
    checklistIssue,
    timetrackIssue,
    waitingForPartsCase,
    reworkWeldsIssue,
    scrappedWeldsIssue,
    watching,
    saveLoading,
    signalRInspections,
    externalUsers,
  } = props;

  const [attachmentState, setAttachmentState] = useState<boolean>(false);
  const [checkpointState, setCheckpointState] = useState<boolean>(false);
  const [removedItem, setRemovedItem] = useState<boolean>(false);
  const [replyToClone, setReplyToClone] = useState<IMessage | undefined>();
  const [issueClone, setIssueClone] = useState<IIssue>(issue);
  const [messageClone, setMessageClone] = useState<IMessage>(message);
  const [accordionState, setAccordionState] = useState<boolean>(false);
  const [showTagOptions, setShowTagOptions] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [showChecklist, setShowChecklist] = useState<boolean>(true);
  const [categories, setCategories] = useState<any[]>([]);
  const [checkpoints, setCheckpoints] = useState<IQcCheckpoint[]>([]);
  const [filteredGroups, setFilteredGroups] = useState<IGroup[]>(groups);
  const [uploading, setUploading] = useState<boolean>(false);
  const [signatureURL, setSignatureURL] = useState<string | null>(null);
  const [signatureName, setSignatureName] = useState<string>("");
  const [images, setImages] = useState<IImage[]>([]);
  const [files, setFiles] = useState<UploadFileInfo[]>([]);
  const [showSignatureCreated, setShowSignatureCreated] = useState<boolean>(
    false
  );
  const [filteredCategories, setFilteredCategories] = useState<any[]>(
    tagCategories
  );
  const internalRoles = process.env.REACT_APP_INTERNAL_ROLES;

  const isInternal = () => {
    return (
      internalRoles && currentUser?.roles.some((r) => internalRoles.includes(r))
    );
  };

  useEffect(() => {
    if (showSignatureCreated) {
      setTimeout(() => setShowSignatureCreated(false), 1000);
    }
  }, [showSignatureCreated]);

  useEffect(() => {
    const noCategoryTags = tags.filter((t) => t.categoryNames.length === 0);
    noCategoryTags.forEach((t) => {
      if (issueClone.tags.some((it) => it.id === t.id)) {
        t.selected = true;
      } else {
        t.selected = false;
      }
    });
    const categoriesWithTags = tagCategories.filter(
      (tc) => tc.tags.length > 0 && !tc.hide
    );
    categoriesWithTags.forEach((tc) => {
      tc.tags.sort((a, b) => {
        return ("" + a.name).localeCompare(b.name);
      });
      if (tc.tags.some((t) => issueClone.tags.some((it) => it.id === t.id))) {
        tc.expanded = true;
      } else {
        tc.expanded = false;
      }
      tc.tags.forEach((t) => {
        if (issueClone.tags.some((it) => it.id === t.id)) {
          t.selected = true;
        } else {
          t.selected = false;
        }
      });
      tc.id = tc.id + 1000;
    });
    setCategories(() => [...categoriesWithTags, ...noCategoryTags]);
  }, [tags, issue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFilteredGroups(groups);
  }, [groups]);

  useEffect(() => {
    setIssueClone({ ...issueClone, users: issueUsers });
  }, [issueUsers]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFilteredCategories(categories);
  }, [categories]);

  const onTagFilterChange = (event: MultiSelectTreeFilterChangeEvent) => {
    const filteredTags = filterBy(categories, [event.filter], "tags");
    if (filteredTags.length > 0) {
      if (event.filter.value.length > 0) {
        filteredTags.forEach((ft) => {
          if (ft.id >= 1000 && ft.tags !== undefined && ft.tags.length > 0) {
            ft.expanded = true;
          }
        });
      } else {
        filteredTags.forEach((ft) => {
          if (
            ft.id >= 1000 &&
            ft.tags.some((t) => issueClone.tags.some((it) => it.id === t.id))
          ) {
            ft.expanded = true;
          } else if (ft.id >= 1000) {
            ft.expanded = false;
          }
        });
      }
      setFilteredCategories(filteredTags);
    }
  };

  const onGoupFilterChange = (event: any) => {
    const filteredGoups = filterProcess(groups, event);
    if (filteredGoups.total > 0) {
      setFilteredGroups(filteredGoups.data);
    }
  };

  useEffect(() => {
    setIssueClone({ ...issue });
    if (issue.id > 0) {
      setShowChecklist(true);
      if (issue.userSignatures && issue.userSignatures.length > 0) {
        setSignatureURL(issue.userSignatures[0].signature);
      }
    }
    setIsSubmitting(false);
  }, [issue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      issueClone.tags &&
      issueClone.tags.some(
        (t) => t.name.toLowerCase() === "tilgangsforespørsel"
      ) &&
      issueClone?.group?.id !== 37
    ) {
      setIssueClone((i) => ({ ...i, group: groups.find((g) => g.id === 37) }));
    }
  }, [issueClone, groups]);

  useEffect(() => {
    if (reworkWeldsIssue && !issue.id) {
      setIssueClone((i) => ({ ...i, group: groups.find((g) => g.id === 1) }));
    } else if (scrappedWeldsIssue && !issue.id) {
      setIssueClone((i) => ({ ...i, group: groups.find((g) => g.id === 4) }));
    }
  }, [issue, groups, reworkWeldsIssue, scrappedWeldsIssue]);

  useEffect(() => {
    if (issueTypes && issueTypes.length > 0 && !issue.id) {
      setIssueClone((i) => ({
        ...i,
        issueType: issueTypes.find((it) => it.id === caseView),
        group: waitingForPartsCase
          ? groups.find((g) => g.id === 2)
          : i.group !== undefined
          ? i.group
          : caseView === 1
          ? groups.find((g) => g.id === 25)
          : caseView === 5
          ? groups.find((g) => g.id === 41)
          : caseView === 4
          ? groups.find((g) => g.id === 5)
          : i.group,
      }));
    }
  }, [waitingForPartsCase, issueTypes, issue, groups, caseView]);

  useEffect(() => {
    setMessageClone({ ...message });
  }, [message]);

  useEffect(() => {
    handleReply(replyTo);
  }, [replyTo]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event: any, value?: any) => {
    const key = event.target.name;
    if (!value) {
      value = event.target.value;
    }
    let tempIssue = { ...issueClone };
    setIssueClone({
      ...tempIssue,
      [key]: value,
      users: addUser(tempIssue, currentUser),
    });
  };

  const addUser = (issue: IIssue, user: IUser) => {
    let users: IUser[] = [];
    if (issue.users) {
      users = issue.users.filter((u) => u.id !== user.id);
    }
    users.push(user);
    return users;
  };

  const handleMessageChange = (event: any) => {
    setMessageClone({
      ...messageClone,
      body: event.target.value,
      userId: currentUser.id,
      issueId: issue.id,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
    setIssueClone({
      ...issueClone,
      users: addUser(issueClone, currentUser),
    });
  };

  const handleItemSelected = (selected: any, inspection: boolean) => {
    const { item, currentTrigger } = selected;
    if (currentTrigger === "#") {
      let products: IProduct[] = [];
      if (issueClone.products) {
        products = issueClone.products.filter(
          (p) => p.productNo !== item.productNo
        );
      }
      products.push(item);
      setIssueClone({
        ...issueClone,
        products: products,
      });
    }
    if (currentTrigger === "€") {
      let mainCauseParts: IProduct[] = [];
      if (issueClone.mainCauseParts) {
        mainCauseParts = issueClone.mainCauseParts.filter(
          (p) => p.productNo !== item.productNo
        );
      }
      mainCauseParts.push(item);
      setIssueClone({
        ...issueClone,
        mainCauseParts: mainCauseParts,
      });
    }
    if (currentTrigger === "%") {
      let chassis: IQcChassi[] = [];
      if (issueClone.qcChassis) {
        chassis = issueClone.qcChassis.filter((c) => c.id !== item.id);
      }
      chassis.push(item);
      setIssueClone({
        ...issueClone,
        qcChassis: chassis,
      });
    }
    if (currentTrigger === "&") {
      let relIssues: IIssue[] = [];
      if (issueClone.relIssues) {
        relIssues = issueClone.relIssues.filter((ri) => ri.id !== item.id);
      }
      relIssues.push(item);
      setIssueClone({
        ...issueClone,
        relIssues: relIssues,
      });
    }
    if (currentTrigger === "$") {
      let orders: IOrder[] = [];
      if (issueClone.orders) {
        orders = issueClone.orders.filter((o) => o.orderNo !== item.orderNo);
      }
      orders.push(item);
      setIssueClone({
        ...issueClone,
        orders: orders,
      });
    }
    if (currentTrigger === "£") {
      let jigs: IJig[] = [];
      if (issueClone.jigs) {
        jigs = issueClone.jigs.filter((j) => j.jignr !== item.jignr);
      }
      jigs.push(item);
      setIssueClone({
        ...issueClone,
        jigs: jigs,
      });
    }
    if (currentTrigger === "*") {
      let sfCases: IIssueSfCase[] = [];
      if (issueClone.salesforceCases) {
        sfCases = issueClone.salesforceCases.filter(
          (sf) => sf.caseNumber !== item.caseNumber
        );
      }
      sfCases.push({ caseNumber: item.caseNumber, id: 0, issueId: 0 });
      setIssueClone({
        ...issueClone,
        salesforceCases: sfCases,
      });
    }
    if (currentTrigger === "@") {
      setIssueClone({
        ...issueClone,
        users: addUser(issueClone, item),
        inspectionUsers: inspection
          ? [...issueClone.inspectionUsers, ...[item]]
          : [],
      });
      setIssueUsers([...issueUsers.filter((iu) => iu.id !== item.id), item]);
      if (!inspection) {
        if (
          isInternal() &&
          externalUsers.some((u) => u.id === item.id) &&
          window.confirm(
            "You tagged an external user. Do you want to make message available to external users?"
          )
        ) {
          setMessageClone({
            ...messageClone,
            roles: [{ name: "external", description: "" }],
            taggedUsers: [...messageClone.taggedUsers, ...[item]],
          });
        } else {
          setMessageClone({
            ...messageClone,
            taggedUsers: [...messageClone.taggedUsers, ...[item]],
          });
        }
      }
    }
  };

  const handleItemRemoved = (item: any) => {
    setRemovedItem(true);
    setIssueClone({
      ...issueClone,
      products: issueClone.products.filter((p) => p !== item),
      users: issueClone.users.filter((u) => u !== item),
      qcChassis: issueClone.qcChassis.filter((c) => c !== item),
      relIssues: issueClone.relIssues.filter((ri) => ri !== item),
      orders: issueClone.orders.filter((o) => o !== item),
      jigs: issueClone.jigs.filter((j) => j !== item),
      salesforceCases: issueClone.salesforceCases.filter((sf) => sf !== item),
    });
  };

  const handleFileAdd = (file: IImage) => {
    const images = messageClone.images ? messageClone.images : [];
    images.push(file);
    const body = messageClone.body ? messageClone.body : "";
    setMessageClone({
      ...messageClone,
      body: body,
      images: images,
    });
  };

  const handleFileRemove = (file: UploadFileInfo) => {
    const images = messageClone.images.filter(
      (i) => !i.name.includes(file.name)
    );
    setMessageClone({
      ...messageClone,
      images: images,
    });
  };

  const handleReply = (replyToMessage?: IMessage) => {
    if (
      !checklistIssue &&
      !timetrackIssue &&
      !reworkWeldsIssue &&
      !scrappedWeldsIssue
    ) {
      if (replyToMessage) {
        setReplyToClone(replyToMessage);
        setReplyTo(replyToMessage);
        setMessageClone({
          ...messageClone,
          body: "",
          replyToId: replyToMessage.id,
          replyTo: replyToMessage,
          createdAt: new Date(),
          updatedAt: new Date(),
        });
      } else {
        setReplyToClone(undefined);
        setReplyTo(undefined);
        setMessageClone({
          ...messageClone,
          body: "",
          replyToId: undefined,
          replyTo: undefined,
          createdAt: new Date(),
          updatedAt: new Date(),
        });
      }
    }
  };

  const onExpandChange = (item: any) => {
    if (categories === undefined) return;
    let categoryList = [...filteredCategories];
    var itemIndex = categoryList.findIndex((x) => x.id === item.id);
    if (itemIndex === -1) return;
    categoryList[itemIndex].expanded = !item.expanded;
    setFilteredCategories(categoryList);
  };

  const onTagSelect = (tag: any, value) => {
    if (categories === undefined) return;
    let tagList = [...filteredCategories];
    var itemIndex = tagList.findIndex((x) => x.id === tag.id);
    if (itemIndex !== -1) {
      tagList[itemIndex].selected = value;
    }
    tagList.forEach((tl) => {
      if (tl.tags !== undefined) {
        var tagIndex = tl.tags.findIndex((x) => x.id === tag.id);
        if (tagIndex !== -1) {
          tl.tags[tagIndex].selected = value;
        }
      }
    });
    setFilteredCategories(tagList);
  };

  const unSelectAllTags = () => {
    let tagList = [...filteredCategories];
    tagList.forEach((tl) => {
      if (tl.tags !== undefined) {
        tl.tags.forEach((t) => {
          t.selected = false;
        });
      } else {
        tl.selected = false;
      }
    });
    setFilteredCategories(tagList);
  };

  const zeroPad = (num, places) => String(num).padStart(places, "0");

  const sigCanvas = useRef<SignatureCanvas>(null);

  const createSignature = () => {
    const URL = sigCanvas.current.getCanvas().toDataURL("image/png");
    setSignatureURL(URL);
    const newSIgature = {
      id: 0,
      issueId: issue.id,
      userName: signatureName,
      signature: URL,
      createdAt: new Date(),
    } as IUserSignature;
    setIssueClone({ ...issueClone, userSignatures: [newSIgature] });
    setShowSignatureCreated(true);
  };

  const handleFollowIssue = () => {
    followIssue(issue.id, currentUser.id);
    setIssueUsers([...issueUsers, currentUser]);
    setIssueClone({
      ...issueClone,
      users: addUser(issueClone, currentUser),
    });
    issue.users = [...issue.users, currentUser];
  };

  const disableSave =
    uploading ||
    (issue.responsibleId === issueClone.responsibleId &&
      (messageClone === undefined || messageClone.body === "") &&
      issue.group?.id === issueClone.group?.id &&
      issue.responsible?.id === issueClone.responsible?.id &&
      issue.issueType?.id === issueClone.issueType?.id &&
      issue.issueRootCause?.id === issueClone.issueRootCause?.id &&
      issue.priority?.id === issueClone.priority?.id &&
      issue.title === issueClone.title &&
      issue.numberOfBales === issueClone.numberOfBales &&
      (issue.hmsLocation === issueClone.hmsLocation ||
        (issue.hmsLocation === undefined && issueClone.hmsLocation === "")) &&
      issue.tags.every((t) => issueClone.tags.some((it) => it.id === t.id)) &&
      issue.tags.length === issueClone.tags.length &&
      issue.users.every((u) => issueUsers.some((iu) => iu.id === u.id)) &&
      issue.users.length === issueUsers.length &&
      issue.status === issueClone.status &&
      images.length === 0 &&
      issue.addedCheckpoints.length === issueClone.addedCheckpoints.length &&
      issue.userSignatures.length === issueClone.userSignatures.length &&
      !isDirty &&
      !removedItem);

  const titleField = (
    <Form.Group className="mb-3">
      <Form.Control
        type="text"
        name="title"
        placeholder="Title, short description of the problem"
        value={issueClone.title ? issueClone.title : ""}
        onChange={handleChange}
        required
        minLength={3}
        className={"issue-placeholder"}
      />
    </Form.Group>
  );

  return (
    <Aux>
      <Form
        className="mb-5 ms-2"
        onSubmit={(e) => {
          e.preventDefault();
          setIsSubmitting(true);
          onSubmit(messageClone, issueClone);
          setIsDirty(false);
          setImages([]);
          setFiles([]);
        }}
      >
        <Prompt
          when={!disableSave && !isSubmitting}
          message="Are you sure you don't want to save before exiting?"
        />
        {!issue.id && (
          <h5>
            New{" "}
            {issueClone.issueType?.id === 4 ? "" : issueClone.issueType?.name}{" "}
            case
          </h5>
        )}
        {issueClone.id ? (
          <div className="d-flex" style={{ flexDirection: "row" }}>
            <h5 style={{ flex: 1 }}>
              {issueClone.id +
                " - " +
                issueClone.title +
                (issueClone.numberOfBales !== undefined &&
                issueClone.numberOfBales > 0
                  ? " - " + issueClone.numberOfBales + " bales"
                  : "")}
            </h5>
            {watching !== undefined && watching > 0 && (
              <small>Watching: {watching}</small>
            )}
          </div>
        ) : (
          titleField
        )}
        <TaggedIssueItems
          issueClone={issueClone}
          currentUser={currentUser}
          history={history}
          handleItemRemoved={handleItemRemoved}
        />
        {issue.id > 0 && issue.issueTypeId === 7 && issue.jig !== undefined && (
          <p className="text-warning">
            Scheduled maintenance time is {issue.jig.maintenanceTime} day
            {issue.jig.maintenanceTime > 1 ? "s" : ""}
          </p>
        )}
        <Form.Group>
          {replyToClone && (
            <Alert variant="warning" onClose={() => handleReply()} dismissible>
              Replying to: <em>{replyToClone.body}</em>
            </Alert>
          )}
          <div className="d-flex">
            <div style={{ width: "100%" }}>
              <TaggableInputField
                handleItemSelected={(selected) =>
                  handleItemSelected(selected, false)
                }
                onChange={(e) => handleMessageChange(e)}
                value={messageClone.body}
                caseView={caseView}
                placeholder="Add a new message (All relevant information about the needed help/problem/solution. See all tags under, remember images/video)"
                className="form-control issue-placeholder"
              />
              <Button
                variant="secondary"
                className="float-end"
                onClick={() => setAttachmentState(!attachmentState)}
              >
                <FontAwesomeIcon icon={faPaperclip} />
              </Button>
            </div>
          </div>
        </Form.Group>
        {attachmentState && (
          <Form.Group className="mb-3">
            <Upload
              images={images}
              currentUser={currentUser}
              files={files}
              disabled={false}
              setUploading={setUploading}
              setFiles={setFiles}
              setImages={setImages}
              handleFileAdd={handleFileAdd}
              handleFileRemove={handleFileRemove}
            />
          </Form.Group>
        )}
        {(caseView === 8 && isInternal()) || caseView !== 8 ? (
          <Row>
            <p className="mb-0">
              Tag options:{" "}
              <span className="ms-2">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  size="lg"
                  className="cursor"
                  onClick={() => setShowTagOptions(!showTagOptions)}
                />
              </span>{" "}
            </p>
          </Row>
        ) : (
          isTechnicalSupport(currentUser) && (
            <small className="text-muted">%: tag chassis no</small>
          )
        )}
        <Accordion.Collapse in={showTagOptions} eventKey="open">
          <div className="bt-0">
            <p className="px-2 mx-2 pb-1 mb-1">@ - User</p>
            <p className="px-2 mx-2 py-1 my-1"># - Product</p>
            {(isInternal() || isKitter(currentUser)) && (
              <p className="px-2 mx-2 py-1 my-1">$ - Order</p>
            )}
            {isInternal() && (
              <div>
                {" "}
                <p className="px-2 mx-2 py-1 my-1">% - Chassis</p>
                <p className="px-2 mx-2 py-1 my-1">& - Case</p>
                <p className="px-2 mx-2 py-1 my-1">* - Salesforce case</p>
                <p className="px-2 mx-2 py-1 my-1">£ - Equipment</p>
                {(caseView === 3 || caseView === 8) && (
                  <p className="px-2 mx-2 py-1 my-1">€ - Main cause part</p>
                )}
              </div>
            )}
          </div>
        </Accordion.Collapse>
        <Form.Group className="mb-3">
          <Row className="mt-4">
            {(isInternal() || isServicePoint(currentUser)) && (
              <Col className="col-12 col-md-6">
                <Form.Label className="mb-0">Responsible</Form.Label>
                <ComboBox
                  className="w-100"
                  placeholder="Responsible"
                  name="responsible"
                  {...usersData}
                  textField="fullNameWithTitle"
                  dataItemKey="id"
                  value={issueClone.responsible}
                  filterable
                  onChange={handleChange}
                  onFilterChange={(e) => onUserRequestChange(e.filter.value)}
                />
              </Col>
            )}
            {(((isInternal() || isServicePoint(currentUser)) &&
              caseView === 3) ||
              caseView === 8) && (
              <Col className="col-12 col-md-6">
                <Form.Label className="mb-0">Number of bales</Form.Label>
                <Form.Control
                  type="number"
                  name="numberOfBales"
                  placeholder="Number of bales"
                  value={
                    issueClone.numberOfBales !== undefined
                      ? issueClone.numberOfBales
                      : ""
                  }
                  onChange={handleChange}
                />
              </Col>
            )}
            {caseView !== 2 &&
              caseView !== 3 &&
              caseView !== 6 &&
              caseView !== 8 &&
              (isInternal() || isServicePoint(currentUser)) && (
                <Col className="col-12 col-md-6">
                  <Form.Label className="mb-0">Group</Form.Label>
                  <ComboBox
                    className="w-100"
                    placeholder="Group"
                    name="group"
                    data={filteredGroups}
                    textField="name"
                    dataItemKey="id"
                    value={issueClone.group}
                    onChange={handleChange}
                    filterable={true}
                    onFilterChange={onGoupFilterChange}
                  />
                </Col>
              )}
          </Row>
        </Form.Group>
        {isInternal() && caseView === 7 && !issue.id && (
          <Form.Group className="mb-3">
            <Row>
              <Col className="col-12 col-md-6">
                <ComboBox
                  className="w-100"
                  placeholder="Maintenance Equipment"
                  name="jig"
                  data={jigs}
                  textField="jignr"
                  dataItemKey="id"
                  value={issueClone.jig}
                  filterable
                  onChange={(e) => {
                    setIssueClone({
                      ...issueClone,
                      jig: e.target.value,
                      tags: [...issueClone.tags, ...e.target.value.tags],
                    });
                  }}
                  onFilterChange={(e) => onJigRequestChange(e.filter.value)}
                  required={caseView === 7 && !issue.id}
                />
              </Col>
            </Row>
          </Form.Group>
        )}
        {caseView === 6 && (
          <Form.Group className="mb-3">
            <Row>
              <Col className="col-md-6">
                <Form.Label className="mb-0">Event date</Form.Label>
              </Col>
              {issueClone.hmsEventDate !== undefined && (
                <Col className="col-md-6">
                  <Form.Label className="mb-0">Time of event</Form.Label>
                </Col>
              )}
            </Row>
            <Row>
              <Col className="col-md-6">
                <Form.Control
                  type="date"
                  name="hmsEventDate"
                  defaultValue={
                    issueClone.hmsEventDate
                      ? new Date(issueClone.hmsEventDate).getFullYear() +
                        "-" +
                        zeroPad(
                          new Date(issueClone.hmsEventDate).getMonth() + 1,
                          2
                        ) +
                        "-" +
                        zeroPad(new Date(issueClone.hmsEventDate).getDate(), 2)
                      : undefined
                  }
                  onChange={(e) => {
                    const newTimeDate = issueClone.hmsEventDate
                      ? new Date(issueClone.hmsEventDate)
                      : new Date(Date.now());
                    const newDate = new Date(e.target.value);
                    newTimeDate.setFullYear(newDate.getFullYear());
                    newTimeDate.setMonth(newDate.getMonth());
                    newTimeDate.setDate(newDate.getDate());
                    setIsDirty(true);
                    handleChange(e, newTimeDate);
                  }}
                  required={caseView === 6}
                />
              </Col>
              {issueClone.hmsEventDate !== undefined && (
                <Col className="col-md-6">
                  <Form.Control
                    type="time"
                    name="hmsEventDate"
                    defaultValue={
                      issueClone.hmsEventDate
                        ? zeroPad(
                            new Date(issueClone.hmsEventDate).getHours(),
                            2
                          ) +
                          ":" +
                          zeroPad(
                            new Date(issueClone.hmsEventDate).getMinutes(),
                            2
                          )
                        : undefined
                    }
                    onChange={(e) => {
                      const newTimeDate = issueClone.hmsEventDate
                        ? new Date(issueClone.hmsEventDate)
                        : new Date(Date.now());
                      const [hour, minutes] = e.target.value
                        .split(":")
                        .map((t) => parseInt(t));
                      newTimeDate.setHours(hour);
                      newTimeDate.setMinutes(minutes);
                      newTimeDate.setSeconds(0, 0);
                      setIsDirty(true);
                      handleChange(e, newTimeDate);
                    }}
                    required={caseView === 6}
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col className="col-md-6">
                <Form.Label className="mb-0 mt-2">Case Deadline</Form.Label>
                <Form.Control
                  type="date"
                  name="deadline"
                  defaultValue={
                    issueClone.deadline
                      ? new Date(issueClone.deadline).getFullYear() +
                        "-" +
                        zeroPad(
                          new Date(issueClone.deadline).getMonth() + 1,
                          2
                        ) +
                        "-" +
                        zeroPad(new Date(issueClone.deadline).getDate(), 2)
                      : undefined
                  }
                  onChange={(e) => {
                    const newTimeDate = issueClone.deadline
                      ? new Date(issueClone.deadline)
                      : new Date(Date.now());
                    const newDate = new Date(e.target.value);
                    newTimeDate.setFullYear(newDate.getFullYear());
                    newTimeDate.setMonth(newDate.getMonth());
                    newTimeDate.setDate(newDate.getDate());
                    setIsDirty(true);
                    handleChange(e, newTimeDate);
                  }}
                />
              </Col>
              <Col className="col-md-6">
                <Form.Label className="mb-0 mt-2">Location of event</Form.Label>
                <Form.Control
                  type="text"
                  name="hmsLocation"
                  defaultValue={issueClone.hmsLocation}
                  onChange={handleChange}
                  required={issueClone.issueTypeId === 6}
                />
              </Col>
            </Row>
          </Form.Group>
        )}
        <Form.Group className="mb-1">
          <Row>
            {(isInternal() ||
              (caseView !== 8 && isServicePoint(currentUser))) && (
              <Col className="mb-4">
                <Form.Label className="mb-0">Tags</Form.Label>
                <MultiSelectTree
                  className="w-100 tag-multiselecttree-placeholder"
                  popupSettings={{
                    className: "tag-multiselecttree",
                    height: "auto",
                  }}
                  name="tags"
                  placeholder="Tags"
                  textField="name"
                  expandField="expanded"
                  checkField="selected"
                  subItemsField="tags"
                  dataItemKey="id"
                  data={filteredCategories}
                  value={issueClone.tags}
                  filterable
                  onFilterChange={onTagFilterChange}
                  onExpandChange={(e) => onExpandChange(e.item)}
                  onChange={(e) => {
                    if (e.operation === "clear") {
                      unSelectAllTags();
                      setIssueClone({ ...issueClone, tags: [] });
                    } else if (e.items[0].resources !== undefined) {
                      if (e.operation === "delete") {
                        onTagSelect(e.items[0], false);
                        setIssueClone({
                          ...issueClone,
                          tags: issueClone.tags.filter(
                            (t) => t.id !== e.items[0].id
                          ),
                        });
                      } else {
                        if (
                          !issueClone.tags.some((t) => t.id === e.items[0].id)
                        ) {
                          onTagSelect(e.items[0], true);
                          setIssueClone({
                            ...issueClone,
                            tags: [...issueClone.tags, ...[e.items[0]]],
                          });
                        } else {
                          onTagSelect(e.items[0], false);
                          setIssueClone({
                            ...issueClone,
                            tags: issueClone.tags.filter(
                              (t) => t.id !== e.items[0].id
                            ),
                          });
                        }
                      }
                    } else {
                      onExpandChange(e.items[0]);
                    }
                  }}
                />
              </Col>
            )}
            <Col>
              <span>&nbsp;</span>
              <ButtonToolbar>
                <ButtonGroup>
                  {issue.id > 0 &&
                    !issue.users.some((iu) => iu.id === currentUser.id) &&
                    isInternal() && (
                      <Button
                        onClick={() => handleFollowIssue()}
                        variant="secondary"
                      >
                        Follow case
                      </Button>
                    )}
                  {((issueClone.tags && issueClone.tags.length > 0) ||
                    (issue.jigId !== undefined && issue.jigId > 0)) &&
                    ((caseView === 8 && isInternal()) || caseView !== 8) && (
                      <Button
                        variant="info"
                        onClick={() => setShowChecklist(!showChecklist)}
                      >
                        Checklist
                      </Button>
                    )}
                  {(issueClone.id || isInternal()) &&
                    ((caseView === 8 && isInternal()) ||
                      (caseView !== 8 &&
                        (isServicePoint(currentUser) || isInternal()))) && (
                      <Button
                        style={
                          window.matchMedia("(min-width: 500px)").matches
                            ? { width: "70px" }
                            : { width: "80px" }
                        }
                        variant="primary"
                        onClick={() => setAccordionState(!accordionState)}
                      >
                        &hellip;
                      </Button>
                    )}
                  <Button
                    type="submit"
                    variant="success"
                    disabled={disableSave}
                  >
                    <span
                      className="k-icon k-i-save k-i-floppy"
                      style={{ top: "-9%" }}
                    />{" "}
                    Save
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Col>
          </Row>
        </Form.Group>
        <Accordion className="mb-3">
          <Accordion.Collapse eventKey="0" in={accordionState}>
            <Card bg="secondary">
              <Card.Body>
                {(isInternal() ||
                  (caseView !== 8 && isServicePoint(currentUser))) && (
                  <Aux>
                    <Row>
                      <Col>
                        <div>
                          <h6>Priority</h6>
                          <ButtonGroup className="mb-2">
                            {priorities &&
                              priorities.map((p, i) => (
                                <Button
                                  name="priority"
                                  onClick={(e: any) => {
                                    handleChange(e, p);
                                  }}
                                  variant={
                                    issueClone.priority?.id === p.id
                                      ? "primary"
                                      : "dark"
                                  }
                                  key={"priority-" + i}
                                >
                                  {p.name}
                                </Button>
                              ))}
                          </ButtonGroup>
                        </div>
                        <div>
                          <h6>Status</h6>
                          <ButtonGroup className="mb-0">
                            {["Open", "Closed"].map((s, i) => (
                              <Button
                                name="status"
                                value={s}
                                onClick={handleChange}
                                variant={
                                  issueClone.status === s ? "primary" : "dark"
                                }
                                disabled={
                                  (issueClone.issueTypeId === 6 &&
                                    !isHmsAdmin(currentUser)) ||
                                  ((!issueClone.issueRootCause ||
                                    issueClone.issueRootCause.statusId < 1) &&
                                    (issueClone.issueType === undefined ||
                                      issueClone.issueType === null ||
                                      [1, 3, 4, 8].includes(
                                        issueClone.issueType.id
                                      )) &&
                                    i === 1)
                                }
                                key={"status-" + i}
                              >
                                {s}
                              </Button>
                            ))}
                          </ButtonGroup>
                        </div>

                        <div className="text-muted mt-0 pt-0">
                          {(!issueClone.issueRootCause ||
                            issueClone.issueRootCause.statusId < 1) &&
                          (issueClone.issueType === undefined ||
                            [1, 3, 4, 8].includes(issueClone.issueType.id)) ? (
                            <small>
                              <em>Root cause required to change status</em>
                            </small>
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </div>
                        {issue.issueTypeId !== 6 && (
                          <div className="mt-2">
                            <h6>Type</h6>
                            <ComboBox
                              className="mb-2"
                              placeholder="Type"
                              name="issueType"
                              data={issueTypes}
                              textField="name"
                              dataItemKey="id"
                              value={issueClone.issueType}
                              onChange={handleChange}
                            />
                          </div>
                        )}
                      </Col>
                      {(issueClone.issueType === undefined ||
                        (issueClone.issueType &&
                          [1, 3, 4, 8].includes(issueClone.issueType.id))) && (
                        <Col>
                          <IssueRootCauseForm
                            currentUser={currentUser}
                            issue={issueClone}
                            isShown={accordionState}
                            dirty={isDirty}
                            uploading={uploading}
                            setUploading={setUploading}
                            setIssueClone={setIssueClone}
                            setDirty={setIsDirty}
                            refetchIssue={refetchIssue}
                          />
                        </Col>
                      )}
                    </Row>
                  </Aux>
                )}
                {issueClone.id && (
                  <div>
                    <h6>Title</h6>
                    {titleField}
                  </div>
                )}
                <Row className="mt-3">
                  <Col>
                    <Accordion>
                      <Card bg="dark">
                        <Accordion.Header
                          as={Card.Header}
                          eventKey={"a"}
                          onClick={(e) => {
                            e.preventDefault();
                            setCheckpointState(!checkpointState);
                          }}
                        >
                          <div className="container-fluid">
                            Case checkpoints
                            <span className="float-end">
                              <FontAwesomeIcon
                                icon={
                                  checkpointState ? faChevronUp : faChevronDown
                                }
                              />{" "}
                            </span>
                          </div>
                        </Accordion.Header>
                        <Accordion.Collapse eventKey="a" in={checkpointState}>
                          <Card.Body
                            className="d-flex"
                            style={{ flexDirection: "column" }}
                          >
                            {issueClone.addedCheckpoints.map((x) => {
                              return (
                                <AddCheckpointInput
                                  key={"custom-spec-" + x.id}
                                  id={x.id}
                                  text={x.name}
                                  currentUser={currentUser}
                                  addedCheckpoint={x}
                                  onRemove={(id) => {
                                    setIssueClone({
                                      ...issueClone,
                                      addedCheckpoints: issueClone.addedCheckpoints.filter(
                                        (y) => y.id !== id
                                      ),
                                    });
                                  }}
                                  onUpdate={(id, string) => {
                                    const checkpoints = [
                                      ...issueClone.addedCheckpoints,
                                    ];
                                    const updateCheckpoint = checkpoints.find(
                                      (x) => x.id === id
                                    );
                                    if (updateCheckpoint !== undefined)
                                      updateCheckpoint.name = string;
                                  }}
                                  disabled={true}
                                />
                              );
                            })}
                            <AddCheckpointInput
                              key="custom-spec-new"
                              isNew={true}
                              currentUser={currentUser}
                              onAdd={(text) => {
                                const checkpoints = [
                                  ...issueClone.addedCheckpoints,
                                ];
                                checkpoints.push({
                                  id: checkpoints.length + 1,
                                  name: text,
                                  typeId: 5,
                                } as IQcCheckpoint);
                                setIssueClone({
                                  ...issueClone,
                                  addedCheckpoints: checkpoints,
                                });
                              }}
                            />
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Accordion.Collapse>
        </Accordion>
        <Accordion>
          <Accordion.Collapse eventKey="1" in={showChecklist}>
            <IssueChecklistContainer
              issue={issueClone}
              isOpen={showChecklist}
              didSave={didSave}
              saveLoading={saveLoading}
              history={history}
              signalRInspections={signalRInspections}
              setIssueCheckpoints={setCheckpoints}
              handleItemSelected={(selected) =>
                handleItemSelected(selected, true)
              }
              setDidSave={setDidSave}
              setIssueClone={setIssueClone}
            />
          </Accordion.Collapse>
        </Accordion>
        {issue.id > 0 &&
          issue.tags &&
          issue.tags.some((t) => t.signatureRequired) && (
            <Form.Group className="mt-3 mb-3">
              <Row>
                <Col className="col-12 col-md-12">
                  <label>Signature</label> <br />
                  {issue.userSignatures !== undefined &&
                    issue.userSignatures.length > 0 && (
                      <small>
                        <em>
                          Signed by: {issue.userSignatures[0].userName} at
                          {" " +
                            dateTimeFormat.format(
                              new Date(issue.userSignatures[0].createdAt)
                            )}
                        </em>
                      </small>
                    )}
                </Col>
              </Row>
              <Row>
                <Col className="col-12 col-md-12">
                  <div
                    style={{ backgroundColor: "white" }}
                    className="signature"
                  >
                    {signatureURL === null ? (
                      <SignatureCanvas
                        penColor="black"
                        canvasProps={{
                          className: "sigCanvas",
                        }}
                        ref={sigCanvas}
                      />
                    ) : (
                      <img
                        src={signatureURL}
                        alt="signature"
                        className="signature-image"
                      />
                    )}
                  </div>
                </Col>
              </Row>
              {(issue.userSignatures === undefined ||
                issue.userSignatures.length === 0) && (
                <Aux>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Signature name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Signature Name"
                          value={
                            issue.userSignatures !== undefined &&
                            issue.userSignatures.length > 0
                              ? issue.userSignatures[0].userName
                              : signatureName
                          }
                          onChange={(e) => setSignatureName(e.target.value)}
                          required={signatureURL !== null}
                          disabled={
                            issue.userSignatures !== undefined &&
                            issue.userSignatures.length > 0
                          }
                          minLength={2}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <ButtonGroup>
                    <Button
                      onClick={() => {
                        setSignatureURL(null);
                        setIssueClone({ ...issueClone, userSignatures: [] });
                        if (sigCanvas.current !== null) {
                          sigCanvas.current.clear();
                        }
                      }}
                      disabled={
                        issue.userSignatures !== undefined &&
                        issue.userSignatures.length > 0
                      }
                    >
                      Clear
                    </Button>
                    <Button
                      onClick={createSignature}
                      variant="success"
                      disabled={
                        signatureURL !== null ||
                        (issue.userSignatures !== undefined &&
                          issue.userSignatures.length > 0) ||
                        signatureName === ""
                      }
                    >
                      Create
                    </Button>
                  </ButtonGroup>
                  {(issue.userSignatures === undefined ||
                    issue.userSignatures.length === 0) && (
                    <Button
                      className="ms-4"
                      type="submit"
                      variant="success"
                      disabled={disableSave}
                    >
                      <span
                        className="k-icon k-i-save k-i-floppy"
                        style={{ top: "-9%" }}
                      />{" "}
                      Save
                    </Button>
                  )}
                  {signatureName === "" &&
                    (issue.userSignatures === undefined ||
                      issue.userSignatures.length === 0) && (
                      <p className="text-warning">Signature name is required</p>
                    )}
                  {showSignatureCreated && (
                    <p className="text-success">Signature created</p>
                  )}
                </Aux>
              )}
            </Form.Group>
          )}
      </Form>
      {issue.id > 0 &&
        (caseView === 6 ||
          caseView === 5 ||
          (issue.tags && issue.tags.some((t) => t.signatureRequired))) && (
          <IssuePdfExport
            currentUser={currentUser}
            issue={issueClone}
            messages={messages}
            checkpoints={checkpoints}
          />
        )}
    </Aux>
  );
};

export default IssueForm;
