import React, { useEffect, useState } from "react";
import {
  faPaperclip,
  faReply,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Upload from "components/Common/Upload";
import { History } from "history";
import { UploadFileInfo } from "@progress/kendo-react-upload";
import {
  Button,
  ButtonGroup,
  Card,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";

import { redirectCase } from "store/actions/salesforce";
import { IImage } from "../../models/image";
import { IIssue } from "../../models/issue";
import { IMessage } from "../../models/message";
import { IOrder } from "../../models/order";
import { getProductLanguage, IProduct } from "../../models/product";
import { IQcChassi } from "../../models/QualityControl/qcChassis";
import {
  isAdmin,
  isInternal,
  isKitter,
  isQcAdmin,
  IUser,
} from "../../models/user";
import { Paths } from "../../routes";
import Aux from "../../utils/auxiliary";
import dateTimeFormat, {
  dateTimeFormatSecond,
} from "../../utils/dateTimeFormat";
import IssueImage from "./IssueImage";
import { IJig } from "models/jig";
import TaggableInputField from "components/Common/TaggableInputField";
import { getRequest } from "store/actions/request";

export interface IProps {
  message: IMessage;
  currentUser: IUser;
  products: IProduct[];
  mainCauseParts: IProduct[];
  chassis: IQcChassi[];
  relIssues: IIssue[];
  orders: IOrder[];
  jigs: IJig[];
  history: History;
  issue: IIssue;
  onToggle: (id: number, message: IMessage) => any;
  onReply: (message?: IMessage) => void;
  setImageShow: (image: IImage) => void;
  editMessage: (message: IMessage, addedItem: boolean) => any;
  setIssue: (issue: IIssue) => any;
  onDeleteClick: ({ image, message }) => void;
}

export const ParseMessageIds = (
  message: string,
  products: IProduct[],
  mainCauseParts: IProduct[],
  chassis: IQcChassi[],
  relIssues: IIssue[],
  orders: IOrder[],
  jigs: IJig[],
  users: IUser[],
  current: IUser,
  history: History,
  inspectionNotes: boolean
): JSX.Element[] => {
  if (
    (!message.includes("#{") || products === undefined) &&
    (!message.includes("€{") || products === undefined) &&
    (!message.includes("%{") || chassis === undefined) &&
    (!message.includes("&{") || relIssues === undefined) &&
    (!message.includes("${") || orders === undefined) &&
    (!message.includes("£{") || jigs === undefined) &&
    (!message.includes("@{") || users.length === 0) &&
    !message.includes("*{") &&
    !message.includes("http") &&
    !inspectionNotes
  ) {
    return [<span key={"msgarray0"}>{message}</span>];
  }

  const messageArray = new Array<JSX.Element>();
  const splitRegex = message.split(
    /(#{[0-9]* - .*?}|€{[0-9]* - .*?}|@{.*?}|%{.*?}|&{[0-9]* - .*?}|[$]{[0-9]*}|\*{[0-9]*}|£{.*?}|https:\/\/[-A-Z0-9+&@#/%=~_|$?!:,.]*)/gi
  );
  for (var regexCount = 0; regexCount < splitRegex.length; regexCount++) {
    const word = splitRegex[regexCount];
    const productMatches = word.trim().match(/(^#{[0-9]+)/g);
    const mainCausePartsMatches = word.trim().match(/(€{[0-9]+)/g);
    const chassisMatches = word.trim().match(/(^%([^}])+)/g);
    const relIssueMatches = word.trim().match(/(^&{[0-9]+)/g);
    const orderMatches = word.trim().match(/(^[$]{[0-9]+)/g);
    const jigMatches = word.trim().match(/(^£([^}])+)/g);
    const userMatches = word.trim().match(/(^@([^}])+)/gi);
    const sfCaseMatches = word.trim().match(/(^\*{[0-9]+)/g);
    const hyperLinkMatches = word
      .trim()
      .match(
        /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim
      );

    if (
      productMatches === null &&
      mainCausePartsMatches === null &&
      chassisMatches === null &&
      relIssueMatches === null &&
      orderMatches === null &&
      jigMatches === null &&
      userMatches === null &&
      sfCaseMatches == null &&
      hyperLinkMatches == null &&
      !inspectionNotes
    ) {
      messageArray.push(<span key={"msgarray" + regexCount}>{word}</span>);
      continue;
    }
    let productId = "";
    let mainCausePartId = "";
    let chassisNo = "";
    let relIssueId = "";
    let orderNo = "";
    let caseNo = "";
    let jignr = "";
    let userName = "";

    if (mainCausePartsMatches && mainCausePartsMatches.length > 0) {
      mainCausePartId = mainCausePartsMatches[0].slice(2);
    }
    if (productMatches && productMatches.length > 0) {
      productId = productMatches[0].slice(2);
    }
    if (chassisMatches && chassisMatches.length > 0) {
      chassisNo = chassisMatches[0].slice(2);
    }
    if (relIssueMatches && relIssueMatches.length > 0) {
      relIssueId = relIssueMatches[0].slice(2);
    }
    if (orderMatches && orderMatches.length > 0) {
      orderNo = orderMatches[0].slice(2);
    }
    if (jigMatches && jigMatches.length > 0) {
      jignr = jigMatches[0].slice(2);
    }
    if (userMatches && userMatches.length > 0) {
      userName = userMatches[0].slice(2);
    }
    if (sfCaseMatches && sfCaseMatches.length > 0) {
      caseNo = sfCaseMatches[0].slice(2);
    }

    let order: IOrder | undefined;
    if (orders && orders.length > 0) {
      order = orders.find((x) => x.orderNo.toString() === orderNo);
    }
    let jig: IJig | undefined;
    if (jigs && jigs.length > 0) {
      jig = jigs.find((x) => x.jignr.toString() === jignr);
    }
    let user: IUser | undefined;
    if (users && users.length > 0) {
      user = users.find((x) => x.fullName === userName);
    }
    let product: IProduct | undefined;
    if (products && products.length > 0) {
      product = products.find((x) => x.productNo === productId);
    }
    let mainCausePart: IProduct | undefined;
    if (mainCauseParts && mainCauseParts.length > 0) {
      mainCausePart = mainCauseParts.find(
        (x) => x.productNo === mainCausePartId
      );
    }
    let chassi: IQcChassi | undefined;
    if (chassis && chassis.length > 0) {
      chassi = chassis.find((x) => x.no === chassisNo);
    }
    let relIssue: IIssue | undefined;
    if (relIssues && relIssues.length > 0) {
      relIssue = relIssues.find((x) => x.id.toString() === relIssueId);
    }

    const mql = window.matchMedia("(max-width: 500px)");

    if (product !== undefined) {
      messageArray.push(
        isInternal(current) ? (
          <a
            key={"msgarray" + regexCount}
            onClick={(e) => {
              e.preventDefault();
              if (product !== undefined)
                history.push(Paths.gProductsId(product.productNo));
            }}
            href={Paths.gProductsId(product.productNo)}
          >
            {` (${product.productNo}) ` + getProductLanguage(current, product)}
          </a>
        ) : (
          <OverlayTrigger
            key={"msgarray" + regexCount}
            placement={mql.matches ? "bottom" : "right"}
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="tooltip">
                You don't have access to this resource
              </Tooltip>
            }
          >
            <span className="text-success">
              {` (${product.productNo}) ` +
                getProductLanguage(current, product)}
            </span>
          </OverlayTrigger>
        )
      );
      continue;
    }
    if (mainCausePart !== undefined) {
      messageArray.push(
        isInternal(current) ? (
          <a
            key={"msgarray" + regexCount}
            onClick={(e) => {
              e.preventDefault();
              if (mainCausePart !== undefined)
                history.push(Paths.gProductsId(mainCausePart.productNo));
            }}
            href={Paths.gProductsId(mainCausePart.productNo)}
          >
            {`Main cause part (${mainCausePart.productNo}) ` +
              getProductLanguage(current, mainCausePart)}
          </a>
        ) : (
          <OverlayTrigger
            key={"msgarray" + regexCount}
            placement={mql.matches ? "bottom" : "right"}
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="tooltip">
                You don't have access to this resource
              </Tooltip>
            }
          >
            <span className="text-success">
              {`Main cause part (${mainCausePart.productNo}) ` +
                getProductLanguage(current, mainCausePart)}
            </span>
          </OverlayTrigger>
        )
      );
      continue;
    }
    if (chassi !== undefined) {
      messageArray.push(
        isQcAdmin(current) ? (
          <a
            key={"msgarray" + regexCount}
            onClick={(e) => {
              e.preventDefault();
              if (chassi !== undefined)
                history.push(Paths.qc.gChassisId(chassi.id));
            }}
            href={Paths.qc.gChassisId(chassi.id)}
          >
            {` Chassis: ${chassi.no}`}
          </a>
        ) : isInternal(current) ? (
          <a
            key={"msgarray" + regexCount}
            onClick={(e) => {
              e.preventDefault();
              if (chassi !== undefined)
                history.push(Paths.qc.gMachineSpecId(chassi.id));
            }}
            href={Paths.qc.gChassisId(chassi.id)}
          >
            {` Chassis: ${chassi.no}`}
          </a>
        ) : (
          <OverlayTrigger
            key={"msgarray" + regexCount}
            placement={mql.matches ? "bottom" : "right"}
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="tooltip">
                You don't have access to this resource
              </Tooltip>
            }
          >
            <span className="text-success">{` Chassis: ${chassi.no}`}</span>
          </OverlayTrigger>
        )
      );
      continue;
    }
    if (relIssue !== undefined) {
      messageArray.push(
        isInternal(current) ? (
          <a
            key={"msgarray" + regexCount}
            onClick={(e) => {
              e.preventDefault();
              if (relIssue !== undefined)
                history.push(Paths.gIssuesId(relIssue.id));
            }}
            href={Paths.gIssuesId(relIssue.id)}
          >
            {` Case: ${relIssue.id + " - " + relIssue.title} `}
          </a>
        ) : (
          <OverlayTrigger
            key={"msgarray" + regexCount}
            placement={mql.matches ? "bottom" : "right"}
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="tooltip">
                You don't have access to this resource
              </Tooltip>
            }
          >
            <span className="text-success">{` Case: ${
              relIssue.id + " - " + relIssue.title
            } `}</span>
          </OverlayTrigger>
        )
      );
      continue;
    }
    if (order !== undefined) {
      messageArray.push(
        isInternal(current) || isKitter(current) ? (
          <a
            key={"msgarray" + regexCount}
            onClick={(e) => {
              e.preventDefault();
              if (order !== undefined)
                history.push(Paths.gOrderId(order.orderNo));
            }}
            href={Paths.gOrderId(order.orderNo)}
          >
            {` Order: ${order.orderNo} `}
          </a>
        ) : (
          <OverlayTrigger
            key={"msgarray" + regexCount}
            placement={mql.matches ? "bottom" : "right"}
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="tooltip">
                You don't have access to this resource
              </Tooltip>
            }
          >
            <span className="text-success">{` Order: ${order.orderNo} `}</span>
          </OverlayTrigger>
        )
      );
      continue;
    }

    if (jig !== undefined) {
      messageArray.push(
        isInternal(current) ? (
          <a
            key={"msgarray" + regexCount}
            onClick={(e) => {
              e.preventDefault();
              if (jig !== undefined) {
                history.push(Paths.gEquipmentsId(jig.id));
              }
            }}
            href={Paths.gEquipmentsId(jig.id)}
          >
            {` Equipment: ${jig.jignr} `}
          </a>
        ) : (
          <OverlayTrigger
            key={"msgarray" + regexCount}
            placement={mql.matches ? "bottom" : "right"}
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="tooltip">
                You don't have access to this resource
              </Tooltip>
            }
          >
            <span className="text-success">{` Equipment: ${jig.jignr} `}</span>
          </OverlayTrigger>
        )
      );
      continue;
    }

    if (user !== undefined) {
      messageArray.push(
        <a key={"msgarray" + regexCount} href={"mailto: " + user.email}>
          {"@{" + user.fullName + "}"}
        </a>
      );
      continue;
    }

    if (caseNo !== "") {
      messageArray.push(
        isInternal(current) ? (
          <a
            key={"msgarray" + regexCount}
            onClick={(e) => {
              e.preventDefault();
              redirectCase(caseNo);
            }}
            href={"/salesforce/case/" + caseNo}
          >
            {`Salesforce Case: ${caseNo} `}
          </a>
        ) : (
          <OverlayTrigger
            key={"msgarray" + regexCount}
            placement={mql.matches ? "bottom" : "right"}
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="tooltip">
                You don't have access to this resource
              </Tooltip>
            }
          >
            <span className="text-success">{`Salesforce Case: ${caseNo} `}</span>
          </OverlayTrigger>
        )
      );
      continue;
    }
    if (hyperLinkMatches !== null) {
      messageArray.push(
        <a
          key={"msgarray" + regexCount}
          href={hyperLinkMatches.toString()}
          target="_blank"
          rel="noreferrer"
        >
          {hyperLinkMatches}
        </a>
      );
      continue;
    }

    if (!inspectionNotes) {
      messageArray.push(<span key={"msgarray" + regexCount}>{word}</span>);
    }
  }
  return messageArray;
};

const IssueMessage: React.FC<IProps> = (props) => {
  const {
    message,
    currentUser,
    products,
    mainCauseParts,
    chassis,
    relIssues,
    orders,
    jigs,
    history,
    issue,
    onToggle,
    onReply,
    setImageShow,
    editMessage,
    setIssue,
    onDeleteClick,
  } = props;

  const [editMode, setEditMode] = useState<boolean>(false);
  const [addedItem, setAddedItem] = useState<boolean>(false);
  const [addedImage, setAddedImage] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [messageClone, setMessageClone] = useState<IMessage>(message);
  const [attachmentState, setAttachmentState] = useState<boolean>(false);
  const [images, setImages] = useState<IImage[]>([]);
  const [files, setFiles] = useState<UploadFileInfo[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);

  useEffect(() => {
    setMessageClone(message);
    setImages(message.images ? message.images : []);
  }, [message]);

  const [internal, setInternal] = useState<boolean>(
    !(message.roles && message.roles.length > 0)
  );

  const toggleMessage = () => {
    message.roles = internal ? [{ name: "external", description: "" }] : [];
    if (message.id) {
      onToggle(message.id, message);
      setInternal(!(message.roles && message.roles.length > 0));
    }
  };

  const internalRoles = process.env.REACT_APP_INTERNAL_ROLES;

  const handleMessageChange = (event: any) => {
    setMessageClone({
      ...messageClone,
      body: event.target.value,
    });
  };

  const handleEdit = (message: IMessage) => {
    setEditMode(false);
    editMessage(message, addedItem);
    setAddedItem(false);
    setAddedImage(false);
    setFiles([]);
  };

  const addUser = (issue: IIssue, user: IUser) => {
    let users: IUser[] = [];
    if (issue.users) {
      users = issue.users.filter((u) => u.id !== user.id);
    }
    users.push(user);
    return users;
  };

  const handleItemSelected = (selected: any) => {
    const { item, currentTrigger } = selected;
    if (currentTrigger === "#") {
      if (issue.products) {
        const duplicate = issue.products.find(
          (p) => p.productNo === item.productNo
        );
        if (duplicate === undefined) {
          issue.products.push(item);
          setIssue({
            ...issue,
          });
          setAddedItem(true);
        }
      }
    }
    if (currentTrigger === "%") {
      if (issue.qcChassis) {
        const duplicate = issue.qcChassis.find((c) => c.id === item.id);
        if (duplicate === undefined) {
          issue.qcChassis.push(item);
          setIssue({
            ...issue,
          });
          setAddedItem(true);
        }
      }
    }
    if (currentTrigger === "&") {
      if (issue.relIssues) {
        const duplicate = issue.relIssues.find((ri) => ri.id === item.id);
        if (duplicate === undefined) {
          issue.relIssues.push(item);
          setIssue({
            ...issue,
          });
          setAddedItem(true);
        }
      }
    }
    if (currentTrigger === "$") {
      if (issue.orders) {
        const duplicate = issue.orders.find((o) => o.orderNo === item.orderNo);
        if (duplicate === undefined) {
          issue.orders.push(item);
          setIssue({
            ...issue,
          });
          setAddedItem(true);
        }
      }
    }
    if (currentTrigger === "*") {
      if (issue.salesforceCases) {
        const duplicate = issue.salesforceCases.find(
          (sf) => sf.caseNumber === item.caseNumber
        );
        if (duplicate === undefined) {
          issue.salesforceCases.push({
            caseNumber: item.caseNumber,
            id: 0,
            issueId: 0,
          });
          setIssue({
            ...issue,
          });
          setAddedItem(true);
        }
      }
    }
    if (currentTrigger === "@") {
      setIssue({
        ...issue,
        users: addUser(issue, item),
      });
      setAddedItem(true);
    }
    if (currentTrigger === "£") {
      let jigs: IJig[] = [];
      if (issue.jigs) {
        jigs = issue.jigs.filter((j) => j.jignr !== item.jignr);
      }
      jigs.push(item);
      setIssue({
        ...issue,
        jigs: jigs,
      });
      setAddedItem(true);
    }
  };

  const handleFileAdd = (file: IImage) => {
    const images = messageClone.images ? messageClone.images : [];
    images.push(file);
    const body = messageClone.body ? messageClone.body : "";
    setAddedImage(true);
    setMessageClone({
      ...messageClone,
      body: body,
      images: images,
    });
  };

  const handleFileRemove = (file: UploadFileInfo) => {
    const images = messageClone.images.filter(
      (i) => !i.name.includes(file.name)
    );
    setMessageClone({
      ...messageClone,
      images: images,
    });
  };

  const download = (image: IImage) => {
    setDownloading(true);
    getRequest(`/images/show/${image.id}`).then((response) => {
      if (response.status !== 200) {
        return;
      }

      const data = response.json;
      if (data === null) return;
      fetch(data)
        .then((res) => res.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          const title = image?.name ?? "undefined";

          const save = document.createElement("a");
          save.href = blobUrl;
          save.target = "_blank";
          save.download = title;

          var evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: false,
          });
          save.dispatchEvent(evt);
          (window.URL || window.webkitURL).revokeObjectURL(save.href);
          save.remove();
          setDownloading(false);
        });
    });
  };

  const mql = window.matchMedia("(max-width: 500px)");

  return (
    <Card
      bg="secondary"
      className={`${
        currentUser && message.userId === currentUser.id
          ? "border-primary"
          : "border-light"
      } mb-2 issue-message`}
    >
      <Card.Body className="pb-3">
        {message.replyTo && (
          <Card
            bg="secondary"
            className={`${
              currentUser &&
              message.replyTo.user &&
              message.replyTo.user.id === currentUser.id
                ? "border-primary"
                : "border-light"
            } mb-2 p-3`}
          >
            <p className="text-truncate font-italic">{message.replyTo.body}</p>
            <div className="text-end text-muted">
              <small>
                <em>
                  {message.replyTo.user &&
                    `${message.replyTo.user.fullName}${
                      message.replyTo.user.title
                        ? " (" + message.replyTo.user.title + ")"
                        : ""
                    } - `}
                  {message.replyTo.createdAt &&
                    dateTimeFormat.format(new Date(message.replyTo.createdAt))}
                </em>
              </small>
            </div>
          </Card>
        )}
        {message.sfCases?.length > 0 &&
          message.sfCases.map((sfCase) => (
            <Card
              key={"case-" + sfCase.caseNumber}
              bg="secondary"
              className="border-info mb-2 p-3"
            >
              <div>
                <em>
                  Salesforce Case:{" "}
                  {isAdmin(currentUser) ? (
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        redirectCase(sfCase.caseNumber);
                      }}
                      href={"/salesforce/case/" + sfCase.caseNumber}
                    >
                      {sfCase.caseNumber}
                    </a>
                  ) : (
                    <OverlayTrigger
                      placement={mql.matches ? "bottom" : "right"}
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="tooltip">
                          You don't have access to this resource
                        </Tooltip>
                      }
                    >
                      <span className="text-success">{sfCase.caseNumber}</span>
                    </OverlayTrigger>
                  )}{" "}
                  {sfCase.subject}
                </em>
                <p className="mb-0">
                  {sfCase.description !== undefined && sfCase.description !== ""
                    ? sfCase.description
                    : "No Description Available"}
                </p>
              </div>
            </Card>
          ))}
        <p style={{ whiteSpace: "pre-line" }}>
          {" "}
          {ParseMessageIds(
            message.body,
            products,
            mainCauseParts,
            chassis,
            relIssues,
            orders,
            jigs,
            message.taggedUsers,
            currentUser,
            history,
            false
          )}{" "}
          {message.userId === currentUser.id && (
            <span
              className="float-end ps-3 text-success"
              style={{ cursor: "pointer" }}
              onClick={() => setEditMode(!editMode)}
            >
              Edit
            </span>
          )}
        </p>
        {editMode && (
          <div className="m-2">
            <div className="clearfix">
              <TaggableInputField
                handleItemSelected={handleItemSelected}
                onChange={(e) => handleMessageChange(e)}
                value={messageClone.body}
                placeholder="Add a new message (All relevant information about the needed help/problem/solution. See all tags under, remember images/video)"
                className="form-control issue-placeholder"
              />
              <Button
                variant="secondary"
                className="float-end"
                onClick={() => setAttachmentState(!attachmentState)}
              >
                <FontAwesomeIcon icon={faPaperclip} />
              </Button>
            </div>
            <p className="text-muted">
              {isInternal(currentUser)
                ? "@: user, #: product, %: chassis, &: case, $: order, *: salesforce case, £: equipment"
                : isKitter(currentUser)
                ? "@: user, #: product, $: order"
                : "@: user, #: product"}
            </p>
            {attachmentState && (
              <Form.Group className="mb-3">
                <Upload
                  images={images}
                  currentUser={currentUser}
                  files={files}
                  disabled={false}
                  setUploading={setUploading}
                  setFiles={setFiles}
                  setImages={setImages}
                  handleFileAdd={handleFileAdd}
                  handleFileRemove={handleFileRemove}
                />
              </Form.Group>
            )}
            <ButtonGroup className="my-1">
              <Button
                variant="success"
                onClick={() =>
                  handleEdit({ ...messageClone, updatedAt: new Date() })
                }
                disabled={
                  ((messageClone.body.length === 0 ||
                    messageClone.body === message.body) &&
                    !addedImage) ||
                  uploading
                }
              >
                <span className="k-icon k-i-save k-i-floppy" /> Save
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  if (
                    window.confirm(
                      (message.body ? message.body + "\n\n" : null) +
                        "Are you sure you want to delete this message?"
                    )
                  ) {
                    handleEdit({
                      ...messageClone,
                      deletedAt: messageClone.updatedAt,
                    });
                  }
                }}
                disabled={uploading}
              >
                <span className="k-icon k-i-delete k-i-trash" /> Delete
              </Button>
            </ButtonGroup>
          </div>
        )}
        {message.images &&
          message.images.map((image) => (
            <div className="mb-3" key={"image-" + image.id}>
              <IssueImage
                currentUser={currentUser}
                editMode={editMode}
                image={image}
                message={message}
                setImageShow={setImageShow}
                onDeleteClick={onDeleteClick}
              />
              <br />
              {image.name !== undefined && (
                <p>
                  <small className="mt-0 mb-0">
                    {image.name}{" "}
                    <span
                      onClick={() => download(image)}
                      className="text-success cursor ml-1"
                    >
                      Download
                    </span>
                    {downloading && (
                      <span>
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spin
                          size="2x"
                          className="ml-2"
                        />
                      </span>
                    )}
                  </small>
                </p>
              )}
            </div>
          ))}
        <div>
          <span
            title="Reply"
            style={{ cursor: "pointer" }}
            onClick={() => {
              onReply(message);
            }}
          >
            <FontAwesomeIcon icon={faReply} size="2x" />
          </span>
          {internalRoles &&
            currentUser?.roles.some((r) => internalRoles.includes(r)) && (
              <Aux>
                {currentUser?.roles.some((r) => r === "support") && (
                  <Button
                    variant={internal ? "outline-light" : "outline-danger"}
                    size="sm"
                    title="Toggle permission"
                    className="mx-3 mb-2"
                    onClick={() => {
                      if (
                        !internal ||
                        window.confirm(
                          "Make message available to external users?"
                        )
                      ) {
                        toggleMessage();
                      }
                    }}
                  >
                    {internal ? "Internal" : "External"}
                  </Button>
                )}
                {!currentUser?.roles.some((r) => r === "support") &&
                  !internal && (
                    <small className="text-danger mx-3 p-2">External</small>
                  )}
              </Aux>
            )}
          <div className="float-end">
            <Row>
              <small className="float-end text-muted me-3">
                <em>
                  {message.user &&
                    `${message.user.fullName}${
                      message.user.title ? " (" + message.user.title + ")" : ""
                    } - `}
                  {message.createdAt &&
                    dateTimeFormat.format(new Date(message.createdAt))}
                </em>
              </small>
            </Row>
            {message.updatedAt &&
              message.createdAt &&
              dateTimeFormatSecond.format(new Date(message.updatedAt)) !==
                dateTimeFormatSecond.format(new Date(message.createdAt)) && (
                <Row>
                  <small className="float-end text-muted me-3">
                    <em style={{ fontSize: "10px" }}>
                      {message.user &&
                        `Edited by ${message.user.fullName}${
                          message.user.title
                            ? " (" + message.user.title + ")"
                            : ""
                        } - `}
                      {message.updatedAt &&
                        dateTimeFormat.format(new Date(message.updatedAt))}
                    </em>
                  </small>
                </Row>
              )}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default IssueMessage;
