import {
  PriorityActionsTypes,
  FETCH_PRIORITIES_SUCCESS,
} from "../actions/priorities";
import { IPriority } from "../../models/priority";

export type PriorityState = {
  readonly priorities: IPriority[];
};

export const initialState: PriorityState = {
  priorities: [],
};

export default function prioritiesReducer(
  state = initialState,
  action: PriorityActionsTypes
): PriorityState {
  switch (action.type) {
    case FETCH_PRIORITIES_SUCCESS:
      return { ...state, priorities: action.priorities };
    default:
      return state;
  }
}
