import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CloseButton } from "components/Common/CloseButton";
import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Form,
  Image,
  Modal,
} from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import { IImage } from "../../../models/image";
import { getRequest } from "../../../store/actions/request";
import Aux from "../../../utils/auxiliary";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export interface IProps {
  image?: IImage;
  view: boolean;
  handleHide: () => void;
}

const QcCheckpointImageModal = (props: IProps) => {
  const { image, view, handleHide } = props;

  const [imageTitle, setImageTitle] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [imageData, setImageData] = useState<string | null>(null);

  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const viewImage = useCallback((image: IImage) => {
    getRequest(`/images/show/${image.id}`).then((response) => {
      if (response.status !== 200) {
        setError(true);
        return;
      }

      const data = response.json;
      setImageData(data);
      setImageTitle(image.name);
    });
  }, []);

  useEffect(() => {
    if (image?.id === undefined) {
      setImageData(null);
      setImageTitle(null);
      return;
    }
    if (!view) return;

    setNumPages(0);
    setPageNumber(1);

    viewImage(image);
  }, [image, view, viewImage]);

  const videoContent = () => imageData && <video controls src={imageData} />;

  const download = () => {
    if (imageData === null) return;
    fetch(imageData)
      .then((res) => res.blob())
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        const title = imageTitle ?? image?.name ?? "undefined";

        const save = document.createElement("a");
        save.href = blobUrl;
        save.target = "_blank";
        save.download = title;

        if (
          navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
          navigator.userAgent.search("Chrome") < 0
        ) {
          window.open(blobUrl, "_blank");
        } else {
          var evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: false,
          });
          save.dispatchEvent(evt);
          (window.URL || window.webkitURL).revokeObjectURL(save.href);
        }
        save.remove();
      });
  };

  const onDocumentLoadSuccess = (e: any) => {
    setNumPages(e.numPages);
  };

  const viewPortWidth = window.innerWidth;

  return (
    <div>
      <Modal show={view} onHide={() => handleHide()} centered>
        <Modal.Header>
          {imageData !== null && (
            <Button onClick={() => download()} className="me-2">
              Download
            </Button>
          )}
          <span
            className="text-truncate me-2"
            style={{ maxWidth: "500px" }}
            title={
              imageTitle === null
                ? error
                  ? "Something went wrong..."
                  : "Loading..."
                : imageTitle
            }
          >
            {imageTitle === null
              ? error
                ? "Something went wrong..."
                : "Loading..."
              : imageTitle}
          </span>
          <CloseButton onCLick={handleHide} />
        </Modal.Header>
        <Modal.Body className="m-0 p-0">
          {imageData !== null ? (
            <Aux>
              {imageData.startsWith("data:image") ? (
                <Image src={imageData} />
              ) : imageData.startsWith("data:application/pdf") ? (
                <div>
                  <Document
                    file={imageData}
                    onLoadSuccess={(e) => onDocumentLoadSuccess(e)}
                    renderMode={"canvas"}
                  >
                    <Page
                      pageNumber={pageNumber}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      width={viewPortWidth - viewPortWidth * 0.1}
                    >
                      <div
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          top: 0,
                        }}
                      >
                        <div
                          style={{ width: "50%", height: "100%", zIndex: 999 }}
                          onClick={() => {
                            if (pageNumber - 1 <= 0) return;
                            setPageNumber(pageNumber - 1);
                          }}
                        ></div>
                        <div
                          style={{ width: "50%", height: "100%", zIndex: 999 }}
                          onClick={() => {
                            if (pageNumber + 1 > numPages) return;
                            setPageNumber(pageNumber + 1);
                          }}
                        ></div>
                      </div>
                    </Page>
                    <ButtonToolbar className="mt-3">
                      <ButtonGroup>
                        <Button
                          className="me-2"
                          disabled={pageNumber - 1 <= 0}
                          onClick={() => setPageNumber(pageNumber - 1)}
                        >
                          <FontAwesomeIcon icon={faArrowLeft} />
                        </Button>
                        <div style={{ display: "inline-flex" }}>
                          <span className="mt-2 pe-1">Page</span>
                          <Form.Control
                            as="select"
                            style={{ width: "55px" }}
                            value={pageNumber}
                            onChange={(e) =>
                              setPageNumber(parseInt(e.target.value))
                            }
                          >
                            {(() => {
                              const options: JSX.Element[] = [];
                              for (var i = 1; i <= numPages; i++)
                                options.push(
                                  <option key={"value-" + i}>{i}</option>
                                );
                              return options;
                            })()}
                          </Form.Control>
                          <span className="mt-2 ps-1"> of {numPages}</span>
                        </div>
                        <Button
                          className="ms-2"
                          disabled={pageNumber + 1 > numPages}
                          onClick={() => setPageNumber(pageNumber + 1)}
                        >
                          <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                      </ButtonGroup>
                    </ButtonToolbar>
                  </Document>
                </div>
              ) : imageData.startsWith("data:video") ? (
                <Aux>{videoContent()}</Aux>
              ) : (
                <div>
                  <span>Unable to load file.</span>
                  <br></br>
                </div>
              )}
            </Aux>
          ) : (
            <p className="text-center mt-2">
              {error ? "Image Not Found" : "Loading..."}
            </p>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default QcCheckpointImageModal;
