import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../../store/actions/request";
import {
  saveSuccess,
  deleteSuccess,
  saveFailed,
} from "../../store/actions/alerts";
import { IUser } from "../../models/user";
import { Dispatch } from "redux";
import { DataResult } from "@progress/kendo-data-query";
import { IRole } from "models/role";
import { DataList } from "utils/DataList";
import { ITraining } from "models/training";

export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_ROLES_SUCCESS = "FETCH_ROLES_SUCCESS";
export const FETCH_FILTEREDUSERS_SUCCESS = "FETCH_FILTEREDUSERS_SUCCESS";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_ROLE_USERS_SUCCESS = "FETCH_ROLE_USERS_SUCCESS";
export const FETCH_USER_TRAININGS_SUCCESS = "FETCH_USER_TRAININGS_SUCCESS";
export const FETCH_TRAINING_PROGRESS_SUCCESS =
  "FETCH_TRAINING_PROGRESS_SUCCESS";

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_TWOFACTOR_SUCCESS = "RESET_TWOFACTOR_SUCCESS";
export const AUTH_SYNC_SUCCESS = "AUTH_SYNC_SUCCESS";
export const AUTH_UNLINK_SUCCESS = "AUTH_UNLINK_SUCCESS";

export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";

export const USER_REQUEST_ERRORS = "USER_REQUEST_ERRORS";
export const USER_REQUEST_FAILURE = "USER_REQUEST_FAILURE";
export const SYNC_REQUEST_FAILURE = "SYNC_REQUEST_FAILURE";

export type UserActions = {
  FETCH_USERS_SUCCESS: {
    type: typeof FETCH_USERS_SUCCESS;
    users: IUser[];
  };
  FETCH_ROLES_SUCCESS: {
    type: typeof FETCH_ROLES_SUCCESS;
    roles: IRole[];
  };
  FETCH_USER_TRAININGS_SUCCESS: {
    type: typeof FETCH_USER_TRAININGS_SUCCESS;
    userTrainings: ITraining[];
  };
  FETCH_TRAINING_PROGRESS_SUCCESS: {
    type: typeof FETCH_TRAINING_PROGRESS_SUCCESS;
    trainingProgress: any;
  };
  FETCH_FILTEREDUSERS_SUCCESS: {
    type: typeof FETCH_FILTEREDUSERS_SUCCESS;
    usersData: DataList<IUser>;
  };
  FETCH_USER_SUCCESS: {
    type: typeof FETCH_USER_SUCCESS;
    user: IUser;
  };
  FETCH_ROLE_USERS_SUCCESS: {
    type: typeof FETCH_ROLE_USERS_SUCCESS;
    users: IUser[];
  };

  RESET_PASSWORD_SUCCESS: {
    type: typeof RESET_PASSWORD_SUCCESS;
  };
  RESET_TWOFACTOR_SUCCESS: {
    type: typeof RESET_TWOFACTOR_SUCCESS;
  };

  AUTH_SYNC_SUCCESS: {
    type: typeof AUTH_SYNC_SUCCESS;
  };
  AUTH_UNLINK_SUCCESS: {
    type: typeof AUTH_UNLINK_SUCCESS;
  };

  CREATE_USER_SUCCESS: {
    type: typeof CREATE_USER_SUCCESS;
    user: IUser;
  };
  EDIT_USER_SUCCESS: {
    type: typeof EDIT_USER_SUCCESS;
    user: IUser;
  };
  DELETE_USER_SUCCESS: {
    type: typeof DELETE_USER_SUCCESS;
  };
  USER_REQUEST_ERRORS: {
    type: typeof USER_REQUEST_ERRORS;
    errors: any;
  };
  USER_REQUEST_FAILURE: {
    type: typeof USER_REQUEST_FAILURE;
    errors: any;
  };
  SYNC_REQUEST_FAILURE: {
    type: typeof SYNC_REQUEST_FAILURE;
    errors: any;
  };
};

export type UserActionsTypes =
  | UserActions[typeof FETCH_USERS_SUCCESS]
  | UserActions[typeof FETCH_ROLES_SUCCESS]
  | UserActions[typeof FETCH_FILTEREDUSERS_SUCCESS]
  | UserActions[typeof FETCH_USER_SUCCESS]
  | UserActions[typeof FETCH_ROLE_USERS_SUCCESS]
  | UserActions[typeof FETCH_USER_TRAININGS_SUCCESS]
  | UserActions[typeof FETCH_TRAINING_PROGRESS_SUCCESS]
  | UserActions[typeof CREATE_USER_SUCCESS]
  | UserActions[typeof EDIT_USER_SUCCESS]
  | UserActions[typeof DELETE_USER_SUCCESS]
  | UserActions[typeof USER_REQUEST_ERRORS]
  | UserActions[typeof USER_REQUEST_FAILURE]
  | UserActions[typeof SYNC_REQUEST_FAILURE];

export const actionCreators = {
  fetchUsersSuccess: (
    users: IUser[]
  ): UserActions[typeof FETCH_USERS_SUCCESS] => ({
    type: FETCH_USERS_SUCCESS,
    users: users,
  }),
  fetchRolesSuccess: (
    roles: IRole[]
  ): UserActions[typeof FETCH_ROLES_SUCCESS] => ({
    type: FETCH_ROLES_SUCCESS,
    roles: roles,
  }),
  fetchTrainingProgressSuccess: (
    trainingProgress: any
  ): UserActions[typeof FETCH_TRAINING_PROGRESS_SUCCESS] => ({
    type: FETCH_TRAINING_PROGRESS_SUCCESS,
    trainingProgress: trainingProgress,
  }),
  fetchUserTrainingsSuccess: (
    userTrainings: ITraining[]
  ): UserActions[typeof FETCH_USER_TRAININGS_SUCCESS] => ({
    type: FETCH_USER_TRAININGS_SUCCESS,
    userTrainings: userTrainings,
  }),
  fetchFilteredUsersSuccess: (
    usersData: DataResult
  ): UserActions[typeof FETCH_FILTEREDUSERS_SUCCESS] => ({
    type: FETCH_FILTEREDUSERS_SUCCESS,
    usersData: usersData,
  }),
  fetchUserSuccess: (user: IUser): UserActions[typeof FETCH_USER_SUCCESS] => ({
    type: FETCH_USER_SUCCESS,
    user: user,
  }),
  fetchRoleUsersSuccess: (
    users: IUser[]
  ): UserActions[typeof FETCH_ROLE_USERS_SUCCESS] => ({
    type: FETCH_ROLE_USERS_SUCCESS,
    users: users,
  }),

  resetPasswordSuccess: (): UserActions[typeof RESET_PASSWORD_SUCCESS] => ({
    type: RESET_PASSWORD_SUCCESS,
  }),
  resetTwoFactorSuccess: (): UserActions[typeof RESET_TWOFACTOR_SUCCESS] => ({
    type: RESET_TWOFACTOR_SUCCESS,
  }),
  authSyncSuccess: (): UserActions[typeof AUTH_SYNC_SUCCESS] => ({
    type: AUTH_SYNC_SUCCESS,
  }),
  authUnlinkSuccess: (): UserActions[typeof AUTH_UNLINK_SUCCESS] => ({
    type: AUTH_UNLINK_SUCCESS,
  }),

  createUserSuccess: (
    user: IUser
  ): UserActions[typeof CREATE_USER_SUCCESS] => ({
    type: CREATE_USER_SUCCESS,
    user: user,
  }),
  editUserSuccess: (user: IUser): UserActions[typeof EDIT_USER_SUCCESS] => ({
    type: EDIT_USER_SUCCESS,
    user: user,
  }),
  deleteUserSuccess: (): UserActions[typeof DELETE_USER_SUCCESS] => ({
    type: DELETE_USER_SUCCESS,
  }),
  userRequestErrors: (
    errors: any
  ): UserActions[typeof USER_REQUEST_ERRORS] => ({
    type: USER_REQUEST_ERRORS,
    errors: errors,
  }),
  userRequestFailure: (
    status: number
  ): UserActions[typeof USER_REQUEST_FAILURE] => ({
    type: USER_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
  syncRequestFailure: (
    status: number,
    error: Error
  ): UserActions[typeof SYNC_REQUEST_FAILURE] => ({
    type: SYNC_REQUEST_FAILURE,
    errors: `${error} (${status})`,
  }),
};

export function fetchUsers() {
  return async (dispatch: Dispatch) => {
    const { json } = await getRequest("/users");
    return dispatch(actionCreators.fetchUsersSuccess(json));
  };
}

export const fetchRoles = () => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest("/users/roles");
    return status === 200
      ? dispatch(actionCreators.fetchRolesSuccess(json))
      : null;
  };
};

export function fetchFilteredUsers(queryStr: string) {
  return async (dispatch: Dispatch) => {
    const { json } = await getRequest(`/users/filtered?${queryStr}`);
    return dispatch(actionCreators.fetchFilteredUsersSuccess(json));
  };
}

export const fetchUser = (id: number, detailed: boolean) => {
  return async (dispatch: Dispatch) => {
    const detailedString = detailed ? "?detailed=true" : "";

    const { status, json } = await getRequest(`/users/${id}` + detailedString);
    return status === 200
      ? dispatch(actionCreators.fetchUserSuccess(json))
      : dispatch(actionCreators.userRequestFailure(status));
  };
};

export const fetchUserTrainings = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/users/usertraining/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchUserTrainingsSuccess(json))
      : dispatch(actionCreators.userRequestFailure(status));
  };
};

export function fetchRoleUsers(roleName: string) {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/users/roleusers/${roleName}`);
    return status === 200
      ? dispatch(actionCreators.fetchRoleUsersSuccess(json))
      : null;
  };
}

export function fetchTrainingProgress(
  userId: number,
  stationId: number | null
) {
  return async (dispatch: Dispatch) => {
    let query = `/users/training/${userId}`;
    if (stationId !== null) {
      query = query + `/${stationId}`;
    }
    const { status, json } = await getRequest(query);
    return status === 200
      ? dispatch(actionCreators.fetchTrainingProgressSuccess(json))
      : null;
  };
}

export const createUser = (user: IUser) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest("/users/create", user);
    switch (status) {
      case 200:
        dispatch(saveSuccess(user.email));
        return dispatch(actionCreators.createUserSuccess(json));
      case 400:
        dispatch(saveFailed(user.email));
        return dispatch(actionCreators.userRequestErrors(json));
      default:
        dispatch(saveFailed(user.email));
        return dispatch(actionCreators.userRequestFailure(status));
    }
  };
};

export const editUser = (id: number, user: IUser) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(`/users/${id}`, user);
    switch (status) {
      case 200:
        dispatch(saveSuccess(user.fullName));
        return dispatch(actionCreators.editUserSuccess(json));
      case 400:
        dispatch(saveFailed(user.fullName));
        return dispatch(actionCreators.userRequestErrors(json));
      default:
        dispatch(saveFailed(user.email));
        return dispatch(actionCreators.userRequestFailure(status));
    }
  };
};

export const deleteUser = (id: number, user: IUser) => {
  return async (dispatch: Dispatch) => {
    const { status } = await deleteRequest(`/users/${id}`, user.email);
    if (status === 200) {
      dispatch(deleteSuccess(user.email));
      return dispatch(actionCreators.deleteUserSuccess());
    }
    dispatch(saveFailed(user.email));
    return dispatch(actionCreators.userRequestFailure(status));
  };
};

export const resetPassword = (id: number, password: string) => {
  return async (dispatch: Dispatch) => {
    const { status } = await postRequest(`/users/${id}/resetpassword`, {
      password,
    });
    if (status === 200) {
      return dispatch(actionCreators.resetPasswordSuccess());
    }
    return dispatch(actionCreators.userRequestFailure(status));
  };
};

export const resetTwoFactor = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status } = await postRequest(`/users/${id}/resetotp`, {});
    if (status === 200) {
      return dispatch(actionCreators.resetTwoFactorSuccess());
    }
    return dispatch(actionCreators.userRequestFailure(status));
  };
};

export const authSyncUser = (user: IUser) => {
  return async (dispatch: Dispatch) => {
    const response = await postRequest(
      `/users/${user.id}/authsync?authId=` + user.authId,
      {}
    );
    if (response.status === 200) {
      return dispatch(actionCreators.authSyncSuccess());
    }
    return dispatch(
      actionCreators.syncRequestFailure(response.status, response.json.Error)
    );
  };
};

export const authUnlinkUser = (user: IUser) => {
  return async (dispatch: Dispatch) => {
    const response = await postRequest(`/users/${user.id}/unlink`, {});
    if (response.status === 200) {
      return dispatch(actionCreators.authUnlinkSuccess());
    }
    return dispatch(
      actionCreators.syncRequestFailure(response.status, response.json.Error)
    );
  };
};
