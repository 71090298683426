import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  requestBegin,
  requestEnd,
} from "../request";
import {
  IQcInspection,
  IQcInspectionList,
} from "../../../models/QualityControl/qcInspection";
import { Dispatch } from "redux";
import { DataResult } from "@progress/kendo-data-query";
import { deleteSuccess, saveFailed, saveSuccess } from "../alerts";

export const FETCH_QC_INSPECTIONS_SUCCESS = "FETCH_QC_INSPECTIONS_SUCCESS";
export const FETCH_QC_INSPECTION_SUCCESS = "FETCH_QC_INSPECTION_SUCCESS";
export const FETCH_FAT_INSPECTION_LIST_SUCCESS =
  "FETCH_FAT_INSPECTION_LIST_SUCCESS";
export const FETCH_FILTERED_QC_INSPECTIONS_SUCCESS =
  "FETCH_FILTERED_QC_INSPECTIONS_SUCCESS";
export const CREATE_QC_INSPECTION_SUCCESS = "CREATE_QC_INSPECTION_SUCCESS";
export const EDIT_QC_INSPECTION_SUCCESS = "EDIT_QC_INSPECTION_SUCCESS";
export const DELETE_QC_INSPECTION_SUCCESS = "DELETE_QC_INSPECTION_SUCCESS";
export const QC_INSPECTION_REQUEST_FAILURE = "QC_INSPECTION_REQUEST_FAILURE";
export const QC_INSPECTION_REQUEST_ERROR = "QC_INSPECTION_REQUEST_ERROR";

export interface QcInspectionActions {
  FETCH_QC_INSPECTIONS_SUCCESS: {
    type: typeof FETCH_QC_INSPECTIONS_SUCCESS;
    qcInspections: IQcInspection[];
  };
  FETCH_QC_INSPECTION_SUCCESS: {
    type: typeof FETCH_QC_INSPECTION_SUCCESS;
    qcInspection: IQcInspection;
  };
  FETCH_FAT_INSPECTION_LIST_SUCCESS: {
    type: typeof FETCH_FAT_INSPECTION_LIST_SUCCESS;
    inspectionList: IQcInspectionList;
  };
  FETCH_FILTERED_QC_INSPECTIONS_SUCCESS: {
    type: typeof FETCH_FILTERED_QC_INSPECTIONS_SUCCESS;
    qcInspections: DataResult;
  };
  CREATE_QC_INSPECTION_SUCCESS: {
    type: typeof CREATE_QC_INSPECTION_SUCCESS;
    qcInspection: IQcInspection;
  };
  EDIT_QC_INSPECTION_SUCCESS: {
    type: typeof EDIT_QC_INSPECTION_SUCCESS;
    qcInspection: IQcInspection;
  };
  DELETE_QC_INSPECTION_SUCCESS: {
    type: typeof DELETE_QC_INSPECTION_SUCCESS;
  };
  QC_INSPECTION_REQUEST_FAILURE: {
    type: typeof QC_INSPECTION_REQUEST_FAILURE;
    errors: any;
  };
  QC_INSPECTION_REQUEST_ERROR: {
    type: typeof QC_INSPECTION_REQUEST_ERROR;
    errors: any;
  };
}

export type QcInspectionActionTypes =
  | QcInspectionActions[typeof FETCH_QC_INSPECTIONS_SUCCESS]
  | QcInspectionActions[typeof FETCH_QC_INSPECTION_SUCCESS]
  | QcInspectionActions[typeof FETCH_FAT_INSPECTION_LIST_SUCCESS]
  | QcInspectionActions[typeof FETCH_FILTERED_QC_INSPECTIONS_SUCCESS]
  | QcInspectionActions[typeof CREATE_QC_INSPECTION_SUCCESS]
  | QcInspectionActions[typeof EDIT_QC_INSPECTION_SUCCESS]
  | QcInspectionActions[typeof DELETE_QC_INSPECTION_SUCCESS]
  | QcInspectionActions[typeof QC_INSPECTION_REQUEST_FAILURE]
  | QcInspectionActions[typeof QC_INSPECTION_REQUEST_ERROR];

export const actionCreators = {
  fetchQcInspectionsSuccess: (
    qcInspections: IQcInspection[]
  ): QcInspectionActions[typeof FETCH_QC_INSPECTIONS_SUCCESS] => ({
    type: FETCH_QC_INSPECTIONS_SUCCESS,
    qcInspections: qcInspections,
  }),
  fetchQcInspectionSuccess: (
    qcInspection: IQcInspection
  ): QcInspectionActions[typeof FETCH_QC_INSPECTION_SUCCESS] => ({
    type: FETCH_QC_INSPECTION_SUCCESS,
    qcInspection: qcInspection,
  }),
  fetchFATInspectionListSuccess: (
    inspectionList: IQcInspectionList
  ): QcInspectionActions[typeof FETCH_FAT_INSPECTION_LIST_SUCCESS] => ({
    type: FETCH_FAT_INSPECTION_LIST_SUCCESS,
    inspectionList: inspectionList,
  }),
  fetchFilteredQcInspectionsSuccess: (
    qcInspections: DataResult
  ): QcInspectionActions[typeof FETCH_FILTERED_QC_INSPECTIONS_SUCCESS] => ({
    type: FETCH_FILTERED_QC_INSPECTIONS_SUCCESS,
    qcInspections: qcInspections,
  }),
  createQcInspectionSuccess: (
    qcInspection: IQcInspection
  ): QcInspectionActions[typeof CREATE_QC_INSPECTION_SUCCESS] => ({
    type: CREATE_QC_INSPECTION_SUCCESS,
    qcInspection: qcInspection,
  }),
  editQcInspectionSuccess: (
    qcInspection: IQcInspection
  ): QcInspectionActions[typeof EDIT_QC_INSPECTION_SUCCESS] => ({
    type: EDIT_QC_INSPECTION_SUCCESS,
    qcInspection: qcInspection,
  }),
  deleteQcInspectionSuccess: (): QcInspectionActions[typeof DELETE_QC_INSPECTION_SUCCESS] => ({
    type: DELETE_QC_INSPECTION_SUCCESS,
  }),
  qcInspectionRequestFailure: (
    status: number
  ): QcInspectionActions[typeof QC_INSPECTION_REQUEST_FAILURE] => ({
    type: QC_INSPECTION_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
  qcInspectionRequestError: (
    errors: any
  ): QcInspectionActions[typeof QC_INSPECTION_REQUEST_ERROR] => ({
    type: QC_INSPECTION_REQUEST_ERROR,
    errors: errors,
  }),
};

export function fetchQcInspections() {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_QC_INSPECTIONS_SUCCESS));
    const { status, json } = await getRequest("/qcInspections");
    dispatch(requestEnd(FETCH_QC_INSPECTIONS_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchQcInspectionsSuccess(json))
      : dispatch(actionCreators.qcInspectionRequestFailure(status));
  };
}

export const fetchQcInspection = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/qcInspections/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchQcInspectionSuccess(json))
      : dispatch(actionCreators.qcInspectionRequestFailure(status));
  };
};

export const fetchFATInspectionList = (qcChassiId: number) => {
  return async (dispatch: Dispatch) => {
    let query = `/qcInspections/fat/${qcChassiId}`;
    const { status, json } = await getRequest(query);
    return status === 200
      ? dispatch(actionCreators.fetchFATInspectionListSuccess(json))
      : dispatch(actionCreators.qcInspectionRequestFailure(status));
  };
};

export function fetchFilteredQcInspections(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_FILTERED_QC_INSPECTIONS_SUCCESS));
    const { status, json } = await getRequest(
      `/qcInspections/filtered?${queryStr}`
    );
    dispatch(requestEnd(FETCH_FILTERED_QC_INSPECTIONS_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchFilteredQcInspectionsSuccess(json))
      : dispatch(actionCreators.qcInspectionRequestFailure(status));
  };
}

export const createQcInspection = (qcInspection: IQcInspection) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest("/qcInspections", qcInspection);
    switch (status) {
      case 200:
        dispatch(saveSuccess("qcInspection saved"));
        return dispatch(actionCreators.createQcInspectionSuccess(json));
      case 400:
        dispatch(saveFailed("qcInspection"));
        return dispatch(actionCreators.qcInspectionRequestError(json));
      default:
        dispatch(saveFailed("qcInspection"));
        return dispatch(actionCreators.qcInspectionRequestFailure(status));
    }
  };
};

export const editQcInspection = (id: number, qcInspection: IQcInspection) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(
      `/qcInspections/${id}`,
      qcInspection
    );
    switch (status) {
      case 200:
        dispatch(saveSuccess(qcInspection.note));
        return dispatch(actionCreators.editQcInspectionSuccess(json));
      case 400:
        dispatch(saveFailed("qcInspection saved"));
        return dispatch(actionCreators.qcInspectionRequestError(json));
      default:
        dispatch(saveFailed("qcInspection"));
        return dispatch(actionCreators.qcInspectionRequestFailure(status));
    }
  };
};

export const deleteQcInspection = (id: number, qcInspection: IQcInspection) => {
  return async (dispatch: Dispatch) => {
    const { status } = await deleteRequest(
      `/qcInspections/${id}`,
      qcInspection
    );
    if (status === 200) {
      dispatch(deleteSuccess("qcInspection saved"));
      return dispatch(actionCreators.deleteQcInspectionSuccess());
    }
    dispatch(saveFailed("qcInspection"));
    return dispatch(actionCreators.qcInspectionRequestFailure(status));
  };
};
