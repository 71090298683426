import React, { useEffect, useState } from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { History } from "history";
import { connect } from "react-redux";
import { useRouteMatch } from "react-router";

import Aux from "../utils/auxiliary";
import ResourceForm from "../components/Resource/ResourceForm";
import { IResource } from "../models/resource";
import { isResourceAdmin, IUser } from "../models/user";
import { Paths } from "../routes";
import {
  createResource,
  editResource,
  fetchResource,
} from "../store/actions/resources";
import { IRootState } from "../store/index";
import { fetchUsers } from "../store/actions/users";
import CloseButton from "components/Common/CloseButton";

export interface MatchParams {
  id: string;
}

export interface IProps {
  fetchResource: (id: number) => any;
  fetchUsers: () => any;
  editResource: (id: number, resource: IResource) => any;
  createResource: (resource: IResource) => any;
  history: History;
  resourceId: number;
  users: IUser[];
  currentUser: IUser;
}

const ResourceFormContainer: React.FC<IProps> = (props) => {
  const {
    fetchResource,
    editResource,
    createResource,
    fetchUsers,
    history,
    resourceId,
    users,
    currentUser,
  } = props;

  const [resource, setResource] = React.useState<IResource>({} as IResource);
  const [loading, setLoading] = useState<boolean>(true);
  const [saveloading, setSaveLoading] = useState<boolean>(false);

  const match = useRouteMatch<MatchParams>();

  useEffect(() => {
    let mounted = true;
    let id;
    if (resourceId) {
      id = resourceId;
    } else if (match.params) {
      id = parseInt(match.params.id);
    }
    if (!isNaN(id)) {
      fetchResource(id).then(({ resource }: { resource: any }) => {
        if (mounted) {
          if (
            isResourceAdmin(currentUser) ||
            currentUser.administeredResources.length > 0
          ) {
            setResource(resource);
            setLoading(false);
          } else {
            history.replace(Paths.issues);
          }
        }
      });
    } else {
      setLoading(false);
    }

    if (users.length === 0) fetchUsers();

    return () => {
      mounted = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (resource: IResource) => {
    setSaveLoading(true);
    if (resource.id) {
      const result = await editResource(resource.id, resource);
      if (result) {
        if (result.errors !== undefined) return;
        setResource(result.resource);
        setSaveLoading(false);
      }
    } else if (match.params.id === "create") {
      const result = await createResource(resource);
      if (result) {
        if (result.errors !== undefined) return;
        setSaveLoading(false);
        history.replace(Paths.gResourcesId(result.resource.id));
      }
    }
  };

  const onCancel = () => {
    history.goBack();
  };

  const isAdministator =
    currentUser.administeredResources.findIndex((ar) => ar.id === resource.id) >
      -1 || isResourceAdmin(currentUser);

  return (
    <Aux>
      {!resourceId && <CloseButton onCLick={onCancel} />}
      {loading ? (
        <p className="text-center m-3">
          <FontAwesomeIcon icon={faSpinner} size="3x" spin />
        </p>
      ) : (
        <div>
          <ResourceForm
            resource={resource}
            users={users}
            canEdit={isAdministator}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
          {saveloading && (
            <p className="text-center m-3">
              <FontAwesomeIcon
                icon={faSpinner}
                size="3x"
                spin
                style={{
                  top: "50%",
                  left: "50%",
                  position: "fixed",
                  zIndex: 999,
                }}
              />
            </p>
          )}
        </div>
      )}
    </Aux>
  );
};

const mapStateToProps = (state: IRootState) => ({
  resources: state.resourceReducer.resources,
  users: state.usersReducer.users,
  currentUser: state.authReducer.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchResource: (id: any) => dispatch(fetchResource(id)),
  editResource: (id: any, resource: any) =>
    dispatch(editResource(id, resource)),
  createResource: (resource: any) => dispatch(createResource(resource)),
  fetchUsers: () => dispatch(fetchUsers()),
});

const formContainer = ResourceFormContainer as (props: IProps) => JSX.Element;

export default connect(mapStateToProps, mapDispatchToProps)(formContainer);
