import React, { RefObject, useEffect, useState } from "react";
import { faImages, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { History } from "history";
import { connect } from "react-redux";

import Aux from "../utils/auxiliary";
import JigGrid from "../components/Jigs/JigGrid";
import JigImageGrid from "../components/Jigs/JigImageGrid";
import { IJig, IJigCategory } from "../models/jig";
import { fetchFilteredJigs, fetchJigCategories } from "../store/actions/jigs";
import { IRootState } from "../store/index";
import { useAsyncErrorHandler } from "../utils/asyncErrorHandler";
import { store } from "../index";
import { Paths } from "../routes";
import { isJigAdmin, IUser } from "../models/user";
import { DataList } from "utils/DataList";
import AddNewButton from "components/Common/AddNewButton";

export interface IProps {
  current: IUser;
  jigs: DataList<IJig>;
  jigCategories: IJigCategory[];
  history: History;
  fetchFilteredJigs: (queryStr: string) => any;
  fetchJigCategories: () => any;
}

export const JigsContainer: React.FC<IProps> = (props) => {
  const {
    current,
    jigs,
    jigCategories,
    history,
    fetchFilteredJigs,
    fetchJigCategories,
  } = props;

  useAsyncErrorHandler(store.getState().jigsReducer.errors);

  const [showGrid, setShowGrid] = useState<boolean>(
    localStorage.getItem("image_view") != null
  );
  const [viewHidden, setViewHidden] = useState<boolean>(
    localStorage.getItem("jigs_hide_state") != null
  );
  const [elRef, setElRef] = useState<RefObject<HTMLInputElement> | null>();

  useEffect(() => {
    if (jigCategories.length <= 0) fetchJigCategories();
  });

  const handleFetch = (query: string) => {
    fetchFilteredJigs(query);
  };

  const editJig = (j: IJig) => {
    history.push(Paths.gEquipmentsId(j.id));
  };

  const newJig = () => {
    history.push(Paths.equipmentsCreate);
  };

  return (
    <Aux>
      <div>
        <FontAwesomeIcon
          icon={faImages}
          size="2x"
          title="Toggle image view"
          className={`cursor${showGrid ? "" : " text-muted"}`}
          onClick={() => {
            const active = !showGrid;
            setShowGrid(active);
            active
              ? localStorage.setItem("image_view", "true")
              : localStorage.removeItem("image_view");
          }}
        />

        <FontAwesomeIcon
          icon={faEyeSlash}
          size="2x"
          title="Toggle view hidden"
          className={`cursor ms-4${viewHidden ? "" : " text-muted"}`}
          onClick={() => {
            localStorage.setItem("clicked_view_hidden_jigs", "true");
            const active = !viewHidden;
            setViewHidden(active);
          }}
        />
      </div>
      <h3>Equipment</h3>
      <br />
      {!showGrid ? (
        <JigGrid
          jigData={jigs}
          jigCategories={jigCategories}
          handleFetch={handleFetch}
          onEditClick={editJig}
          viewHidden={viewHidden}
          setElRef={setElRef}
          elRef={elRef}
        />
      ) : (
        <JigImageGrid
          current={current}
          jigs={jigs.data}
          totalJigs={jigs.total}
          handleFetch={handleFetch}
          viewHidden={viewHidden}
          onEditClick={editJig}
        />
      )}
      {isJigAdmin(current) && <AddNewButton onCLick={() => newJig()} />}
    </Aux>
  );
};

const mapStateToProps = (state: IRootState) => ({
  jigs: state.jigsReducer.filteredJigs,
  current: state.authReducer.user,
  jigCategories: state.jigsReducer.jigCategories,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchFilteredJigs: (queryStr: string) =>
    dispatch(fetchFilteredJigs(queryStr)),
  fetchJigCategories: () => dispatch(fetchJigCategories()),
});

export default connect(mapStateToProps, mapDispatchToProps)(JigsContainer);
