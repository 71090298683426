import { createBrowserHistory } from "history";
import "izitoast/dist/css/iziToast.min.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { initI18Next } from "translations/translations";
import ErrorBoundary from "./components/Error/ErrorBoundary";
import CacheClearer from "./components/Misc/ClearCache";
import App from "./containers/AppContainer";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { configureStore } from "./store/index";
import "./styles/scss/index.scss";

export const history = createBrowserHistory({ basename: "/" });
export const store = configureStore();

initI18Next();

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <CacheClearer>
        <App history={history} />
      </CacheClearer>
    </ErrorBoundary>
  </Provider>,
  document.getElementById("orkel-pi-container")
);

serviceWorkerRegistration.register();
