import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataSourceRequestState } from "@progress/kendo-data-query";
import React from "react";

export interface IProps {
  requestState: DataSourceRequestState;
  arrayLength: number;
  total: number;
  disabled?: boolean;
  setRequestState: (requestState: DataSourceRequestState) => any;
}

export const PageNavigation: React.FC<IProps> = (props) => {
  const { requestState, arrayLength, total, disabled, setRequestState } = props;

  return (
    <div className="text-center">
      <FontAwesomeIcon
        icon={faAngleDoubleLeft}
        size="2x"
        className="mx-2"
        onClick={() => {
          if (requestState.skip && !disabled) {
            setRequestState({
              ...requestState,
              skip: 0,
            });
          }
        }}
        style={
          requestState.skip && !disabled
            ? { cursor: "pointer" }
            : { opacity: 0.5 }
        }
      />
      <FontAwesomeIcon
        icon={faAngleLeft}
        size="2x"
        className="mx-2"
        onClick={() => {
          if (requestState.skip && !disabled) {
            setRequestState({
              ...requestState,
              skip:
                requestState.skip - (requestState.take ? requestState.take : 0),
            });
          }
        }}
        style={
          requestState.skip && !disabled
            ? { cursor: "pointer" }
            : { opacity: 0.5 }
        }
      />
      <span>
        {(requestState.skip ? requestState.skip : 0) + 1} -{" "}
        {(requestState.skip ? requestState.skip : 0) + arrayLength}
      </span>
      <FontAwesomeIcon
        icon={faAngleRight}
        size="2x"
        className="mx-2"
        onClick={() => {
          if (
            requestState.take === arrayLength &&
            total - requestState.take !== requestState.skip &&
            requestState.take !== undefined &&
            !disabled
          ) {
            setRequestState({
              ...requestState,
              skip:
                (requestState.skip ? requestState.skip : 0) + requestState.take,
            });
          }
        }}
        style={
          requestState.take &&
          requestState.take === arrayLength &&
          total - requestState.take !== requestState.skip &&
          !disabled
            ? { cursor: "pointer" }
            : { opacity: 0.5 }
        }
      />
      <FontAwesomeIcon
        icon={faAngleDoubleRight}
        size="2x"
        className="mx-2"
        onClick={() => {
          if (
            arrayLength === requestState.take &&
            total - requestState.take !== requestState.skip &&
            requestState.take !== undefined &&
            !disabled
          ) {
            setRequestState({
              ...requestState,
              skip:
                total % requestState.take !== 0
                  ? Math.floor(total / requestState.take) * requestState.take
                  : total - requestState.take,
              take: requestState.take,
            });
          }
        }}
        style={
          arrayLength === requestState.take &&
          total - requestState.take !== requestState.skip &&
          !disabled
            ? { cursor: "pointer" }
            : { opacity: 0.5 }
        }
      />
      <p className="text-muted">
        shows {(requestState.skip ? requestState.skip : 0) + 1} -{" "}
        {(requestState.skip ? requestState.skip : 0) + arrayLength} out of{" "}
        {total}
      </p>
    </div>
  );
};

export default PageNavigation;
