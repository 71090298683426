import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  requestBegin,
  requestEnd,
} from "../../store/actions/request";
import { IJig, IJigCategory, IJigProduct } from "../../models/jig";
import { Dispatch } from "redux";
import { deleteSuccess, saveFailed, saveSuccess } from "./alerts";
import { IJigImage } from "models/image";
import { DataList } from "utils/DataList";

export const FETCH_JIGG_SUCCESS = "FETCH_JIGG_SUCCESS";
export const FETCH_JIGGS_SUCCESS = "FETCH_JIGGS_SUCCESS";
export const FETCH_FILTEREDJIGGS_SUCCESS = "FETCH_FILTEREDJIGGS_SUCCESS";
export const CREATE_JIGG_SUCCESS = "CREATE_JIGG_SUCCESS";
export const EDIT_JIGG_SUCCESS = "EDIT_JIGG_SUCCESS";
export const DELETE_JIGG_SUCCESS = "DELETE_JIGG_SUCCESS";
export const JIGG_REQUEST_FAILURE = "JIGG_REQUEST_FAILURE";
export const JIGG_REQUEST_ERRORS = "JIGG_REQUEST_ERRORS";
export const FETCH_JIGG_CATEGORIES_SUCCESS = "FETCH_JIGG_CATEGORIES_SUCCESS";
export const CREATE_JIG_CATEGORY_SUCCESS = "CREATE_JIG_CATEGORY_SUCCESS";
export const EDIT_JIG_CATEGORY_SUCCESS = "EDIT_JIG_CATEGORY_SUCCESS";
export const CREATE_JIGG_IMAGE_SUCCESS = "CREATE_JIGG_IMAGE_SUCCESS";
export const CREATE_JIGG_PRODUCT_SUCCESS = "CREATE_JIGG_PRODUCT_SUCCESS";

export type JigActions = {
  FETCH_JIGG_SUCCESS: {
    type: typeof FETCH_JIGG_SUCCESS;
    jig: IJig;
  };
  FETCH_JIGGS_SUCCESS: {
    type: typeof FETCH_JIGGS_SUCCESS;
    jigs: IJig[];
  };
  FETCH_FILTEREDJIGGS_SUCCESS: {
    type: typeof FETCH_FILTEREDJIGGS_SUCCESS;
    filteredJigs: DataList<IJig>;
  };
  CREATE_JIGG_SUCCESS: {
    type: typeof CREATE_JIGG_SUCCESS;
    jig: IJig;
  };
  EDIT_JIGG_SUCCESS: {
    type: typeof EDIT_JIGG_SUCCESS;
    jig: IJig;
  };
  CREATE_JIG_CATEGORY_SUCCESS: {
    type: typeof CREATE_JIG_CATEGORY_SUCCESS;
    jigCategory: IJigCategory;
  };
  EDIT_JIG_CATEGORY_SUCCESS: {
    type: typeof EDIT_JIG_CATEGORY_SUCCESS;
    jigCategory: IJigCategory;
  };
  DELETE_JIGG_SUCCESS: {
    type: typeof DELETE_JIGG_SUCCESS;
  };
  JIGG_REQUEST_FAILURE: {
    type: typeof JIGG_REQUEST_FAILURE;
    errors: any;
  };
  JIGG_REQUEST_ERRORS: {
    type: typeof JIGG_REQUEST_ERRORS;
    errors: any;
  };
  FETCH_JIGG_CATEGORIES_SUCCESS: {
    type: typeof FETCH_JIGG_CATEGORIES_SUCCESS;
    jigCategories: IJigCategory[];
  };
  CREATE_JIGG_IMAGE_SUCCESS: {
    type: typeof CREATE_JIGG_IMAGE_SUCCESS;
    jigImage: IJigImage;
  };
  CREATE_JIGG_PRODUCT_SUCCESS: {
    type: typeof CREATE_JIGG_PRODUCT_SUCCESS;
    jigProduct: IJigProduct;
  };
};

export type JigActionTypes =
  | JigActions[typeof FETCH_JIGG_SUCCESS]
  | JigActions[typeof FETCH_JIGGS_SUCCESS]
  | JigActions[typeof FETCH_FILTEREDJIGGS_SUCCESS]
  | JigActions[typeof CREATE_JIGG_SUCCESS]
  | JigActions[typeof EDIT_JIGG_SUCCESS]
  | JigActions[typeof DELETE_JIGG_SUCCESS]
  | JigActions[typeof JIGG_REQUEST_FAILURE]
  | JigActions[typeof JIGG_REQUEST_ERRORS]
  | JigActions[typeof FETCH_JIGG_CATEGORIES_SUCCESS]
  | JigActions[typeof CREATE_JIGG_IMAGE_SUCCESS]
  | JigActions[typeof CREATE_JIG_CATEGORY_SUCCESS]
  | JigActions[typeof EDIT_JIG_CATEGORY_SUCCESS]
  | JigActions[typeof CREATE_JIGG_PRODUCT_SUCCESS];

export const actionCreators = {
  fetchJig: (jig: IJig): JigActions[typeof FETCH_JIGG_SUCCESS] => ({
    type: FETCH_JIGG_SUCCESS,
    jig: jig,
  }),
  fetchJigsSuccess: (jigs: IJig[]): JigActions[typeof FETCH_JIGGS_SUCCESS] => ({
    type: FETCH_JIGGS_SUCCESS,
    jigs: jigs,
  }),
  fetchFilteredJigsSuccess: (
    jigs: DataList<IJig>
  ): JigActions[typeof FETCH_FILTEREDJIGGS_SUCCESS] => ({
    type: FETCH_FILTEREDJIGGS_SUCCESS,
    filteredJigs: jigs,
  }),
  createJigSuccess: (jig: IJig): JigActions[typeof CREATE_JIGG_SUCCESS] => ({
    type: CREATE_JIGG_SUCCESS,
    jig: jig,
  }),
  editJigSuccess: (jig: IJig): JigActions[typeof EDIT_JIGG_SUCCESS] => ({
    type: EDIT_JIGG_SUCCESS,
    jig: jig,
  }),
  createJigCategorySuccess: (
    jigCategory: IJigCategory
  ): JigActions[typeof CREATE_JIG_CATEGORY_SUCCESS] => ({
    type: CREATE_JIG_CATEGORY_SUCCESS,
    jigCategory: jigCategory,
  }),
  editJigCategorySuccess: (
    jigCategory: IJigCategory
  ): JigActions[typeof EDIT_JIG_CATEGORY_SUCCESS] => ({
    type: EDIT_JIG_CATEGORY_SUCCESS,
    jigCategory: jigCategory,
  }),
  deleteJigSuccess: (): JigActions[typeof DELETE_JIGG_SUCCESS] => ({
    type: DELETE_JIGG_SUCCESS,
  }),
  jigRequestFailure: (
    status: any
  ): JigActions[typeof JIGG_REQUEST_FAILURE] => ({
    type: JIGG_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
  jigRequestErrors: (errors: any): JigActions[typeof JIGG_REQUEST_ERRORS] => ({
    type: JIGG_REQUEST_ERRORS,
    errors: errors,
  }),
  fetchJigCategoriesSuccess: (
    jigCategories: IJigCategory[]
  ): JigActions[typeof FETCH_JIGG_CATEGORIES_SUCCESS] => ({
    type: FETCH_JIGG_CATEGORIES_SUCCESS,
    jigCategories: jigCategories,
  }),
  createJigImageSuccess: (
    jigImage: IJigImage
  ): JigActions[typeof CREATE_JIGG_IMAGE_SUCCESS] => ({
    type: CREATE_JIGG_IMAGE_SUCCESS,
    jigImage: jigImage,
  }),
  createJigProductSuccess: (
    jigProduct: IJigProduct
  ): JigActions[typeof CREATE_JIGG_PRODUCT_SUCCESS] => ({
    type: CREATE_JIGG_PRODUCT_SUCCESS,
    jigProduct: jigProduct,
  }),
};

export const fetchJig = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/jigs/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchJig(json))
      : dispatch(actionCreators.jigRequestFailure(status));
  };
};

export function fetchJigs() {
  return async (dispatch) => {
    const { json } = await getRequest("/jigs");
    return dispatch(actionCreators.fetchJigsSuccess(json));
  };
}

export function fetchFilteredJigs(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_FILTEREDJIGGS_SUCCESS));
    const { status, json } = await getRequest(`/jigs/filtered?${queryStr}`);
    dispatch(requestEnd(FETCH_FILTEREDJIGGS_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchFilteredJigsSuccess(json))
      : dispatch(actionCreators.jigRequestFailure(status));
  };
}

export const createJig = (jig: IJig) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest(`/jigs`, jig);
    switch (status) {
      case 200:
        dispatch(saveSuccess(json.jignr));
        return dispatch(actionCreators.createJigSuccess(json));
      case 400:
        return dispatch(actionCreators.jigRequestErrors(json));
      default:
        return dispatch(actionCreators.jigRequestFailure(json));
    }
  };
};

export const editJig = (id: number, jig: IJig) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(`/jigs/${id}`, jig);
    switch (status) {
      case 200:
        dispatch(saveSuccess(jig.jignr));
        return dispatch(actionCreators.editJigSuccess(json));
      case 400:
        dispatch(saveFailed(jig.jignr));
        return dispatch(actionCreators.jigRequestErrors(json));
      default:
        dispatch(saveFailed(jig.jignr));
        return dispatch(actionCreators.jigRequestFailure(status));
    }
  };
};

export const createJigCategory = (jigCategory: IJigCategory) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest(`/jigs/category`, jigCategory);
    switch (status) {
      case 200:
        dispatch(saveSuccess(json.jignr));
        return dispatch(actionCreators.createJigSuccess(json));
      case 400:
        return dispatch(actionCreators.jigRequestErrors(json));
      default:
        return dispatch(actionCreators.jigRequestFailure(json));
    }
  };
};

export const editJigCategory = (id: number, jigCategory: IJigCategory) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(
      `/jigs/category/${id}`,
      jigCategory
    );
    switch (status) {
      case 200:
        dispatch(saveSuccess(jigCategory.name));
        return dispatch(actionCreators.editJigCategorySuccess(json));
      case 400:
        dispatch(saveFailed(jigCategory.name));
        return dispatch(actionCreators.jigRequestErrors(json));
      default:
        dispatch(saveFailed(jigCategory.name));
        return dispatch(actionCreators.jigRequestFailure(status));
    }
  };
};

export const deleteJig = (id: number, jig: IJig) => {
  return async (dispatch: Dispatch) => {
    const { status } = await deleteRequest(`/jigs/${id}`, jig);
    if (status === 200) {
      dispatch(deleteSuccess(jig.jignr));
      return dispatch(actionCreators.deleteJigSuccess());
    }
    dispatch(saveFailed(jig.jignr));
    return dispatch(actionCreators.jigRequestFailure(status));
  };
};

export function fetchJigCategories() {
  return async (dispatch) => {
    const { json } = await getRequest("/jigs/categories");
    return dispatch(actionCreators.fetchJigCategoriesSuccess(json));
  };
}

export const createJigImage = (jigImage: IJigImage) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest(`/jigs/images`, jigImage);
    switch (status) {
      case 200:
        dispatch(saveSuccess("Image saved"));
        return dispatch(actionCreators.createJigImageSuccess(json));
      case 400:
        dispatch(saveFailed("Image"));
        return dispatch(actionCreators.jigRequestErrors(json));
      default:
        dispatch(saveFailed("Image"));
        return dispatch(actionCreators.jigRequestFailure(status));
    }
  };
};

export const createJigProduct = (jigProduct: IJigProduct) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest(`/jigs/products`, jigProduct);
    switch (status) {
      case 200:
        dispatch(saveSuccess("product " + jigProduct.productNo + " saved"));
        return dispatch(actionCreators.createJigProductSuccess(json));
      case 400:
        dispatch(saveFailed(jigProduct.productNo));
        return dispatch(actionCreators.jigRequestErrors(json));
      default:
        dispatch(saveFailed(jigProduct.productNo));
        return dispatch(actionCreators.jigRequestFailure(status));
    }
  };
};
