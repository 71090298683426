import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  requestBegin,
  requestEnd,
} from "../../store/actions/request";
import { IStation } from "../../models/station";
import { Dispatch } from "redux";
import { deleteSuccess, saveFailed, saveSuccess } from "./alerts";
import { DataList } from "utils/DataList";

export const FETCH_STATION_SUCCESS = "FETCH_STATION_SUCCESS";
export const FETCH_STATIONS_SUCCESS = "FETCH_STATIONS_SUCCESS";
export const FETCH_FILTERED_STATIONS_SUCCESS =
  "FETCH_FILTERED_STATIONS_SUCCESS";
export const STATION_REQUEST_FAILURE = "STATION_REQUEST_FAILURE";
export const CREATE_STATION_SUCCESS = "CREATE_STATION_SUCCESS";
export const EDIT_STATION_ORDER_SUCCESS = "EDIT_STATION_ORDER_SUCCESS";
export const STATION_REQUEST_ERRORS = "STATION_REQUEST_ERRORS";
export const DELETE_STATION_SUCCESS = "DELETE_STATION_SUCCESS";
export const EDIT_STATION_SUCCESS = "EDIT_STATION_SUCCESS";

export type StationActions = {
  FETCH_STATION_SUCCESS: {
    type: typeof FETCH_STATION_SUCCESS;
    station: IStation;
  };
  FETCH_STATIONS_SUCCESS: {
    type: typeof FETCH_STATIONS_SUCCESS;
    stations: IStation[];
  };
  FETCH_FILTERED_STATIONS_SUCCESS: {
    type: typeof FETCH_FILTERED_STATIONS_SUCCESS;
    filteredStations: DataList<IStation>;
  };
  CREATE_STATION_SUCCESS: {
    type: typeof CREATE_STATION_SUCCESS;
    station: IStation;
  };
  DELETE_STATION_SUCCESS: {
    type: typeof DELETE_STATION_SUCCESS;
  };
  EDIT_STATION_SUCCESS: {
    type: typeof EDIT_STATION_SUCCESS;
    station: IStation;
  };
  EDIT_STATION_ORDER_SUCCESS: {
    type: typeof EDIT_STATION_ORDER_SUCCESS;
    stations: IStation[];
  };
  STATION_REQUEST_ERRORS: {
    type: typeof STATION_REQUEST_ERRORS;
    errors: any;
  };
  STATION_REQUEST_FAILURE: {
    type: typeof STATION_REQUEST_FAILURE;
    errors: any;
  };
};

export type StationActionsTypes =
  | StationActions[typeof FETCH_STATION_SUCCESS]
  | StationActions[typeof FETCH_STATIONS_SUCCESS]
  | StationActions[typeof FETCH_FILTERED_STATIONS_SUCCESS]
  | StationActions[typeof CREATE_STATION_SUCCESS]
  | StationActions[typeof DELETE_STATION_SUCCESS]
  | StationActions[typeof EDIT_STATION_SUCCESS]
  | StationActions[typeof EDIT_STATION_ORDER_SUCCESS]
  | StationActions[typeof STATION_REQUEST_ERRORS]
  | StationActions[typeof STATION_REQUEST_FAILURE];

export const actionCreators = {
  fetchStationSuccess: (
    station: IStation
  ): StationActions[typeof FETCH_STATION_SUCCESS] => ({
    type: FETCH_STATION_SUCCESS,
    station: station,
  }),
  fetchStationsSuccess: (
    stations: IStation[]
  ): StationActions[typeof FETCH_STATIONS_SUCCESS] => ({
    type: FETCH_STATIONS_SUCCESS,
    stations: stations,
  }),
  fetchFilteredStationsSuccess: (
    stations: DataList<IStation>
  ): StationActions[typeof FETCH_FILTERED_STATIONS_SUCCESS] => ({
    type: FETCH_FILTERED_STATIONS_SUCCESS,
    filteredStations: stations,
  }),
  createStationSuccess: (
    station: IStation
  ): StationActions[typeof CREATE_STATION_SUCCESS] => ({
    type: CREATE_STATION_SUCCESS,
    station: station,
  }),
  deleteStationSuccess: (): StationActions[typeof DELETE_STATION_SUCCESS] => ({
    type: DELETE_STATION_SUCCESS,
  }),
  editStationSuccess: (
    station: IStation
  ): StationActions[typeof EDIT_STATION_SUCCESS] => ({
    type: EDIT_STATION_SUCCESS,
    station: station,
  }),
  editStationOrderSuccess: (
    stations: IStation[]
  ): StationActions[typeof EDIT_STATION_ORDER_SUCCESS] => ({
    type: EDIT_STATION_ORDER_SUCCESS,
    stations: stations,
  }),
  stationRequestErrors: (
    errors: any
  ): StationActions[typeof STATION_REQUEST_ERRORS] => ({
    type: STATION_REQUEST_ERRORS,
    errors: errors,
  }),
  stationRequestFailure: (
    status: number
  ): StationActions[typeof STATION_REQUEST_FAILURE] => ({
    type: STATION_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
};
export const fetchStation = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/stations/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchStationSuccess(json))
      : dispatch(actionCreators.stationRequestFailure(status));
  };
};
export function fetchStations() {
  return async (dispatch: Dispatch) => {
    const { json } = await getRequest("/stations");
    return dispatch(actionCreators.fetchStationsSuccess(json));
  };
}
export function fetchFilteredStations(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_FILTERED_STATIONS_SUCCESS));
    const { status, json } = await getRequest(`/stations/filtered?${queryStr}`);
    dispatch(requestEnd(FETCH_FILTERED_STATIONS_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchFilteredStationsSuccess(json))
      : dispatch(actionCreators.stationRequestFailure(status));
  };
}
export const createStation = (station: IStation) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest(`/stations`, station);
    switch (status) {
      case 200:
        dispatch(saveSuccess(station.name));
        return dispatch(actionCreators.createStationSuccess(json));
      case 400:
        dispatch(saveFailed(station.name));
        return dispatch(actionCreators.stationRequestErrors(json));
      default:
        dispatch(saveFailed(station.name));
        return dispatch(actionCreators.stationRequestFailure(status));
    }
  };
};
export const editStation = (id: number, station: IStation) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(`/stations/${id}`, station);
    switch (status) {
      case 200:
        dispatch(saveSuccess(station.name));
        return dispatch(actionCreators.editStationSuccess(json));
      case 400:
        dispatch(saveFailed(station.name));
        return dispatch(actionCreators.stationRequestErrors(json));
      default:
        dispatch(saveFailed(station.name));
        return dispatch(actionCreators.stationRequestFailure(status));
    }
  };
};
export const editStationOrder = (stations: IStation[]) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(`/stations/order`, stations);
    switch (status) {
      case 200:
        dispatch(saveSuccess("Station Order"));
        return dispatch(actionCreators.editStationOrderSuccess(json));
      case 400:
        dispatch(saveFailed("Station Order"));
        return dispatch(actionCreators.stationRequestErrors(json));
      default:
        dispatch(saveFailed("Station Order"));
        return dispatch(actionCreators.stationRequestFailure(status));
    }
  };
};
export const deleteStation = (id: number, station: IStation) => {
  return async (dispatch: Dispatch) => {
    const { status } = await deleteRequest(`/stations/${id}`, station);
    if (status === 200) {
      dispatch(deleteSuccess(station.name));
      return dispatch(actionCreators.deleteStationSuccess());
    }
    dispatch(saveFailed(station.name));
    return dispatch(actionCreators.stationRequestFailure(status));
  };
};
