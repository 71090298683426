import {
  GroupActionTypes,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUP_SUCCESS,
  CREATE_GROUP_SUCCESS,
  EDIT_GROUP_SUCCESS,
  DELETE_GROUP_SUCCESS,
  GROUP_REQUEST_FAILURE,
  GROUP_REQUEST_ERRORS
} from '../actions/groups';
import { IGroup } from '../../models/group';

export type GroupState = {
  readonly groups: IGroup[];
  readonly group: IGroup | null;
  errors: any;
};

export const initialState: GroupState = {
  groups: [],
  group: null,
  errors: null,
};

export default function groupsReducer(
  state = initialState,
  action: GroupActionTypes
): GroupState {
  switch (action.type) {
    case FETCH_GROUPS_SUCCESS:
      return { ...state, groups: action.groups };
    case FETCH_GROUP_SUCCESS:
      return { ...state, group: action.group };
    case CREATE_GROUP_SUCCESS:
      return {
        ...state,
        group: action.group,
        groups:
          state.groups.length > 0
            ? [...state.groups.concat([action.group])]
            : [],
      };
    case EDIT_GROUP_SUCCESS:
      if (state.groups.length) {
        state.groups.splice(
          state.groups.findIndex((t) => t.id === action.group.id),
          1,
          action.group
        );
      }
      return {
        ...state,
        group: action.group,
        groups: state.groups.length > 0 ? state.groups : [],
      };
    case DELETE_GROUP_SUCCESS:
      return { ...state, group: null, groups: [] };
    case GROUP_REQUEST_FAILURE:
      return { ...state, errors: action.errors };
    case GROUP_REQUEST_ERRORS:
      return { ...state, errors: action.errors };
    default:
      return state;
  }
}
