import {
  User as OidcUser,
  UserManager,
  UserManagerSettings,
  WebStorageStateStore,
} from "oidc-client-ts";
import { Dispatch } from "redux";
import { getRequest } from "./request";
import { IUser } from "../../models/user";

export const AUTH_OIDC_REQUEST = "AUTH_OIDC_REQUEST";
export const AUTH_OIDC_SUCCESS = "AUTH_OIDC_SUCCESS";
export const AUTH_OIDC_FAILURE = "AUTH_OIDC_FAILURE";
export const AUTH_OIDC_LOGOUT = "AUTH_OIDC_LOGOUT";
export const AUTH_USER_UPDATE = "AUTH_USER_UPDATE";

export type AuthActions = {
  AUTH_OIDC_REQUEST: {
    type: typeof AUTH_OIDC_REQUEST;
  };
  AUTH_OIDC_SUCCESS: {
    type: typeof AUTH_OIDC_SUCCESS;
    oidcUser: OidcUser;
    user: IUser;
  };
  AUTH_OIDC_FAILURE: {
    type: typeof AUTH_OIDC_FAILURE;
    error: object;
  };
  AUTH_OIDC_LOGOUT: {
    type: typeof AUTH_OIDC_LOGOUT;
  };
  AUTH_USER_UPDATE: {
    type: typeof AUTH_USER_UPDATE;
    user: IUser;
  };
};

export type AuthActionTypes =
  | AuthActions[typeof AUTH_OIDC_REQUEST]
  | AuthActions[typeof AUTH_OIDC_SUCCESS]
  | AuthActions[typeof AUTH_OIDC_FAILURE]
  | AuthActions[typeof AUTH_OIDC_LOGOUT]
  | AuthActions[typeof AUTH_USER_UPDATE];

export const actionCreators = {
  authRequest: (): AuthActions[typeof AUTH_OIDC_REQUEST] => ({
    type: AUTH_OIDC_REQUEST,
  }),
  authSuccess: (
    oidcUser: OidcUser,
    user: IUser
  ): AuthActions[typeof AUTH_OIDC_SUCCESS] => ({
    type: AUTH_OIDC_SUCCESS,
    oidcUser: oidcUser,
    user: user,
  }),
  authFailure: (error: Error): AuthActions[typeof AUTH_OIDC_FAILURE] => ({
    type: AUTH_OIDC_FAILURE,
    error: error,
  }),
  authLogout: (): AuthActions[typeof AUTH_OIDC_LOGOUT] => {
    return {
      type: AUTH_OIDC_LOGOUT,
    };
  },
  authUpdate: (user: IUser): AuthActions[typeof AUTH_USER_UPDATE] => ({
    type: AUTH_USER_UPDATE,
    user: user,
  }),
};

export const getClientSettings = (): UserManagerSettings => ({
  authority: process.env.REACT_APP_AUTH ?? "",
  client_id: "orkel-pi-app",
  redirect_uri: process.env.REACT_APP_APP ?? "",
  post_logout_redirect_uri: process.env.REACT_APP_APP,
  response_type: "code",
  scope: "openid profile email orkel-pi-api roles",
  filterProtocolClaims: true, // Filter out unecessary properties
  loadUserInfo: true, // Get all claims after token is verified
  automaticSilentRenew: true,
  silent_redirect_uri: process.env.REACT_APP_APP + "/silent-refresh",
  userStore: new WebStorageStateStore({ store: localStorage }),
  revokeTokensOnSignout: true,
  prompt: "login",
});

export const userManager = new UserManager(getClientSettings());

export const validateAuth = () => {
  return async (dispatch: Dispatch) => {
    dispatch(actionCreators.authRequest());
    return userManager.getUser().then(async (oidcUser) => {
      if (oidcUser) {
        const user = await getCurrentUser();
        if (user === null) {
          return dispatch(
            actionCreators.authFailure(new Error("Failed to fetch user"))
          );
        }
        return dispatch(actionCreators.authSuccess(oidcUser, user));
      }
      return dispatch(actionCreators.authLogout());
    });
  };
};

export const startAuthentication = () => {
  return async (dispatch: Dispatch) => {
    await userManager.clearStaleState();
    await userManager.removeUser();

    userManager.signinRedirect().then((args) => {
      return dispatch(actionCreators.authRequest());
    });
  };
};

export const completeAuthentication = () => {
  return async (dispatch: Dispatch) => {
    dispatch(actionCreators.authRequest());
    return userManager.getUser().then(async (oidcUser) => {
      if (!oidcUser) {
        oidcUser = await userManager.signinRedirectCallback();
      }
      if (oidcUser) {
        const user = await getCurrentUser();
        if (user == null) {
          return dispatch(
            actionCreators.authFailure(new Error("Failed to fetch user"))
          );
        }
        return dispatch(actionCreators.authSuccess(oidcUser, user));
      }
      return dispatch(actionCreators.authLogout());
    });
  };
};

export const logoutOidc = () => {
  return async (dispatch: Dispatch) => {
    userManager.signoutRedirect().then((user) => {
      userManager.removeUser();
      return dispatch(actionCreators.authLogout());
    });
  };
};

export const updateUser = () => {
  return async (dispatch: Dispatch) => {
    const user = await getCurrentUser();
    if (user === null) {
      return dispatch(
        actionCreators.authFailure(new Error("Failed to fetch user"))
      );
    }
    return dispatch(actionCreators.authUpdate(user));
  };
};

export const renewToken = (): Promise<OidcUser | null> => {
  return userManager.signinSilent();
};

export const getCurrentUser = async () => {
  const status = await getRequest("/users/current");
  if (status.status !== 200) return null;
  return status.json as IUser;
};
