import {
  faAngleLeft,
  faAngleRight,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Image, Modal } from "react-bootstrap";
import { IProductImage } from "../../models/image";
import { getRequest } from "../../store/actions/request";

export interface IProps {
  image: IProductImage;
  images: IProductImage[];
  show: boolean;
  navigateImages: boolean;
  setStructureImage: (image: IProductImage) => any;
  onHide: () => any;
}

const ProductStructureImage: React.FC<IProps> = (props) => {
  const {
    image,
    images,
    show,
    navigateImages,
    setStructureImage,
    onHide,
  } = props;

  const [imageContent, setImageContent] = useState<string[]>();

  useEffect(() => {
    let mounted = false;
    if (image.id && show) {
      getRequest(`/productsimages/${image.id}`).then((response) => {
        if (response.json && response.status !== 500) {
          if (!mounted) setImageContent(JSON.parse(response.json));
        } else {
          if (!mounted) setImageContent(undefined);
        }
      });
    }
    return () => {
      mounted = true;
    };
  }, [image, show]);

  const imageIndex = images.findIndex((img) => img.id === image?.id);

  return (
    <div>
      <div>
        <Modal show={show} onHide={onHide}>
          <Modal.Header closeButton>
            <span className="text-truncate" style={{ maxWidth: "500px" }}>
              Product Structure Image: {image.productNo} - Rev {image.revision}
            </span>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            {imageContent ? (
              <div id="print-images">
                {imageContent.map((content, index) => (
                  <div key={"image-" + index}>
                    <Image
                      src={content}
                      className="img-fluid bg-white images"
                    />
                  </div>
                ))}
              </div>
            ) : (
              <FontAwesomeIcon icon={faFile} size="2x" className="m-2" />
            )}
          </Modal.Body>
          {images.length > 1 && navigateImages && (
            <Modal.Footer
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="text-center">
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  size="2x"
                  className="mx-3"
                  onClick={() =>
                    imageIndex > 0
                      ? setStructureImage(images[imageIndex - 1])
                      : null
                  }
                  style={
                    imageIndex > 0 ? { cursor: "pointer" } : { opacity: 0.5 }
                  }
                />
                <FontAwesomeIcon
                  icon={faAngleRight}
                  size="2x"
                  className="mx-3"
                  onClick={() =>
                    imageIndex !== images.length - 1
                      ? setStructureImage(images[imageIndex + 1])
                      : null
                  }
                  style={
                    imageIndex !== images.length - 1
                      ? { cursor: "pointer" }
                      : { opacity: 0.5 }
                  }
                />
                <p className="text-muted m-0 p-0">Navigate revisions</p>
              </div>
            </Modal.Footer>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default ProductStructureImage;
