import { getRequest, putRequest } from "./request";
import { IProduct, IProductUser } from "../../models/product";
import { saveFailed, saveSuccess } from "./alerts";
import { Dispatch } from "redux";
import { DataList } from "utils/DataList";

export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_FILTEREDPRODUCTS_SUCCESS = "FETCH_FILTEREDPRODUCTS_SUCCESS";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_BY_NO_SUCCESS = "FETCH_PRODUCT_BY_NO_SUCCESS";
export const FETCH_PRODUCT_STRUCTURES = "FETCH_PRODUCT_STRUCTURES";
export const FETCH_PRODUCT_USERS = "FETCH_PRODUCT_USERS";
export const FETCH_NEW_ORDER_PRODUCTS_SUCCESS =
  "FETCH_NEW_ORDER_PRODUCTS_SUCCESS";
export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCT_SUCCESS";
export const PRODUCT_REQUEST_ERRORS = "PRODUCT_REQUEST_ERRORS";
export const PRODUCT_REQUEST_FAILURE = "PRODUCT_REQUEST_FAILURE";

export type ProductActions = {
  FETCH_PRODUCTS_SUCCESS: {
    type: typeof FETCH_PRODUCTS_SUCCESS;
    products: IProduct[];
  };
  FETCH_FILTEREDPRODUCTS_SUCCESS: {
    type: typeof FETCH_FILTEREDPRODUCTS_SUCCESS;
    products: DataList<IProduct>;
  };
  FETCH_PRODUCT_SUCCESS: {
    type: typeof FETCH_PRODUCT_SUCCESS;
    product: IProduct;
  };
  FETCH_PRODUCT_BY_NO_SUCCESS: {
    type: typeof FETCH_PRODUCT_BY_NO_SUCCESS;
    product: IProduct;
  };
  FETCH_PRODUCT_STRUCTURES: {
    type: typeof FETCH_PRODUCT_STRUCTURES;
    product: IProduct;
  };
  FETCH_PRODUCT_USERS: {
    type: typeof FETCH_PRODUCT_USERS;
    productUsers: IProductUser[];
  };
  FETCH_NEW_ORDER_PRODUCTS_SUCCESS: {
    type: typeof FETCH_NEW_ORDER_PRODUCTS_SUCCESS;
    products: IProduct[];
  };
  EDIT_PRODUCT_SUCCESS: {
    type: typeof EDIT_PRODUCT_SUCCESS;
    product: IProduct;
  };
  PRODUCT_REQUEST_ERRORS: {
    type: typeof PRODUCT_REQUEST_ERRORS;
    errors: any;
  };
  PRODUCT_REQUEST_FAILURE: {
    type: typeof PRODUCT_REQUEST_FAILURE;
    errors: any;
  };
};

export type ProductActionTypes =
  | ProductActions[typeof FETCH_PRODUCTS_SUCCESS]
  | ProductActions[typeof FETCH_FILTEREDPRODUCTS_SUCCESS]
  | ProductActions[typeof FETCH_PRODUCT_SUCCESS]
  | ProductActions[typeof FETCH_PRODUCT_BY_NO_SUCCESS]
  | ProductActions[typeof FETCH_PRODUCT_STRUCTURES]
  | ProductActions[typeof FETCH_PRODUCT_USERS]
  | ProductActions[typeof FETCH_NEW_ORDER_PRODUCTS_SUCCESS]
  | ProductActions[typeof EDIT_PRODUCT_SUCCESS]
  | ProductActions[typeof PRODUCT_REQUEST_ERRORS]
  | ProductActions[typeof PRODUCT_REQUEST_FAILURE];

export const actionCreators = {
  fetchProductsSuccess: (
    products: IProduct[]
  ): ProductActions[typeof FETCH_PRODUCTS_SUCCESS] => ({
    type: FETCH_PRODUCTS_SUCCESS,
    products: products,
  }),
  fetchProductSuccess: (
    product: IProduct
  ): ProductActions[typeof FETCH_PRODUCT_SUCCESS] => ({
    type: FETCH_PRODUCT_SUCCESS,
    product: product,
  }),
  fetchProductByNoSuccess: (
    product: IProduct
  ): ProductActions[typeof FETCH_PRODUCT_BY_NO_SUCCESS] => ({
    type: FETCH_PRODUCT_BY_NO_SUCCESS,
    product: product,
  }),
  fetchNewOrderProductsSuccess: (
    products: IProduct[]
  ): ProductActions[typeof FETCH_NEW_ORDER_PRODUCTS_SUCCESS] => ({
    type: FETCH_NEW_ORDER_PRODUCTS_SUCCESS,
    products: products,
  }),
  fetchFilteredProductsSuccess: (
    products: DataList<IProduct>
  ): ProductActions[typeof FETCH_FILTEREDPRODUCTS_SUCCESS] => ({
    type: FETCH_FILTEREDPRODUCTS_SUCCESS,
    products: products,
  }),
  fetchProductStructuresSuccess: (
    product: IProduct
  ): ProductActions[typeof FETCH_PRODUCT_STRUCTURES] => ({
    type: FETCH_PRODUCT_STRUCTURES,
    product: product,
  }),
  fetchProductUsersSuccess: (
    productUsers: IProductUser[]
  ): ProductActions[typeof FETCH_PRODUCT_USERS] => ({
    type: FETCH_PRODUCT_USERS,
    productUsers: productUsers,
  }),
  editProductSuccess: (
    product: IProduct
  ): ProductActions[typeof EDIT_PRODUCT_SUCCESS] => ({
    type: EDIT_PRODUCT_SUCCESS,
    product: product,
  }),
  productRequestErrors: (
    errors: any
  ): ProductActions[typeof PRODUCT_REQUEST_ERRORS] => ({
    type: PRODUCT_REQUEST_ERRORS,
    errors: errors,
  }),
  productRequestFailure: (
    status: number
  ): ProductActions[typeof PRODUCT_REQUEST_FAILURE] => ({
    type: PRODUCT_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
};

export function fetchProducts() {
  return async (dispatch) => {
    const { json } = await getRequest("/products");
    return dispatch(actionCreators.fetchProductsSuccess(json));
  };
}

export const fetchProduct = (id: string) => {
  return async (dispatch) => {
    const { status, json } = await getRequest(`/products/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchProductSuccess(json))
      : dispatch(actionCreators.productRequestFailure(status));
  };
};

export const fetchProductStructures = (id: string) => {
  return async (dispatch) => {
    const { status, json } = await getRequest(`/products/structures/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchProductStructuresSuccess(json))
      : dispatch(actionCreators.productRequestFailure(status));
  };
};

export const fetchProductUsers = (id: string) => {
  return async (dispatch) => {
    const { status, json } = await getRequest(`/products/users/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchProductUsersSuccess(json))
      : dispatch(actionCreators.productRequestFailure(status));
  };
};

export const fetchProductByNo = (productNo: string) => {
  return async (dispatch) => {
    const { status, json } = await getRequest(
      `/products/productNo/${productNo}`
    );
    return status === 200
      ? dispatch(actionCreators.fetchProductSuccess(json))
      : dispatch(actionCreators.productRequestFailure(status));
  };
};

export const fetchNewOrderProducts = (orderNo: number) => {
  return async (dispatch) => {
    const { status, json } = await getRequest(`/orders/newProducts/${orderNo}`);
    return status === 200
      ? dispatch(actionCreators.fetchNewOrderProductsSuccess(json))
      : dispatch(actionCreators.productRequestFailure(status));
  };
};

export function fetchFilteredProducts(queryStr: string) {
  return async (dispatch) => {
    const { json } = await getRequest(`/products/filtered?${queryStr}`);
    return dispatch(actionCreators.fetchFilteredProductsSuccess(json));
  };
}

export function fetchFilteredDbProducts(queryStr: string) {
  return async (dispatch) => {
    const { json } = await getRequest(`/products/filteredDb?${queryStr}`);
    return dispatch(actionCreators.fetchFilteredProductsSuccess(json));
  };
}

export const editProduct = (id: string, product: IProduct) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(`/products/${id}`, product);
    switch (status) {
      case 200:
        dispatch(saveSuccess(product.productNo));
        return dispatch(actionCreators.editProductSuccess(json));
      case 400:
        dispatch(saveFailed(product.productNo));
        return dispatch(actionCreators.productRequestErrors(json));
      default:
        dispatch(saveFailed(product.productNo));
        return dispatch(actionCreators.productRequestFailure(status));
    }
  };
};
