import React, {
  useState,
  useEffect,
  ReactNode,
  useCallback,
  useRef,
} from "react";
import metaJson from "../../meta.json";
import UpdateDialog from "./UpdateDialog";

const buildDateGreaterThan = (latestDate: number, currentDate: number) => {
  const momLatestDateTime = new Date(latestDate);
  const momCurrentDateTime = new Date(currentDate);

  return momLatestDateTime > momCurrentDateTime;
};

export interface IProps {
  children?: ReactNode;
}

const CacheClearer = (props: IProps) => {
  const [isLatestBuildDate, setIsLatestBuildDate] = useState(true);
  const versionTimer = useRef<NodeJS.Timeout>();

  const checkVersion = useCallback((refresh: boolean) => {
    fetch("/meta.json?t=" + Date.now())
      .then((response) => response.json())
      .then((meta) => {
        const latestVersionDate = meta.buildDate;
        const currentVersionDate = metaJson.buildDate;

        const shouldUpdate = buildDateGreaterThan(
          latestVersionDate,
          currentVersionDate
        );

        if (shouldUpdate) {
          setIsLatestBuildDate(false);
          if (refresh) refreshCacheAndReload();
        } else {
          setIsLatestBuildDate(true);
        }
      })
      .catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    checkVersion(true);

    versionTimer.current = setInterval(() => {
      console.log("Checking update...");
      checkVersion(false);
    }, 600000);
  }, [checkVersion]);

  const refreshCacheAndReload = () => {
    console.log("Clearing Cache and refreshing...");
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }

        window.location.reload();
      });
    } else {
      // Reload Site
      window.location.reload();
    }
  };

  return (
    <React.Fragment>
      <UpdateDialog
        show={!isLatestBuildDate}
        onUpdateClick={refreshCacheAndReload}
      />
      {props.children}
    </React.Fragment>
  );
};

export default CacheClearer;
