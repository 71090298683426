import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  requestBegin,
  requestEnd,
} from "../request";
import {
  IQcChassi,
  IQcChassiSpecDiff,
  IQcChassisProgress,
} from "../../../models/QualityControl/qcChassis";
import { Dispatch } from "redux";
import { DataResult } from "@progress/kendo-data-query";
import { deleteSuccess, saveFailed, saveSuccess } from "../alerts";
import { IAuditEntry } from "models/audit";
import { IQcComponent } from "models/QualityControl/qcComponent";

export const FETCH_QC_CHASSIS_SUCCESS = "FETCH_QC_CHASSIS_SUCCESS";
export const FETCH_QC_CHASSI_SUCCESS = "FETCH_QC_CHASSI_SUCCESS";
export const FETCH_CHASSI_COMPONENTS_SUCCESS =
  "FETCH_CHASSI_COMPONENTS_SUCCESS";
export const FETCH_FILTERED_QC_CHASSIS_SUCCESS =
  "FETCH_FILTERED_QC_CHASSIS_SUCCESS";
export const FETCH_FILTERED_CHECKLISTPROGRESS_SUCCESS =
  "FETCH_FILTERED_CHECKLISTPROGRESS_SUCCESS";
export const FETCH_FILTERED_BASIC_CHASSIS_SUCCESS =
  "FETCH_FILTERED_BASIC_CHASSIS_SUCCESS";
export const CREATE_QC_CHASSI_SUCCESS = "CREATE_QC_CHASSI_SUCCESS";
export const EDIT_QC_CHASSI_SUCCESS = "EDIT_QC_CHASSI_SUCCESS";
export const DELETE_QC_CHASSI_SUCCESS = "DELETE_QC_CHASSI_SUCCESS";
export const QC_CHASSI_REQUEST_FAILURE = "QC_CHASSI_REQUEST_FAILURE";
export const QC_CHASSI_REQUEST_ERROR = "QC_CHASSI_REQUEST_ERROR";
export const FETCH_QC_CHASSI_AUDIT_SUCCESS = "FETCH_QC_CHASSI_AUDIT_SUCCESS";
export const FETCH_QC_CHASSI_SPECDIFF_SUCCESS =
  "FETCH_QC_CHASSI_SPECDIFF_SUCCESS";
export const EDIT_QC_CHASSIS_ORDER_SUCCESS = "EDIT_QC_CHASSIS_ORDER_SUCCESS";

export interface QcChassisActions {
  FETCH_QC_CHASSIS_SUCCESS: {
    type: typeof FETCH_QC_CHASSIS_SUCCESS;
    qcChassis: IQcChassi[];
  };
  FETCH_CHASSI_COMPONENTS_SUCCESS: {
    type: typeof FETCH_CHASSI_COMPONENTS_SUCCESS;
    qcComponents: IQcComponent[];
  };
  FETCH_QC_CHASSI_AUDIT_SUCCESS: {
    type: typeof FETCH_QC_CHASSI_AUDIT_SUCCESS;
    audit: IAuditEntry[];
  };
  FETCH_QC_CHASSI_SPECDIFF_SUCCESS: {
    type: typeof FETCH_QC_CHASSI_SPECDIFF_SUCCESS;
    specDiff: IQcChassiSpecDiff[];
  };
  FETCH_QC_CHASSI_SUCCESS: {
    type: typeof FETCH_QC_CHASSI_SUCCESS;
    qcChassi: IQcChassi;
  };
  FETCH_FILTERED_QC_CHASSIS_SUCCESS: {
    type: typeof FETCH_FILTERED_QC_CHASSIS_SUCCESS;
    qcChassis: DataResult;
  };
  FETCH_FILTERED_BASIC_CHASSIS_SUCCESS: {
    type: typeof FETCH_FILTERED_BASIC_CHASSIS_SUCCESS;
    qcChassis: DataResult;
  };
  FETCH_FILTERED_CHECKLISTPROGRESS_SUCCESS: {
    type: typeof FETCH_FILTERED_CHECKLISTPROGRESS_SUCCESS;
    qcChassizProgress: IQcChassisProgress;
  };
  EDIT_QC_CHASSIS_ORDER_SUCCESS: {
    type: typeof EDIT_QC_CHASSIS_ORDER_SUCCESS;
    qcChassis: IQcChassi[];
  };
  CREATE_QC_CHASSI_SUCCESS: {
    type: typeof CREATE_QC_CHASSI_SUCCESS;
    qcChassi: IQcChassi;
  };
  EDIT_QC_CHASSI_SUCCESS: {
    type: typeof EDIT_QC_CHASSI_SUCCESS;
    qcChassi: IQcChassi;
  };
  DELETE_QC_CHASSI_SUCCESS: {
    type: typeof DELETE_QC_CHASSI_SUCCESS;
  };
  QC_CHASSI_REQUEST_FAILURE: {
    type: typeof QC_CHASSI_REQUEST_FAILURE;
    errors: any;
  };
  QC_CHASSI_REQUEST_ERROR: {
    type: typeof QC_CHASSI_REQUEST_ERROR;
    errors: any;
  };
}

export type QcChassisActionTypes =
  | QcChassisActions[typeof FETCH_QC_CHASSIS_SUCCESS]
  | QcChassisActions[typeof FETCH_CHASSI_COMPONENTS_SUCCESS]
  | QcChassisActions[typeof FETCH_QC_CHASSI_SUCCESS]
  | QcChassisActions[typeof FETCH_QC_CHASSI_AUDIT_SUCCESS]
  | QcChassisActions[typeof FETCH_QC_CHASSI_SPECDIFF_SUCCESS]
  | QcChassisActions[typeof FETCH_FILTERED_QC_CHASSIS_SUCCESS]
  | QcChassisActions[typeof FETCH_FILTERED_BASIC_CHASSIS_SUCCESS]
  | QcChassisActions[typeof FETCH_FILTERED_CHECKLISTPROGRESS_SUCCESS]
  | QcChassisActions[typeof EDIT_QC_CHASSIS_ORDER_SUCCESS]
  | QcChassisActions[typeof CREATE_QC_CHASSI_SUCCESS]
  | QcChassisActions[typeof EDIT_QC_CHASSI_SUCCESS]
  | QcChassisActions[typeof DELETE_QC_CHASSI_SUCCESS]
  | QcChassisActions[typeof QC_CHASSI_REQUEST_FAILURE]
  | QcChassisActions[typeof QC_CHASSI_REQUEST_ERROR];

export const actionCreators = {
  fetchQcChassisSuccess: (
    qcChassis: IQcChassi[]
  ): QcChassisActions[typeof FETCH_QC_CHASSIS_SUCCESS] => ({
    type: FETCH_QC_CHASSIS_SUCCESS,
    qcChassis: qcChassis,
  }),
  fetchChassiComponentsSuccess: (
    qcComponents: IQcComponent[]
  ): QcChassisActions[typeof FETCH_CHASSI_COMPONENTS_SUCCESS] => ({
    type: FETCH_CHASSI_COMPONENTS_SUCCESS,
    qcComponents: qcComponents,
  }),
  fetchQcChassiSuccess: (
    qcChassi: IQcChassi
  ): QcChassisActions[typeof FETCH_QC_CHASSI_SUCCESS] => ({
    type: FETCH_QC_CHASSI_SUCCESS,
    qcChassi: qcChassi,
  }),
  fetchQcChassiAuditSuccess: (
    audit: IAuditEntry[]
  ): QcChassisActions[typeof FETCH_QC_CHASSI_AUDIT_SUCCESS] => ({
    type: FETCH_QC_CHASSI_AUDIT_SUCCESS,
    audit: audit,
  }),
  fetchQcChassiSpecDiffSuccess: (
    specDiff: IQcChassiSpecDiff[]
  ): QcChassisActions[typeof FETCH_QC_CHASSI_SPECDIFF_SUCCESS] => ({
    type: FETCH_QC_CHASSI_SPECDIFF_SUCCESS,
    specDiff: specDiff,
  }),
  fetchFilteredQcChassisSuccess: (
    qcChassis: DataResult
  ): QcChassisActions[typeof FETCH_FILTERED_QC_CHASSIS_SUCCESS] => ({
    type: FETCH_FILTERED_QC_CHASSIS_SUCCESS,
    qcChassis: qcChassis,
  }),
  fetchFilteredBasicChassisSuccess: (
    qcChassis: DataResult
  ): QcChassisActions[typeof FETCH_FILTERED_BASIC_CHASSIS_SUCCESS] => ({
    type: FETCH_FILTERED_BASIC_CHASSIS_SUCCESS,
    qcChassis: qcChassis,
  }),
  fetchFilteredChecklistProgressSuccess: (
    qcChassizProgress: IQcChassisProgress
  ): QcChassisActions[typeof FETCH_FILTERED_CHECKLISTPROGRESS_SUCCESS] => ({
    type: FETCH_FILTERED_CHECKLISTPROGRESS_SUCCESS,
    qcChassizProgress: qcChassizProgress,
  }),
  createQcChassiSuccess: (
    qcChassi: IQcChassi
  ): QcChassisActions[typeof CREATE_QC_CHASSI_SUCCESS] => ({
    type: CREATE_QC_CHASSI_SUCCESS,
    qcChassi: qcChassi,
  }),
  editQcChassiSuccess: (
    qcChassi: IQcChassi
  ): QcChassisActions[typeof EDIT_QC_CHASSI_SUCCESS] => ({
    type: EDIT_QC_CHASSI_SUCCESS,
    qcChassi: qcChassi,
  }),
  editQcChassisOrderSuccess: (
    qcChassis: IQcChassi[]
  ): QcChassisActions[typeof EDIT_QC_CHASSIS_ORDER_SUCCESS] => ({
    type: EDIT_QC_CHASSIS_ORDER_SUCCESS,
    qcChassis: qcChassis,
  }),
  deleteQcChassiSuccess: (): QcChassisActions[typeof DELETE_QC_CHASSI_SUCCESS] => ({
    type: DELETE_QC_CHASSI_SUCCESS,
  }),
  qcChassiRequestFailure: (
    status: number
  ): QcChassisActions[typeof QC_CHASSI_REQUEST_FAILURE] => ({
    type: QC_CHASSI_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
  qcChassiRequestError: (
    errors: any
  ): QcChassisActions[typeof QC_CHASSI_REQUEST_ERROR] => ({
    type: QC_CHASSI_REQUEST_ERROR,
    errors: errors,
  }),
};

export function fetchQcChassis() {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_QC_CHASSIS_SUCCESS));
    const { status, json } = await getRequest("/qcChassiz");
    dispatch(requestEnd(FETCH_QC_CHASSIS_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchQcChassisSuccess(json))
      : dispatch(actionCreators.qcChassiRequestFailure(status));
  };
}

export function fetchChassiComponents(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_CHASSI_COMPONENTS_SUCCESS));
    const { status, json } = await getRequest(`/qcChassiz/components/${id}`);
    dispatch(requestEnd(FETCH_CHASSI_COMPONENTS_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchChassiComponentsSuccess(json))
      : dispatch(actionCreators.qcChassiRequestFailure(status));
  };
}

export const fetchQcChassi = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/qcChassiz/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchQcChassiSuccess(json))
      : dispatch(actionCreators.qcChassiRequestFailure(status));
  };
};

export const fetchQcChassiAudit = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/qcChassiz/${id}/audit`);
    return status === 200
      ? dispatch(actionCreators.fetchQcChassiAuditSuccess(json))
      : dispatch(actionCreators.qcChassiRequestFailure(status));
  };
};

export const fetchQcChassiSpecDiff = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/qcChassiz/${id}/specDiff`);
    return status === 200
      ? dispatch(actionCreators.fetchQcChassiSpecDiffSuccess(json))
      : dispatch(actionCreators.qcChassiRequestFailure(status));
  };
};

export function fetchFilteredQcChassis(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_FILTERED_QC_CHASSIS_SUCCESS));
    const { status, json } = await getRequest(
      `/qcChassiz/filtered?${queryStr}`
    );
    dispatch(requestEnd(FETCH_FILTERED_QC_CHASSIS_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchFilteredQcChassisSuccess(json))
      : dispatch(actionCreators.qcChassiRequestFailure(status));
  };
}

export function fetchFilteredBasicChassis(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_FILTERED_BASIC_CHASSIS_SUCCESS));
    const { status, json } = await getRequest(`/qcChassiz/basic?${queryStr}`);
    dispatch(requestEnd(FETCH_FILTERED_BASIC_CHASSIS_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchFilteredBasicChassisSuccess(json))
      : dispatch(actionCreators.qcChassiRequestFailure(status));
  };
}

export function fetchFilteredChecklistProgress(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_FILTERED_CHECKLISTPROGRESS_SUCCESS));
    const { status, json } = await getRequest(
      `/qcChassiz/progress?${queryStr}`
    );
    dispatch(requestEnd(FETCH_FILTERED_CHECKLISTPROGRESS_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchFilteredChecklistProgressSuccess(json))
      : dispatch(actionCreators.qcChassiRequestFailure(status));
  };
}

export const createQcChassi = (qcChassi: IQcChassi) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest("/qcChassiz", qcChassi);
    switch (status) {
      case 200:
        dispatch(saveSuccess(qcChassi.no));
        return dispatch(actionCreators.createQcChassiSuccess(json));
      case 400:
        dispatch(saveFailed(qcChassi.no + ", " + json.Error));
        return dispatch(actionCreators.qcChassiRequestError(json));
      default:
        dispatch(saveFailed(qcChassi.no + ", " + json.Error));
        return dispatch(actionCreators.qcChassiRequestFailure(status));
    }
  };
};

export const editQcChassi = (id: number, qcChassi: IQcChassi) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(`/qcChassiz/${id}`, qcChassi);
    switch (status) {
      case 200:
        dispatch(saveSuccess(qcChassi.no));
        return dispatch(actionCreators.editQcChassiSuccess(json));
      case 400:
        dispatch(saveFailed(qcChassi.no + ", " + json.Error));
        return dispatch(actionCreators.qcChassiRequestError(json));
      default:
        dispatch(saveFailed(qcChassi.no + ", " + json.Error));
        return dispatch(actionCreators.qcChassiRequestFailure(status));
    }
  };
};

export const deleteQcChassi = (id: number, qcChassi: IQcChassi) => {
  return async (dispatch: Dispatch) => {
    const { status } = await deleteRequest(`/qcChassiz/${id}`, qcChassi);
    if (status === 200) {
      dispatch(deleteSuccess(qcChassi.no));
      return dispatch(actionCreators.deleteQcChassiSuccess());
    }
    dispatch(saveFailed(qcChassi.no));
    return dispatch(actionCreators.qcChassiRequestFailure(status));
  };
};

export const editQcChassisOrder = (qcChassis: IQcChassi[]) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(`/qcChassiz/order`, qcChassis);
    switch (status) {
      case 200:
        dispatch(saveSuccess("Chassis Order"));
        return dispatch(actionCreators.editQcChassisOrderSuccess(json));
      case 400:
        dispatch(saveFailed("Chassis Order"));
        return dispatch(actionCreators.qcChassiRequestError(json));
      default:
        dispatch(saveFailed("Chassis Order"));
        return dispatch(actionCreators.qcChassiRequestFailure(status));
    }
  };
};
