import React, { useCallback, useEffect, useState } from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect, ConnectedProps } from "react-redux";
import { Button, Col, Modal } from "react-bootstrap";
import { useHistory, useRouteMatch } from "react-router";

import Aux from "utils/auxiliary";
import OrderImagesForm from "components/Orders/OrderImagesForm";
import { IRootState } from "store";
import { fetchOrder, sendOrdersNotification } from "store/actions/orders";
import { getMediaRequest } from "store/actions/request";
import { IOrder } from "../../models/order";
import { isKitter, isPurchaser, isSupplier } from "models/user";
import { IProduct } from "models/product";
import { fetchNewOrderProducts } from "store/actions/products";

export interface MatchParams {
  id: string;
}
export interface IProps {
  orderNo: number;
}

const mapState = (state: IRootState) => ({
  currentUser: state.authReducer.user,
});

const mapDispatch = (dispatch: any) => ({
  fetchOrder: (orderNo: number, images: boolean) =>
    dispatch(fetchOrder(orderNo, images)),
  sendOrdersNotification: (orderNo: number) =>
    dispatch(sendOrdersNotification(orderNo)),
  fetchNewOrderProducts: (orderNo: number) =>
    dispatch(fetchNewOrderProducts(orderNo)),
});

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & IProps;

export const OrderImagesFormContainer: React.FC<Props> = (props) => {
  const {
    orderNo,
    currentUser,
    fetchOrder,
    sendOrdersNotification,
    fetchNewOrderProducts,
  } = props;

  const fetchOrderCallback = useCallback(fetchOrder, [fetchOrder]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filesLoading, setFilesLoading] = useState<boolean>(true);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [emailLoading, setEmailLoading] = useState<boolean>(false);
  const [emailConfirmation, setEmailConfirmation] = useState<boolean>(false);
  const [showNoImagesFound, setShowNoImagesFound] = useState<boolean>(false);
  const [order, setOrder] = useState<IOrder>({} as IOrder);
  const [newProducts, setNewProducts] = useState<IProduct[]>([]);
  const [newProdLoading, setNewProdLoading] = useState<boolean>(false);

  const match = useRouteMatch<MatchParams>();
  const history = useHistory();

  useEffect(() => {
    let mounted = true;
    let id;
    if (orderNo) {
      id = orderNo;
    } else if (match) {
      id = match.params.id;
    }
    fetchOrderCallback(id, false).then((result) => {
      if (result.errors) return;
      if (mounted) {
        if (
          ((isKitter(currentUser) || isPurchaser(currentUser)) &&
            !isSupplier(currentUser)) ||
          (isSupplier(currentUser) &&
            currentUser.supplierNo === result.order.supplierNumber)
        ) {
          setOrder(result.order);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    });
    return () => {
      mounted = false;
    };
  }, [orderNo, match, fetchOrderCallback]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loading) {
      fetchOrderCallback(order.orderNo, true).then((result) => {
        if (result.errors) return;
        setOrder(result.order);
        setFilesLoading(false);
      });
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showNoImagesFound) {
      setTimeout(() => setShowNoImagesFound(false), 1500);
    }
  }, [showNoImagesFound]);

  useEffect(() => {
    if (!loading && order && order.orderNo > 0) {
      if (
        (order.supplierNumber === 20396 &&
          order.allocatedOrderLines.length > 0) ||
        (order.supplierNumber !== 20396 && order.orderLines.length > 0)
      ) {
        setNewProdLoading(true);
        fetchNewOrderProducts(order.orderNo).then((result) => {
          if (result.errors) {
            setNewProdLoading(false);
            return;
          }
          setNewProducts(result.products);
          setNewProdLoading(false);
        });
      } else {
        setNewProdLoading(false);
      }
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (emailLoading) {
      setTimeout(() => {
        setEmailLoading(false);
      }, 1000);
    }
  }, [emailLoading]);

  const downloadZip = (orderNo: number, newFiles: boolean) => {
    setDownloadLoading(true);
    getMediaRequest(`/orders/imagezip/${orderNo}/${newFiles}`)
      .then((res) => {
        return res.blob();
      })
      .then((data) => {
        if (data.size > 0) {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = "Order-" + orderNo + " images.zip";
          a.click();
          setDownloadLoading(false);
        } else {
          setDownloadLoading(false);
          setShowNoImagesFound(true);
        }
      });
  };

  const sendEmail = () => {
    setEmailLoading(true);
    sendOrdersNotification(order.orderNo);
  };

  return (
    <Aux>
      {loading || order === undefined ? (
        <p className="text-center m-3">
          <FontAwesomeIcon icon={faSpinner} size="3x" spin />
        </p>
      ) : (
        <OrderImagesForm
          order={order}
          orderLines={
            order.supplierNumber === 20396
              ? order.allocatedOrderLines.filter((l) => l.product !== undefined)
              : order.orderLines.filter((l) => l.product !== undefined)
          }
          newProducts={newProducts}
          currentUser={currentUser}
          newProdLoading={newProdLoading}
          history={history}
          filesLoading={filesLoading}
          downloadZip={downloadZip}
          sendEmail={() => setEmailConfirmation(true)}
        />
      )}
      {(downloadLoading || emailLoading) && (
        <p className="text-center m-3">
          <FontAwesomeIcon
            icon={faSpinner}
            size="3x"
            spin
            style={{
              top: "50%",
              left: "50%",
              position: "fixed",
              zIndex: 999,
            }}
          />
        </p>
      )}
      <Modal
        show={showNoImagesFound}
        onHide={() => setShowNoImagesFound(false)}
        centered
      >
        <Modal.Body>
          <h5 className="px-4 py-2">No images found</h5>
        </Modal.Body>
      </Modal>
      <Modal
        show={emailConfirmation}
        onHide={() => setEmailConfirmation(false)}
        centered
      >
        <Modal.Body>
          <h5 className="py-3">
            Confirm sending Email to {order.supplierEmail}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Col>
            <Button
              variant="success"
              onClick={() => {
                sendEmail();
                setEmailConfirmation(false);
              }}
            >
              Confirm
            </Button>
          </Col>
          <Col>
            <Button
              variant="danger"
              className="float-end"
              onClick={() => {
                setEmailConfirmation(false);
              }}
            >
              Cancel
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    </Aux>
  );
};

export default connector(OrderImagesFormContainer);
