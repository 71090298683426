import {
  AuthActionTypes,
  AUTH_OIDC_REQUEST,
  AUTH_OIDC_SUCCESS,
  AUTH_OIDC_FAILURE,
  AUTH_OIDC_LOGOUT,
  AUTH_USER_UPDATE,
} from "../actions/auth";
import { IUser } from "../../models/user";

export type AuthState = {
  readonly user: IUser;
  readonly isAuthenticating: boolean;
  readonly isAuthenticated: boolean;
  readonly requestComplete: boolean;
  readonly authFailed: boolean;
  readonly error: object | null;
};

export const initialState: AuthState = {
  user: {} as IUser,
  isAuthenticating: false,
  isAuthenticated: false,
  requestComplete: false,
  authFailed: false,
  error: null,
};

export default function authReducer(
  state = initialState,
  action: AuthActionTypes
): AuthState {
  switch (action.type) {
    case AUTH_OIDC_REQUEST:
      return {
        ...state,
        isAuthenticating: true,
      };
    case AUTH_OIDC_SUCCESS:
      return {
        ...state,
        user: action.user,
        isAuthenticating: false,
        isAuthenticated: true,
        requestComplete: true,
        error: null,
        authFailed: false,
      };
    case AUTH_OIDC_FAILURE:
      return {
        ...state,
        user: {} as IUser,
        isAuthenticated: false,
        isAuthenticating: false,
        requestComplete: false,
        error: action.error,
        authFailed: true,
      };
    case AUTH_OIDC_LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        requestComplete: true,
        user: {} as IUser,
      };
    case AUTH_USER_UPDATE:
      return {
        ...state,
        user: action.user,
        error: null,
        authFailed: false,
      };
    default:
      return state;
  }
}
