import { IQcChassi } from "../../../models/QualityControl/qcChassis";
import { IQcSpec } from "../../../models/QualityControl/qcSpec";
import {
  QcMachineSpecActionTypes,
  FETCH_QC_MACHINESPEC_SUCCESS,
} from "../../actions/QualityControl/qcMachineSpec";

export interface QcMachineSpecState {
  readonly qcChassi: IQcChassi | null;
  readonly qcSpec: IQcSpec | null;
  readonly errors: any;
}

export const initialState: QcMachineSpecState = {
  qcChassi: null,
  qcSpec: null,
  errors: null,
};

const qcMachineSpecReducer = (
  state = initialState,
  action: QcMachineSpecActionTypes
): QcMachineSpecState => {
  switch (action.type) {
    case FETCH_QC_MACHINESPEC_SUCCESS:
      return {
        ...state,
        qcChassi: action.qcMachineSpec.qcChassis,
        qcSpec: action.qcMachineSpec.qcSpec,
      };
    default:
      return state;
  }
};

export default qcMachineSpecReducer;
