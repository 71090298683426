import {
  QcSpecActionTypes,
  FETCH_QC_SPECS_SUCCESS,
  FETCH_QC_SPEC_SUCCESS,
  FETCH_FILTERED_QC_SPECS_SUCCESS,
  CREATE_QC_SPEC_SUCCESS,
  EDIT_QC_SPEC_SUCCESS,
  DELETE_QC_SPEC_SUCCESS,
  FETCH_SEARCH_QC_SPECS_SUCCESS,
} from "../../actions/QualityControl/qcSpec";
import {
  IQcSpec,
  IQcSpecSearchResult,
} from "../../../models/QualityControl/qcSpec";
import { DataList } from "../../../utils/DataList";

export interface QcSpecState {
  readonly qcSpecs: IQcSpec[];
  readonly filteredQcSpecs: DataList<IQcSpec>;
  readonly qcSpec: IQcSpec | null;
  readonly searchResult: IQcSpecSearchResult | null;
  readonly errors: any;
}

export const initialState: QcSpecState = {
  qcSpecs: [],
  filteredQcSpecs: { data: [], total: 0 },
  qcSpec: null,
  searchResult: null,
  errors: null,
};

const qcSpecReducer = (
  state = initialState,
  action: QcSpecActionTypes
): QcSpecState => {
  switch (action.type) {
    case FETCH_QC_SPECS_SUCCESS:
      return { ...state, qcSpecs: action.qcSpecs };
    case FETCH_QC_SPEC_SUCCESS:
      return { ...state, qcSpec: action.qcSpec };
    case FETCH_FILTERED_QC_SPECS_SUCCESS:
      return { ...state, filteredQcSpecs: action.qcSpecs };
    case FETCH_SEARCH_QC_SPECS_SUCCESS:
      return { ...state, searchResult: action.searchResult };
    case CREATE_QC_SPEC_SUCCESS:
      return {
        ...state,
        qcSpec: action.qcSpec,
        qcSpecs:
          state.qcSpecs.length > 0
            ? [...state.qcSpecs.concat([action.qcSpec])]
            : [],
      };
    case EDIT_QC_SPEC_SUCCESS:
      if (state.qcSpecs.length > 0) {
        state.qcSpecs.splice(
          state.qcSpecs.findIndex((t) => t.id === action.qcSpec.id),
          1,
          action.qcSpec
        );
      }
      return {
        ...state,
        qcSpec: action.qcSpec,
        qcSpecs: state.qcSpecs.length > 0 ? state.qcSpecs : [],
      };
    case DELETE_QC_SPEC_SUCCESS:
      return { ...state, qcSpec: null, qcSpecs: [] };
    default:
      return state;
  }
};

export default qcSpecReducer;
