import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  requestBegin,
  requestEnd,
} from "../../store/actions/request";
import {
  saveSuccess,
  deleteSuccess,
  saveFailed,
} from "../../store/actions/alerts";
import { IIssue, IIssueChecklist, ITaggedIssueItems } from "../../models/issue";
import { Dispatch } from "redux";
import { DataList } from "utils/DataList";
import { IAuditEntry } from "models/audit";
import { IQcInspection } from "models/QualityControl/qcInspection";

export const FETCH_ISSUES_SUCCESS = "FETCH_ISSUES_SUCCESS";
export const FETCH_FILTERED_ISSUES_SUCCESS = "FETCH_FILTERED_ISSUES_SUCCESS";
export const FETCH_FILTERED_REL_ISSUES_SUCCESS =
  "FETCH_FILTERED_REL_ISSUES_SUCCESS";
export const FETCH_ISSUE_SUCCESS = "FETCH_ISSUE_SUCCESS";
export const CREATE_ISSUE_SUCCESS = "CREATE_ISSUE_SUCCESS";
export const EDIT_ISSUE_SUCCESS = "EDIT_ISSUE_SUCCESS";
export const EDIT_CASE_INSPECTION_SUCCESS = "EDIT_CASE_INSPECTION_SUCCESS";
export const DELETE_ISSUE_SUCCESS = "DELETE_ISSUE_SUCCESS";
export const ISSUE_REQUEST_ERRORS = "ISSUE_REQUEST_ERRORS";
export const ISSUE_REQUEST_FAILURE = "ISSUE_REQUEST_FAILURE";
export const FETCH_ISSUE_CHECKLIST_SUCCESS = "FETCH_ISSUE_CHECKLIST_SUCCESS";
export const FETCH_ISSUE_AUDITS_SUCCESS = "FETCH_ISSUE_AUDITS_SUCCESS";
export const FETCH_UNREAD_ISSUES_SUCCESS = "FETCH_UNREAD_ISSUES_SUCCESS";
export const FETCH_RECENT_ISSUES_SUCCESS = "FETCH_RECENT_ISSUES_SUCCESS";
export const FETCH_TAGGED_ITEMS_SUCCESS = "FETCH_TAGGED_ITEMS_SUCCESS";
export const FOLLOW_ISSUE_SUCCESS = "FOLLOW_ISSUE_SUCCESS";
export const UPDATE_UNREAD_ISSUE_SUCCESS = "UPDATE_UNREAD_ISSUE_SUCCESS";
export const UPDATE_ALL_UNREAD_ISSUES_SUCCESS =
  "UPDATE_ALL_UNREAD_ISSUES_SUCCESS";

export interface IssueActions {
  FETCH_ISSUES_SUCCESS: {
    type: typeof FETCH_ISSUES_SUCCESS;
    issues: IIssue[];
  };
  FETCH_FILTERED_ISSUES_SUCCESS: {
    type: typeof FETCH_FILTERED_ISSUES_SUCCESS;
    issues: DataList<IIssue>;
  };
  FETCH_FILTERED_REL_ISSUES_SUCCESS: {
    type: typeof FETCH_FILTERED_REL_ISSUES_SUCCESS;
    issues: DataList<IIssue>;
  };
  FETCH_ISSUE_SUCCESS: {
    type: typeof FETCH_ISSUE_SUCCESS;
    issue: IIssue;
  };
  CREATE_ISSUE_SUCCESS: {
    type: typeof CREATE_ISSUE_SUCCESS;
    issue: IIssue;
  };
  EDIT_ISSUE_SUCCESS: {
    type: typeof EDIT_ISSUE_SUCCESS;
    issue: IIssue;
  };
  EDIT_CASE_INSPECTION_SUCCESS: {
    type: typeof EDIT_CASE_INSPECTION_SUCCESS;
    inspection: IQcInspection;
  };
  DELETE_ISSUE_SUCCESS: {
    type: typeof DELETE_ISSUE_SUCCESS;
  };
  ISSUE_REQUEST_ERRORS: {
    type: typeof ISSUE_REQUEST_ERRORS;
    errors: any;
  };
  ISSUE_REQUEST_FAILURE: {
    type: typeof ISSUE_REQUEST_FAILURE;
    errors: any;
  };
  FETCH_ISSUE_CHECKLIST_SUCCESS: {
    type: typeof FETCH_ISSUE_CHECKLIST_SUCCESS;
    checklist: IIssueChecklist;
  };
  FETCH_ISSUE_AUDITS_SUCCESS: {
    type: typeof FETCH_ISSUE_AUDITS_SUCCESS;
    entries: IAuditEntry[];
  };
  FETCH_TAGGED_ITEMS_SUCCESS: {
    type: typeof FETCH_TAGGED_ITEMS_SUCCESS;
    taggedItems: ITaggedIssueItems;
  };
  FETCH_UNREAD_ISSUES_SUCCESS: {
    type: typeof FETCH_UNREAD_ISSUES_SUCCESS;
    unreadIssues: IIssue[];
  };
  FETCH_RECENT_ISSUES_SUCCESS: {
    type: typeof FETCH_RECENT_ISSUES_SUCCESS;
    recentlyReadIssues: IIssue[];
  };
  FOLLOW_ISSUE_SUCCESS: {
    type: typeof FOLLOW_ISSUE_SUCCESS;
    issue: IIssue;
  };
  UPDATE_UNREAD_ISSUE_SUCCESS: {
    type: typeof UPDATE_UNREAD_ISSUE_SUCCESS;
    issue: IIssue;
  };
  UPDATE_ALL_UNREAD_ISSUES_SUCCESS: {
    type: typeof UPDATE_ALL_UNREAD_ISSUES_SUCCESS;
    issues: IIssue[];
  };
}

export type IssueActionsTypes =
  | IssueActions[typeof FETCH_ISSUES_SUCCESS]
  | IssueActions[typeof FETCH_FILTERED_ISSUES_SUCCESS]
  | IssueActions[typeof FETCH_FILTERED_REL_ISSUES_SUCCESS]
  | IssueActions[typeof FETCH_ISSUE_CHECKLIST_SUCCESS]
  | IssueActions[typeof FETCH_ISSUE_AUDITS_SUCCESS]
  | IssueActions[typeof FETCH_ISSUE_SUCCESS]
  | IssueActions[typeof FETCH_UNREAD_ISSUES_SUCCESS]
  | IssueActions[typeof FETCH_RECENT_ISSUES_SUCCESS]
  | IssueActions[typeof FETCH_TAGGED_ITEMS_SUCCESS]
  | IssueActions[typeof UPDATE_UNREAD_ISSUE_SUCCESS]
  | IssueActions[typeof FOLLOW_ISSUE_SUCCESS]
  | IssueActions[typeof UPDATE_ALL_UNREAD_ISSUES_SUCCESS]
  | IssueActions[typeof CREATE_ISSUE_SUCCESS]
  | IssueActions[typeof EDIT_ISSUE_SUCCESS]
  | IssueActions[typeof EDIT_CASE_INSPECTION_SUCCESS]
  | IssueActions[typeof DELETE_ISSUE_SUCCESS]
  | IssueActions[typeof ISSUE_REQUEST_ERRORS]
  | IssueActions[typeof ISSUE_REQUEST_FAILURE];

export const actionCreators = {
  fetchIssuesSuccess: (
    issues: IIssue[]
  ): IssueActions[typeof FETCH_ISSUES_SUCCESS] => ({
    type: FETCH_ISSUES_SUCCESS,
    issues: issues,
  }),
  fetchFilteredIssuesSuccess: (
    issues: DataList<IIssue>
  ): IssueActions[typeof FETCH_FILTERED_ISSUES_SUCCESS] => ({
    type: FETCH_FILTERED_ISSUES_SUCCESS,
    issues: issues,
  }),
  fetchFilteredRelIssuesSuccess: (
    issues: DataList<IIssue>
  ): IssueActions[typeof FETCH_FILTERED_REL_ISSUES_SUCCESS] => ({
    type: FETCH_FILTERED_REL_ISSUES_SUCCESS,
    issues: issues,
  }),
  fetchIssueSuccess: (
    issue: IIssue
  ): IssueActions[typeof FETCH_ISSUE_SUCCESS] => ({
    type: FETCH_ISSUE_SUCCESS,
    issue: issue,
  }),
  fetchUnreadIssuesSuccess: (
    unreadIssues: IIssue[]
  ): IssueActions[typeof FETCH_UNREAD_ISSUES_SUCCESS] => ({
    type: FETCH_UNREAD_ISSUES_SUCCESS,
    unreadIssues: unreadIssues,
  }),
  fetchRecentlyreadIssuesSuccess: (
    recentlyReadIssues: IIssue[]
  ): IssueActions[typeof FETCH_RECENT_ISSUES_SUCCESS] => ({
    type: FETCH_RECENT_ISSUES_SUCCESS,
    recentlyReadIssues: recentlyReadIssues,
  }),
  UpdateUnreadIssueSuccess: (
    issue: IIssue
  ): IssueActions[typeof UPDATE_UNREAD_ISSUE_SUCCESS] => ({
    type: UPDATE_UNREAD_ISSUE_SUCCESS,
    issue: issue,
  }),
  FollowIssueSuccess: (
    issue: IIssue
  ): IssueActions[typeof FOLLOW_ISSUE_SUCCESS] => ({
    type: FOLLOW_ISSUE_SUCCESS,
    issue: issue,
  }),
  UpdateAllUnreadIssuesSuccess: (
    issues: IIssue[]
  ): IssueActions[typeof UPDATE_ALL_UNREAD_ISSUES_SUCCESS] => ({
    type: UPDATE_ALL_UNREAD_ISSUES_SUCCESS,
    issues: issues,
  }),
  createIssueSuccess: (
    issue: IIssue
  ): IssueActions[typeof CREATE_ISSUE_SUCCESS] => ({
    type: CREATE_ISSUE_SUCCESS,
    issue: issue,
  }),
  editIssueSuccess: (
    issue: IIssue
  ): IssueActions[typeof EDIT_ISSUE_SUCCESS] => ({
    type: EDIT_ISSUE_SUCCESS,
    issue: issue,
  }),
  editCaseInspectionSuccess: (
    inspection: IQcInspection
  ): IssueActions[typeof EDIT_CASE_INSPECTION_SUCCESS] => ({
    type: EDIT_CASE_INSPECTION_SUCCESS,
    inspection: inspection,
  }),
  deleteIssueSuccess: (): IssueActions[typeof DELETE_ISSUE_SUCCESS] => ({
    type: DELETE_ISSUE_SUCCESS,
  }),
  issueRequestErrors: (
    errors: any
  ): IssueActions[typeof ISSUE_REQUEST_ERRORS] => ({
    type: ISSUE_REQUEST_ERRORS,
    errors: errors,
  }),
  issueRequestFailure: (
    status: number
  ): IssueActions[typeof ISSUE_REQUEST_FAILURE] => ({
    type: ISSUE_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
  fetchQcChecklistSuccess: (
    checklist: IIssueChecklist
  ): IssueActions[typeof FETCH_ISSUE_CHECKLIST_SUCCESS] => ({
    type: FETCH_ISSUE_CHECKLIST_SUCCESS,
    checklist: checklist,
  }),
  fetchIssueAuditSuccess: (
    entries: IAuditEntry[]
  ): IssueActions[typeof FETCH_ISSUE_AUDITS_SUCCESS] => ({
    type: FETCH_ISSUE_AUDITS_SUCCESS,
    entries: entries,
  }),
  fetchTaggedIssueItemsSuccess: (
    taggedItems: ITaggedIssueItems
  ): IssueActions[typeof FETCH_TAGGED_ITEMS_SUCCESS] => ({
    type: FETCH_TAGGED_ITEMS_SUCCESS,
    taggedItems: taggedItems,
  }),
};

export function fetchIssues() {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_ISSUES_SUCCESS));
    const { status, json } = await getRequest("/issues");
    dispatch(requestEnd(FETCH_ISSUES_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchIssuesSuccess(json))
      : null;
  };
}

export function fetchUnreadIssues(userId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_UNREAD_ISSUES_SUCCESS));
    const { status, json } = await getRequest(`/issues/unread/${userId}`);
    dispatch(requestEnd(FETCH_UNREAD_ISSUES_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchUnreadIssuesSuccess(json))
      : null;
  };
}

export function fetchRecentlyReadIssues(userId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_RECENT_ISSUES_SUCCESS));
    const { status, json } = await getRequest(`/issues/recent/${userId}`);
    dispatch(requestEnd(FETCH_RECENT_ISSUES_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchRecentlyreadIssuesSuccess(json))
      : null;
  };
}

export function followIssue(issueId: number, userId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FOLLOW_ISSUE_SUCCESS));
    const { status, json } = await getRequest(
      `/issues/follow/${issueId}/${userId}`
    );
    switch (status) {
      case 200:
        dispatch(saveSuccess("followed " + json.title));
        return dispatch(actionCreators.editIssueSuccess(json));
      case 400:
        dispatch(saveFailed(json.title));
        return dispatch(actionCreators.issueRequestErrors(json));
      default:
        dispatch(saveFailed(json.title));
        return dispatch(actionCreators.issueRequestFailure(status));
    }
  };
}

export function updateUnreadIssue(issueId: number, userId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(UPDATE_UNREAD_ISSUE_SUCCESS));
    const { status, json } = await getRequest(
      `/issues/read/${issueId}/${userId}`
    );
    dispatch(requestEnd(UPDATE_UNREAD_ISSUE_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.UpdateUnreadIssueSuccess(json))
      : null;
  };
}

export function updateAllUnreadIssues(userId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(UPDATE_ALL_UNREAD_ISSUES_SUCCESS));
    const { status, json } = await getRequest(`/issues/read/${userId}`);
    dispatch(requestEnd(UPDATE_ALL_UNREAD_ISSUES_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.UpdateAllUnreadIssuesSuccess(json))
      : null;
  };
}

export function fetchFilteredIssues(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_FILTERED_ISSUES_SUCCESS));
    const { status, json } = await getRequest(`/issues/filtered?${queryStr}`);
    dispatch(requestEnd(FETCH_FILTERED_ISSUES_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchFilteredIssuesSuccess(json))
      : dispatch(actionCreators.issueRequestFailure(status));
  };
}

export function fetchFilteredRelIssues(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_FILTERED_REL_ISSUES_SUCCESS));
    const { status, json } = await getRequest(`/issues/filtered?${queryStr}`);
    dispatch(requestEnd(FETCH_FILTERED_REL_ISSUES_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchFilteredRelIssuesSuccess(json))
      : dispatch(actionCreators.issueRequestFailure(status));
  };
}

export const fetchIssue = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/issues/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchIssueSuccess(json))
      : dispatch(actionCreators.issueRequestFailure(status));
  };
};

export const createIssue = (issue: IIssue) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest("/issues", issue);
    switch (status) {
      case 200:
        dispatch(saveSuccess(issue.title));
        return dispatch(actionCreators.createIssueSuccess(json));
      case 400:
        dispatch(saveFailed(issue.title));
        return dispatch(actionCreators.issueRequestErrors(json));
      default:
        dispatch(saveFailed(issue.title));
        return dispatch(actionCreators.issueRequestFailure(status));
    }
  };
};

export const editIssue = (id: number, issue: IIssue) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(`/issues/${id}`, issue);
    switch (status) {
      case 200:
        dispatch(saveSuccess(issue.title));
        return dispatch(actionCreators.editIssueSuccess(json));
      case 400:
        dispatch(saveFailed(issue.title));
        return dispatch(actionCreators.issueRequestErrors(json));
      default:
        dispatch(saveFailed(issue.title));
        return dispatch(actionCreators.issueRequestFailure(status));
    }
  };
};

export const editCaseInspection = (
  issueId: number,
  inspection: IQcInspection
) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(
      `/issues/checkpoint/${issueId}`,
      inspection
    );
    switch (status) {
      case 200:
        dispatch(saveSuccess(json.qcCheckpointName));
        return dispatch(actionCreators.editCaseInspectionSuccess(json));
      case 400:
        dispatch(saveFailed(json.qcCheckpointName));
        return dispatch(actionCreators.issueRequestErrors(json));
      default:
        dispatch(saveFailed(json.qcCheckpointName));
        return dispatch(actionCreators.issueRequestFailure(status));
    }
  };
};

export const deleteIssue = (id: number, issue: IIssue) => {
  return async (dispatch: Dispatch) => {
    const { status } = await deleteRequest(`/issues/${id}`, issue);
    if (status === 200) {
      dispatch(deleteSuccess(issue.title));
      return dispatch(actionCreators.deleteIssueSuccess());
    }
    dispatch(saveFailed(issue.title));
    return dispatch(actionCreators.issueRequestFailure(status));
  };
};

export const fetchIssueChecklist = (
  issueId: number | null,
  tagIds: number[]
) => {
  return async (dispatch: Dispatch) => {
    let query = `/issues/checklist`;
    if (issueId !== null) {
      query = query + `/${issueId}`;
    } else {
      query = (
        query +
        `?` +
        tagIds.map((id, index) =>
          index === 0 ? `tagIds=${id}` : `&tagIds=${id}`
        )
      ).replace(/,/g, "");
    }
    const { status, json } = await getRequest(query);
    return status === 200
      ? dispatch(actionCreators.fetchQcChecklistSuccess(json))
      : dispatch(actionCreators.issueRequestFailure(status));
  };
};

export const fetchIssueAudits = (issueId: number) => {
  return async (dispatch: Dispatch) => {
    let query = `/issues/entries/${issueId}`;
    const { status, json } = await getRequest(query);
    switch (status) {
      case 200:
        return dispatch(actionCreators.fetchIssueAuditSuccess(json));
      case 400:
        return dispatch(actionCreators.issueRequestErrors(json));
      default:
        return dispatch(actionCreators.issueRequestFailure(status));
    }
  };
};

export const fetchTaggedIssueItems = (issueId: number) => {
  return async (dispatch: Dispatch) => {
    let query = `/issues/taggeditems/${issueId}`;
    const { status, json } = await getRequest(query);
    switch (status) {
      case 200:
        return dispatch(actionCreators.fetchTaggedIssueItemsSuccess(json));
      case 400:
        return dispatch(actionCreators.issueRequestErrors(json));
      default:
        return dispatch(actionCreators.issueRequestFailure(status));
    }
  };
};
