import { Dispatch } from "redux";
import { IIssueType } from "../../models/issuetype";
import { saveFailed, saveSuccess } from "./alerts";
import { getRequest, putRequest } from "./request";

export const FETCH_ISSUETYPES_SUCCESS = "FETCH_ISSUETYPES_SUCCESS";
export const FETCH_ISSUETYPE_SUCCESS = "FETCH_ISSUETYPE_SUCCESS";
export const EDIT_ISSUETYPE_SUCCESS = "EDIT_ISSUETYPE_SUCCESS";
export const ISSUETYPE_REQUEST_ERRORS = "ISSUETYPE_REQUEST_ERRORS";
export const ISSUETYPE_REQUEST_FAILURE = "ISSUETYPE_REQUEST_FAILURE";

export type IssueTypeActions = {
  FETCH_ISSUETYPES_SUCCESS: {
    type: typeof FETCH_ISSUETYPES_SUCCESS;
    issuetypes: IIssueType[];
  };
  FETCH_ISSUETYPE_SUCCESS: {
    type: typeof FETCH_ISSUETYPE_SUCCESS;
    issueType: IIssueType;
  };
  EDIT_ISSUETYPE_SUCCESS: {
    type: typeof EDIT_ISSUETYPE_SUCCESS;
    issueType: IIssueType;
  };
  ISSUETYPE_REQUEST_ERRORS: {
    type: typeof ISSUETYPE_REQUEST_ERRORS;
    errors: any;
  };
  ISSUETYPE_REQUEST_FAILURE: {
    type: typeof ISSUETYPE_REQUEST_FAILURE;
    errors: any;
  };
};

export type IssueTypeActionsTypes =
  | IssueTypeActions[typeof FETCH_ISSUETYPES_SUCCESS]
  | IssueTypeActions[typeof FETCH_ISSUETYPE_SUCCESS]
  | IssueTypeActions[typeof EDIT_ISSUETYPE_SUCCESS]
  | IssueTypeActions[typeof ISSUETYPE_REQUEST_ERRORS]
  | IssueTypeActions[typeof ISSUETYPE_REQUEST_FAILURE];

export const actionCreators = {
  fetchIssueTypesSuccess: (
    issuetypes: IIssueType[]
  ): IssueTypeActions[typeof FETCH_ISSUETYPES_SUCCESS] => ({
    type: FETCH_ISSUETYPES_SUCCESS,
    issuetypes: issuetypes,
  }),
  fetchIssueTypeSuccess: (
    issueType: IIssueType
  ): IssueTypeActions[typeof FETCH_ISSUETYPE_SUCCESS] => ({
    type: FETCH_ISSUETYPE_SUCCESS,
    issueType: issueType,
  }),
  editTagSuccess: (
    issueType: IIssueType
  ): IssueTypeActions[typeof EDIT_ISSUETYPE_SUCCESS] => ({
    type: EDIT_ISSUETYPE_SUCCESS,
    issueType: issueType,
  }),
  issueTypeRequestErrors: (
    errors: any
  ): IssueTypeActions[typeof ISSUETYPE_REQUEST_ERRORS] => ({
    type: ISSUETYPE_REQUEST_ERRORS,
    errors: errors,
  }),
  issueTypeRequestFailure: (
    status: number
  ): IssueTypeActions[typeof ISSUETYPE_REQUEST_FAILURE] => ({
    type: ISSUETYPE_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
};

export function fetchIssueType(id: number) {
  return async (dispatch: Dispatch) => {
    const { json } = await getRequest(`/issueTypes/${id}`);
    return dispatch(actionCreators.fetchIssueTypeSuccess(json));
  };
}

export const editIssueType = (id: number, issueType: IIssueType) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(`/issueTypes/${id}`, issueType);
    switch (status) {
      case 200:
        dispatch(saveSuccess(issueType.name));
        return dispatch(actionCreators.editTagSuccess(json));
      case 400:
        dispatch(saveFailed(issueType.name));
        return dispatch(actionCreators.issueTypeRequestErrors(json));
      default:
        dispatch(saveFailed(issueType.name));
        return dispatch(actionCreators.issueTypeRequestFailure(status));
    }
  };
};

export function fetchIssueTypes() {
  return async (dispatch: Dispatch) => {
    const { json } = await getRequest("/issuetypes");
    return dispatch(actionCreators.fetchIssueTypesSuccess(json));
  };
}
