import { IQcChassi } from "../../../models/QualityControl/qcChassis";
import { IQcCheckpoint } from "../../../models/QualityControl/qcCheckpoint";
import {
  QcChecklistActionTypes,
  FETCH_QC_CHECKLIST_SUCCESS,
} from "../../actions/QualityControl/qcChecklist";

export interface QcChecklistState {
  readonly qcChassi: IQcChassi | null;
  readonly qcCheckpoints: IQcCheckpoint[];
  readonly errors: any;
}

export const initialState: QcChecklistState = {
  qcChassi: null,
  qcCheckpoints: [],
  errors: null,
};

const qcCheklistReducer = (
  state = initialState,
  action: QcChecklistActionTypes
): QcChecklistState => {
  switch (action.type) {
    case FETCH_QC_CHECKLIST_SUCCESS:
      return {
        ...state,
        qcChassi: action.qcChecklist.qcChassis,
        qcCheckpoints: action.qcChecklist.qcCheckpoints,
      };
    default:
      return state;
  }
};

export default qcCheklistReducer;
