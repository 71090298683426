import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Accordion, Button, ButtonGroup, Card, Modal } from "react-bootstrap";
import {
  DataResult,
  DataSourceRequestState,
  toDataSourceRequestString,
} from "@progress/kendo-data-query";

import SearchForm from "../Common/SearchForm";
import JigFormContainer from "../../containers/JigFormContainer";
import { IProduct } from "../../models/product";
import { fetchFilteredJigs, createJigProduct } from "../../store/actions/jigs";
import { IRootState } from "../../store";
import { IJig, IJigProduct } from "../../models/jig";
import { isJigAdmin, IUser } from "../../models/user";

export interface IProps {
  show: boolean;
  addCarrier?: boolean;
  product: IProduct;
  jigs: DataResult;
  currentUser: IUser;
  onHide: () => any;
  setProduct: (product: IProduct) => any;
  fetchFilteredJigs: (query: string) => any;
  createJigProduct: (jigProduct: IJigProduct) => any;
}

const AddNewJigModal: React.FC<IProps> = (props) => {
  const {
    show,
    addCarrier,
    product,
    jigs,
    currentUser,
    onHide,
    setProduct,
    fetchFilteredJigs,
    createJigProduct,
  } = props;

  const [newJigAccordionState, setNewJigAccordionState] = useState<boolean>(
    false
  );

  const [
    existingJigAccordionState,
    setExistingJigAccordionState,
  ] = useState<boolean>(!isJigAdmin(currentUser));

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedJig, setSelectedJig] = useState<IJig>({} as IJig);

  const [requestState, setRequestState] = useState<DataSourceRequestState>({
    skip: 0,
    take: 10,
    filter: {
      filters: [],
      logic: "and" as const,
    },
  });

  useEffect(() => {
    if (existingJigAccordionState) {
      fetchFilteredJigs(`${toDataSourceRequestString(requestState)}`);
    }
  }, [requestState]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (addCarrier) {
      setExistingJigAccordionState(true);
    }
  }, [addCarrier]);

  const handleJigSearchChange = (value: string) => {
    if (value !== searchTerm) {
      setSelectedJig({} as IJig);
      setSearchTerm(value);
      const request = {
        ...requestState,
        skip: 0,
        filter: {
          filters: [
            {
              field: "hidden",
              operator: "eq",
              value: false,
            },
            {
              field: "JigNr",
              operator: "contains",
              value: value,
            },
            {
              field: "jigCategoryId",
              operator: addCarrier ? "eq" : "neq",
              value: 5,
            },
          ],
          logic: "and" as const,
        },
      };
      setRequestState(request);
    }
  };

  const handleExistingJigSubmit = async () => {
    if (selectedJig.id > 0) {
      const newJigProduct = {
        jigId: selectedJig.id,
        productNo: product.productNo,
      } as IJigProduct;

      const result = await createJigProduct(newJigProduct);
      if (result) {
        if (result.errors !== undefined) return;
        setProduct({ ...product, jigs: product.jigs.concat([selectedJig]) });
        setSelectedJig({} as IJig);
        setSearchTerm("");
        setExistingJigAccordionState(!isJigAdmin(currentUser) ? true : false);
        setNewJigAccordionState(false);
        onHide();
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      if (jigs.total === 1) {
        setSelectedJig(
          selectedJig?.id !== jigs.data[0].id ? jigs.data[0] : ({} as IJig)
        );
      } else {
        event.preventDefault();
      }
    }
  };

  const mql = window.matchMedia("(min-width: 500px)");

  return (
    <Modal
      show={show}
      onHide={() => {
        onHide();
        setSelectedJig({} as IJig);
        setSearchTerm("");
        setExistingJigAccordionState(!isJigAdmin(currentUser) ? true : false);
        setNewJigAccordionState(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add new {addCarrier ? "carrier" : "equipment"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isJigAdmin(currentUser) && (
          <div style={{ width: mql.matches ? "450px" : "350px" }}>
            <ButtonGroup>
              {!addCarrier && (
                <Button
                  className="noOutlineBtn"
                  variant={newJigAccordionState ? "info" : "outline-info"}
                  onClick={() => {
                    setNewJigAccordionState(!newJigAccordionState);
                    setExistingJigAccordionState(false);
                  }}
                >
                  Create new Equipment
                </Button>
              )}
              <Button
                className="noOutlineBtn"
                variant={existingJigAccordionState ? "info" : "outline-info"}
                onClick={() => {
                  setExistingJigAccordionState(!existingJigAccordionState);
                  setNewJigAccordionState(false);
                }}
              >
                Add Existing Equipment
              </Button>
            </ButtonGroup>
          </div>
        )}
        <Accordion.Collapse eventKey="a" in={newJigAccordionState}>
          <div style={{ width: mql.matches ? "450px" : "350px" }}>
            <JigFormContainer
              embeded={true}
              timetrackProd={product}
              closeModal={onHide}
              setTimetrackProd={setProduct}
            />
          </div>
        </Accordion.Collapse>
        <Accordion.Collapse
          eventKey="b"
          in={existingJigAccordionState || addCarrier}
          className="mt-3"
        >
          <div style={{ width: mql.matches ? "450px" : "350px" }}>
            <SearchForm
              searchTerm={searchTerm}
              handleChange={handleJigSearchChange}
              placeholder="Search Jig nr"
              handleEnterClick={handleKeyDown}
              disabled={false}
            />
            {jigs &&
              jigs.total > 0 &&
              jigs.data
                .filter((j) =>
                  addCarrier ? j.jigCategoryId === 5 : j.jigCategoryId !== 5
                )
                .map((j: IJig, index) => {
                  if (index < 10) {
                    return (
                      <Card
                        key={j.id}
                        onClick={() =>
                          setSelectedJig(
                            selectedJig?.id !== j.id ? j : ({} as IJig)
                          )
                        }
                        bg={selectedJig?.id === j.id ? "primary" : "secondary"}
                      >
                        <p className="pt-2 ps-2">{j.jignr}</p>
                      </Card>
                    );
                  } else {
                    return null;
                  }
                })}
            <Button
              variant="success"
              className="mt-2"
              onClick={() => handleExistingJigSubmit()}
              disabled={!selectedJig.id}
            >
              <span
                className="k-icon k-i-save k-i-floppy"
                style={{ top: "-9%" }}
              />{" "}
              Save
            </Button>
          </div>
        </Accordion.Collapse>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            onHide();
            setSelectedJig({} as IJig);
            setSearchTerm("");
            setExistingJigAccordionState(
              !isJigAdmin(currentUser) ? true : false
            );
            setNewJigAccordionState(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state: IRootState) => ({
  jigs: state.jigsReducer.filteredJigs,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchFilteredJigs: (queryStr: string) =>
    dispatch(fetchFilteredJigs(queryStr)),
  createJigProduct: (jigProduct: IJigProduct) =>
    dispatch(createJigProduct(jigProduct)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewJigModal);
