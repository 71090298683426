import {
  DataResult,
  DataSourceRequestState,
  FilterDescriptor,
  SortDescriptor,
} from "@progress/kendo-data-query";
import { DatePicker, SelectionRange } from "@progress/kendo-react-dateinputs";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import {
  Grid,
  GridColumn,
  GridColumnMenuItemGroup,
} from "@progress/kendo-react-grid";
import { GridColumnMenuFilterProps } from "@progress/kendo-react-grid/dist/npm/columnMenu/GridColumnMenuFilter";
import React, { useCallback, useEffect, useState } from "react";
import { Button, ButtonGroup, ButtonToolbar, Col, Row } from "react-bootstrap";
import { dateTimeFormatDay as dateTimeFormat } from "utils/dateTimeFormat";
import { IProduct } from "../../models/product";
import { IUser } from "../../models/user";
import Aux from "../../utils/auxiliary";
import { DateCell } from "../Common/GridComponents";

export interface IProps {
  productsData: DataResult;
  onProductRequestChange: (state: DataSourceRequestState) => any;
  onViewClick: (product: IProduct) => any;
  handleProductExcelRequestChange: (excelExport: ExcelExport) => any;
  current: IUser;
}

type SortDirection = "asc" | "desc" | null;

interface ITempRequestState {
  requestState: DataSourceRequestState;
  updatedAt: SelectionRange;
  createdAt: SelectionRange;

  updatedAtSort: SortDirection;
  createdAtSort: SortDirection;
}

const ProductGrid: React.FC<IProps> = (props) => {
  const {
    productsData,
    onProductRequestChange,
    onViewClick,
    handleProductExcelRequestChange,
    current,
  } = props;

  const defaultSort: SortDescriptor = {
    field: "productNo",
    dir: "asc",
  };

  const createdSortAsc: SortDescriptor[] = [
    {
      field: "createdAtDate",
      dir: "asc",
    },
    {
      field: "createdAtTime",
      dir: "asc",
    },
  ];

  const createdSortDesc: SortDescriptor[] = [
    {
      field: "createdAtDate",
      dir: "desc",
    },
    {
      field: "createdAtTime",
      dir: "desc",
    },
  ];

  const updatedSortAsc: SortDescriptor[] = [
    {
      field: "updatedAtDate",
      dir: "asc",
    },
    {
      field: "updatedAtTime",
      dir: "asc",
    },
  ];

  const updatedSortDesc: SortDescriptor[] = [
    {
      field: "updatedAtDate",
      dir: "desc",
    },
    {
      field: "updatedAtTime",
      dir: "desc",
    },
  ];

  const tempRequestItem = "products_grid_request_state";
  const fetchTempRequestState = () => {
    const request = localStorage.getItem(tempRequestItem);
    if (!request) return null;
    const result = JSON.parse(request) as ITempRequestState;

    if (result.createdAt && result?.createdAt?.start)
      result.createdAt.start = new Date(result.createdAt.start.toString());
    if (result.createdAt && result?.createdAt?.end)
      result.createdAt.end = new Date(result.createdAt.end.toString());
    if (result.updatedAt && result?.updatedAt.start)
      result.updatedAt.start = new Date(result.updatedAt.start.toString());
    if (result.updatedAt && result?.updatedAt.end)
      result.updatedAt.end = new Date(result.updatedAt.end.toString());

    return result;
  };

  const [tempReqState] = useState(fetchTempRequestState());

  const [requestState, setRequestState] = useState<DataSourceRequestState>(
    tempReqState?.requestState ?? {
      skip: 0,
      take: 20,
      sort: [defaultSort],
      filter: {
        filters: [],
        logic: "and" as const,
      },
    }
  );

  const [updatedAtSort, setUpdatedSort] = useState<SortDirection>(
    tempReqState?.updatedAtSort ?? null
  );
  const [createdAtSort, setCreatedSort] = useState<SortDirection>(
    tempReqState?.createdAtSort ?? null
  );

  const productRequestStateChange = useCallback(onProductRequestChange, [
    onProductRequestChange,
  ]);

  useEffect(() => {
    if (!requestState.filter) {
      setRequestState({
        ...requestState,
        filter: {
          filters: [],
          logic: "and" as const,
        },
      });
    } else {
      productRequestStateChange(requestState);
    }
  }, [requestState, productRequestStateChange]);

  const [createdAt, setCreatedAt] = useState<SelectionRange>(
    tempReqState?.createdAt ?? {
      start: null,
      end: null,
    }
  );
  const [updatedAt, setUpdatedAt] = useState<SelectionRange>(
    tempReqState?.updatedAt ?? {
      start: null,
      end: null,
    }
  );

  const updateSort = (
    created: "asc" | "desc" | null,
    updated: "asc" | "desc" | null,
    ignore?: boolean
  ) => {
    var sort: SortDescriptor[] | null = null;
    switch (created) {
      case "asc":
        sort = createdSortAsc;
        break;
      case "desc":
        sort = createdSortDesc;
        break;
    }
    setCreatedSort(created);

    switch (updated) {
      case "asc":
        sort = updatedSortAsc;
        break;
      case "desc":
        sort = updatedSortDesc;
        break;
    }
    setUpdatedSort(updated);

    if (sort === null && ignore) return;

    setRequestState((r) => ({
      ...r,
      sort: sort === null ? [defaultSort] : [...sort],
    }));
  };

  const onCreatedAtChange = (value: Date | null, operator: string) => {
    const range = { ...createdAt };
    if (operator === "gt") range.start = value;
    else range.end = value;
    setCreatedAt(range);
  };

  const onUpdatedAtChange = (value: Date | null, operator: string) => {
    const range = { ...updatedAt };
    if (operator === "gt") range.start = value;
    else range.end = value;
    setUpdatedAt(range);
  };

  const onDateClear = (
    dateField: string,
    setDateState: (state: SelectionRange) => any
  ) => {
    const range = { start: null, end: null } as SelectionRange;
    setDateState(range);
    onDateSubmit(range, dateField);
  };

  const dateFormatter = (date: Date | null) => {
    if (date === null) return null;

    const monthString = (date.getMonth() + 1).toString();
    const dayString = date.getDate().toString();

    const dateTime =
      date.getFullYear().toString() +
      (monthString.length === 1 ? "0" : "") +
      monthString +
      (dayString.length === 1 ? "0" : "") +
      dayString;
    const dateInt = parseInt(dateTime);
    if (isNaN(dateInt)) return null;

    return dateInt;
  };

  const onDateSubmit = (dateState: SelectionRange, dateField: string) => {
    const updatedRequestState = { ...requestState };
    if (!updatedRequestState.filter) return;

    const dateStartDate = dateFormatter(dateState.start);
    const dateEndDate = dateFormatter(dateState.end);

    var filters = updatedRequestState.filter.filters as FilterDescriptor[];

    filters = updateField(filters, dateStartDate, dateField, "gt");
    filters = updateField(filters, dateEndDate, dateField, "lt");

    setRequestState({
      ...updatedRequestState,
      filter: {
        filters: [...filters],
        logic: "and" as const,
      },
    });
  };

  const updateField = (
    filters: FilterDescriptor[],
    input: number | null,
    field: string,
    operator: string
  ): FilterDescriptor[] => {
    const filterCopy = [...filters];

    var gtIndex = filterCopy.findIndex(
      (x) => x.field === field && x.operator === operator
    );
    if (gtIndex !== -1 && input !== null) filterCopy[gtIndex].value = input;
    else if (gtIndex === -1 && input !== null)
      filterCopy.push({ field: field, operator: operator, value: input });
    else if (gtIndex !== -1 && input === null) filterCopy.splice(gtIndex, 1);

    return filterCopy;
  };

  const lang = "desc" + current.userPrefs[0].lang.toUpperCase();
  const mql = window.matchMedia("(min-width: 600px)");

  return (
    <ExcelExport data={productsData.data} ref={handleProductExcelRequestChange}>
      <Grid
        {...productsData}
        {...requestState}
        sortable
        filterable
        pageable={{
          info: true,
          pageSizes: [5, 10, 15, 20, 30, 40, 50],
          buttonCount: 10,
        }}
        onRowClick={(objects) => {
          localStorage.setItem(
            tempRequestItem,
            JSON.stringify({
              requestState,
              updatedAt,
              createdAt,
              createdAtSort,
              updatedAtSort,
            } as ITempRequestState)
          );
          onViewClick(objects.dataItem);
        }}
        onDataStateChange={(e) => {
          setRequestState(e.dataState);
        }}
        className={mql.matches ? "" : "mobile-grid"}
      >
        <GridColumn
          field="productNo"
          title="Product No"
          filter="text"
          width={mql.matches ? undefined : 200}
        />
        <GridColumn
          field={lang}
          title="Description"
          width={mql.matches ? undefined : 200}
        />
        <GridColumn
          field="createdAt"
          title="Created At"
          width={mql.matches ? undefined : 200}
          cell={(props) => <DateCell {...props} />}
          headerCell={() => (
            <span
              className="k-link"
              onClick={() => {
                switch (createdAtSort) {
                  case "asc":
                    updateSort("desc", null);
                    break;
                  case "desc":
                    updateSort(null, null);
                    break;
                  default:
                    updateSort("asc", null);
                    break;
                }
              }}
            >
              Created At
              {createdAtSort === "asc" && (
                <span className="k-icon k-i-sort-asc-sm"></span>
              )}
              {createdAtSort === "desc" && (
                <span className="k-icon k-i-sort-desc-sm"></span>
              )}
            </span>
          )}
          columnMenu={(prop) => {
            const dateProp: IProductDateTimeMenuProps = {
              ...prop,
              rangedValue: createdAt,
              onDateTimeChange: onCreatedAtChange,
              onDateTimeClear: () => {
                onDateClear("createdAtDate", setCreatedAt);
              },
              onSubmit: () => {
                onDateSubmit(createdAt, "createdAtDate");
              },
            };
            return ProductDateTimeMenu(dateProp);
          }}
          filterCell={(prop) => {
            return (
              <Aux>
                {(createdAt.start != null || createdAt.end != null) && (
                  <Row>
                    <Col className="col-12 col-md-9">
                      <p>
                        {(createdAt.start
                          ? dateTimeFormat.format(new Date(createdAt.start))
                          : "") +
                          " - " +
                          (createdAt.end
                            ? dateTimeFormat.format(new Date(createdAt.end))
                            : "")}
                      </p>
                    </Col>
                    <Col className="col-12 col-md-3">
                      <button
                        title="Clear"
                        type="button"
                        className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-icon-button"
                        onClick={(event) => {
                          event.preventDefault();
                          onDateClear("createdAtDate", setCreatedAt);
                        }}
                      >
                        <span className="k-svg-icon k-svg-i-filter-clear k-button-icon">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path d="m143.5 64 168.2 168.2L288 256v160l-64 64V256L64 96V64h79.5zm236.1 100.4L448 96V64H279.3l-64-64L192 22l298 298 22-23.3-132.4-132.3z"></path>
                          </svg>
                        </span>
                      </button>
                    </Col>
                  </Row>
                )}
              </Aux>
            );
          }}
        />
        <GridColumn
          field="updatedAt"
          title="Updated At"
          width={mql.matches ? undefined : 200}
          cell={(props) => <DateCell {...props} />}
          headerCell={() => (
            <span
              className="k-link"
              onClick={() => {
                switch (updatedAtSort) {
                  case "asc":
                    updateSort(null, "desc");
                    break;
                  case "desc":
                    updateSort(null, null);
                    break;
                  default:
                    updateSort(null, "asc");
                    break;
                }
              }}
            >
              Updated At
              {updatedAtSort === "asc" && (
                <span className="k-icon k-i-sort-asc-sm"></span>
              )}
              {updatedAtSort === "desc" && (
                <span className="k-icon k-i-sort-desc-sm"></span>
              )}
            </span>
          )}
          columnMenu={(prop) => {
            const dateProp: IProductDateTimeMenuProps = {
              ...prop,
              rangedValue: updatedAt,
              onDateTimeChange: onUpdatedAtChange,
              onDateTimeClear: () => {
                onDateClear("updatedAtDate", setUpdatedAt);
              },
              onSubmit: () => {
                onDateSubmit(updatedAt, "updatedAtDate");
              },
            };
            return ProductDateTimeMenu(dateProp);
          }}
          filterCell={(prop) => {
            return (
              <Aux>
                {(updatedAt.start != null || updatedAt.end != null) && (
                  <Row>
                    <Col className="col-12 col-md-9">
                      <p>
                        {(updatedAt.start
                          ? dateTimeFormat.format(new Date(updatedAt.start))
                          : "") +
                          " - " +
                          (updatedAt.end
                            ? dateTimeFormat.format(new Date(updatedAt.end))
                            : "")}
                      </p>
                    </Col>
                    <Col className="col-12 col-md-3">
                      <button
                        title="Clear"
                        type="button"
                        className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-icon-button"
                        onClick={(event) => {
                          event.preventDefault();
                          onDateClear("updatedAtDate", setUpdatedAt);
                        }}
                      >
                        <span className="k-svg-icon k-svg-i-filter-clear k-button-icon">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path d="m143.5 64 168.2 168.2L288 256v160l-64 64V256L64 96V64h79.5zm236.1 100.4L448 96V64H279.3l-64-64L192 22l298 298 22-23.3-132.4-132.3z"></path>
                          </svg>
                        </span>
                      </button>
                    </Col>
                  </Row>
                )}
              </Aux>
            );
          }}
        />
      </Grid>
    </ExcelExport>
  );
};

interface IProductDateTimeMenuProps extends GridColumnMenuFilterProps {
  rangedValue: SelectionRange;
  onDateTimeChange: (value: Date | null, operator: string) => void;
  onDateTimeClear: () => void;
  onSubmit: () => void;
}

const ProductDateTimeMenu = (props: IProductDateTimeMenuProps) => {
  const onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    props.onSubmit();
    if (props.onCloseMenu) {
      props.onCloseMenu();
    }
  };

  const onReset = (event) => {
    if (event) event.preventDefault();
    props.onDateTimeClear();
    onSubmit(event);
  };

  return (
    <div>
      <GridColumnMenuItemGroup>
        <div className="k-column-list-wrapper">
          <form onSubmit={onSubmit} onReset={onReset}>
            <div className={"k-column-list"}>
              <div key={0} className={"k-column-list-item"}>
                <span>
                  After:
                  <DatePicker
                    {...props}
                    format="dd/MM/yyyy"
                    value={props.rangedValue.start}
                    onChange={(event) => {
                      props.onDateTimeChange(event.value, "gt");
                    }}
                  />
                </span>
              </div>

              <div key={1} className={"k-column-list-item"}>
                <span>
                  Before:
                  <DatePicker
                    {...props}
                    format="dd/MM/yyyy"
                    value={props.rangedValue.end}
                    onChange={(event) => {
                      props.onDateTimeChange(event.value, "lt");
                    }}
                  />
                </span>
              </div>
            </div>
            <ButtonToolbar className="k-columnmenu-actions">
              <ButtonGroup>
                <Button type="submit" variant="primary">
                  Save
                </Button>
                <Button type="reset" variant="secondary">
                  Reset
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </form>
        </div>
      </GridColumnMenuItemGroup>
    </div>
  );
};

export default ProductGrid;
