import {
  MeasurementActionTypes,
  FETCH_MEASUREMENTUNITS_SUCCESS,
  FETCH_MEASUREMENTUNIT_SUCCESS,
  CREATE_MEASUREMENTUNIT_SUCCESS,
  EDIT_MEASUREMENTUNIT_SUCCESS,
  DELETE_MEASUREMENTUNIT_SUCCESS,
  MEASUREMENTUNIT_REQUEST_ERRORS,
  MEASUREMENTUNIT_REQUEST_FAILURE,
  EDIT_MEASUREMENTUNITS_SUCCESS,
} from "../actions/measurementunits";
import { IMeasurementUnit } from "../../models/measurementunit";

export interface MeasurementUnitState {
  readonly measurementUnits: IMeasurementUnit[];
  readonly measurementUnit: IMeasurementUnit | null;
  readonly errors: any;
}

export const initialState: MeasurementUnitState = {
  measurementUnits: [],
  measurementUnit: null,
  errors: null,
};

const measurementUnitReducer = (
  state = initialState,
  action: MeasurementActionTypes
): MeasurementUnitState => {
  switch (action.type) {
    case FETCH_MEASUREMENTUNITS_SUCCESS:
      return { ...state, measurementUnits: action.measurementUnits };
    case FETCH_MEASUREMENTUNIT_SUCCESS:
      return { ...state, measurementUnit: action.measurementUnit };
    case CREATE_MEASUREMENTUNIT_SUCCESS:
      return { ...state, measurementUnit: action.measurementUnit };
    case EDIT_MEASUREMENTUNIT_SUCCESS:
      return { ...state, measurementUnit: action.measurementUnit };
    case EDIT_MEASUREMENTUNITS_SUCCESS:
      return { ...state, measurementUnits: action.measurementUnits };
    case DELETE_MEASUREMENTUNIT_SUCCESS:
      return { ...state, measurementUnit: null };
    case MEASUREMENTUNIT_REQUEST_ERRORS:
      return { ...state, errors: action.errors };
    case MEASUREMENTUNIT_REQUEST_FAILURE:
      return { ...state, errors: action.errors };
    default:
      return state;
  }
};

export default measurementUnitReducer;
