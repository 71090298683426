import React, { useEffect, useState } from "react";
import {
  DataResult,
  DataSourceRequestState,
  toDataSourceRequestString,
  process as filterProcess,
} from "@progress/kendo-data-query";
import {
  Grid,
  GridColumn,
  GridDataStateChangeEvent,
  GridFilterCellProps,
  GridRowClickEvent,
} from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Prompt } from "react-router";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "@progress/kendo-react-inputs";
import { connect } from "react-redux";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Dropdown,
  DropdownButton,
  Row,
} from "react-bootstrap";
import { IRootState } from "store";

import {
  IQcCheckpoint,
  IExportCheckpoint,
} from "../../../models/QualityControl/qcCheckpoint";
import {
  DragCell,
  DragCellProps,
  DropDownMultiSelectFilterCell,
  DropDownFilterBooleanCell,
  DropDownFilterCell,
} from "../../Common/GridComponents";
import { IStation } from "../../../models/station";
import { IQcAttrValue } from "../../../models/QualityControl/qcAttrValue";
import { editQcCheckpointsOrder } from "../../../store/actions/QualityControl/qcCheckpoint";
import { ITag } from "../../../models/tag";
import SearchForm from "../../Common/SearchForm";
import SaveBottomBar from "components/Common/SaveBottomBar";
import { getRequest } from "store/actions/request";
import { IQcProd } from "models/QualityControl/qcProd";
import CheckpointGridColumns, {
  ICheckpointColumns,
} from "./CheckpointGridColumns";
import { IJig } from "models/jig";
import {
  IUser,
  isCaseAdmin,
  isJigAdmin,
  isQcAdmin,
  isQcTeamleader,
  isTTAdmin,
} from "models/user";

export interface IProps {
  qcCheckpointsData: DataResult;
  stations?: IStation[];
  attrValues?: IQcAttrValue[];
  tags?: ITag[];
  jigs?: IJig[];
  qcProds?: IQcProd[];
  typeId: number;
  jig?: IJig;
  currentUser: IUser;
  editQcCheckpointsOrder: (
    checkpoints: IQcCheckpoint[],
    stationId?: number,
    tagId?: number
  ) => Promise<any>;
  handleFetch: (query: string) => void;
  onEditClick: (qcCheckpoint: IQcCheckpoint) => any;
  onJigRequestChange?: (event: any) => void;
  newQcCheckpoint?: () => any;
}

const QcCheckpointGrid: React.FC<IProps> = (props) => {
  const {
    qcCheckpointsData,
    stations,
    attrValues,
    tags,
    jigs,
    qcProds,
    typeId,
    jig,
    currentUser,
    editQcCheckpointsOrder,
    handleFetch,
    onEditClick,
    onJigRequestChange,
    newQcCheckpoint,
  } = props;

  const localstorageLoc =
    typeId === 2
      ? "temp_issue_checkpoint_req_state"
      : typeId === 3
      ? "temp_prod_checkpoint_req_state"
      : typeId === 4
      ? "temp_jig_checkpoint_req_state"
      : "temp_qc_checkpoint_req_state";

  const fetchTempRequestState = () => {
    const request = localStorage.getItem(localstorageLoc);
    localStorage.removeItem(localstorageLoc);
    if (!request) return null;
    return JSON.parse(request) as {
      requestState: DataSourceRequestState;
      selectedAttrValues: IQcAttrValue[];
      selectedStation: IStation;
      selectedTags: ITag[];
      selectedTag: ITag;
      selectedQcProd: string;
      selectedFilterStation: string;
      viewHidden: boolean;
      productSearchTerm: string;
      selectedJig: IJig;
      productQcBool: { name: string; value: boolean };
    };
  };

  const fetchGridColumns = () => {
    const request = localStorage.getItem(
      typeId === 2
        ? "issueCheckpointGridColumns"
        : typeId === 3
        ? "prodCheckpointGridColumns"
        : typeId === 4
        ? "jigCheckpointGridColumns"
        : "QcCheckpointGridColumns"
    );
    if (request) {
      const tempColumns = CheckpointGridColumns.filter((c) =>
        typeId === 2
          ? c.case
          : typeId === 3
          ? c.tt
          : typeId === 4
          ? c.jig
          : c.qc
      );
      let storedClumns = JSON.parse(request) as ICheckpointColumns[];
      tempColumns.forEach((c) => {
        if (!storedClumns.some((sc) => sc.title === c.title)) {
          storedClumns.push(c);
        }
      });
      return storedClumns;
    }
    if (!request) return CheckpointGridColumns;
    return JSON.parse(request) as ICheckpointColumns[];
  };

  const [tempReqState] = useState(fetchTempRequestState());
  const [selectedAttrValues, setSelectedAttrValues] = useState<IQcAttrValue[]>(
    tempReqState?.selectedAttrValues ?? []
  );
  const [selectedStation, setSelectedStation] = useState<IStation | undefined>(
    tempReqState?.selectedStation
  );
  const [selectedJig, setSelectedJig] = useState<IJig | undefined>(
    tempReqState?.selectedJig
  );
  const [selectedFilterStation, setSelectedFilterStation] = useState<string>(
    tempReqState?.selectedFilterStation || ""
  );
  const [selectedTags, setSelectedTags] = useState<ITag[]>(
    tempReqState?.selectedTags ?? []
  );
  const [selectedTag, setSelectedTag] = useState<ITag | undefined>(
    tempReqState?.selectedTag
  );
  const [selectedQcProd, setSelectedQcProd] = useState<string>(
    tempReqState?.selectedQcProd || ""
  );
  const [dirty, setDirty] = useState<boolean>(false);
  const [checkpointsReordered, setCheckpointsReordered] = useState<
    IQcCheckpoint[]
  >();
  const [prevCheckpointsOrder, setPrevCheckpointsOrder] = useState<
    IQcCheckpoint[]
  >();
  const [activeItem, setActiveItem] = useState<IQcCheckpoint | null>(null);
  const [filteredTags, setFilteredTags] = useState<ITag[]>([]);
  const [filteredStations, setFilteredStations] = useState<IStation[]>([]);
  const [isQualified, setIsQualified] = useState<boolean>(false);
  const [viewHidden, setViewHidden] = useState<boolean>(
    tempReqState?.viewHidden || false
  );
  const [productSearchTerm, setProductSearchTerm] = useState<string>(
    tempReqState?.productSearchTerm || ""
  );
  const [oneVisibleColumn, setOneVisibleColumn] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [columns, setColumns] = useState<ICheckpointColumns[]>(
    fetchGridColumns()
  );
  const [exportState, setExportState] = useState<ExcelExport>();
  const [exporting, setExportingState] = useState<boolean>();

  const [productQcBool, setProductQcBool] = useState<{
    name: string;
    value: boolean;
  }>();

  useEffect(() => {
    if (typeId === 1 && isQcAdmin(currentUser)) {
      setIsQualified(true);
    } else if (typeId === 2 && isCaseAdmin(currentUser)) {
      setIsQualified(true);
    } else if (
      typeId === 3 &&
      (isQcAdmin(currentUser) || isTTAdmin(currentUser))
    ) {
      setIsQualified(true);
    } else if (typeId === 4 && isJigAdmin(currentUser)) {
      setIsQualified(true);
    } else {
      setIsQualified(false);
    }
  }, [typeId, currentUser]);

  const changeOrderView =
    selectedStation !== undefined ||
    selectedTag !== undefined ||
    productSearchTerm !== "" ||
    selectedJig !== undefined;

  const defaultReqState = (): DataSourceRequestState => ({
    skip: 0,
    take: 20,
    filter: {
      filters: [
        {
          field: "hide",
          operator: "eq",
          value: viewHidden,
        },
        {
          field: "typeId",
          operator: "eq",
          value: typeId,
        },
      ],
      logic: "and" as const,
    },
  });

  const [requestState, setRequestState] = useState<DataSourceRequestState>(
    tempReqState?.requestState || defaultReqState
  );

  useEffect(() => {
    if (jig !== undefined) {
      setSelectedJig(jig);
    }
  }, [jig]);

  useEffect(() => {
    if (qcCheckpointsData?.data === undefined) return;
    if (selectedStation !== undefined) {
      qcCheckpointsData.data.map((c: IQcCheckpoint) => {
        var checkpointStation = c.stationCheckpoints.find(
          (s) => s.stationId === selectedStation.id
        );
        if (checkpointStation !== undefined) {
          c.sort = checkpointStation.sort;
        }
        return c;
      });
    }
    if (selectedFilterStation.length > 0) {
      qcCheckpointsData.data.map((c: IQcCheckpoint) => {
        var checkpointStation = c.stationCheckpoints.find(
          (s) => s.stationName === selectedFilterStation
        );
        if (checkpointStation !== undefined) {
          c.sort = checkpointStation.sort;
        }
        return c;
      });
    }
    if (selectedTag !== undefined || selectedTags.length === 1) {
      qcCheckpointsData.data.map((c: IQcCheckpoint) => {
        var checkpointTag = c.tagCheckpoints.find((t) =>
          selectedTag !== undefined
            ? t.tagId === selectedTag.id
            : t.tagId === selectedTags[0].id
        );
        if (checkpointTag !== undefined) {
          c.sort = checkpointTag.sort;
        }
        return c;
      });
    }
    setCheckpointsReordered(
      qcCheckpointsData.data.sort((a, b) => a.sort - b.sort)
    );
    setPrevCheckpointsOrder(
      qcCheckpointsData.data.sort((a, b) => a.sort - b.sort)
    );
  }, [
    qcCheckpointsData,
    selectedStation,
    selectedFilterStation,
    selectedTag,
    selectedTags,
  ]);

  useEffect(() => {
    if (tempReqState?.productQcBool === undefined) return;
    setProductQcBool(tempReqState?.productQcBool);
  }, [tempReqState]);

  useEffect(() => {
    if (tags !== undefined) {
      setFilteredTags(tags);
    }
  }, [tags]);

  const onTagFilterChange = (event: any) => {
    if (tags !== undefined) {
      const tempFilteredTags = filterProcess(tags, event);
      if (tempFilteredTags.total > 0) {
        setFilteredTags(tempFilteredTags.data);
      }
    }
  };

  useEffect(() => {
    if (stations !== undefined) {
      setFilteredStations(stations);
    }
  }, [stations]);

  const onStationFilterChange = (event: any) => {
    if (stations !== undefined) {
      const tempFilteredStations = filterProcess(stations, event);
      if (tempFilteredStations.total > 0) {
        setFilteredStations(tempFilteredStations.data);
      }
    }
  };

  useEffect(
    () => {
      localStorage.setItem(
        localstorageLoc,
        JSON.stringify({
          requestState,
          selectedAttrValues,
          selectedStation,
          selectedTags,
          selectedTag,
          viewHidden,
          productSearchTerm,
          selectedFilterStation,
          productQcBool,
        })
      );

      handleFetch(
        `${toDataSourceRequestString(requestState)}` +
          (selectedAttrValues.length > 0
            ? `&jsonAttrFilter=${encodeURIComponent(
                JSON.stringify(
                  selectedAttrValues.map((x) => x.qcAttrTypeValueName)
                )
              )}`
            : "") +
          (selectedTags.length > 0
            ? `&jsonTagFilter=${encodeURIComponent(
                JSON.stringify(selectedTags.map((x) => x.name))
              )}`
            : "") +
          (selectedTag !== undefined ? `&tagName=${selectedTag.name}` : "") +
          (selectedQcProd !== "" ? `&QcProd=${selectedQcProd}` : "") +
          (productSearchTerm !== "" ? `&prodNo=${productSearchTerm}` : "") +
          (selectedJig !== undefined || jig !== undefined
            ? `&jigNr=${selectedJig?.jignr ?? jig?.jignr}`
            : "") +
          (selectedFilterStation !== ""
            ? `&stationName=${selectedFilterStation}`
            : selectedStation !== undefined
            ? `&stationName=${selectedStation.name}`
            : "")
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      requestState,
      selectedAttrValues,
      selectedTags,
      selectedJig,
      productSearchTerm,
      selectedQcProd,
      selectedFilterStation,
    ]
    // eslint-enable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (selectedStation !== undefined) {
      setSelectedQcProd("");
      setSelectedAttrValues([]);
      setSelectedFilterStation("");
      setRequestState({
        skip: 0,
        take: 0,
        filter: {
          filters: [
            {
              field: "hide",
              operator: "eq",
              value: viewHidden,
            },
            {
              field: "typeId",
              operator: "eq",
              value: typeId,
            },
          ],
          logic: "and" as const,
        },
      });
    } else if (localStorage.getItem(localstorageLoc) === null) {
      setRequestState(defaultReqState());
    }
  }, [selectedStation, viewHidden, typeId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedTag !== undefined) {
      setSelectedTags([]);
      setRequestState({
        skip: 0,
        take: 0,
        filter: {
          filters: [
            {
              field: "hide",
              operator: "eq",
              value: viewHidden,
            },
            {
              field: "typeId",
              operator: "eq",
              value: typeId,
            },
          ],
          logic: "and" as const,
        },
      });
    } else if (localStorage.getItem(localstorageLoc) === null) {
      setRequestState(defaultReqState());
    }
  }, [selectedTag, viewHidden]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      checkpointsReordered === undefined ||
      prevCheckpointsOrder === undefined
    )
      return;
    if (selectedStation !== undefined) {
      checkpointsReordered.forEach((chp, index) => {
        chp.sort = index;
      });
    } else if (selectedTag !== undefined) {
      checkpointsReordered.forEach((chp, index) => {
        chp.sort = index;
      });
    } else {
      checkpointsReordered.forEach((cpR, index) => {
        const oldIndex = prevCheckpointsOrder?.findIndex(
          (c) => c.id === cpR.id
        );
        if (index < oldIndex) {
          cpR.sort = prevCheckpointsOrder[index].sort ?? 0;
        } else if (index > oldIndex) {
          cpR.sort = (prevCheckpointsOrder[index].sort ?? 0) + 1;
        }
      });
    }

    setPrevCheckpointsOrder(checkpointsReordered);
  }, [checkpointsReordered]); // eslint-disable-line react-hooks/exhaustive-deps

  const reOrder = (dataItem: IQcCheckpoint) => {
    if (checkpointsReordered === undefined) return;
    if (activeItem === null) return;
    if (activeItem?.id === dataItem.id) {
      return;
    }

    var reorderedData = [...checkpointsReordered];
    var prevIndex = reorderedData.findIndex((p) => p.id === activeItem?.id);
    var nextIndex = reorderedData.findIndex((p) => p.id === dataItem.id);
    reorderedData.splice(prevIndex, 1);
    reorderedData.splice(nextIndex, 0, activeItem);

    setDirty(true);
    setCheckpointsReordered(reorderedData);
  };

  const reOrderPosition = (dataItem: IQcCheckpoint, position: number) => {
    if (checkpointsReordered === undefined) return;
    if (position > 0) position = 1;
    if (position < 0) position = -1;

    var reorderedData = [...checkpointsReordered];
    var prevIndex = reorderedData.findIndex((p) => p.id === dataItem.id);
    if (prevIndex + position >= reorderedData.length) return;
    if (prevIndex + position < 0) return;

    var nextIndex = prevIndex + position;
    reorderedData.splice(prevIndex, 1);
    reorderedData.splice(nextIndex, 0, dataItem);

    setActiveItem(dataItem);
    setDirty(true);
    setCheckpointsReordered(reorderedData);
  };

  const dragStart = (dataItem: IQcCheckpoint) => {
    setActiveItem(dataItem);
  };

  const dragEnd = (dataItem: IQcCheckpoint) => {
    if (activeItem?.id === dataItem.id) setActiveItem(null);
  };

  const saveOrder = () => {
    if (checkpointsReordered === undefined) return;

    editQcCheckpointsOrder(
      checkpointsReordered,
      selectedStation !== undefined ? selectedStation.id : undefined,
      selectedTag !== undefined ? selectedTag.id : undefined
    ).then((status) => {
      if (status.errors !== undefined) return;
      setDirty(false);
    });
  };

  const mql = window.matchMedia("(min-width: 800px)");

  const checkpointsData = () => {
    if (
      selectedStation === undefined &&
      selectedTag === undefined &&
      selectedJig === undefined &&
      productSearchTerm === ""
    ) {
      return qcCheckpointsData;
    } else {
      return { data: checkpointsReordered };
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const handleExcelRequestChange = (exporter: ExcelExport) => {
    setExportState(exporter);
  };

  const handleExportClick = () => {
    const newState = { ...requestState };
    newState.take = undefined;
    newState.skip = undefined;

    setExportingState(true);
    const queryStr = `${toDataSourceRequestString(newState)}`;
    getRequest(
      `/qcCheckpoints/filtered?${queryStr}` +
        (selectedAttrValues.length > 0
          ? `&jsonAttrFilter=${encodeURIComponent(
              JSON.stringify(
                selectedAttrValues.map((x) => x.qcAttrTypeValueName)
              )
            )}`
          : "") +
        (selectedTags.length > 0 || selectedTag !== undefined
          ? `&jsonTagFilter=${encodeURIComponent(
              JSON.stringify(
                (selectedTag !== undefined ? [selectedTag] : selectedTags).map(
                  (x) => x.name
                )
              )
            )}`
          : "") +
        (selectedQcProd !== "" ? `&QcProd=${selectedQcProd}` : "") +
        (productSearchTerm !== "" ? `&prodNo=${productSearchTerm}` : "") +
        (selectedJig !== undefined ? `&jigNr=${selectedJig.jignr}` : "") +
        (selectedFilterStation !== ""
          ? `&stationName=${selectedFilterStation}`
          : selectedStation !== undefined
          ? `&stationName=${selectedStation.name}`
          : "")
    ).then((response) => {
      const exportCheckpoints: IExportCheckpoint[] = response.json.data;
      exportCheckpoints.forEach((ec) => {
        ec.qcAttrValueNames = ec.qcAttrValues
          .filter((av) => !av.hide)
          .map((qcAv) => qcAv.qcAttrTypeValueName)
          .sort()
          .join(", ");
        ec.tagNames = ec.tags
          .map((t) => t.name)
          .sort()
          .join(", ");
        ec.prodNumbers = ec.prodNos.join(", ");
        ec.joinedJigNrs = ec.jigNrs.join(", ");
        ec.joinedQcProdNames = ec.qcProdNames.join(", ");
        ec.joinedStationNames = ec.stationNames.join(", ");
      });
      exportState?.save(exportCheckpoints.sort((a, b) => a.sort - b.sort));
      setExportingState(false);
    });
  };

  const onToggleColumn = (column: ICheckpointColumns) => {
    let tempColumns = [...columns];
    const toggeld = tempColumns.find((c) => c.title === column.title);
    if (toggeld !== undefined) {
      toggeld.show = !toggeld.show;
    }
    setColumns(tempColumns);
    setOneVisibleColumn(
      tempColumns.filter(
        (c) =>
          c.show &&
          (typeId === 2
            ? c.case
            : typeId === 3
            ? c.tt
            : typeId === 4
            ? c.jig
            : c.qc)
      ).length === 1
    );
    localStorage.setItem(
      typeId === 2
        ? "issueCheckpointGridColumns"
        : typeId === 3
        ? "prodCheckpointGridColumns"
        : typeId === 4
        ? "jigCheckpointGridColumns"
        : "QcCheckpointGridColumns",
      JSON.stringify(tempColumns)
    );
  };

  const resetFilter = () => {
    setSelectedAttrValues([]);
    setSelectedFilterStation("");
    setSelectedQcProd("");
    setSelectedJig(undefined);
    setProductSearchTerm("");
    setSelectedStation(undefined);
    setSelectedTag(undefined);
    setSelectedTags([]);
    setProductQcBool(undefined);
    setRequestState(defaultReqState());
  };

  const columsWidth = mql.matches
    ? (window.innerWidth -
        (columns.find((x) => x.title === "Name")?.show
          ? window.innerWidth * 0.315
          : 0) -
        (selectedStation === undefined ? 0 : 80)) /
      (columns.filter(
        (c) =>
          c.show &&
          (typeId === 2
            ? c.case
            : typeId === 3
            ? c.tt
            : typeId === 4
            ? c.jig
            : c.qc)
      ).length -
        (columns.find((x) => x.title === "Name")?.show ? 1 : 0))
    : 200;

  return (
    <div style={{ minHeight: "80vh" }}>
      {typeId === 1 && (
        <Row>
          <Col className="col-12 col-md-2 mb-0">
            <DropDownList
              className="w-100"
              textField="name"
              dataItemKey="id"
              value={selectedStation || ""}
              popupSettings={{ width: "auto" }}
              data={filteredStations}
              filterable={true}
              onFilterChange={onStationFilterChange}
              onChange={(e) => setSelectedStation(e.target.value)}
              disabled={selectedStation !== undefined}
            />
            <small className="text-muted mt-0">
              {selectedStation === undefined ? (
                <em>Select station</em>
              ) : (
                <span>&nbsp;</span>
              )}
            </small>
          </Col>
          <Col className="col-12 col-md-2">
            <Button
              variant="secondary"
              onClick={() => setSelectedStation(undefined)}
              disabled={selectedStation === undefined}
            >
              View All
            </Button>
          </Col>
        </Row>
      )}
      {typeId === 2 && (
        <Row>
          <Col className="col-12 col-md-2">
            <DropDownList
              className="w-100"
              textField="name"
              dataItemKey="id"
              value={selectedTag || ""}
              popupSettings={{ width: "auto" }}
              data={filteredTags}
              filterable={true}
              onFilterChange={onTagFilterChange}
              onChange={(e) => setSelectedTag(e.target.value)}
              disabled={selectedTag !== undefined}
            />
            <small className="text-muted mt-0">
              {selectedTag === undefined ? (
                <em>Select tag</em>
              ) : (
                <span>&nbsp;</span>
              )}
            </small>
          </Col>
          <Col className="col-12 col-md-2">
            <Button
              variant="secondary"
              onClick={() => setSelectedTag(undefined)}
              disabled={selectedTag === undefined}
            >
              View All
            </Button>
          </Col>
        </Row>
      )}
      {typeId === 4 && (
        <Row>
          <Col className="col-12 col-md-2">
            <DropDownList
              className="w-100"
              textField="jignr"
              dataItemKey="id"
              value={selectedJig || ""}
              popupSettings={{ width: "auto" }}
              data={jigs}
              filterable={true}
              onFilterChange={onJigRequestChange}
              onChange={(e) => setSelectedJig(e.target.value)}
              disabled={jig !== undefined}
            />
          </Col>
          <Col className="col-12 col-md-2">
            {jig === undefined && (
              <Button
                variant="secondary"
                onClick={() => setSelectedJig(undefined)}
                disabled={selectedJig === undefined}
              >
                View All
              </Button>
            )}
          </Col>
        </Row>
      )}
      {typeId === 3 && (
        <div className="my-2 p-0">
          <Col className="col-md-3 m-0 p-0">
            <div>
              <button
                title="Clear filter"
                onClick={() => setProductSearchTerm("")}
                className="border-0 text-white float-end"
                style={{ cursor: "pointer", background: "transparent" }}
              >
                <span
                  className="k-icon k-i-filter-clear"
                  style={{ fontSize: "32px" }}
                ></span>
              </button>
              <SearchForm
                searchTerm={productSearchTerm}
                handleChange={(e) => setProductSearchTerm(e)}
                placeholder="Search Product number"
                handleEnterClick={handleKeyDown}
                disabled={false}
              />
            </div>
          </Col>
        </div>
      )}
      <DropdownButton
        id="dropdown-item-button"
        title="Grid Options"
        className="my-2"
        onToggle={() => setShowOptions(!showOptions)}
        onBlur={undefined}
        show={showOptions}
        style={{ width: "150px" }}
      >
        <Dropdown.ItemText onClick={() => setShowOptions(true)}>
          Columns
        </Dropdown.ItemText>
        {columns
          .filter((c) =>
            typeId === 2
              ? c.case
              : typeId === 3
              ? c.tt
              : typeId === 4
              ? c.jig
              : c.qc
          )
          .map((column) => {
            return (
              <Dropdown.Item
                key={column.title}
                as={Checkbox}
                value={column.show}
                onChange={() => {
                  onToggleColumn(column);
                  setShowOptions(true);
                }}
                disabled={oneVisibleColumn && column.show}
                label={column.title}
                onClick={() => setShowOptions(true)}
              />
            );
          })}
      </DropdownButton>
      <Row>
        <Col>
          <div className="mb-2">
            <Checkbox
              name="hide"
              label="View Hidden"
              value={viewHidden}
              onChange={() => {
                setViewHidden(!viewHidden);
              }}
              style={{ height: "20px", width: "20px" }}
            />
          </div>
        </Col>
        {jig === undefined && (
          <Col>
            <button
              title="Clear filter"
              onClick={() => resetFilter()}
              className="border-0 text-white float-end mt-0"
              style={{ cursor: "pointer", background: "transparent" }}
            >
              <span
                className="k-icon k-i-filter-clear"
                style={{
                  fontSize: "40px",
                  color: "white",
                }}
              ></span>
            </button>
          </Col>
        )}
      </Row>

      <ExcelExport
        data={qcCheckpointsData.data}
        ref={handleExcelRequestChange}
        fileName={
          (typeId === 2
            ? "Case"
            : typeId === 3
            ? "Product"
            : typeId === 4
            ? "Jig"
            : "QC") +
          " Checkpoints export" +
          (selectedTag !== undefined ? " (" + selectedTag?.name + ")" : "") +
          (selectedJig !== undefined ? " (" + selectedJig?.jignr + ")" : "") +
          (selectedStation !== undefined
            ? " (" + selectedStation?.name + ")"
            : "") +
          (productSearchTerm !== "" ? " (" + productSearchTerm + ")" : "") +
          ".xlsx"
        }
      >
        <Grid
          {...requestState}
          {...checkpointsData()}
          className={mql.matches ? "" : "mobile-grid"}
          sortable={!changeOrderView}
          filterable={!changeOrderView}
          pageable={
            !changeOrderView && {
              info: true,
              pageSizes: [5, 10, 15, 20, 30, 40, 50],
              buttonCount: 10,
            }
          }
          onRowClick={(event: GridRowClickEvent) => {
            if (
              (typeId === 1 &&
                (isQcAdmin(currentUser) || isQcTeamleader(currentUser))) ||
              (typeId === 2 && isCaseAdmin(currentUser)) ||
              (typeId === 3 && isTTAdmin(currentUser)) ||
              (typeId === 4 && isJigAdmin(currentUser))
            ) {
              localStorage.setItem(
                localstorageLoc,
                JSON.stringify({
                  requestState,
                  selectedAttrValues,
                  selectedStation,
                  selectedTags,
                  selectedTag,
                  selectedQcProd,
                  selectedFilterStation,
                  viewHidden,
                  productSearchTerm,
                  productQcBool,
                })
              );
              onEditClick(event.dataItem);
            }
          }}
          onDataStateChange={(event: GridDataStateChangeEvent) => {
            setRequestState(event.dataState);
          }}
          rowRender={(props, item) => {
            const propProps = { ...props.props };
            const active =
              changeOrderView && item.dataItem.id === activeItem?.id;
            const wasHidden = item.dataItem.hide;

            let newClassName = propProps.className;
            if (wasHidden) newClassName += " text-danger";
            if (active) newClassName += " bg-secondary";
            propProps.className = newClassName;

            return { ...props, props: { ...propProps } };
          }}
        >
          {changeOrderView && isQualified && (
            <GridColumn
              title=""
              headerClassName="drag-button"
              className="drag-button"
              width="80px"
              cell={(cell) => {
                return DragCell({
                  ...cell,
                  dragEnd: (item) => dragEnd(item),
                  dragStart: (item) => dragStart(item),
                  reOrder: (item) => reOrder(item),
                  reOrderPosition: (item, position) =>
                    reOrderPosition(item, position),
                  arrows: !mql.matches,
                  dataItem: cell.dataItem,
                  isActive: cell.dataItem.id === activeItem?.id,
                  className: "drag-button",
                } as DragCellProps);
              }}
            />
          )}
          {columns.find((x) => x.title === "No")?.show && (
            <GridColumn
              field="no"
              title="No"
              filter="numeric"
              width={columsWidth}
            />
          )}
          {columns.find((x) => x.title === "Name")?.show && (
            <GridColumn
              field="name"
              title="Name"
              width={mql.matches ? window.innerWidth * 0.3 : columsWidth}
            />
          )}
          {typeId === 3 &&
            columns.find((x) => x.title === "Product Nos")?.show && (
              <GridColumn
                field="prodNumbers"
                title="Product Nos"
                filterable={false}
                cell={(props) => {
                  return <td>{props.dataItem.prodNos.join(", ")}</td>;
                }}
                width={columsWidth}
              />
            )}
          {typeId === 4 && columns.find((x) => x.title === "Jigs")?.show && (
            <GridColumn
              field="joinedJigNrs"
              title="Equipment"
              filterable={false}
              cell={(props) => {
                return <td>{props.dataItem.jigNrs.join(", ")}</td>;
              }}
              width={columsWidth}
            />
          )}
          {typeId === 3 && columns.find((x) => x.title === "Product Qc")?.show && (
            <GridColumn
              field="productQc"
              title="Weldment Qc"
              cell={(props) => {
                return <td>{props.dataItem.productQc ? "Yes" : "No"}</td>;
              }}
              filterCell={(props) => {
                return DropDownFilterBooleanCell({
                  ...props,
                  trueName: "Yes",
                  falseName: "No",
                  boolValue: productQcBool,
                  setBoolValue: setProductQcBool,
                });
              }}
              filterable
              sortable
              width={columsWidth}
            />
          )}
          {typeId === 1 &&
            columns.find((x) => x.title === "Qc Products")?.show && (
              <GridColumn
                field="joinedQcProdNames"
                title="Qc Products"
                cell={(props) => {
                  return (
                    <td>
                      {props.dataItem.qcProdNames &&
                        props.dataItem.qcProdNames.join(", ")}
                    </td>
                  );
                }}
                filterCell={(props) => {
                  return (
                    <div className="k-filtercell">
                      <DropDownList
                        {...props}
                        data={qcProds ? qcProds.map((p) => p.name) : []}
                        onChange={(event) => {
                          setSelectedQcProd(event.target.value);
                          setRequestState({ ...requestState, skip: 0 });
                        }}
                        value={selectedQcProd}
                      />
                      {selectedQcProd !== "" && (
                        <button
                          className="k-button k-button-icon"
                          title="Clear"
                          onClick={() => {
                            setSelectedQcProd("");
                            setRequestState({ ...requestState, skip: 0 });
                          }}
                        >
                          <span className="k-icon k-i-filter-clear" />
                        </button>
                      )}
                    </div>
                  );
                }}
                width={columsWidth}
              />
            )}
          {typeId === 1 &&
            columns.find((x) => x.title === "Attribute Values")?.show && (
              <GridColumn
                field="qcAttrValueNames"
                title="Attribute Values"
                sortable={false}
                cell={(props) => {
                  return (
                    <td>
                      {props.dataItem.qcAttrValues &&
                        props.dataItem.qcAttrValues
                          .filter((x) => !x.hide)
                          .map((x) => x.qcAttrTypeValueName)
                          .sort()
                          .join(", ")}
                    </td>
                  );
                }}
                filterCell={(props) => {
                  return DropDownMultiSelectFilterCell(
                    {
                      ...props,
                      dataItemKey: "id",
                      textField: "qcAttrTypeValueName",
                    } as GridFilterCellProps,
                    attrValues &&
                      attrValues.sort((a, b) =>
                        a.qcAttrTypeSort > b.qcAttrTypeSort ? 1 : -1
                      ),
                    selectedAttrValues,
                    setSelectedAttrValues,
                    requestState,
                    setRequestState
                  );
                }}
                width={columsWidth}
              />
            )}
          {typeId === 1 && columns.find((x) => x.title === "Station")?.show && (
            <GridColumn
              field="joinedStationNames"
              title="Stations"
              cell={(props) => {
                return (
                  <td className="truncate">
                    {props.dataItem.stationNames.join(", ")}
                  </td>
                );
              }}
              filterCell={(props) => {
                return DropDownFilterCell(
                  props,
                  stations ? stations.map((s) => s.name) : [],
                  (event) => {
                    setSelectedFilterStation(event.value);
                    setRequestState({ ...requestState, skip: 0 });
                  },
                  selectedFilterStation
                );
              }}
              width={columsWidth}
            />
          )}
          {typeId === 2 && columns.find((x) => x.title === "Tags")?.show && (
            <GridColumn
              field="tagNames"
              title="Tags"
              sortable={false}
              cell={(props) => {
                return (
                  <td>
                    {props.dataItem.tagCheckpoints
                      .map((x) => x.tagName)
                      .sort()
                      .join(", ")}
                  </td>
                );
              }}
              filterCell={(props) => {
                return DropDownMultiSelectFilterCell(
                  {
                    ...props,
                    dataItemKey: "id",
                    textField: "name",
                  } as GridFilterCellProps,
                  tags,
                  selectedTags,
                  setSelectedTags,
                  requestState,
                  setRequestState
                );
              }}
              width={columsWidth}
            />
          )}
          {columns.find((x) => x.title === "Attachments")?.show && (
            <GridColumn
              field="numberOfAttachments"
              title="Attachments"
              filter="numeric"
              width={columsWidth}
            />
          )}
          {typeId === 1 && columns.find((x) => x.title === "Training")?.show && (
            <GridColumn
              field="trainingRequired"
              title="Training"
              filterable={false}
              cell={(props) => {
                return (
                  <td>{props.dataItem.trainingRequired ? "Yes" : "No"}</td>
                );
              }}
              width={columsWidth}
            />
          )}
          {columns.find((x) => x.title === "Position")?.show && (
            <GridColumn
              field="sort"
              title="Position"
              filter="numeric"
              width={columsWidth}
            />
          )}
          {columns.find((x) => x.title === "Version")?.show && (
            <GridColumn
              field="version"
              title="Version"
              filter="numeric"
              width={columsWidth}
            />
          )}
        </Grid>
      </ExcelExport>
      <ButtonToolbar>
        <ButtonGroup>
          {changeOrderView && (
            <Button
              disabled={!dirty}
              variant="success"
              onClick={() => saveOrder()}
            >
              Save Order
            </Button>
          )}
          {jig !== undefined &&
            newQcCheckpoint !== undefined &&
            (isQcAdmin(currentUser) || isTTAdmin(currentUser)) && (
              <Button
                title="Export Excel"
                variant="primary"
                onClick={() => {
                  newQcCheckpoint();
                }}
              >
                New Checkpoint
              </Button>
            )}
          <Button
            title="Export Excel"
            variant="info"
            onClick={handleExportClick}
          >
            Export to Excel
          </Button>
          {exporting && (
            <FontAwesomeIcon
              icon={faSpinner}
              size="2x"
              style={{ float: "right" }}
              spin
            />
          )}
        </ButtonGroup>
      </ButtonToolbar>
      {changeOrderView && (
        <div>
          <SaveBottomBar
            saveTitle={"Save Order"}
            hideCancel={true}
            show={dirty}
            onSubmit={() => saveOrder()}
          />
          <Prompt
            when={dirty}
            message="Are you sure you don't want to save before exiting?"
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: IRootState) => ({
  currentUser: state.authReducer.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  editQcCheckpointsOrder: (
    checkpoints: IQcCheckpoint[],
    stationId?: number,
    tagId?: number
  ) => dispatch(editQcCheckpointsOrder(checkpoints, stationId, tagId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QcCheckpointGrid);
