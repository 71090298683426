import { IMachineQueryResponse } from "models/Salesforce/machinequeryresponse";
import { ICaseQueryResponse } from "../../models/Salesforce/casequeryresponse";
import { ICaseSearchResponse } from "../../models/Salesforce/casesearchresponse";
import { getRequest } from "./request";

export const SEARCH_SF_CASE_SUCCESS = "SEARCH_SF_CASE_SUCCESS";
export const SEARCH_SF_MACHINE_SUCCESS = "SEARCH_SF_MACHINE_SUCCESS";
export const FIND_SF_CASE_SUCCESS = "FIND_SF_CASE_SUCCESS";

export type SalesforceActions = {
  SEARCH_SF_CASE_SUCCESS: {
    type: typeof SEARCH_SF_CASE_SUCCESS;
    caseSearch: ICaseSearchResponse;
  };
  FIND_SF_CASE_SUCCESS: {
    type: typeof FIND_SF_CASE_SUCCESS;
    caseQuery: ICaseQueryResponse;
  };
  SEARCH_SF_MACHINE_SUCCESS: {
    type: typeof SEARCH_SF_MACHINE_SUCCESS;
    machineQuery: IMachineQueryResponse;
  };
};

export type SalesforceActionTypes =
  | SalesforceActions[typeof SEARCH_SF_CASE_SUCCESS]
  | SalesforceActions[typeof FIND_SF_CASE_SUCCESS]
  | SalesforceActions[typeof SEARCH_SF_MACHINE_SUCCESS];

export const actionCreators = {
  searchCaseSuccess: (
    caseSearch: ICaseSearchResponse
  ): SalesforceActions[typeof SEARCH_SF_CASE_SUCCESS] => ({
    type: SEARCH_SF_CASE_SUCCESS,
    caseSearch: caseSearch,
  }),
  findCaseSuccess: (
    caseQuery: ICaseQueryResponse
  ): SalesforceActions[typeof FIND_SF_CASE_SUCCESS] => ({
    type: FIND_SF_CASE_SUCCESS,
    caseQuery: caseQuery,
  }),
  searchMachineuccess: (
    machineQuery: IMachineQueryResponse
  ): SalesforceActions[typeof SEARCH_SF_MACHINE_SUCCESS] => ({
    type: SEARCH_SF_MACHINE_SUCCESS,
    machineQuery: machineQuery,
  }),
};

export function searchCase(search: string) {
  return async (dispatch) => {
    const { json } = await getRequest(
      "/salesforce/case/search/?search=" + search
    );
    return dispatch(actionCreators.searchCaseSuccess(json));
  };
}

export function searchMachine(chassisNumber: string) {
  return async (dispatch) => {
    const { json } = await getRequest(
      "/salesforce/machine/search/?chassisNumber=" + chassisNumber
    );
    return dispatch(actionCreators.searchMachineuccess(json));
  };
}

export function findCase(search: string) {
  return async (dispatch) => {
    const { json } = await getRequest(
      "/salesforce/case/find/?caseNumber=" + search
    );
    return dispatch(actionCreators.findCaseSuccess(json));
  };
}

export function redirectCase(caseNumber: string, newWindow: boolean = true) {
  getRequest("/salesforce/case/" + caseNumber).then((result) => {
    const json = result.json;

    if (newWindow) {
      const sfWindow = window.open(json.url, "_blank");
      sfWindow?.focus();
    } else {
      window.location.assign(json.url);
    }
  });
}
