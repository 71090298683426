import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";

export interface IProps {
  onCLick: () => any;
}

export const AddNewButton: React.FC<IProps> = (props) => {
  const { onCLick } = props;

  const mql = window.matchMedia("(max-width: 500px)");

  return (
    <Button
      className={(mql.matches ? "my-4 " : "my-3 ") + "me-4 ms-3 fixed-bottom"}
      variant="success"
      style={{ left: "auto", cursor: "pointer", borderRadius: "15px" }}
      onClick={() => onCLick()}
    >
      <Row>
        <Col>
          <FontAwesomeIcon icon={faPlus} />
        </Col>
        <Col className="ps-0">
          <h5 className="m-0">NEW</h5>
        </Col>
      </Row>
    </Button>
  );
};

export default AddNewButton;
