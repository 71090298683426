import React from 'react';
import { IUser } from '../../models/user';

export interface IAuthContextProps {
  user: IUser | null;
  isAuthenticated: boolean;
}

const AuthContext = React.createContext<IAuthContextProps>({
  user: null,
  isAuthenticated: false,
});

export default AuthContext;
