import {
  QcCheckpointActionTypes,
  FETCH_QC_CHECKPOINTS_SUCCESS,
  FETCH_QC_CHECKPOINT_SUCCESS,
  FETCH_FILTERED_QC_CHECKPOINTS_SUCCESS,
  CREATE_QC_CHECKPOINT_SUCCESS,
  EDIT_QC_CHECKPOINT_SUCCESS,
  DELETE_QC_CHECKPOINT_SUCCESS,
  EDIT_QC_CHECKPOINTS_ORDER_SUCCESS,
} from "../../actions/QualityControl/qcCheckpoint";
import { IQcCheckpoint } from "../../../models/QualityControl/qcCheckpoint";
import { DataList } from "../../../utils/DataList";

export interface QcCheckpointState {
  readonly qcCheckpoints: IQcCheckpoint[];
  readonly filteredQcCheckpoints: DataList<IQcCheckpoint>;
  readonly qcCheckpoint: IQcCheckpoint | null;
  readonly errors: any;
}

export const initialState: QcCheckpointState = {
  qcCheckpoints: [],
  filteredQcCheckpoints: { data: [], total: 0 },
  qcCheckpoint: null,
  errors: null,
};

const qcCheckpointReducer = (
  state = initialState,
  action: QcCheckpointActionTypes
): QcCheckpointState => {
  switch (action.type) {
    case FETCH_QC_CHECKPOINTS_SUCCESS:
      return { ...state, qcCheckpoints: action.qcCheckpoints };
    case FETCH_QC_CHECKPOINT_SUCCESS:
      return { ...state, qcCheckpoint: action.qcCheckpoint };
    case FETCH_FILTERED_QC_CHECKPOINTS_SUCCESS:
      return { ...state, filteredQcCheckpoints: action.qcCheckpoints };
    case CREATE_QC_CHECKPOINT_SUCCESS:
      return {
        ...state,
        qcCheckpoint: action.qcCheckpoint,
        qcCheckpoints:
          state.qcCheckpoints.length > 0
            ? [...state.qcCheckpoints.concat([action.qcCheckpoint])]
            : [],
      };
    case EDIT_QC_CHECKPOINTS_ORDER_SUCCESS:
      return { ...state, qcCheckpoints: action.qcCheckpoints };
    case EDIT_QC_CHECKPOINT_SUCCESS:
      if (state.qcCheckpoints.length > 0) {
        state.qcCheckpoints.splice(
          state.qcCheckpoints.findIndex((t) => t.id === action.qcCheckpoint.id),
          1,
          action.qcCheckpoint
        );
      }
      return {
        ...state,
        qcCheckpoint: action.qcCheckpoint,
        qcCheckpoints:
          state.qcCheckpoints.length > 0 ? state.qcCheckpoints : [],
      };
    case DELETE_QC_CHECKPOINT_SUCCESS:
      return { ...state, qcCheckpoint: null, qcCheckpoints: [] };
    default:
      return state;
  }
};

export default qcCheckpointReducer;
