import {
  faCircle,
  faEye,
  faFlagCheckered,
  faLink,
  faListAlt,
  faMapMarkerAlt,
  faPen,
  faTag,
  faUnlink,
  faUser,
  faUserPlus,
  faUsers,
  faUserSlash,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";

enum AuditAction {
  NEW,
  EDIT,
  ADDED,
  REMOVED,
}

enum AuditTypes {
  ISSUE,
  GROUP,
  JIGS,
  USER,
  PRODUCT,
  CHASSIS,
  ISSUEMESSAGE,
  ORDER,
  TAG,
  SFCASE,
  INSPECTION,
  STRING,
}

export interface IAuditGroup {
  latestDate: Date;
  entries: IAuditEntry[];
  actionGUID: string;
}

export interface IAuditEntry {
  objectType: string;
  objectId: number;
  action: string;
  field: string;
  oldValue: string;
  value: string;
  editedBy: number;
  editedByName: string;
  dateTime: Date;
  actionGUID: string;
}

interface IAuditIcon {
  requirement: IAuditRequirement;
  icon: IconDefinition;
}

interface IAuditColor {
  requirement: IAuditRequirement;
  color: string;
}

interface IAuditRequirement {
  objectType?: AuditTypes;
  action?: AuditAction;
  field?: string;
  value?: string;
}

const requirementCheck = (entry: IAuditEntry, req: IAuditRequirement) => {
  var otReq = req.objectType === undefined;
  var actionReq = req.action === undefined;
  var fieldReq = req.field === undefined;
  var valueReq = req.value === undefined;

  if (!otReq) otReq = AuditTypes[entry.objectType] === req.objectType;
  if (!actionReq) actionReq = AuditAction[entry.action] === req.action;
  if (!fieldReq) fieldReq = entry.field === req.field;
  if (!valueReq) valueReq = entry.value === req.value;

  return otReq && actionReq && fieldReq && valueReq;
};

export const auditRequirement = (
  objectType: AuditTypes,
  action: AuditAction,
  field: string,
  value?: string
): IAuditRequirement => {
  return { objectType, action, field, value };
};

export const getAuditIcon = (entry: IAuditEntry): IconDefinition => {
  var icon = AuditIcons.find((i) => requirementCheck(entry, i.requirement));
  return icon?.icon ?? faPen;
};

export const getAuditColor = (entry: IAuditEntry): string => {
  var color = AuditColors.find((i) => requirementCheck(entry, i.requirement));
  return color?.color ?? "gray";
};

export const AuditIcons: IAuditIcon[] = [
  {
    requirement: auditRequirement(
      AuditTypes.ISSUE,
      AuditAction.EDIT,
      "status",
      "Open"
    ),
    icon: faCircle,
  },
  {
    requirement: auditRequirement(
      AuditTypes.ISSUE,
      AuditAction.EDIT,
      "status",
      "Closed"
    ),
    icon: faFlagCheckered,
  },
  {
    requirement: auditRequirement(
      AuditTypes.ISSUE,
      AuditAction.EDIT,
      "priority"
    ),
    icon: faTag,
  },
  {
    requirement: auditRequirement(AuditTypes.ISSUE, AuditAction.EDIT, "group"),
    icon: faUsers,
  },
  {
    requirement: auditRequirement(
      AuditTypes.ISSUE,
      AuditAction.EDIT,
      "responsible"
    ),
    icon: faUser,
  },
  {
    requirement: auditRequirement(AuditTypes.ISSUE, AuditAction.EDIT, "title"),
    icon: faPen,
  },
  {
    requirement: auditRequirement(AuditTypes.ISSUE, AuditAction.EDIT, "type"),
    icon: faListAlt,
  },

  {
    requirement: auditRequirement(
      AuditTypes.ISSUE,
      AuditAction.ADDED,
      "products"
    ),
    icon: faLink,
  },
  {
    requirement: auditRequirement(
      AuditTypes.ISSUE,
      AuditAction.REMOVED,
      "products"
    ),
    icon: faUnlink,
  },

  {
    requirement: auditRequirement(
      AuditTypes.ISSUE,
      AuditAction.ADDED,
      "orders"
    ),
    icon: faLink,
  },
  {
    requirement: auditRequirement(
      AuditTypes.ISSUE,
      AuditAction.REMOVED,
      "orders"
    ),
    icon: faUnlink,
  },

  {
    requirement: auditRequirement(
      AuditTypes.ISSUE,
      AuditAction.ADDED,
      "chassis"
    ),
    icon: faLink,
  },
  {
    requirement: auditRequirement(
      AuditTypes.ISSUE,
      AuditAction.REMOVED,
      "chassis"
    ),
    icon: faUnlink,
  },

  {
    requirement: auditRequirement(
      AuditTypes.ISSUE,
      AuditAction.ADDED,
      "issues"
    ),
    icon: faLink,
  },
  {
    requirement: auditRequirement(
      AuditTypes.ISSUE,
      AuditAction.REMOVED,
      "chassis"
    ),
    icon: faUnlink,
  },

  {
    requirement: auditRequirement(AuditTypes.ISSUE, AuditAction.ADDED, "tags"),
    icon: faLink,
  },
  {
    requirement: auditRequirement(
      AuditTypes.ISSUE,
      AuditAction.REMOVED,
      "tags"
    ),
    icon: faUnlink,
  },

  {
    requirement: auditRequirement(
      AuditTypes.JIGS,
      AuditAction.ADDED,
      "products"
    ),
    icon: faLink,
  },
  {
    requirement: auditRequirement(
      AuditTypes.ISSUE,
      AuditAction.REMOVED,
      "products"
    ),
    icon: faUnlink,
  },

  {
    requirement: auditRequirement(
      AuditTypes.JIGS,
      AuditAction.EDIT,
      "location"
    ),
    icon: faMapMarkerAlt,
  },
  {
    requirement: auditRequirement(AuditTypes.JIGS, AuditAction.EDIT, "hidden"),
    icon: faEye,
  },

  {
    requirement: auditRequirement(
      AuditTypes.GROUP,
      AuditAction.EDIT,
      "responsible"
    ),
    icon: faUser,
  },
  {
    requirement: auditRequirement(
      AuditTypes.GROUP,
      AuditAction.EDIT,
      "priority"
    ),
    icon: faTag,
  },
  {
    requirement: auditRequirement(
      AuditTypes.GROUP,
      AuditAction.ADDED,
      "members"
    ),
    icon: faUserPlus,
  },
  {
    requirement: auditRequirement(
      AuditTypes.GROUP,
      AuditAction.REMOVED,
      "members"
    ),
    icon: faUserSlash,
  },
];

export const AuditColors: IAuditColor[] = [
  {
    requirement: auditRequirement(
      AuditTypes.ISSUE,
      AuditAction.EDIT,
      "status",
      "Open"
    ),
    color: "green",
  },
  {
    requirement: auditRequirement(
      AuditTypes.ISSUE,
      AuditAction.EDIT,
      "status",
      "Closed"
    ),
    color: "red",
  },
];
