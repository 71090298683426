import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import { IProduct, getProductLanguage } from "../../models/product";
import { IIssue, IIssueSfCase } from "models/issue";
import { IJig } from "models/jig";
import { IOrder } from "models/order";
import { IQcChassi } from "models/QualityControl/qcChassis";
import { isInternal, isKitter, isQcAdmin, IUser } from "models/user";
import { redirectCase } from "store/actions/salesforce";
import { Paths } from "routes";
import Aux from "utils/auxiliary";

export interface IProps {
  issueClone: IIssue;
  currentUser: IUser;
  history: any;
  handleItemRemoved: (item: any) => void;
}

const TaggedIssueItems: React.FC<IProps> = (props) => {
  const { issueClone, currentUser, history, handleItemRemoved } = props;

  const handleProductClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    product: IProduct
  ) => {
    event.preventDefault();
    history.push(Paths.gProductsId(product.productNo));
  };
  const handleChassisClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    chassis: IQcChassi
  ) => {
    event.preventDefault();
    if (isQcAdmin(currentUser)) {
      history.push(Paths.qc.gChassisId(chassis.id));
    } else {
      history.push(Paths.qc.gMachineSpecId(chassis.id));
    }
  };
  const handleRelIssueClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    relIssue: IIssue
  ) => {
    event.preventDefault();
    history.push(Paths.gIssuesId(relIssue.id));
  };
  const handleOrderClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    order: IOrder
  ) => {
    event.preventDefault();
    history.push(Paths.gOrderId(order.orderNo));
  };
  const handleJigClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    jig: IJig | undefined
  ) => {
    event.preventDefault();
    if (jig !== undefined) {
      history.push(Paths.gEquipmentsId(jig.id));
    }
  };

  const handleSfCaseClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    sfCase: IIssueSfCase
  ) => {
    event.preventDefault();
    redirectCase(sfCase.caseNumber.toString());
  };

  return (
    <Aux>
      {issueClone.products &&
        issueClone.products.map((p, i) => (
          <Button
            variant="outline-light"
            size="sm"
            title={getProductLanguage(currentUser, p)}
            className="pt-1 me-1 mb-3"
            key={"product" + p.productNo + i}
          >
            {isInternal(currentUser) ? (
              <div>
                <a
                  href={"/products/" + p.productNo}
                  onClick={(e) => handleProductClick(e, p)}
                  style={{ textDecoration: "none" }}
                  title={getProductLanguage(currentUser, p)}
                >
                  #{p.productNo}{" "}
                </a>
                <span
                  className="k-icon k-i-close k-i-x ms-1"
                  onClick={() => {
                    handleItemRemoved(p);
                  }}
                />
              </div>
            ) : (
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id="tooltip">
                    You don't have access to this resource
                  </Tooltip>
                }
              >
                <span className="text-success">#{p.productNo} </span>
              </OverlayTrigger>
            )}
          </Button>
        ))}
      {issueClone.mainCauseParts &&
        issueClone.mainCauseParts.map((p, i) => (
          <Button
            variant="outline-light"
            size="sm"
            title={getProductLanguage(currentUser, p)}
            className="pt-1 me-1 mb-3"
            key={"product" + p.productNo + i}
          >
            {isInternal(currentUser) ? (
              <div>
                <a
                  href={"/products/" + p.productNo}
                  onClick={(e) => handleProductClick(e, p)}
                  style={{ textDecoration: "none" }}
                  title={getProductLanguage(currentUser, p)}
                >
                  €{p.productNo}{" "}
                </a>
                <span
                  className="k-icon k-i-close k-i-x ms-1"
                  onClick={() => {
                    handleItemRemoved(p);
                  }}
                />
              </div>
            ) : (
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id="tooltip">
                    You don't have access to this resource
                  </Tooltip>
                }
              >
                <span className="text-success">€{p.productNo} </span>
              </OverlayTrigger>
            )}
          </Button>
        ))}
      {issueClone.qcChassis &&
        issueClone.qcChassis.map((c) => (
          <Button
            variant="outline-light"
            size="sm"
            title={c.no}
            className="pt-1 me-1 mb-3"
            key={"chassis" + c.id}
            disabled={!isQcAdmin(currentUser)}
          >
            {isQcAdmin(currentUser) ? (
              <a
                href={"/qc/qcChassis/" + c.id}
                onClick={(e) => handleChassisClick(e, c)}
                style={{ textDecoration: "none" }}
                title={c.no}
              >
                %{c.no}{" "}
              </a>
            ) : isInternal(currentUser) ? (
              <a
                href={"/qc/machinespecs/" + c.id}
                onClick={(e) => handleChassisClick(e, c)}
                style={{ textDecoration: "none" }}
                title={c.no}
              >
                %{c.no}{" "}
              </a>
            ) : (
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id="tooltip">
                    You don't have access to this resource
                  </Tooltip>
                }
              >
                <span className="text-success">%{c.no} </span>
              </OverlayTrigger>
            )}

            {isInternal(currentUser) && (
              <span
                className="k-icon k-i-close k-i-x ms-1"
                onClick={() => {
                  handleItemRemoved(c);
                }}
              />
            )}
          </Button>
        ))}
      {issueClone.relIssues &&
        issueClone.relIssues.map((ri) => (
          <Button
            variant="outline-light"
            size="sm"
            title={ri.id.toString()}
            className="pt-1 me-1 mb-3"
            key={"issue" + ri.id}
            disabled={!isInternal(currentUser)}
          >
            {isInternal(currentUser) ? (
              <a
                href={"/issues/" + ri.id}
                onClick={(e) => handleRelIssueClick(e, ri)}
                style={{ textDecoration: "none" }}
                title={ri.id.toString()}
              >
                &{ri.id}{" "}
              </a>
            ) : (
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id="tooltip">
                    You don't have access to this resource
                  </Tooltip>
                }
              >
                <span className="text-success"> &{ri.id} </span>
              </OverlayTrigger>
            )}

            {isInternal(currentUser) && (
              <span
                className="k-icon k-i-close k-i-x ms-1"
                onClick={() => {
                  handleItemRemoved(ri);
                }}
              />
            )}
          </Button>
        ))}
      {issueClone.issues &&
        issueClone.issues.map((i) => (
          <Button
            variant="outline-light"
            size="sm"
            title={i.id.toString()}
            className="pt-1 me-1 mb-3"
            key={"issue" + i.id}
            disabled={!isInternal(currentUser)}
          >
            <a
              href={"/issues/" + i.id}
              onClick={(e) => handleRelIssueClick(e, i)}
              style={{ textDecoration: "none" }}
              title={i.id.toString()}
            >
              &{i.id}{" "}
            </a>
          </Button>
        ))}
      {issueClone.orders &&
        issueClone.orders.map((o) => (
          <Button
            variant="outline-light"
            size="sm"
            title={o.orderNo.toString()}
            className="pt-1 me-1 mb-3"
            key={"order" + o.orderNo}
            disabled={!isInternal(currentUser) && !isKitter(currentUser)}
          >
            {isInternal(currentUser) || isKitter(currentUser) ? (
              <a
                href={"/orders/" + o.orderNo}
                onClick={(e) => handleOrderClick(e, o)}
                style={{ textDecoration: "none" }}
                title={o.orderNo.toString()}
              >
                ${o.orderNo}{" "}
              </a>
            ) : (
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id="tooltip">
                    You don't have access to this resource
                  </Tooltip>
                }
              >
                <span className="text-success"> ${o.orderNo} </span>
              </OverlayTrigger>
            )}

            {isInternal(currentUser) && (
              <span
                className="k-icon k-i-close k-i-x ms-1"
                onClick={() => {
                  handleItemRemoved(o);
                }}
              />
            )}
          </Button>
        ))}
      {issueClone.jigs &&
        issueClone.jigs.map((j) => (
          <Button
            variant="outline-light"
            size="sm"
            title={j.jignr.toString()}
            className="pt-1 me-1 mb-3"
            key={"jig" + j.jignr}
            disabled={!isInternal(currentUser)}
          >
            {isInternal(currentUser) ? (
              <a
                href={"/equipments/" + j.jignr}
                onClick={(e) => handleJigClick(e, j)}
                style={{ textDecoration: "none" }}
                title={j.jignr.toString()}
              >
                £{j.jignr}{" "}
              </a>
            ) : (
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id="tooltip">
                    You don't have access to this resource
                  </Tooltip>
                }
              >
                <span className="text-success"> £{j.jignr} </span>
              </OverlayTrigger>
            )}

            {isInternal(currentUser) && (
              <span
                className="k-icon k-i-close k-i-x ms-1"
                onClick={() => {
                  handleItemRemoved(j);
                }}
              />
            )}
          </Button>
        ))}
      {issueClone.jig && (
        <Button
          variant="outline-light"
          size="sm"
          title={issueClone.jig.id.toString()}
          className="pt-1 me-1 mb-3"
          key={"jig" + issueClone.jig.id}
          disabled={!isInternal(currentUser)}
        >
          {isInternal(currentUser) ? (
            <a
              href={"/equipments/" + issueClone.jig.jignr}
              onClick={(e) => handleJigClick(e, issueClone.jig)}
              style={{ textDecoration: "none" }}
              title={issueClone.jig.jignr.toString()}
            >
              {issueClone.jig.jignr}{" "}
            </a>
          ) : (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="tooltip">
                  You don't have access to this resource
                </Tooltip>
              }
            >
              <span className="text-success"> {issueClone.jig.jignr} </span>
            </OverlayTrigger>
          )}
        </Button>
      )}
      {issueClone.salesforceCases &&
        issueClone.salesforceCases.map((sf) => (
          <Button
            variant="outline-light"
            size="sm"
            title={sf.caseNumber.toString()}
            className="pt-1 me-1 mb-3"
            key={"sfcase" + sf.caseNumber}
            disabled={!isInternal(currentUser)}
          >
            {isInternal(currentUser) ? (
              <a
                href={"/salesforce/case/" + sf.caseNumber}
                onClick={(e) => handleSfCaseClick(e, sf)}
                style={{ textDecoration: "none" }}
                title={sf.caseNumber.toString()}
              >
                *{sf.caseNumber}{" "}
              </a>
            ) : (
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id="tooltip">
                    You don't have access to this resource
                  </Tooltip>
                }
              >
                <span className="text-success"> ${sf.caseNumber} </span>
              </OverlayTrigger>
            )}

            {isInternal(currentUser) && (
              <span
                className="k-icon k-i-close k-i-x ms-1"
                onClick={() => {
                  handleItemRemoved(sf);
                }}
              />
            )}
          </Button>
        ))}
    </Aux>
  );
};

export default TaggedIssueItems;
