import {
  QcStatusActionTypes,
  FETCH_QC_STATUSES_SUCCESS,
  FETCH_QC_STATUS_SUCCESS,
  FETCH_FILTERED_QC_STATUSES_SUCCESS,
  CREATE_QC_STATUS_SUCCESS,
  EDIT_QC_STATUS_SUCCESS,
  DELETE_QC_STATUS_SUCCESS,
} from "../../actions/QualityControl/qcStatus";
import { IQcStatus } from "../../../models/QualityControl/qcStatus";
import { DataList } from "../../../utils/DataList";

export interface QcStatusState {
  readonly qcStatuses: IQcStatus[];
  readonly filteredQcStatuses: DataList<IQcStatus>;
  readonly qcStatus: IQcStatus | null;
  readonly errors: any;
}

export const initialState: QcStatusState = {
  qcStatuses: [],
  filteredQcStatuses: { data: [], total: 0 },
  qcStatus: null,
  errors: null,
};

const qcStatusReducer = (
  state = initialState,
  action: QcStatusActionTypes
): QcStatusState => {
  switch (action.type) {
    case FETCH_QC_STATUSES_SUCCESS:
      return { ...state, qcStatuses: action.qcStatuses };
    case FETCH_QC_STATUS_SUCCESS:
      return { ...state, qcStatus: action.qcStatus };
    case FETCH_FILTERED_QC_STATUSES_SUCCESS:
      return { ...state, filteredQcStatuses: action.qcStatuses };
    case CREATE_QC_STATUS_SUCCESS:
      return {
        ...state,
        qcStatus: action.qcStatus,
        qcStatuses:
          state.qcStatuses.length > 0
            ? [...state.qcStatuses.concat([action.qcStatus])]
            : [],
      };
    case EDIT_QC_STATUS_SUCCESS:
      if (state.qcStatuses.length > 0) {
        state.qcStatuses.splice(
          state.qcStatuses.findIndex((t) => t.id === action.qcStatus.id),
          1,
          action.qcStatus
        );
      }
      return {
        ...state,
        qcStatus: action.qcStatus,
        qcStatuses: state.qcStatuses.length > 0 ? state.qcStatuses : [],
      };
    case DELETE_QC_STATUS_SUCCESS:
      return { ...state, qcStatus: null, qcStatuses: [] };
    default:
      return state;
  }
};

export default qcStatusReducer;
