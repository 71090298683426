import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en_json from "./en.json";
import nb_json from "./nb.json";
import { store } from "../index";

export const supportedLanguages = [
  {
    lng: "en",
    image: "en",
    name: "English",
    englishName: "English",
    shortName: "EN",
  },
  {
    lng: "no",
    image: "no",
    name: "Norsk",
    englishName: "Norwegian",
    shortName: "NO",
  },
];

export const initI18Next = () => {
  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: en_json,
      },
      no: {
        translation: nb_json,
      },
    },
    supportedLngs: supportedLanguages.map((x) => x.lng),
    lng: localStorage.getItem("language") ?? "en",
    fallbackLng: "en",
  });

  i18n.services.formatter?.add("qcstatus", (value, lng, options) => {
    var qcStatuses = store.getState().qcStatusReducer.qcStatuses;
    var qcStatusNum = parseInt(value);
    if (qcStatusNum === 1) {
      return "pending";
    }
    if (qcStatusNum >= 0 && qcStatuses.length > 0) {
      return qcStatuses
        .find((status) => status.id === qcStatusNum)
        ?.name?.toLowerCase() ?? value;
    } else if (qcStatusNum >= 0) {
      if (qcStatusNum === 0) {
        return "pending";
      }
      if (qcStatusNum === 2) {
        return "approved";
      }
    }
    return value;
  });

  i18n.services.formatter?.add("orderstatus", (value, lng, options) => {
    var orderStatuses = store.getState().orderStatusReducer.orderStatuses;
    var orderStatusNum = parseInt(value);
    if (orderStatusNum >= 0 && orderStatuses.length > 0) {
      return (
        orderStatuses
          .find((status) => status.id === orderStatusNum)
          ?.name?.toLowerCase() ?? value
      );
    }
    return value;
  });

  i18n.services.formatter?.add("priority", (value, lng, options) => {
    var priorities = store.getState().prioritiesReducer.priorities;
    var prioNum = parseInt(value);
    if (prioNum >= 0) {
      return (
        priorities
          .find((priority) => priority.id === prioNum)
          ?.name?.toLowerCase() ?? value
      );
    }
    return value;
  });

  i18n.services.formatter?.add("qcprod", (value, lng, options) => {
    var qcProds = store.getState().qcProdReducer.qcProds;
    var prodNum = parseInt(value);
    if (prodNum >= 0) {
      return (
        qcProds.find((qcprod) => qcprod.id === prodNum)?.name?.toLowerCase() ??
        value
      );
    }
    return value;
  });

  i18n.services.formatter?.add("tags", (value, lng, options) => {
    var tags = store.getState().tagReducer.tags;
    var tagNum = parseInt(value);
    if (tagNum >= 0) {
      return (
        tags.find((tag) => tag.id === tagNum)?.name?.toLowerCase() ?? value
      );
    }
    return value;
  });
};
