import {
  QcAttrValueActionTypes,
  FETCH_QC_ATTR_VALUES_SUCCESS,
  FETCH_QC_ATTR_VALUE_SUCCESS,
  FETCH_FILTERED_QC_ATTR_VALUES_SUCCESS,
  CREATE_QC_ATTR_VALUE_SUCCESS,
  EDIT_QC_ATTR_VALUE_SUCCESS,
  DELETE_QC_ATTR_VALUE_SUCCESS,
} from "../../actions/QualityControl/qcAttrValue";
import { IQcAttrValue } from "../../../models/QualityControl/qcAttrValue";
import { DataList } from "../../../utils/DataList";

export interface QcAttrValueState {
  readonly qcAttrValues: IQcAttrValue[];
  readonly filteredQcAttrValues: DataList<IQcAttrValue>;
  readonly qcAttrValue: IQcAttrValue | null;
  readonly errors: any;
}

export const initialState: QcAttrValueState = {
  qcAttrValues: [],
  filteredQcAttrValues: { data: [], total: 0 },
  qcAttrValue: null,
  errors: null,
};

const qcAttrValueReducer = (
  state = initialState,
  action: QcAttrValueActionTypes
): QcAttrValueState => {
  switch (action.type) {
    case FETCH_QC_ATTR_VALUES_SUCCESS:
      return { ...state, qcAttrValues: action.qcAttrValues };
    case FETCH_QC_ATTR_VALUE_SUCCESS:
      return { ...state, qcAttrValue: action.qcAttrValue };
    case FETCH_FILTERED_QC_ATTR_VALUES_SUCCESS:
      return { ...state, filteredQcAttrValues: action.qcAttrValues };
    case CREATE_QC_ATTR_VALUE_SUCCESS:
      return {
        ...state,
        qcAttrValue: action.qcAttrValue,
        qcAttrValues:
          state.qcAttrValues.length > 0
            ? [...state.qcAttrValues.concat([action.qcAttrValue])]
            : [],
      };
    case EDIT_QC_ATTR_VALUE_SUCCESS:
      if (state.qcAttrValues.length > 0) {
        state.qcAttrValues.splice(
          state.qcAttrValues.findIndex((t) => t.id === action.qcAttrValue.id),
          1,
          action.qcAttrValue
        );
      }
      return {
        ...state,
        qcAttrValue: action.qcAttrValue,
        qcAttrValues: state.qcAttrValues.length > 0 ? state.qcAttrValues : [],
      };
    case DELETE_QC_ATTR_VALUE_SUCCESS:
      return { ...state, qcAttrValue: null, qcAttrValues: [] };
    default:
      return state;
  }
};

export default qcAttrValueReducer;
