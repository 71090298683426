import { History } from "history";
import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { logoutOidc } from "../store/actions/auth";
import { IRootState } from "../store/index";

export interface IProps {
  logoutOidc: () => Promise<any>;
  history: History;
}

const LogoutContainer: React.FC<IProps> = (props) => {
  const { logoutOidc } = props;

  const logout = useCallback(logoutOidc, [logoutOidc]);

  useEffect(() => {
    logout();
  }, [logout]);
  return null;
};

const mapStateToProps = (state: IRootState) => ({
  navigationActive: state.uiReducer.navigationActive,
});

export default connect(mapStateToProps, {
  logoutOidc,
})(LogoutContainer);
