import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  requestBegin,
  requestEnd,
} from "../request";
import { IQcStatus } from "../../../models/QualityControl/qcStatus";
import { Dispatch } from "redux";
import { DataResult } from "@progress/kendo-data-query";
import { deleteSuccess, saveFailed, saveSuccess } from "../alerts";

export const FETCH_QC_STATUSES_SUCCESS = "FETCH_QC_STATUSES_SUCCESS";
export const FETCH_QC_STATUS_SUCCESS = "FETCH_QC_STATUS_SUCCESS";
export const FETCH_FILTERED_QC_STATUSES_SUCCESS =
  "FETCH_FILTERED_QC_STATUSES_SUCCESS";
export const CREATE_QC_STATUS_SUCCESS = "CREATE_QC_STATUS_SUCCESS";
export const EDIT_QC_STATUS_SUCCESS = "EDIT_QC_STATUS_SUCCESS";
export const DELETE_QC_STATUS_SUCCESS = "DELETE_QC_STATUS_SUCCESS";
export const QC_STATUS_REQUEST_FAILURE = "QC_STATUS_REQUEST_FAILURE";
export const QC_STATUS_REQUEST_ERROR = "QC_STATUS_REQUEST_ERROR";

export interface QcStatusActions {
  FETCH_QC_STATUSES_SUCCESS: {
    type: typeof FETCH_QC_STATUSES_SUCCESS;
    qcStatuses: IQcStatus[];
  };
  FETCH_QC_STATUS_SUCCESS: {
    type: typeof FETCH_QC_STATUS_SUCCESS;
    qcStatus: IQcStatus;
  };
  FETCH_FILTERED_QC_STATUSES_SUCCESS: {
    type: typeof FETCH_FILTERED_QC_STATUSES_SUCCESS;
    qcStatuses: DataResult;
  };
  CREATE_QC_STATUS_SUCCESS: {
    type: typeof CREATE_QC_STATUS_SUCCESS;
    qcStatus: IQcStatus;
  };
  EDIT_QC_STATUS_SUCCESS: {
    type: typeof EDIT_QC_STATUS_SUCCESS;
    qcStatus: IQcStatus;
  };
  DELETE_QC_STATUS_SUCCESS: {
    type: typeof DELETE_QC_STATUS_SUCCESS;
  };
  QC_STATUS_REQUEST_FAILURE: {
    type: typeof QC_STATUS_REQUEST_FAILURE;
    errors: any;
  };
  QC_STATUS_REQUEST_ERROR: {
    type: typeof QC_STATUS_REQUEST_ERROR;
    errors: any;
  };
}

export type QcStatusActionTypes =
  | QcStatusActions[typeof FETCH_QC_STATUSES_SUCCESS]
  | QcStatusActions[typeof FETCH_QC_STATUS_SUCCESS]
  | QcStatusActions[typeof FETCH_FILTERED_QC_STATUSES_SUCCESS]
  | QcStatusActions[typeof CREATE_QC_STATUS_SUCCESS]
  | QcStatusActions[typeof EDIT_QC_STATUS_SUCCESS]
  | QcStatusActions[typeof DELETE_QC_STATUS_SUCCESS]
  | QcStatusActions[typeof QC_STATUS_REQUEST_FAILURE]
  | QcStatusActions[typeof QC_STATUS_REQUEST_ERROR];

export const actionCreators = {
  fetchQcStatusesSuccess: (
    qcStatuses: IQcStatus[]
  ): QcStatusActions[typeof FETCH_QC_STATUSES_SUCCESS] => ({
    type: FETCH_QC_STATUSES_SUCCESS,
    qcStatuses: qcStatuses,
  }),
  fetchQcStatusSuccess: (
    qcStatus: IQcStatus
  ): QcStatusActions[typeof FETCH_QC_STATUS_SUCCESS] => ({
    type: FETCH_QC_STATUS_SUCCESS,
    qcStatus: qcStatus,
  }),
  fetchFilteredQcStatusesSuccess: (
    qcStatuses: DataResult
  ): QcStatusActions[typeof FETCH_FILTERED_QC_STATUSES_SUCCESS] => ({
    type: FETCH_FILTERED_QC_STATUSES_SUCCESS,
    qcStatuses: qcStatuses,
  }),
  createQcStatusSuccess: (
    qcStatus: IQcStatus
  ): QcStatusActions[typeof CREATE_QC_STATUS_SUCCESS] => ({
    type: CREATE_QC_STATUS_SUCCESS,
    qcStatus: qcStatus,
  }),
  editQcStatusSuccess: (
    qcStatus: IQcStatus
  ): QcStatusActions[typeof EDIT_QC_STATUS_SUCCESS] => ({
    type: EDIT_QC_STATUS_SUCCESS,
    qcStatus: qcStatus,
  }),
  deleteQcStatusSuccess: (): QcStatusActions[typeof DELETE_QC_STATUS_SUCCESS] => ({
    type: DELETE_QC_STATUS_SUCCESS,
  }),
  qcStatusRequestFailure: (
    status: number
  ): QcStatusActions[typeof QC_STATUS_REQUEST_FAILURE] => ({
    type: QC_STATUS_REQUEST_FAILURE,
    errors: `Something went wrong, status code ${status}`,
  }),
  qcStatusRequestError: (
    errors: any
  ): QcStatusActions[typeof QC_STATUS_REQUEST_ERROR] => ({
    type: QC_STATUS_REQUEST_ERROR,
    errors: errors,
  }),
};

export function fetchQcStatuses() {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_QC_STATUSES_SUCCESS));
    const { status, json } = await getRequest("/qcStatuses");
    dispatch(requestEnd(FETCH_QC_STATUSES_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchQcStatusesSuccess(json))
      : dispatch(actionCreators.qcStatusRequestFailure(status));
  };
}

export const fetchQcStatus = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await getRequest(`/qcStatuses/${id}`);
    return status === 200
      ? dispatch(actionCreators.fetchQcStatusSuccess(json))
      : dispatch(actionCreators.qcStatusRequestFailure(status));
  };
};

export function fetchFilteredQcStatuses(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FETCH_FILTERED_QC_STATUSES_SUCCESS));
    const { status, json } = await getRequest(
      `/qcStatuses/filtered?${queryStr}`
    );
    dispatch(requestEnd(FETCH_FILTERED_QC_STATUSES_SUCCESS));
    return status === 200
      ? dispatch(actionCreators.fetchFilteredQcStatusesSuccess(json))
      : dispatch(actionCreators.qcStatusRequestFailure(status));
  };
}

export const createQcStatus = (qcStatus: IQcStatus) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await postRequest("/qcStatuses", qcStatus);
    switch (status) {
      case 200:
        dispatch(saveSuccess(qcStatus.name));
        return dispatch(actionCreators.createQcStatusSuccess(json));
      case 400:
        return dispatch(actionCreators.qcStatusRequestError(json));
      default:
        dispatch(saveFailed(qcStatus.name));
        return dispatch(actionCreators.qcStatusRequestFailure(status));
    }
  };
};

export const editQcStatus = (id: number, qcStatus: IQcStatus) => {
  return async (dispatch: Dispatch) => {
    const { status, json } = await putRequest(`/qcStatuses/${id}`, qcStatus);
    switch (status) {
      case 200:
        dispatch(saveSuccess(qcStatus.name));
        return dispatch(actionCreators.editQcStatusSuccess(json));
      case 400:
        dispatch(saveFailed(qcStatus.name));
        return dispatch(actionCreators.qcStatusRequestError(json));
      default:
        dispatch(saveFailed(qcStatus.name));
        return dispatch(actionCreators.qcStatusRequestFailure(status));
    }
  };
};

export const deleteQcStatus = (id: number, qcStatus: IQcStatus) => {
  return async (dispatch: Dispatch) => {
    const { status } = await deleteRequest(`/qcStatuses/${id}`, qcStatus);
    if (status === 200) {
      dispatch(deleteSuccess(qcStatus.name));
      return dispatch(actionCreators.deleteQcStatusSuccess());
    }
    dispatch(saveFailed(qcStatus.name));
    return dispatch(actionCreators.qcStatusRequestFailure(status));
  };
};
