import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  GridColumn,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { PDFExport } from "@progress/kendo-react-pdf";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import i18next from "i18next";

import { IOrder } from "models/order";
import { isPurchaser, IUser } from "models/user";
import { IProduct } from "models/product";
import { IProductImage } from "models/image";
import { IOrderLine } from "models/orderline";
import { CreateIssueModal } from "components/QualityControl/QcChecklist/CreateIssueModal";
import ProductImageModal from "components/Products/ProductImageModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { dateTimeFormatDay } from "utils/dateTimeFormat";

export interface IProps {
  order: IOrder;
  orderLines: IOrderLine[];
  newProducts: IProduct[];
  newProdLoading: boolean;
  currentUser: IUser;
  history: any;
  filesLoading: boolean;
  downloadZip: (orderNo: number, newFiles: boolean) => any;
  sendEmail: () => any;
}

const initialSort: Array<SortDescriptor> = [{ field: "lineNo", dir: "desc" }];

export const OrderImagesForm: React.FC<IProps> = (props) => {
  const {
    order,
    orderLines,
    newProducts,
    history,
    currentUser,
    newProdLoading,
    filesLoading,
    downloadZip,
    sendEmail,
  } = props;

  const [caseOrderNo, setCaseOrderNo] = useState<number | null>(null);
  const [sort, setSort] = useState(initialSort);
  const [noImgProds, setNoImgProds] = useState<IProduct[]>([]);
  const [modalImage, setModalImage] = useState<IProductImage>();
  const [images, setImages] = useState<IProductImage[]>([]);
  const [imageModalShow, setImageModalShow] = useState<boolean>(false);
  const [exportState, setExportState] = useState<ExcelExport>();
  const [exporting, setExportingState] = useState<boolean>();
  const mql = window.matchMedia("(min-width: 1765px)");

  const { t } = useTranslation();

  useEffect(() => {
    let productImages: IProductImage[] = [];
    orderLines.forEach((ol) => {
      let lineImages = ol.product.images.filter(
        (i: IProductImage) => i.revision === ol.productRev
      );
      lineImages.forEach((li) => productImages.push(li));
    });
    setImages(productImages);
  }, [orderLines]);

  const handleMissingImagesCase = () => {
    if (order !== undefined) {
      const noImgProduct = orderLines
        .filter((ol) => ol.product.images.length === 0)
        .map((ol) => {
          return ol.product;
        });
      setNoImgProds(noImgProduct);
    }
    setCaseOrderNo(order.orderNo);
  };

  let totalOrderPrice = orderLines.reduce(
    (sum, { totalPrice }: { totalPrice: number }) => sum + totalPrice,
    0
  );

  let allOrderlinesFin = orderLines.reduce(
    (sum, { amount }: { amount: number }) => sum + amount,
    0
  );

  const currency =
    orderLines && orderLines.length > 0 ? orderLines[0].currencyName : "NOK";

  const pdfEl = useRef<PDFExport>(null);

  const onPdfExportClick = () => {
    if (pdfEl.current !== null) {
      pdfEl.current.save();
    }
  };

  const handleRowClick = (orderLine: IOrderLine) => {
    if (
      orderLine.product.images.filter(
        (i) => i.revision === orderLine.productRev
      ).length > 0
    ) {
      setModalImage(
        orderLine.product.images.filter(
          (i) => i.revision === orderLine.productRev
        )[0]
      );
      setImageModalShow(true);
    }
  };

  const ExcelRef = (exporter: ExcelExport) => {
    setExportState(exporter);
  };

  const handleExportClick = () => {
    setExportingState(true);
    const exportOrderLines: IOrderLine[] = orderBy(orderLines, sort);
    exportOrderLines.forEach((ol) => {
      ol.qty = ol.amount + ol.finished;
      ol.element = ol.product.element;
      ol.description =
        i18next.language === "en" ? ol.product.descEN : ol.product.descNO;
    });
    exportState?.save(exportOrderLines);
    setExportingState(false);
  };

  var formatter = new Intl.NumberFormat("nb-NO", {
    style: "currency",
    currency: currency,
  });

  var deliveryDate = order.targetProductDeliveryDate
    .toString()
    .match(/(\d\d\d\d)(\d\d)(\d\d)/);

  return (
    <div>
      <h5>Order: {order.orderNo}</h5>
      <p>
        {order.supplierNumber} {order.supplierName}
      </p>
      <Row className="mx-2">
        <Col md={12}>
          <ButtonGroup className="my-3 me-2 pl-1">
            <Button
              onClick={() => downloadZip(order?.orderNo, false)}
              variant="primary"
              disabled={allOrderlinesFin === 0}
            >
              Download All Files
            </Button>
            <Button
              onClick={() => downloadZip(order?.orderNo, true)}
              variant="secondary"
              disabled={allOrderlinesFin === 0}
            >
              Download new Files
            </Button>
            <Button
              onClick={() => onPdfExportClick()}
              variant="success"
              disabled={allOrderlinesFin === 0}
            >
              Download PDF
            </Button>
            <Button
              onClick={() => handleExportClick()}
              variant="info"
              disabled={allOrderlinesFin === 0}
            >
              Excel export
            </Button>
          </ButtonGroup>
          {order.supplierEmail.trim().length > 0 && isPurchaser(currentUser) && (
            <ButtonGroup className="my-3 me-2 pl-1">
              <Button
                onClick={() => sendEmail()}
                variant="warning"
                disabled={allOrderlinesFin === 0}
              >
                Send Email
              </Button>
            </ButtonGroup>
          )}
          <ButtonGroup className="my-3 float-end pr-1">
            <Button
              onClick={() => handleMissingImagesCase()}
              disabled={allOrderlinesFin === 0}
            >
              Missing Files Case
            </Button>
            <Button
              onClick={() => setCaseOrderNo(order.orderNo)}
              variant="secondary"
              disabled={allOrderlinesFin === 0}
            >
              Open Case
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <p className="mx-2">
        {"Total order price: " + formatter.format(totalOrderPrice)}
        <span className="mx-5">
          {t("order.pdf.deliveryDate")}:{" "}
          {deliveryDate !== null &&
            dateTimeFormatDay.format(
              new Date(
                parseInt(deliveryDate[1]),
                parseInt(deliveryDate[2]) - 1,
                parseInt(deliveryDate[3])
              )
            )}
        </span>
        <span className="mx-5">
          {t("order.pdf.marked")}: {order.label}
        </span>
        <span className="mx-5">
          Orkel ref: <a href={"mailto: " + order.yourRef}>{order.yourRef}</a>
        </span>
      </p>
      {allOrderlinesFin === 0 && (
        <p className="text-warning mx-2">All orderlines are finished</p>
      )}
      {newProdLoading && (
        <p className="mx-2">
          Checking for new products{" "}
          <FontAwesomeIcon icon={faSpinner} size="1x" spin />
        </p>
      )}

      <ExcelExport
        data={orderBy(orderLines, sort)}
        ref={ExcelRef}
        fileName={order.orderNo + " excel export.xlsx"}
      >
        <Grid
          data={orderBy(orderLines, sort)}
          style={mql.matches ? {} : { height: "67vh" }}
          className={"order-images-grid " + (mql.matches ? "" : "mobile-grid")}
          sortable
          sort={sort}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
          }}
          onRowClick={(e) => handleRowClick(e.dataItem)}
          rowRender={(props, item) => {
            const propProps = { ...props.props };
            let newClassName = propProps.className;
            if (
              item.dataItem.product.images.filter(
                (i: IProductImage) => i.revision === item.dataItem.productRev
              ).length > 0
            )
              newClassName += " order-image-row-hover";
            propProps.className = newClassName;

            return { ...props, props: { ...propProps } };
          }}
        >
          <GridColumn field="orderNo" title="Prod OrderNo" width={140} />
          <GridColumn
            field="productNo"
            title="ProductNo"
            width={115}
            cell={(props) => (
              <td>
                {props.dataItem && props.dataItem.productNo}
                <span className="text-warning">
                  {newProducts.some(
                    (np) => np.productNo === props.dataItem.productNo
                  )
                    ? " (new)"
                    : ""}
                </span>
              </td>
            )}
          />
          <GridColumn
            field="supplierProductNo"
            title="Supplier Product No"
            width={170}
          />
          <GridColumn
            field="productRev"
            title="Rev"
            width={75}
            className="text-center"
          />
          <GridColumn
            field="qty"
            title="Qty"
            width={80}
            cell={(props) => (
              <td>
                {props.dataItem &&
                  props.dataItem.amount + props.dataItem.finished}
              </td>
            )}
            className="text-center"
          />
          <GridColumn field="unit" title="Unit" width={80} />
          <GridColumn
            field="element"
            title="Element"
            cell={(props) => (
              <td>
                {props.dataItem.product && props.dataItem.product.element}
              </td>
            )}
            width={160}
          />
          <GridColumn
            field="code"
            title="Code"
            cell={(props) => <td>{props.dataItem.product.code}</td>}
            width={100}
          />
          <GridColumn
            field="material"
            title="Material"
            cell={(props) => (
              <td>
                {i18next.language === "en"
                  ? props.dataItem.product.materialEn
                  : props.dataItem.materialNo}
              </td>
            )}
            width={mql.matches ? undefined : 300}
          />
          <GridColumn
            field="description"
            title="Description"
            cell={(props) => (
              <td>
                {i18next.language === "en"
                  ? props.dataItem.product.descEN
                  : props.dataItem.product.descNO}
              </td>
            )}
            width={mql.matches ? undefined : 300}
          />
          <GridColumn
            field="price"
            title="Price"
            cell={(props) => (
              <td>
                {props.dataItem && formatter.format(props.dataItem.price)}
              </td>
            )}
            width={100}
          />
          <GridColumn
            field="totalPrice"
            title="Total Price"
            cell={(props) => (
              <td>
                {props.dataItem && formatter.format(props.dataItem.totalPrice)}
              </td>
            )}
            width={130}
          />
          <GridColumn field="currencyName" title="Currency" width={100} />
          <GridColumn
            title="Product Files"
            cell={(props) => {
              return (
                <td
                  className={
                    "text-center " +
                    (props.dataItem.numberOfFiles &&
                    props.dataItem.numberOfFiles > 0
                      ? "text-success"
                      : "text-danger")
                  }
                >
                  {filesLoading ? (
                    <p className="mx-2">
                      <FontAwesomeIcon icon={faSpinner} size="1x" spin />
                    </p>
                  ) : (
                    <p>{props.dataItem.numberOfFiles}</p>
                  )}
                </td>
              );
            }}
            width={120}
          />
        </Grid>
      </ExcelExport>

      <div className="bottom-scroll"></div>
      {caseOrderNo && (
        <CreateIssueModal
          show={caseOrderNo !== null}
          onHide={() => {
            setCaseOrderNo(null);
            setNoImgProds([]);
          }}
          setModalShow={(v) => {
            if (v === false) {
              setCaseOrderNo(null);
            }
          }}
          orderProducts={noImgProds}
          history={history}
          timetrackIssue={true}
          orderNo={caseOrderNo}
        />
      )}
      <ProductImageModal
        image={modalImage}
        show={imageModalShow}
        images={images}
        setImageShow={(image) => setModalImage(image)}
        handleHide={() => {
          setImageModalShow(false);
          setModalImage(undefined);
        }}
      />
      {order && orderLines && (
        <div
          style={{
            position: "absolute",
            left: "-5000px",
            top: 0,
            color: "black",
          }}
        >
          <PDFExport
            paperSize="A4"
            landscape
            margin="1cm"
            fileName={order.orderNo.toString()}
            ref={pdfEl}
            repeatHeaders={true}
            keepTogether="div"
          >
            <div style={{ backgroundColor: "white" }}>
              <Row>
                <Col md={4}>
                  <h6>Orkel AS</h6>
                </Col>
                <Col md={4}>
                  <h6>
                    {t(`order.pdf.purchaseOrder`)} {order.orderNo}
                  </h6>
                </Col>
                <Col md={4}></Col>
              </Row>
              <div className="order-pdf-export mt-2 mx-2">
                <Row>
                  <Col md={8}>
                    <p>{t(`order.pdf.deliveryAddress`)}:</p>
                    <p className="ms-3">Orkel AS</p>
                    <p className="ms-3">Johan Gjønnes veg 25</p>
                    <p className="ms-3">NO NO 7300 FANNREM</p>
                    <br />
                    <p>{order.supplierName}</p>
                    <p>{order.supplierAddress}</p>
                    <p>{order.supplierArea}</p>
                    <p>{order.supplierCountry}</p>
                    <p>
                      {t(`order.pdf.fax`)}: {order.supplierFax}
                    </p>
                  </Col>
                  <Col md={4}>
                    <Row>
                      <Col>
                        <p>{t(`order.pdf.date`)}:</p>
                      </Col>
                      <Col>
                        <p>{t(`order.pdf.orderNo`)}:</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>{order.targetProductDeliveryDate}</p>
                      </Col>
                      <Col>
                        <p>{order.orderNo}</p>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <p>{t(`order.pdf.paymentTerms`)}:</p>
                      </Col>
                      <Col>
                        <p>{t(`order.pdf.netto`)}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>{t(`order.pdf.yourRef`)}:</p>
                      </Col>
                      <Col>
                        <p>
                          {order.yourRef && order.yourRef.trim().length > 0 ? (
                            order.yourRef
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>{t(`order.pdf.ourRef`)}:</p>
                      </Col>
                      <Col>
                        <p>
                          {order.ourRef && order.ourRef.trim().length > 0 ? (
                            order.ourRef
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>{t(`order.pdf.marked`)}:</p>
                      </Col>
                      <Col>
                        <p>
                          {order.label && order.label.trim().length > 0 ? (
                            order.label
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>{t(`order.pdf.invoice`)}:</p>
                      </Col>
                      <Col>
                        <p>{currency}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>{t(`order.pdf.deliveryMethod`)}:</p>
                      </Col>
                      <Col>
                        <p>
                          {i18next.language === "en"
                            ? order.deliveryMethodEn
                            : order.deliveryMethodNo}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>{t(`order.pdf.deliveryTerms`)}:</p>
                      </Col>
                      <Col>
                        <p>
                          {i18next.language === "en"
                            ? order.deliveryTermsEn
                            : order.deliveryTermsNo}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="mt-4">
                <table className="order-pdf-export-table mb-5">
                  <thead>
                    <tr>
                      <th>Prod</th>
                      <th>Rev</th>
                      <th>{t(`order.pdf.supNo`)}</th>
                      <th>{t(`order.pdf.description`)}</th>
                      <th>Material</th>
                      <th>{t(`order.pdf.element`)}</th>
                      <th>{t(`order.pdf.code`)}</th>
                      <th>{t(`order.pdf.qty`)}</th>
                      <th>{t(`order.pdf.unit`)}</th>
                      <th>{t(`order.pdf.price`)}</th>
                      <th>{t(`order.pdf.delDt`)}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderLines
                      .sort((a, b) => (a.productNo > b.productNo ? 1 : -1))
                      .map((line: IOrderLine, index) => {
                        return (
                          <tr key={index}>
                            <td>{line.productNo}</td>
                            <td>{line.productRev}</td>
                            <td>{line.supplierProductNo}</td>
                            <td id="line-desc">
                              {i18next.language === "en"
                                ? line.product.descEN
                                : line.product.descNO}
                            </td>
                            <td>
                              {i18next.language === "en"
                                ? line.product.materialEn
                                : line.product.materialNo}
                            </td>
                            <td>
                              {line.product?.element !== undefined
                                ? line.product?.element
                                    .slice(0, 6)
                                    .replaceAll(",", "")
                                : ""}
                            </td>
                            <td>{line.product?.code}</td>
                            <td>{line.amount + line.finished}</td>
                            <td>{line.unit}</td>
                            <td>{formatter.format(line.price)}</td>
                            <td>{line.targetDeliveryDate}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="mt-4">
                <Row className="mx-2">
                  <Col md={8}>
                    <p
                      style={{
                        fontSize: "11px",
                        fontWeight: "bold",
                      }}
                    >
                      {t(`order.pdf.started delivery`)}
                    </p>
                  </Col>
                  <Col md={4}>
                    <p>{formatter.format(totalOrderPrice)}</p>
                  </Col>
                </Row>
                <Row className="mx-2">
                  <Col>
                    <p style={{ fontSize: "11px", fontWeight: "bold" }}>
                      {t(`order.pdf.orkel as confirmation`)}
                    </p>
                  </Col>
                </Row>
              </div>
              <footer className="order-pdf-export-footer">
                <Row>
                  <Col md={2}>
                    <p>Orkel AS</p>
                    <p>Johan Gjønnes veg 25</p>
                    <p>NO 7320 FANNREM</p>
                  </Col>
                  <Col md={2}>
                    <p>
                      <span>&nbsp;</span>
                    </p>
                    <p>
                      <span>&nbsp;</span>
                    </p>
                    <p>http://www.orkel.no</p>
                  </Col>
                  <Col md={2}>
                    <p>{t(`order.pdf.phone`)}:</p>
                    <p>{t(`order.pdf.fax`)}:</p>
                    <p>{t(`order.pdf.vatNo`)}:</p>
                  </Col>
                  <Col md={2}>
                    <p>+47 953 04 020</p>
                    <p>+47 947 40 234</p>
                    <p>984963890</p>
                  </Col>
                  <Col md={2}>
                    <p>{t(`order.pdf.nok`)}:</p>
                    <p>IBAN EUR:</p>
                    <p>Swift:</p>
                  </Col>
                  <Col md={2}>
                    <p>15038693112</p>
                    <p>NO4950260447502</p>
                    <p>DNBANOKK</p>
                  </Col>
                </Row>
              </footer>
            </div>
          </PDFExport>
        </div>
      )}
      {exporting && (
        <p className="text-center m-3">
          <FontAwesomeIcon
            icon={faSpinner}
            size="3x"
            spin
            style={{
              top: "50%",
              left: "50%",
              position: "fixed",
              zIndex: 999,
            }}
          />
        </p>
      )}
    </div>
  );
};

export default OrderImagesForm;
