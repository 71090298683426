import {
  OrderActionTypes,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDER_BY_NO_SUCCESS,
  FETCH_FILTERED_ORDERS_SUCCESS,
  FETCH_WORKLIST_ORDERS_SUCCESS,
  FETCH_FILTERED_DB_ORDERS_SUCCESS,
  FETCH_FILTERED_TIMETRACK_ORDERS_SUCCESS,
  ORDER_REQUEST_FAILURE,
  FETCH_ORDER_SUCCESS,
  ORDER_REQUEST_ERRORS,
  CREATE_ORDER_SUCCESS,
  DELETE_ORDER_SUCCESS,
  EDIT_ORDER_SUCCESS,
  FETCH_ORDER_TIMETRACK_SUCCESS,
  FETCH_ORDER_KITITNG_SUCCESS,
} from "../actions/orders";
import { IOrder, IOrderTimeTrack } from "../../models/order";
import { DataList } from "../../utils/DataList";

export interface OrderState {
  readonly orders: IOrder[];
  readonly filteredOrders: DataList<IOrder>;
  readonly filteredTimetrackOrders: DataList<IOrder>;
  readonly order: IOrder | null;
  readonly orderKitting: IOrder | null;
  readonly errors: any;
  readonly orderTimeTrack: IOrderTimeTrack | null;
}

export const initialState: OrderState = {
  orders: [],
  filteredOrders: { data: [], total: 0 },
  filteredTimetrackOrders: { data: [], total: 0 },
  order: null,
  orderKitting: null,
  errors: null,
  orderTimeTrack: null,
};

const ordersReducer = (
  state = initialState,
  action: OrderActionTypes
): OrderState => {
  switch (action.type) {
    case FETCH_ORDERS_SUCCESS:
      return { ...state, orders: action.orders };
    case FETCH_ORDER_BY_NO_SUCCESS:
      return { ...state, order: action.order };
    case FETCH_FILTERED_ORDERS_SUCCESS:
      return { ...state, filteredOrders: action.orders };
    case FETCH_WORKLIST_ORDERS_SUCCESS:
      return { ...state, filteredOrders: action.orders };
    case FETCH_FILTERED_DB_ORDERS_SUCCESS:
      return { ...state, filteredOrders: action.orders };
    case FETCH_FILTERED_TIMETRACK_ORDERS_SUCCESS:
      return { ...state, filteredTimetrackOrders: action.orders };
    case ORDER_REQUEST_FAILURE:
      return { ...state, errors: action.errors };
    case FETCH_ORDER_SUCCESS:
      return { ...state, order: action.order };
    case FETCH_ORDER_KITITNG_SUCCESS:
      return { ...state, orderKitting: action.order };
    case ORDER_REQUEST_ERRORS:
      return { ...state, errors: action.errors };
    case CREATE_ORDER_SUCCESS:
      return { ...state, order: action.order };
    case DELETE_ORDER_SUCCESS:
      return { ...state, order: null };
    case EDIT_ORDER_SUCCESS:
      return { ...state, order: action.order };
    case FETCH_ORDER_TIMETRACK_SUCCESS:
      return { ...state, orderTimeTrack: action.orderTimeTrack };
    default:
      return state;
  }
};

export default ordersReducer;
