import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from "../../../store";
import { isAdmin, IUser } from "../../../models/user";
import Unauthorized from '../../../components/Auth/Unauthorized';
import { useEffect } from 'react';
import { redirectCase } from '../../../store/actions/salesforce';
import { useRouteMatch } from 'react-router';
import { useRef } from 'react';

export interface MatchParams {
    id: string;
}

export interface IProps {
    current: IUser;
}

const SalesforceCaseContainer = (props: IProps) => {
    const { current } = props;

    const match = useRouteMatch<MatchParams>();

    const hasMounted = useRef(false);

    useEffect(() => {
        if(!isAdmin(current)) return;
        if(hasMounted.current) return;

        var caseNumber = parseInt(match.params.id);
        
        if(isNaN(caseNumber)) return;

        hasMounted.current = true;
        redirectCase(match.params.id, false);
    }, [current, match.params.id]);

    if(isAdmin(current)) {
        return <div></div>
    }

    return <Unauthorized />
}

const mapStateToProps = (state: IRootState) => ({
    current: state.authReducer.user
});

export default connect(mapStateToProps)(SalesforceCaseContainer);