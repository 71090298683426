import { IIssueTemplate } from "models/issueTemplate";
import {
  TemplateActionTypes,
  FETCH_TEMPLATES_SUCCESS,
  FETCH_FILTERED_TEMPLATES_SUCCESS,
  FETCH_TEMPLATE_SUCCESS,
  CREATE_TEMPLATE_SUCCESS,
  EDIT_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_SUCCESS,
  TEMPLATE_REQUEST_ERRORS,
  TEMPLATE_REQUEST_FAILURE,
} from "../actions/issuetemplates";
import { DataList } from "utils/DataList";

export interface IssueTemplateState {
  readonly templates: IIssueTemplate[];
  readonly filteredTemplates: DataList<IIssueTemplate>;
  readonly template: IIssueTemplate | null;
  readonly errors: any;
}

export const initialState: IssueTemplateState = {
  templates: [],
  filteredTemplates: { data: [], total: 0 },
  template: null,
  errors: null,
};

const issueTemplateReducer = (
  state = initialState,
  action: TemplateActionTypes
): IssueTemplateState => {
  switch (action.type) {
    case FETCH_TEMPLATES_SUCCESS:
      return { ...state, templates: action.templates };
    case FETCH_FILTERED_TEMPLATES_SUCCESS:
      return { ...state, filteredTemplates: action.templates };
    case FETCH_TEMPLATE_SUCCESS:
      return { ...state, template: action.template };
    case CREATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        template: action.template,
        templates:
          state.templates.length > 0
            ? [...state.templates.concat([action.template])]
            : [action.template],
      };
    case EDIT_TEMPLATE_SUCCESS:
      if (state.templates.length > 0) {
        state.templates.splice(
          state.templates.findIndex((t) => t.id === action.template.id),
          1,
          action.template
        );
      }
      return {
        ...state,
        template: action.template,
        templates:
          state.templates.length > 0 ? state.templates : [action.template],
      };
    case DELETE_TEMPLATE_SUCCESS:
      return { ...state, template: null };
    case TEMPLATE_REQUEST_ERRORS:
      return { ...state, errors: action.errors };
    case TEMPLATE_REQUEST_FAILURE:
      return { ...state, errors: action.errors };
    default:
      return state;
  }
};

export default issueTemplateReducer;
