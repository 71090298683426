import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Image, Modal } from "react-bootstrap";
import {
  faAngleLeft,
  faAngleRight,
  faFile,
} from "@fortawesome/free-solid-svg-icons";

import { IProductImage } from "models/image";
import { getRequest } from "store/actions/request";

export interface IProps {
  image?: IProductImage;
  show: boolean;
  images: IProductImage[];
  handleHide: () => void;
  setImageShow: (image: IProductImage) => void;
}

const ProductImageModal: React.FC<IProps> = (props) => {
  const { image, show, images, handleHide, setImageShow } = props;

  const [imageContent, setImageContent] = useState<string[]>();

  useEffect(() => {
    let mounted = false;
    if (image && image.id) {
      getRequest(`/productsimages/${image.id}`).then((response) => {
        if (response.json) {
          if (!mounted) setImageContent(JSON.parse(response.json));
        } else {
          if (!mounted) setImageContent(undefined);
        }
      });
    }
    return () => {
      mounted = true;
    };
  }, [image]);

  const imageIndex = images.findIndex((img) => img.id === image?.id);

  return (
    <Modal
      show={show}
      onHide={() => {
        handleHide();
        setImageContent(undefined);
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {image ? image.productNo + " - " + image.revision : ""}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="m-0 p-0">
        {imageContent ? (
          imageContent.map((content, index) => (
            <Image
              src={content}
              key={"image-" + index}
              className="img-fluid bg-white mb-3"
            />
          ))
        ) : (
          <FontAwesomeIcon icon={faFile} size="2x" className="m-2" />
        )}
      </Modal.Body>
      {images.length > 1 && (
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="text-center">
            <FontAwesomeIcon
              icon={faAngleLeft}
              size="2x"
              className="mx-3"
              onClick={() =>
                imageIndex > 0 ? setImageShow(images[imageIndex - 1]) : null
              }
              style={imageIndex > 0 ? { cursor: "pointer" } : { opacity: 0.5 }}
            />
            <FontAwesomeIcon
              icon={faAngleRight}
              size="2x"
              className="mx-3"
              onClick={() =>
                imageIndex !== images.length - 1
                  ? setImageShow(images[imageIndex + 1])
                  : null
              }
              style={
                imageIndex !== images.length - 1
                  ? { cursor: "pointer" }
                  : { opacity: 0.5 }
              }
            />
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ProductImageModal;
