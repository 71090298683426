import {
  DataSourceRequestState,
  toDataSourceRequestString,
} from "@progress/kendo-data-query";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { IJig } from "../../models/jig";
import { IImage } from "../../models/image";
import Aux from "../../utils/auxiliary";
import JigImage from "./JigImage";
import SearchForm from "../Common/SearchForm";
import { IUser } from "../../models/user";
import PageNavigation from "components/Common/PageNavigation";

export interface IProps {
  current: IUser;
  jigs: IJig[];
  totalJigs: number;
  viewHidden: boolean;
  handleFetch: (query: string) => void;
  onEditClick: (jig: IJig) => any;
}

const JigImageGrid: React.FC<IProps> = (props) => {
  const {
    current,
    jigs,
    totalJigs,
    viewHidden,
    handleFetch,
    onEditClick,
  } = props;

  const fetchTempRequestState = () => {
    const request = localStorage.getItem("temp_image_grid_req_state");
    if (!request) return null;
    return JSON.parse(request) as {
      requestState: DataSourceRequestState;
      searchTerm: string;
    };
  };

  const [tempReqState] = useState(fetchTempRequestState());
  const [viewHiddenState, setViewHiddenState] = useState<boolean>(viewHidden);
  const [searchTerm, setSearchTerm] = React.useState<string>(
    tempReqState?.searchTerm || ""
  );
  const [requestState, setRequestState] = useState<DataSourceRequestState>(
    tempReqState?.requestState || {
      skip: 0,
      take: 20,
      filter: {
        filters: [
          {
            field: "hidden",
            operator: "eq",
            value: viewHidden,
          },
        ],
        logic: "and" as const,
      },
    }
  );

  useEffect(() => {
    handleFetch(`${toDataSourceRequestString(requestState)}`);
    localStorage.removeItem("jigs_hide_state");
  }, [requestState]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (viewHiddenState !== viewHidden) {
      setViewHiddenState(viewHidden);
      setRequestState({
        skip: 0,
        take: 20,
        filter: {
          filters: [
            {
              field: "hidden",
              operator: "eq",
              value: viewHidden,
            },
          ],
          logic: "and" as const,
        },
      });
    }
  }, [viewHidden]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearchChange = (value: string) => {
    if (value !== searchTerm) {
      setSearchTerm(value);
      let field = "JigNr";
      const request = {
        ...requestState,
        skip: 0,
        filter: {
          filters: [
            {
              field: "hidden",
              operator: "eq",
              value: viewHidden,
            },
            {
              field: field,
              operator: "startswith",
              value: value,
            },
          ],
          logic: "and" as const,
        },
      };
      setRequestState(request);
    }
  };

  const onDeleteClick = (image: IImage) => {};

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      if (jigs.length > 0) {
        localStorage.setItem(
          "temp_image_grid_req_state",
          JSON.stringify({ requestState, searchTerm })
        );
        viewHidden
          ? localStorage.setItem("jigs_hide_state", "true")
          : localStorage.removeItem("jigs_hide_state");
        onEditClick(jigs[0]);
      }
    }
  };

  return (
    <Aux>
      <button
        title="Clear filter"
        onClick={() => {
          localStorage.removeItem("temp_image_grid_req_state");
          setSearchTerm("");
          setRequestState({
            skip: 0,
            take: 20,
            filter: {
              filters: [
                {
                  field: "hidden",
                  operator: "eq",
                  value: viewHidden,
                },
              ],
              logic: "and" as const,
            },
          });
        }}
        className="border-0 text-white float-end"
        style={{ cursor: "pointer", background: "transparent" }}
      >
        <span
          className="k-icon k-i-filter-clear"
          style={{ fontSize: "32px" }}
        ></span>
      </button>
      <SearchForm
        searchTerm={searchTerm}
        handleChange={handleSearchChange}
        placeholder="Search JigNo startswith"
        handleEnterClick={handleKeyDown}
        disabled={false}
      />
      <p className="text-muted">
        <small>
          Clicking enter in the form takes you to the first one in the list
        </small>
      </p>
      <br />
      <div className="row">
        {jigs &&
          jigs.length > 0 &&
          jigs.map((j, i) => {
            localStorage.removeItem("temp_image_grid_req_state");
            return (
              <Aux key={j.id}>
                <div className="col-sm-4 mb-3 col-lg-3 col-xl-2" key={j.id}>
                  <Card
                    bg="secondary"
                    className="border-light mb-2"
                    key={j.id}
                    style={{
                      textAlign: "center",
                      minHeight: "300px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      localStorage.setItem(
                        "temp_image_grid_req_state",
                        JSON.stringify({ requestState, searchTerm })
                      );
                      viewHidden
                        ? localStorage.setItem("jigs_hide_state", "true")
                        : localStorage.removeItem("jigs_hide_state");
                      onEditClick(j);
                    }}
                  >
                    <Card.Body>
                      {j.images.length > 0 ? (
                        <JigImage
                          current={current}
                          image={j.images[0]}
                          onDeleteClick={onDeleteClick}
                        />
                      ) : (
                        <p className="pt-5">
                          No image found for this equipment
                        </p>
                      )}
                      <p className={j.hidden ? "text-danger" : ""}>
                        No - {j.jignr}
                      </p>
                    </Card.Body>
                  </Card>
                </div>
              </Aux>
            );
          })}
        {jigs && jigs.length === 0 && current.id > 0 && (
          <p className="ps-3">No equipment found matching this search/filter</p>
        )}
      </div>
      {jigs?.length > 0 && (
        <PageNavigation
          requestState={requestState}
          arrayLength={jigs.length}
          total={totalJigs}
          setRequestState={setRequestState}
        />
      )}
    </Aux>
  );
};

export default JigImageGrid;
