import React, { useEffect, useRef, useState } from "react";
import {
  CompositeFilterDescriptor,
  DataSourceRequestState,
  toDataSourceRequestString,
} from "@progress/kendo-data-query";
import { connect } from "react-redux";
import { TriggerType } from "@webscopeio/react-textarea-autocomplete";
import emoji from "@jukben/emoji-search";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";

import { getDescFieldName, getProductLanguage, IProduct } from "models/product";
import { isInternal, isKitter, isTechnicalSupport, IUser } from "models/user";
import { IRootState } from "store";
import { fetchFilteredRelIssues } from "store/actions/issues";
import { fetchFilteredOrders } from "store/actions/orders";
import { fetchFilteredProducts } from "store/actions/products";
import { fetchFilteredBasicChassis } from "store/actions/QualityControl/qcChassis";
import { fetchFilteredUsers } from "store/actions/users";
import { findCase } from "store/actions/salesforce";
import { fetchFilteredJigs } from "store/actions/jigs";

export interface IProps {
  currentUser: IUser;
  value: string;
  placeholder?: string;
  className?: string;
  caseView?: number;
  handleItemSelected: (selected: any) => any;
  onChange: (event: any) => any;
  fetchFilteredProducts: (queryStr: string) => any;
  fetchFilteredRelIssues: (queryStr: string) => any;
  fetchFilteredUsers: (queryStr: string) => any;
  fetchFilteredOrders: (queryStr: string) => any;
  fetchFilteredBasicChassis: (queryStr: string) => any;
  querySalesforceCase: (query: string) => any;
  fetchFilteredJigs: (queryStr: string) => any;
}

const TaggableInputField: React.FC<IProps> = (props) => {
  const {
    currentUser,
    value,
    placeholder,
    className,
    caseView,
    handleItemSelected,
    onChange,
    fetchFilteredProducts,
    fetchFilteredRelIssues,
    fetchFilteredUsers,
    fetchFilteredOrders,
    fetchFilteredBasicChassis,
    querySalesforceCase,
    fetchFilteredJigs,
  } = props;

  const EmojiItem = ({ entity: { name, char } }) => (
    <div>{`${name}: ${char}`}</div>
  );
  const ProductItem = ({ entity }) => (
    <div>{`${entity.productNo} - ${getProductLanguage(
      currentUser,
      entity as IProduct
    )}`}</div>
  );
  const MainCausePartItem = ({ entity }) => (
    <div>{`${entity.productNo} - ${getProductLanguage(
      currentUser,
      entity as IProduct
    )}`}</div>
  );
  const UserItem = ({ entity: { fullName, email, title } }) => (
    <div key={email}>{`${fullName} ${
      title ? "(" + title + ")" : ""
    } <${email}>`}</div>
  );
  const ChassisItem = ({ entity: { no } }) => <div>{`${no}`}</div>;
  const RelIssueItem = ({ entity: { id, title } }) => (
    <div>{`${id} - ${title}`}</div>
  );
  const OrderItem = ({ entity: { orderNo } }) => <div>{`${orderNo}`}</div>;
  const CaseItem = ({ entity: { caseNumber, subject } }) => (
    <div>{`${caseNumber} - ${subject}`}</div>
  );
  const JigItem = ({ entity: { jignr } }) => <div>{`${jignr}`}</div>;
  const Loading = (data: any) => <div>Loading</div>;

  const [requestState, setRequestState] = useState<DataSourceRequestState>({
    skip: 0,
    take: 10,
  });

  const [textAreaHeight, setTextAreaHeight] = useState("38px");
  const textAreaRef = useRef<ReactTextareaAutocomplete>(null);

  useEffect(() => {
    if (textAreaRef !== null) {
      setTextAreaHeight(`${textAreaRef.current!.textareaRef.scrollHeight}px`);
    }
  }, [textAreaRef, value]);

  const generateUserFilter = (value: string) => {
    const userFilter: CompositeFilterDescriptor = {
      filters: [
        {
          field: "firstname",
          operator: "startswith",
          value: value,
        },
        {
          field: "lastname",
          operator: "startswith",
          value: value,
        },
      ],
      logic: "or" as const,
    };
    return userFilter;
  };

  const fetchProductData = (request?: DataSourceRequestState | undefined) => {
    if (fetchFilteredProducts) {
      if (!request) {
        request = requestState;
      }
      return fetchFilteredProducts(toDataSourceRequestString(request));
    }
  };

  const fetchChassisData = (request?: DataSourceRequestState | undefined) => {
    if (fetchFilteredBasicChassis) {
      if (!request) {
        request = requestState;
      }
      return fetchFilteredBasicChassis(
        `${toDataSourceRequestString(request)}&IssueFetch=${true}`
      );
    }
  };

  const fetchRelIssuesData = (request?: DataSourceRequestState | undefined) => {
    if (fetchFilteredRelIssues) {
      if (!request) {
        request = requestState;
      }
      return fetchFilteredRelIssues(toDataSourceRequestString(request));
    }
  };

  const fetchOrdersData = (request?: DataSourceRequestState | undefined) => {
    if (fetchFilteredOrders) {
      if (!request) {
        request = requestState;
      }
      return fetchFilteredOrders(toDataSourceRequestString(request));
    }
  };

  const fetchUserData = (request?: DataSourceRequestState | undefined) => {
    if (fetchFilteredUsers) {
      if (!request) {
        request = requestState;
      }
      return fetchFilteredUsers(toDataSourceRequestString(request));
    }
  };

  const fetchJigData = (request?: DataSourceRequestState | undefined) => {
    if (fetchFilteredJigs) {
      if (!request) {
        request = requestState;
      }
      return fetchFilteredJigs(toDataSourceRequestString(request));
    }
  };

  const handleProductRequestChange = (token: any) => {
    let field = "productNo";
    if (isNaN(token)) {
      field = getDescFieldName(currentUser);
    }
    const request = {
      ...requestState,
      filter: {
        filters: [
          {
            field: field,
            operator: "startswith",
            value: token,
          },
        ],
        logic: "and" as const,
      },
    };
    setRequestState(request);
    return fetchProductData(request);
  };

  const handleOrderRequestChange = (token: any) => {
    const orderNum = parseInt(token);
    let field = "orderNo";
    const request = {
      ...requestState,
      filter: {
        filters: [
          {
            field: field,
            operator: "eq",
            value: orderNum,
          },
        ],
        logic: "and" as const,
      },
    };
    setRequestState(request);
    return fetchOrdersData(request);
  };

  const handleRelIssuesRequestChange = (token: any) => {
    let field = "Id";
    const request = {
      ...requestState,
      filter: {
        filters: [
          {
            field: field,
            operator: "eq",
            value: token,
          },
        ],
        logic: "and" as const,
      },
    };
    setRequestState(request);
    return fetchRelIssuesData(request);
  };

  const handleChassisRequestChange = (token: any) => {
    let field = "No";
    const request = {
      ...requestState,
      filter: {
        filters: [
          {
            field: field,
            operator: "startswith",
            value: token,
          },
          {
            field: "hide",
            operator: "eq",
            value: false,
          },
        ],
        logic: "and" as const,
      },
    };
    setRequestState(request);
    return fetchChassisData(request);
  };

  const handleJigRequestChange = (token: any) => {
    let field = "jignr";
    const request = {
      ...requestState,
      filter: {
        filters: [
          {
            field: field,
            operator: "startswith",
            value: token,
          },
          {
            field: "hidden",
            operator: "eq",
            value: false,
          },
        ],
        logic: "and" as const,
      },
    };
    setRequestState(request);
    return fetchJigData(request);
  };

  const handleUserRequestChange = (token: any) => {
    const inactiveFilter: CompositeFilterDescriptor = {
      filters: [
        {
          field: "inactive",
          operator: "eq",
          value: 0,
        },
      ],
      logic: "and" as const,
    };
    const filters = [
      generateUserFilter(token),
      inactiveFilter,
    ] as CompositeFilterDescriptor[];

    const request = {
      ...requestState,
      filter: {
        filters,
        logic: "and" as const,
      },
    };
    setRequestState(request);
    return fetchUserData(request);
  };

  const handleCaseRequestChange = (token: any) => {
    return querySalesforceCase(token);
  };

  const triggers: TriggerType<string | object> = isTechnicalSupport(currentUser)
    ? {
        "%": {
          dataProvider: (token: any) =>
            handleChassisRequestChange(token).then((res) => {
              return res.qcChassis.data;
            }),
          component: ChassisItem,
          output: (item: any) => `%{${item.no}}`,
        },
      }
    : {
        ":": {
          dataProvider: (token: any) => {
            return emoji(token)
              .slice(0, 10)
              .map(({ name, char }) => ({ name, char }));
          },
          component: EmojiItem,
          output: (item: any) => item.char,
        },
        "#": {
          dataProvider: (token: any) =>
            handleProductRequestChange(token).then((res) => {
              return res.products.data;
            }),
          component: ProductItem,
          output: (item: any) =>
            `#{${item.productNo} - ${getProductLanguage(
              currentUser,
              item as IProduct
            )}}`,
        },
        "€": caseView &&
          (caseView === 3 || caseView === 8) && {
            dataProvider: (token: any) =>
              handleProductRequestChange(token).then((res) => {
                return res.products.data;
              }),
            component: MainCausePartItem,
            output: (item: any) =>
              `€{${item.productNo} - ${getProductLanguage(
                currentUser,
                item as IProduct
              )}}`,
          },
        "£": {
          dataProvider: (token: any) =>
            handleJigRequestChange(token).then((res) => {
              return res.filteredJigs.data;
            }),
          component: JigItem,
          output: (item: any) => `£{${item.jignr}}`,
        },
        "%": isInternal(currentUser)
          ? {
              dataProvider: (token: any) =>
                handleChassisRequestChange(token).then((res) => {
                  return res.qcChassis.data;
                }),
              component: ChassisItem,
              output: (item: any) => `%{${item.no}}`,
            }
          : {},
        "&": isInternal(currentUser)
          ? {
              dataProvider: (token: any) =>
                handleRelIssuesRequestChange(token).then((res) => {
                  return res.issues.data;
                }),
              component: RelIssueItem,
              output: (item: any) => `&{${item.id} - ${item.title}}`,
            }
          : {},
        $:
          isInternal(currentUser) || isKitter(currentUser)
            ? {
                dataProvider: (token: any) =>
                  handleOrderRequestChange(token).then((res) => {
                    return res.orders.data;
                  }),
                component: OrderItem,
                output: (item: any) => `${"$"}{${item.orderNo}}`,
              }
            : {},
        "@": {
          dataProvider: (token: any) =>
            token.length > 1
              ? handleUserRequestChange(token).then((res) => {
                  return res.usersData.data;
                })
              : [],
          component: UserItem,
          output: (item: any) => `@{${item.fullName}}`,
        },
        "*": isInternal(currentUser)
          ? {
              dataProvider: (token: any) =>
                handleCaseRequestChange(token).then((res) => {
                  return res.caseQuery.records;
                }),
              component: CaseItem,
              output: (item: any) => `*{${item.caseNumber}}`,
            }
          : {},
      };

  return (
    <ReactTextareaAutocomplete
      placeholder={placeholder}
      className={className}
      trigger={triggers}
      onItemSelected={(selected) => handleItemSelected(selected)}
      loadingComponent={Loading}
      onChange={(e) => onChange(e)}
      value={value}
      rows={1}
      style={{
        minHeight: "38px",
        height: textAreaHeight,
        overflow: "hidden",
      }}
      ref={textAreaRef}
    />
  );
};

const mapStateToProps = (state: IRootState) => ({
  currentUser: state.authReducer.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchFilteredProducts: (queryStr: string) =>
    dispatch(fetchFilteredProducts(queryStr)),
  fetchFilteredUsers: (queryStr: string) =>
    dispatch(fetchFilteredUsers(queryStr)),
  fetchFilteredBasicChassis: (queryStr: string) =>
    dispatch(fetchFilteredBasicChassis(queryStr)),
  fetchFilteredRelIssues: (queryStr: string) =>
    dispatch(fetchFilteredRelIssues(queryStr)),
  fetchFilteredOrders: (queryStr: string) =>
    dispatch(fetchFilteredOrders(queryStr)),
  querySalesforceCase: (query: string) => dispatch(findCase(query)),
  fetchFilteredJigs: (query: string) => dispatch(fetchFilteredJigs(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaggableInputField);
