import {
  Drawer as DrawerSidebar,
  DrawerContent,
} from "@progress/kendo-react-layout";
import DrawerItem, { IProps as DrawerItemProps } from "./DrawerItem";
import React from "react";

export interface IProps {
  expanded: boolean;
  items: DrawerItemProps[];
  selected: any;
  onSelect: (event: any) => any;
  onOverlayClick: (event: any) => any;
}

const Drawer: React.FC<IProps> = (props) => {
  const { expanded, items, onSelect, onOverlayClick } = props;

  return (
    <DrawerSidebar
      expanded={expanded}
      items={items.map((item) => {
        const { parentId, ...others } = item;
        if (parentId !== undefined) {
          const parent = items.find((parent) => parent.id === parentId);
          if (parent === undefined) return item;

          return {
            ...others,
            parentId,
            visible: parent["data-expanded"],
          };
        }
        return item;
      })}
      item={DrawerItem}
      position="end"
      mode="overlay"
      onSelect={onSelect}
      onOverlayClick={onOverlayClick}
    >
      <DrawerContent className="px-3 pt-0 pb-3">{props.children}</DrawerContent>
    </DrawerSidebar>
  );
};

export default Drawer;
