import {
  UserActionsTypes,
  FETCH_USERS_SUCCESS,
  FETCH_FILTEREDUSERS_SUCCESS,
  FETCH_ROLES_SUCCESS,
  FETCH_USER_SUCCESS,
  FETCH_ROLE_USERS_SUCCESS,
  CREATE_USER_SUCCESS,
  EDIT_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  USER_REQUEST_ERRORS,
  USER_REQUEST_FAILURE,
} from "../actions/users";

import { IUser } from "../../models/user";
import { DataList } from "../../utils/DataList";
import { IRole } from "models/role";

export type UserState = {
  readonly users: IUser[];
  readonly filteredUsers: DataList<IUser>;
  readonly roles: IRole[];
  readonly roleUsers: IUser[];
  readonly user: IUser | null;
  readonly errors: any;
};

export const initialState: UserState = {
  users: [],
  filteredUsers: { data: [], total: 0 },
  roles: [],
  roleUsers: [],
  user: null,
  errors: null,
};

const usersReducer = (
  state = initialState,
  action: UserActionsTypes
): UserState => {
  switch (action.type) {
    case FETCH_USERS_SUCCESS:
      return { ...state, users: action.users };
    case FETCH_ROLE_USERS_SUCCESS:
      return { ...state, roleUsers: action.users };
    case FETCH_FILTEREDUSERS_SUCCESS:
      return { ...state, filteredUsers: action.usersData };
    case FETCH_ROLES_SUCCESS:
      return { ...state, roles: action.roles };
    case FETCH_USER_SUCCESS:
      return { ...state, user: action.user };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        user: action.user,
        users:
          state.users.length > 0 ? [...state.users.concat([action.user])] : [],
      };
    case EDIT_USER_SUCCESS:
      if (state.users.length > 0) {
        state.users.splice(
          state.users.findIndex((t) => t.id === action.user.id),
          1,
          action.user
        );
      }
      return {
        ...state,
        user: action.user,
        users: state.users.length > 0 ? state.users : [],
      };
    case DELETE_USER_SUCCESS:
      return { ...state, user: null, users: [] };
    case USER_REQUEST_ERRORS:
      return { ...state, errors: action.errors };
    case USER_REQUEST_FAILURE:
      return { ...state, errors: action.errors };
    default:
      return state;
  }
};

export default usersReducer;
