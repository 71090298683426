import {
  TrainingActionTypes,
  FETCH_TRAININGS_SUCCESS,
  FETCH_TRAINING_SUCCESS,
  FETCH_FILTERED_TRAININGS_SUCCESS,
  CREATE_TRAINING_SUCCESS,
  EDIT_TRAINING_SUCCESS,
  DELETE_TRAINING_SUCCESS,
} from "../actions/training";
import { DataList } from "utils/DataList";
import { ITraining } from "models/training";

export interface TrainingState {
  readonly trainings: ITraining[];
  readonly filteredTrainings: DataList<ITraining>;
  readonly training: ITraining | null;
  readonly errors: any;
}

export const initialState: TrainingState = {
  trainings: [],
  filteredTrainings: { data: [], total: 0 },
  training: null,
  errors: null,
};

const trainingReducer = (
  state = initialState,
  action: TrainingActionTypes
): TrainingState => {
  switch (action.type) {
    case FETCH_TRAININGS_SUCCESS:
      return { ...state, trainings: action.trainings };
    case FETCH_TRAINING_SUCCESS:
      return { ...state, training: action.training };
    case FETCH_FILTERED_TRAININGS_SUCCESS:
      return { ...state, filteredTrainings: action.trainings };
    case CREATE_TRAINING_SUCCESS:
      return { ...state, training: action.training };
    case EDIT_TRAINING_SUCCESS:
      return { ...state, training: action.training };
    case DELETE_TRAINING_SUCCESS:
      return { ...state, training: null };
    default:
      return state;
  }
};

export default trainingReducer;
