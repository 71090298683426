import React, { useEffect, useState } from "react";
import {
  CompositeFilterDescriptor,
  DataResult,
  DataSourceRequestState,
  FilterDescriptor,
  toDataSourceRequestString,
} from "@progress/kendo-data-query";
import {
  Grid,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridRowClickEvent,
} from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { SelectionRange } from "@progress/kendo-react-dateinputs";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import {
  faFilter,
  faSpinner,
  faUser,
  faUserCheck,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import _ from "lodash";

import { IGroup } from "../../models/group";
import { IIssue, IIssueExport } from "../../models/issue";
import { IIssueType } from "../../models/issuetype";
import { IPriority } from "../../models/priority";
import {
  isAdmin,
  isHmsUser,
  isHrUser,
  isInternal,
  isTechnicalSupport,
  IUser,
} from "../../models/user";
import {
  DateCell,
  DateTimeRangeMenu,
  DropDownFilterCell,
  IDateTimeMenuProps,
} from "../Common/GridComponents";
import SearchForm from "../Common/SearchForm";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { getRequest } from "../../store/actions/request";
import { IRootState } from "../../store";
import { fetchGroups } from "../../store/actions/groups";
import { fetchPriorities } from "../../store/actions/priorities";
import { fetchIssueTypes } from "../../store/actions/issuetypes";
import { fetchTags } from "../../store/actions/tag";
import { ITag, ITagCategory } from "../../models/tag";
import { fetchTagCategories } from "store/actions/tagCategory";
import IssueGridColumns from "./IssueGridColumns";
import TagDropDownTree from "./TagDropdownTree";
import Aux from "../../utils/auxiliary";

export interface IProps {
  issues: DataResult;
  issuetypes: IIssueType[];
  groups: IGroup[];
  tags: ITag[];
  tagCategories: ITagCategory[];
  priorities: IPriority[];
  current: IUser;
  caseView: number;
  handleFetch: (query: string) => void;
  handleRowClick: (issue: IIssue) => void;
  onUserEdit: (user: IUser) => Promise<any>;
  fetchIssueTypes: () => Promise<any>;
  fetchGroups: () => Promise<any>;
  fetchPriorities: () => Promise<any>;
  fetchTags: () => Promise<any>;
  fetchTagCategories: () => Promise<any>;
}
interface IssueGridLocalStorage {
  requestState?: DataSourceRequestState;
  searchTerm?: string;
  updatedAt?: SelectionRange;
  createdAt?: SelectionRange;
  selectedTag?: ITag | null;
  rootCause?: { name: string; statusId: number };
  myCases?: boolean;
  involvedCases?: boolean;
  groupCases?: boolean;
  viewAllTypes?: boolean;
}

const IssueGrid: React.FC<IProps> = (props) => {
  const {
    issues,
    issuetypes,
    groups,
    tags,
    tagCategories,
    priorities,
    current,
    caseView,
    handleFetch,
    handleRowClick,
    onUserEdit,
    fetchGroups,
    fetchPriorities,
    fetchIssueTypes,
    fetchTags,
    fetchTagCategories,
  } = props;

  const [requestTimer, setRequestTimer] = useState<NodeJS.Timeout>();
  const storage: IssueGridLocalStorage | undefined = (() => {
    const stringJson = localStorage.getItem("issuegrid");
    if (!stringJson) return;
    try {
      const loaded = JSON.parse(stringJson) as IssueGridLocalStorage;
      if (loaded.requestState) {
        loaded.requestState = {
          ...loaded.requestState,
          take: current.userPrefs[0]?.issueGridLength || 15,
        };
      }

      if (loaded.createdAt) {
        if (loaded.createdAt.end)
          loaded.createdAt.end = new Date(
            Date.parse(loaded.createdAt.end.toString())
          );
        if (loaded.createdAt.start)
          loaded.createdAt.start = new Date(
            Date.parse(loaded.createdAt.start.toString())
          );
      }

      if (loaded.updatedAt) {
        if (loaded.updatedAt.end)
          loaded.updatedAt.end = new Date(
            Date.parse(loaded.updatedAt.end.toString())
          );
        if (loaded.updatedAt.start)
          loaded.updatedAt.end = new Date(
            Date.parse(loaded.updatedAt.start.toString())
          );
      }
      return loaded;
    } catch (err) {
      console.error("(issuegrid) Failed to parse LocalStorage JSON;\n{0}", err);
    }
  })();

  const userFilter: CompositeFilterDescriptor = {
    filters: [{ field: "responsibleId", operator: "eq", value: current.id }],
    logic: "or" as const,
  };
  const makeGroupFilter = (): CompositeFilterDescriptor => {
    const my_groups: number[] = current.groups.map((group) => group.id);
    my_groups.push(0);

    const groupFilterDescriptor: FilterDescriptor[] = [];
    my_groups.forEach((group) => {
      groupFilterDescriptor.push({
        field: "groupId",
        operator: "eq",
        value: group,
      });
    });
    return {
      filters: groupFilterDescriptor,
      logic: "or" as const,
    } as CompositeFilterDescriptor;
  };

  const defaultRequestState: DataSourceRequestState = {
    skip: 0,
    take: current.userPrefs[0]?.issueGridLength || 15,
    sort: [
      {
        field: "id",
        dir: "desc",
      },
    ],
    filter: {
      filters: [
        {
          field: "status",
          operator: "eq",
          value: "Open",
        },
      ],
      logic: "and" as const,
    },
  };

  const [requestState, setRequestState] = useState<DataSourceRequestState>(
    storage?.requestState || defaultRequestState
  );
  const [searchTerm, setSearchTerm] = useState<string>(
    storage?.searchTerm || ""
  );
  const [createdAtState, setCreatedAtState] = useState<SelectionRange>(
    storage?.createdAt || { start: null, end: null }
  );
  const [updatedAtState, setUpdatedAtState] = useState<SelectionRange>(
    storage?.updatedAt || { start: null, end: null }
  );
  const [selectedTag, setSelectedTag] = useState<ITag | null>(
    storage?.selectedTag || null
  );
  const [myCases, setMyCases] = useState<boolean>(storage?.myCases || false);
  const [involvedCases, setInvolvedCases] = useState<boolean>(
    storage?.involvedCases || false
  );
  const [groupCases, setGroupCases] = useState<boolean>(
    storage?.groupCases || false
  );

  const [viewAllTypes, setViewAllTypes] = useState<boolean>(
    storage?.viewAllTypes || false
  );

  const fetchGridColumns = () => {
    const request = localStorage.getItem("issueGridColumns");
    if (!request) return IssueGridColumns;
    return JSON.parse(request) as { title: string; show: boolean }[];
  };

  const [exportState, setExportState] = useState<ExcelExport>();
  const [exporting, setExportingState] = useState<boolean>();
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [caseViewState, setCaseViewState] = useState<number | null>(caseView);
  const [categories, setCategories] = useState<any[]>(tagCategories);

  const [columns, setColumns] = useState<{ title: string; show: boolean }[]>(
    fetchGridColumns()
  );
  const [rootCause, setRootCause] = useState<{
    name: string;
    statusId: number;
  }>(
    storage?.rootCause ||
      ({} as {
        name: string;
        statusId: number;
      })
  );
  const [oneVisibleColumn, setOneVisibleColumn] = useState<boolean>(false);

  useEffect(() => {
    if (columns.length !== IssueGridColumns.length) {
      let tempColumns = [...IssueGridColumns];
      tempColumns.forEach((igc) => {
        igc.show = columns.find((c) => c.title === igc.title)?.show ?? true;
      });
      setColumns(tempColumns);
    }
  }, [columns]);

  useEffect(() => {
    if (groups.length <= 0) fetchGroups();
    if (priorities.length <= 0) fetchPriorities();
    if (issuetypes.length <= 0) fetchIssueTypes();
    if (isInternal(current)) fetchTags();
    if (isInternal(current)) fetchTagCategories();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (requestState.filter !== undefined && caseView === 4) {
      const filters: FilterDescriptor[] = requestState.filter
        .filters as FilterDescriptor[];
      var typeIndex = filters.findIndex(
        (x) =>
          x.value === "Part Request" ||
          x.value === "HMS" ||
          x.value === "Orkel Direkte" ||
          x.value === "Administration" ||
          x.value === "Warranty" ||
          x.value === "Maintenance" ||
          x.value === "Orkel Direkte" ||
          x.value === "Case"
      );
      setCaseViewState(typeIndex > -1 ? null : caseView);
    } else {
      setCaseViewState(caseView);
    }
    if (caseView !== caseViewState) {
      setViewAllTypes(false);
      setRequestState(defaultRequestState);
      if (caseView === 5) {
        setMyCases(true);
      } else {
        setMyCases(false);
      }
    }
  }, [caseView]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const noCategoryTags = tags.filter((t) => t.categoryNames.length === 0);
    noCategoryTags.forEach((t) => {
      if (selectedTag !== null && selectedTag?.name === t.name) {
        t.selected = true;
      } else {
        t.selected = false;
      }
    });
    const categoriesWithTags = tagCategories.filter(
      (tc) => tc.tags.length > 0 && !tc.hide
    );
    categoriesWithTags.forEach((tc) => {
      tc.tags.sort((a, b) => {
        return ("" + a.name).localeCompare(b.name);
      });
      if (
        selectedTag !== null &&
        tc.tags.some((t) => selectedTag?.name === t.name)
      ) {
        tc.expanded = true;
      } else {
        tc.expanded = false;
      }
      tc.tags.forEach((t) => {
        if (selectedTag !== null && selectedTag?.name === t.name) {
          t.selected = true;
        } else {
          t.selected = false;
        }
      });
      tc.id = tc.id + 1000;
    });
    setCategories(() => [...categoriesWithTags, ...noCategoryTags]);
  }, [tags]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    () => {
      if (requestTimer) {
        clearTimeout(requestTimer);
      }
      let newRequestState = { ...requestState };
      if (newRequestState.filter?.filters === undefined) {
        newRequestState = {
          ...requestState,
          filter: {
            filters: [],
            logic: "and" as const,
          },
        };
        setRequestState(newRequestState);
      } else {
        if (myCases) {
          const filters = [...newRequestState.filter.filters];
          filters.push(userFilter);
          newRequestState = {
            ...requestState,
            filter: {
              filters: filters,
              logic: "and" as const,
            },
          };
        } else if (groupCases) {
          const filters = [...newRequestState.filter.filters];
          filters.push(makeGroupFilter());
          newRequestState = {
            ...requestState,
            filter: {
              filters: filters,
              logic: "and" as const,
            },
          };
        }
        setRequestTimer(
          setTimeout(() => {
            handleFetch(
              `${toDataSourceRequestString(
                myCases || groupCases ? newRequestState : requestState
              )}&term=${searchTerm}&allTypes=${
                caseView === 4 ? viewAllTypes : false
              }&SelectedTag=${
                selectedTag !== null && selectedTag?.name !== undefined
                  ? selectedTag?.name
                  : ""
              }` +
                (isTechnicalSupport(current)
                  ? "&caseView=8"
                  : caseViewState !== null
                  ? `&caseView=${JSON.stringify(caseViewState)}`
                  : "") +
                ((caseViewState === 2 && !isHrUser(current)) ||
                (caseViewState === 6 && !isHmsUser(current)) ||
                involvedCases
                  ? `&user=${current.id}`
                  : "")
            );
            const storage: IssueGridLocalStorage = {
              requestState: requestState,
              searchTerm: searchTerm,
              createdAt: createdAtState,
              updatedAt: updatedAtState,
              selectedTag: selectedTag,
              rootCause: rootCause,
              myCases: myCases,
              involvedCases: involvedCases,
              groupCases: groupCases,
              viewAllTypes: viewAllTypes,
            };
            localStorage.setItem("issuegrid", JSON.stringify(storage));
          }, 200)
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      requestState,
      searchTerm,
      caseViewState,
      myCases,
      groupCases,
      involvedCases,
      viewAllTypes,
    ] // eslint-enable-line react-hooks/exhaustive-deps
  );

  const resetFilter = () => {
    setCaseViewState(caseView);
    setCreatedAtState({ start: null, end: null });
    setUpdatedAtState({ start: null, end: null });
    setSearchTerm("");
    setSelectedTag(null);
    setViewAllTypes(false);
    setMyCases(false);
    setInvolvedCases(false);
    setGroupCases(false);
    setRootCause({} as { name: string; statusId: number });
    setRequestState(defaultRequestState);
  };

  const onCreatedAtChange = (value: Date | null, operator: string) => {
    const range = { ...createdAtState };
    if (operator === "gt") range.start = value;
    else range.end = value;
    setCreatedAtState(range);
  };

  const onUpdatedAtChange = (value: Date | null, operator: string) => {
    const range = { ...updatedAtState };
    if (operator === "gt") range.start = value;
    else range.end = value;
    setUpdatedAtState(range);
  };

  const onDateClear = (
    field: string,
    setDateState: (state: SelectionRange) => any
  ) => {
    const range = { ...createdAtState };
    range.start = null;
    range.end = null;
    setDateState(range);
    onDateSubmit(range, field);
  };

  const onDateSubmit = (dateState: SelectionRange, field: string) => {
    const updatedRequestState = { ...requestState };

    if (updatedRequestState.filter?.filters !== undefined) {
      const filters: FilterDescriptor[] = updatedRequestState.filter
        .filters as FilterDescriptor[];

      var gtIndex = filters.findIndex(
        (x) => x.field === field && x.operator === "gt"
      );
      if (gtIndex !== -1 && dateState.start !== null)
        filters[gtIndex].value = dateState.start;
      else if (gtIndex === -1 && dateState.start !== null)
        filters.push({ field: field, operator: "gt", value: dateState.start });
      else if (gtIndex !== -1 && dateState.start === null)
        filters.splice(gtIndex, 1);

      var ltIndex = filters.findIndex(
        (x) => x.field === field && x.operator === "lt"
      );
      if (ltIndex !== -1 && dateState.end !== null)
        filters[ltIndex].value = dateState.end;
      else if (ltIndex === -1 && dateState.end !== null)
        filters.push({ field: field, operator: "lt", value: dateState.end });
      else if (ltIndex !== -1 && dateState.end === null)
        filters.splice(ltIndex, 1);

      setRequestState(updatedRequestState);
    }
  };

  const handleIssuesExcelRequestChange = (exporter: ExcelExport) => {
    setExportState(exporter);
  };

  const handleExportClick = () => {
    const newState = { ...requestState };
    newState.take = undefined;
    newState.skip = undefined;

    setExportingState(true);
    const queryStr = `${toDataSourceRequestString(newState)}`;
    getRequest(
      `/issues/filtered?${queryStr}&term=${searchTerm}&SelectedTag=${selectedTag}&caseView=${JSON.stringify(
        caseViewState
      )}`
    ).then((response) => {
      const exportIssues: IIssueExport[] = response.json.data;
      exportIssues.forEach((i) => {
        i.joinedTagNames = i.tagNames.join(", ");
      });
      exportState?.save(exportIssues);
      setExportingState(false);
    });
  };

  const onLengthChanged = (event) => {
    const user = { ...current };
    user.userPrefs[0].issueGridLength = event.value ? event.value : 15;
    onUserEdit(user).then(() => {
      setRequestState({
        ...requestState,
        take: current.userPrefs[0].issueGridLength,
      });
    });
  };

  const handleSearchChange = (value: string) => {
    if (value !== searchTerm) {
      setSearchTerm(value);
      if (value !== "") {
        setRequestState({
          ...requestState,
          skip: 0,
        });
      }
    }
  };

  const onToggleColumn = (idx: number) => {
    let tempColumns = [...columns];
    tempColumns[idx] = { ...tempColumns[idx], show: !tempColumns[idx].show };
    setColumns(tempColumns);
    setOneVisibleColumn(tempColumns.filter((c) => c.show).length === 1);
    localStorage.setItem("issueGridColumns", JSON.stringify(tempColumns));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      if (issues.total === 1) {
        handleRowClick(issues.data[0]);
      } else {
        event.preventDefault();
      }
    }
  };

  const rootCauses: { name: string; statusId: number }[] = [
    { name: "None", statusId: 0 },
    { name: "Found", statusId: 1 },
    { name: "Not Found", statusId: 2 },
    { name: "Not Applicable", statusId: 3 },
  ];

  return (
    <div>
      <button
        title="My Cases"
        onClick={() => {
          setMyCases(!myCases);
          setInvolvedCases(false);
          setGroupCases(false);
        }}
        className="border-0 text-white float-start mt-0 ps-0 py-2"
        style={{ cursor: "pointer", background: "transparent" }}
      >
        <span
          style={{
            color: myCases ? "white" : "grey",
          }}
        >
          <FontAwesomeIcon icon={faUser} size="2x" />
        </span>
      </button>
      <button
        title="Cases involving me"
        onClick={() => {
          setInvolvedCases(!involvedCases);
          setMyCases(false);
          setGroupCases(false);
        }}
        className="border-0 text-white float-start mt-0 py-2"
        style={{ cursor: "pointer", background: "transparent" }}
      >
        <span
          style={{
            color: involvedCases ? "white" : "grey",
          }}
        >
          <FontAwesomeIcon icon={faUserCheck} size="2x" />
        </span>
      </button>
      {isInternal(current) && caseView !== 6 && (
        <button
          title={`${current.groups.map((group) => group.name)}`}
          onClick={() => {
            setGroupCases(!groupCases);
            setInvolvedCases(false);
            setMyCases(false);
          }}
          className="border-0 text-white float-start mt-0 py-2"
          style={{ cursor: "pointer", background: "transparent" }}
        >
          <span
            style={{
              color: groupCases ? "white" : "grey",
            }}
          >
            <FontAwesomeIcon icon={faUsers} size="2x" />
          </span>
        </button>
      )}
      <button
        title="Clear filter"
        onClick={() => resetFilter()}
        className="border-0 text-white float-end mt-0 py-2"
        style={{ cursor: "pointer", background: "transparent" }}
        disabled={
          searchTerm === "" &&
          _.isEqual(requestState, defaultRequestState) &&
          selectedTag === null &&
          !myCases &&
          !involvedCases &&
          !groupCases
        }
      >
        <span
          style={{
            color:
              searchTerm === "" &&
              _.isEqual(requestState, defaultRequestState) &&
              selectedTag === null &&
              !myCases &&
              !involvedCases &&
              !groupCases
                ? "grey"
                : "white",
          }}
        >
          <FontAwesomeIcon icon={faFilter} size="2x" />
        </span>
      </button>
      <SearchForm
        handleChange={handleSearchChange}
        searchTerm={searchTerm}
        placeholder="Search"
        handleEnterClick={handleKeyDown}
        disabled={false}
      />
      <DropdownButton
        id="dropdown-item-button"
        title="Grid Options"
        className="my-2"
        onToggle={() => setShowOptions(!showOptions)}
        onBlur={undefined}
        show={showOptions}
        style={{ width: "150px" }}
      >
        <Dropdown.ItemText onClick={() => setShowOptions(true)}>
          Columns
        </Dropdown.ItemText>
        {columns.map((column, idx) => {
          return (
            <Dropdown.Item
              key={column.title}
              as={Checkbox}
              value={column.show}
              onChange={() => {
                onToggleColumn(idx);
                setShowOptions(true);
              }}
              disabled={oneVisibleColumn && column.show}
              label={column.title}
              onClick={() => setShowOptions(true)}
            />
          );
        })}
      </DropdownButton>
      <ExcelExport
        data={issues.data}
        ref={handleIssuesExcelRequestChange}
        fileName={
          (caseView === 1
            ? "partrequest "
            : caseView === 2
            ? "administration "
            : caseView === 3
            ? "warranty "
            : caseView === 5
            ? "Orkel Direkte cases"
            : caseView === 6
            ? "HMS"
            : caseView === 7
            ? "Maintenance"
            : "case ") + "export.xlsx"
        }
      >
        <Grid
          {...requestState}
          {...issues}
          sortable
          filterable
          pageable
          onDataStateChange={(event: GridDataStateChangeEvent) => {
            setRequestState(event.dataState);
          }}
          onRowClick={(event: GridRowClickEvent) => {
            handleRowClick(event.dataItem);
          }}
          rowRender={(props, item) => {
            const propProps = { ...props.props };
            propProps.className = propProps.className + " cursor";
            return { ...props, props: { ...propProps } };
          }}
        >
          {columns.find((x) => x.title === "Id")?.show && (
            <Column
              field="id"
              title="Id"
              filter="numeric"
              width={undefined}
              key="Id"
            />
          )}
          {columns.find((x) => x.title === "Priority")?.show && (
            <Column
              field="priorityName"
              title="Priority"
              width={undefined}
              key="Priority"
              filterCell={(props) => {
                return DropDownFilterCell(
                  props,
                  priorities.map((p) => p.name)
                );
              }}
            />
          )}
          {columns.find((x) => x.title === "Title")?.show && (
            <Column field="title" title="Title" width={400} key="Title" />
          )}
          {columns.find((x) => x.title === "Responsible")?.show && (
            <Column
              field="responsibleFullName"
              title="Responsible"
              width={undefined}
              key="Responsible"
            />
          )}
          {columns.find((x) => x.title === "Status")?.show && (
            <Column
              field="status"
              title="Status"
              width={150}
              key="Status"
              filterCell={(props) => {
                return DropDownFilterCell(props, ["Open", "Closed"]);
              }}
            />
          )}
          {caseView !== 2 &&
            caseView !== 3 &&
            caseView !== 6 &&
            columns.find((x) => x.title === "Group")?.show && (
              <Column
                field="groupName"
                title="Group"
                width={undefined}
                key="Group"
                filterable={!groupCases}
                filterCell={(props) => {
                  return DropDownFilterCell(
                    props,
                    groups
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((g) => g.name)
                  );
                }}
              />
            )}
          {columns.find((x) => x.title === "Type")?.show && caseView === 4 && (
            <Column
              field="issueTypeName"
              title="Type"
              width={undefined}
              key="Type"
              filterCell={(props) => {
                return (
                  <div className="k-filtercell">
                    <DropDownList
                      {...props}
                      popupSettings={{ width: "auto" }}
                      data={[
                        "All",
                        ...issuetypes
                          .filter((it) =>
                            isAdmin(current)
                              ? true
                              : isHrUser(current)
                              ? it.id !== 6
                              : it.id !== 2 && it.id !== 6
                          )
                          .map((it) => it.name),
                      ]}
                      value={viewAllTypes ? "All" : props.value}
                      onChange={(event) => {
                        setCaseViewState(null);
                        if (event.target.value === "All") {
                          setViewAllTypes(true);
                          props.onChange({
                            value: "",
                            operator: "contains",
                            syntheticEvent: event.syntheticEvent,
                          });
                        } else {
                          setViewAllTypes(false);
                          props.onChange({
                            value: event.target.value,
                            operator: "eq",
                            syntheticEvent: event.syntheticEvent,
                          });
                        }
                      }}
                    />
                    {(props.value !== "" || viewAllTypes) && (
                      <button
                        title="Clear"
                        type="button"
                        className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-icon-button"
                        onClick={(event) => {
                          event.preventDefault();
                          setViewAllTypes(false);
                          props.onChange({
                            value: "",
                            operator: "",
                            syntheticEvent: event,
                          });
                          setCaseViewState(caseView);
                        }}
                      >
                        <span className="k-svg-icon k-svg-i-filter-clear k-button-icon">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path d="m143.5 64 168.2 168.2L288 256v160l-64 64V256L64 96V64h79.5zm236.1 100.4L448 96V64H279.3l-64-64L192 22l298 298 22-23.3-132.4-132.3z"></path>
                          </svg>
                        </span>
                      </button>
                    )}
                  </div>
                );
              }}
            />
          )}
          {columns.find((x) => x.title === "Tag")?.show && isInternal(current) && (
            <Column
              field="joinedTagNames"
              title="Tag"
              width={undefined}
              key="Tag"
              sortable={false}
              cell={(props) => {
                return <td>{props.dataItem.tagNames.join(", ")}</td>;
              }}
              filterCell={(props) => {
                return (
                  <TagDropDownTree
                    tags={categories}
                    selectedTag={selectedTag}
                    requestState={requestState}
                    setSelectedTag={setSelectedTag}
                    setRequestState={setRequestState}
                  />
                );
              }}
            />
          )}
          {columns.find((x) => x.title === "Root Cause")?.show &&
            isInternal(current) && (
              <Column
                field="rootCauseStatusId"
                title="root cause"
                filter="numeric"
                key="root cause"
                cell={(props) => {
                  return (
                    <td>
                      {
                        rootCauses.find(
                          (rc) =>
                            rc.statusId === props.dataItem.rootCauseStatusId
                        )?.name
                      }
                    </td>
                  );
                }}
                filterCell={(props) => {
                  return (
                    <div className="k-filtercell">
                      <DropDownList
                        {...props}
                        popupSettings={{ width: "auto" }}
                        data={rootCauses}
                        textField="name"
                        dataItemKey="statusId"
                        value={rootCause}
                        onChange={(event) => {
                          props.onChange({
                            value: event.target.value.statusId,
                            operator: "eq",
                            syntheticEvent: event.syntheticEvent,
                          });
                          setRootCause(event.target.value);
                        }}
                      />
                      {props.value !== null && (
                        <button
                          className="k-button k-button-icon"
                          title="Clear"
                          onClick={(event) => {
                            event.preventDefault();
                            props.onChange({
                              value: "",
                              operator: "",
                              syntheticEvent: event,
                            });
                            setRootCause(
                              {} as { name: string; statusId: number }
                            );
                          }}
                        >
                          <span className="k-icon k-i-filter-clear" />
                        </button>
                      )}
                    </div>
                  );
                }}
              />
            )}
          {columns.find((x) => x.title === "Created By")?.show && (
            <Column
              field="userFullName"
              title="Created By"
              width={undefined}
              key="Created By"
            />
          )}
          {columns.find((x) => x.title === "Number Of Bales")?.show &&
            caseView === 3 && (
              <Column
                field="numberOfBales"
                title="Number Of Bales"
                filter="numeric"
                width={undefined}
                key="Number Of Bales"
              />
            )}
          {columns.find((x) => x.title === "test")?.show && caseView === 3 && (
            <Column
              field="numberOfBales"
              title="Number Of Bales"
              filter="numeric"
              width={undefined}
              key="Number Of Bales"
            />
          )}
          {columns.find((x) => x.title === "Created")?.show && (
            <Column
              field="createdAt"
              title="Created"
              filter="date"
              width={undefined}
              key="Created"
              cell={(props) => <DateCell {...props} />}
              columnMenu={(prop) => {
                const dateProp: IDateTimeMenuProps = {
                  ...prop,
                  rangedValue: createdAtState,
                  onDateTimeChange: onCreatedAtChange,
                  onDateTimeClear: () => {
                    onDateClear("createdAt", setCreatedAtState);
                  },
                  onSubmit: () => {
                    onDateSubmit(createdAtState, "createdAt");
                  },
                };
                return DateTimeRangeMenu(dateProp);
              }}
              filterCell={(prop) => {
                return (
                  <Aux>
                    {(createdAtState.start != null ||
                      createdAtState.end != null) && (
                      <button
                        className="k-button k-button-icon"
                        title="Clear"
                        onClick={(event) => {
                          event.preventDefault();
                          onDateClear("createdAt", setCreatedAtState);
                        }}
                      >
                        <span className="k-icon k-i-filter-clear" />
                      </button>
                    )}
                  </Aux>
                );
              }}
            />
          )}
          {columns.find((x) => x.title === "Updated")?.show && (
            <Column
              field="updatedAt"
              title="Updated"
              filter="date"
              width={undefined}
              key="Updated"
              cell={(props) => <DateCell {...props} />}
              columnMenu={(prop) => {
                const dateProp: IDateTimeMenuProps = {
                  ...prop,
                  rangedValue: updatedAtState,
                  onDateTimeChange: onUpdatedAtChange,
                  onDateTimeClear: () => {
                    onDateClear("updatedAt", setUpdatedAtState);
                  },
                  onSubmit: () => {
                    onDateSubmit(updatedAtState, "updatedAt");
                  },
                };
                return DateTimeRangeMenu(dateProp);
              }}
              filterCell={(prop) => {
                return (
                  <Aux>
                    {(updatedAtState.start !== null ||
                      updatedAtState.end !== null) && (
                      <button
                        className="k-button k-button-icon"
                        title="Clear"
                        onClick={(event) => {
                          event.preventDefault();
                          onDateClear("updatedAt", setUpdatedAtState);
                        }}
                      >
                        <span className="k-icon k-i-filter-clear" />
                      </button>
                    )}
                  </Aux>
                );
              }}
            />
          )}
        </Grid>
      </ExcelExport>
      <div className="my-3">
        <div className="float-end d-inline-flex">
          <div>
            {"Page length: "}
            <DropDownList
              data={[5, 10, 20, 40, 80]}
              value={current.userPrefs[0].issueGridLength}
              onChange={onLengthChanged}
            />
          </div>
        </div>
        {isInternal(current) && (
          <div style={{ width: "170px" }}>
            <Button
              title="Export Excel"
              className="k-button k-primary"
              onClick={handleExportClick}
            >
              Export to Excel
            </Button>
            {exporting && (
              <FontAwesomeIcon
                icon={faSpinner}
                size="2x"
                style={{ float: "right" }}
                spin
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => ({
  issuetypes: state.issueTypesReducer.issuetypes,
  groups: state.groupsReducer.groups,
  priorities: state.prioritiesReducer.priorities,
  tags: state.tagReducer.tags,
  tagCategories: state.tagCategoryReducer.tagCategories,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchGroups: () => dispatch(fetchGroups()),
  fetchPriorities: () => dispatch(fetchPriorities()),
  fetchIssueTypes: () => dispatch(fetchIssueTypes()),
  fetchTags: () => dispatch(fetchTags()),
  fetchTagCategories: () => dispatch(fetchTagCategories()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IssueGrid);
