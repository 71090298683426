import {
  TagCategoryActionsTypes,
  TAG_CATEGORY_REQUEST_FAILURE,
  FETCH_TAG_CATEGORIES_SUCCESS,
  FETCH_TAG_CATEGORY_SUCCESS,
  TAG_CATEGORY_REQUEST_ERRORS,
  CREATE_TAG_CATEGORY_SUCCESS,
  EDIT_TAG_CATEGORY_SUCCESS,
} from "../actions/tagCategory";
import { ITagCategory } from "../../models/tag";

export interface TagCategoryState {
  readonly tagCategories: ITagCategory[];
  readonly tagCategory: ITagCategory;
  readonly errors: any;
}

export const initialState: TagCategoryState = {
  tagCategories: [],
  tagCategory: {} as ITagCategory,
  errors: null,
};

const tagCategoryReducer = (
  state = initialState,
  action: TagCategoryActionsTypes
): TagCategoryState => {
  switch (action.type) {
    case FETCH_TAG_CATEGORIES_SUCCESS:
      return { ...state, tagCategories: action.tagCategories };
    case TAG_CATEGORY_REQUEST_FAILURE:
      return { ...state, errors: action.errors };
    case FETCH_TAG_CATEGORY_SUCCESS:
      return { ...state, tagCategory: action.tagCategory };
    case TAG_CATEGORY_REQUEST_ERRORS:
      return { ...state, errors: action.errors };
    case CREATE_TAG_CATEGORY_SUCCESS:
      return {
        ...state,
        tagCategory: action.tagCategory,
        tagCategories:
          state.tagCategories.length > 0
            ? [...state.tagCategories.concat([action.tagCategory])]
            : [],
      };
    case EDIT_TAG_CATEGORY_SUCCESS:
      if (state.tagCategories.length > 0) {
        state.tagCategories.splice(
          state.tagCategories.findIndex((t) => t.id === action.tagCategory.id),
          1,
          action.tagCategory
        );
      }
      return {
        ...state,
        tagCategory: action.tagCategory,
        tagCategories:
          state.tagCategories.length > 0 ? state.tagCategories : [],
      };
    default:
      return state;
  }
};

export default tagCategoryReducer;
